import React, { useState } from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert/Alert";

import { useSearchImageMutation } from "../../slices/api/unsplashApiSlice";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Grid from "@mui/material/Grid";
import mediaTypes from "../../utils/mediaTypes";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TemplatePreview from "../templatePreview/TemplatePreview";


const UnplashSearch = ({media, handleAddUnsplash, removeUnsplashImage, openUnsplashSearch, updateOptionSelected, handleCloseEmbedImage}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [list, setList] = useState([]);
  const [errMsg, setErrMsg] = useState('');

  const [searchImage] = useSearchImageMutation();

  const handleSearchTermChange = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
    setList([])
  };

  const onSubmit = async (e) => {
    e?.preventDefault();
    await fetchImages();
  };

  const handleCancel = (e) => {
    e?.preventDefault();
    openUnsplashSearch();
    updateOptionSelected('')
  }

  function handleRemoveUnsplashImage() {
    removeUnsplashImage()
  }
  const fetchImages = async () => {
    try {
      const searchResponse = await searchImage(`${searchTerm}}}`).unwrap();
      setList((prevImgs) => [...prevImgs, ...searchResponse.images]);
    } catch (err) {
      if(err.status=== 400) {
        setErrMsg('Enter a search term');
      } else if (err.status === 401) {
        setErrMsg('Not authorized');
      } else {
        setErrMsg('Something failed')
      }
    }
  };

  let ImagesList = (
    <React.Fragment>
      <Box
        component="div"
        sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: {xs: 'space-between', sm: 'flex-start'}, gap: '10px', padding: '0', marginTop: '20px'}}>
        {list.map(image => {
          return (
            <Box sx={{
              width: {
                xs: '146px',
                sm: '120px',
              },
            }}
                 key={image.id}
            >
              <Box

                onClick={(e) => {
                  e?.preventDefault();
                  handleCloseEmbedImage()
                  handleAddUnsplash(image)
                }}
                component="div"
                sx={{
                  width: {
                    xs: '146px',
                    sm: '120px',
                  },
                  height: {
                    xs: '146px',
                    sm: '120px',
                  },
                  borderRadius: '14px',
                  backgroundImage: `url(${image.photoSmallS3})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  textAlign: 'center',
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}>

              </Box>
              <Typography onClick={() => {
                if(image?.user?.url){
                  return window.open(`${image?.user?.url}?utm_source=recognitionlink_production&utm_medium=referral`, '_blank').focus();
                }
                return null;
              }} sx={{ fontSize: '11px', lineHeight: '16px','&:hover': {cursor: 'pointer'}}}>By {image?.user?.name}</Typography>
            </Box>
          )
        })}
      </Box>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>

          <Box
            component="form"
            onSubmit={onSubmit}
            sx={{mb:1}}
          >
            {media.type !== mediaTypes.USER_IMAGE &&
            <>
            {media.type === mediaTypes.UNSPLASH_IMAGE &&
              <Box sx={{ width: '100%', position: 'relative'}}>
                <img width="100%" src={media?.data[0]?.fileUrl} alt={media?.data[0]?.unsplash?.altDescription} />
                <Box
                  onClick={handleRemoveUnsplashImage}
                  sx={{
                    '&:hover':{ cursor: 'pointer'},
                    width: '30px',
                    height: '30px',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'rgba(255,255,255,0.8)',
                    borderRadius: '50%',
                    marginRight: '5px',
                    marginTop: '5px', textAlign:'center'}}>
                  <DeleteForeverIcon sx={{ color: 'primary.main', marginTop: '2px'}} />
                </Box>
              </Box>
            }
              <Typography variant="body2" align="left" sx={ { ml:0.5, mt:2, marginBottom:'10px' } } >
                Search millions of free images from Unsplash.
              </Typography>
              { errMsg ? <Alert sx={{mb:1}} severity="error">{errMsg}</Alert> : ''}
              <TextField
                name="imagesearch" fullWidth
                id="searchterm"
                value={searchTerm}
                onChange={handleSearchTermChange}
                sx={{mt:1}}
              />

              <Box display="flex" justifyContent="flex-end" sx={{mt:1}}>
                <Button color="primary" variant="contained" onClick={onSubmit} disabled={!(searchTerm.length > 1)}>
                  Search Images
                </Button>
              </Box>
              <Typography variant="caption">Powered by Unsplash</Typography>
            </>
            }
            {media.type === mediaTypes.USER_IMAGE &&
              <Typography variant="body2">Please remove the uploaded image(s) so that you can search for free images</Typography>
            }
          </Box>
        </Grid>
      </Grid>
      {/*If results, show them*/}
      {list.length > 0 &&
        <Grid container sx={{maxHeight: 500, overflow: "scroll"}}>
          <Grid item xs={12}>
            <Typography variant="body2" align="left" sx={ { ml:0.5, mt:2 } } >
              Tap/Click on the image to add it to your post
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {ImagesList}
          </Grid>
        </Grid> }
    </React.Fragment>
  )
};

export default UnplashSearch;