import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import PurplePill from "../../components/purplePill/PurplePill";
import TitleText from "../../components/titleText/TitleText";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Loader} from "@giphy/react-components";
import {useConfirmReplyQuery} from "../../slices/api/replyApiSlice";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import BlackSection from "../home/blocks/BlackSection";

export default function ConfirmReplyPage(){
  const {token} = useParams();
  const navigate = useNavigate();
  const {
    data: confirmation,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useConfirmReplyQuery(token)


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return(
    <Box sx={{minHeight: {xs:'calc(100vh - 70px)', md: 'calc(100vh - 100px)'} }}>
      <Box sx={{
        backgroundImage: `url(https://storage.googleapis.com/prod-website-images-rlink/blue-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/red-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/orange-vector.svg)`,
        backgroundPosition: 'top right, top 20% left, bottom right',
        backgroundRepeat: 'no-repeat',
        minHeight: {xs:'calc(100vh - 70px)', md: 'calc(100vh - 100px)'}
      }}>

        <Grid maxWidth="xl" container sx={{minHeight:'100%',padding: { xs: '64px 30px 40px 30px', sm: '64px 30px 20px 30px', md: '58px 104px', lg:'90px 120px', xl: '100px 200px'},margin: '0 auto'}}>
          <Grid item xs={12} >
            <PurplePill>Hello</PurplePill>
            {isLoading && <Box sx={{textAlign:'center'}}>
              <TitleText align="center">Sending your reply</TitleText>
              <Loader />
            </Box>}

            {isSuccess &&
              <Box>
                <Box sx={{ textAlign: 'center'}}>
                  <TitleText align="center">Reply sent</TitleText>
                  <img className="icon-png" src="https://storage.googleapis.com/prod-website-images-rlink/multiple/congrats-icon.png" alt="congrats icon" />
                  <Typography variant="body1">Reply sent successfully. People you selected will be receiving an email with a link to your message</Typography>
                  <Button onClick={() => navigate(`/card/${confirmation.cardId}/mycard`)} sx={{ maxWidth:'200px', margin:'20px auto'}} variant="outlined" color={"secondary"}>Go to card</Button>
                </Box>
                <BlackSection title="Want to create a group card?" subtitle="View designs. Create your first card for free. No registration required." />
              </Box>
            }
            {isError &&
            <Box>
              <Box sx={{ textAlign: 'center'}}>
                <TitleText align="center">{error?.data?.error}</TitleText>
                {error?.data?.cardId && <Button onClick={() => navigate(`/card/${error.data.cardId}/mycard`)} sx={{ maxWidth:'200px', margin:'20px auto'}} variant="outlined" color={"secondary"}>Go to card</Button>}
              </Box>
              <BlackSection title="Want to create a group card?" subtitle="View designs. Create cards for free. No registration required." />

            </Box>
            }
          </Grid>
        </Grid>
      </Box>
    </Box>

  )
}