import { apiSlice } from "./apiSlice";

export const messageApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    reorderMessages: builder.mutation({
      query: (body) => {
        return {
          url: `/messages/reorder`,
          method: 'PUT',
          body: {...body}
        }
      },
      invalidatesTags: ['Messages'],
    })
  })
});

export const { useReorderMessagesMutation } = messageApiSlice ;