import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import { templateSelected, categoryUpdated, titleUpdated } from "../../slices/cardSlice";
import {linkPaths} from "../../utils/linkPaths";


export default function TemplatePreview({template, category}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if(!template) return null;

  function handleTemplateSelection() {
    const templateSel= Object.assign({}, template);
    //TODO: check on this, needs to be fixed in database
    templateSel.isCustomBackgroundImage = false;
    delete templateSel.categories;
    delete templateSel.createdAt;
    delete templateSel.updatedAt;
    delete templateSel.id;
    delete templateSel.title;
    dispatch(templateSelected(templateSel));
    dispatch(categoryUpdated({title: category.title, id: category.id}));
    navigate(linkPaths.createCardSettings.path )
  }

  return (
    <Box
      data-testid={template.id} id={template.id}
      onClick={() => { handleTemplateSelection() }}
      component="div"
      sx={{
        width: {
          xs: '154px',
          sm: '180px',
          md: '180px',
          lg: '220px'
        },
        height: {
          xs: '148px',
          sm: '172px',
          md: '170px',
          lg: '210px'
        },
        borderRadius: '14px',
        backgroundColor: template.cardBackgroundColor,
        backgroundImage: template.cardBackgroundImageThumbnail ? `url(${template.cardBackgroundImageThumbnail})` : `url(${template.cardBackgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        textAlign: 'center',
        '&:hover': {
          cursor: 'pointer'
        }
    }}>
      {/*<Typography component="p" variant="caption" sx={{color: template.cardTitleColor, paddingTop: '50%', fontWeight: '700', fontSize:'80%'}}>{template.title}</Typography>*/}
    </Box>
  )
}