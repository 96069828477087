import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import VideoPlayer from "../../components/videoPlayer/VideoPlayer";
import HighlightsHome from "../../components/highlights/HighlightsHome";
import {useNavigate} from "react-router-dom";
import {linkPaths} from "../../utils/linkPaths";
import HowItWorksHR from "./HowItWorksHR";
import PurplePill from "../../components/purplePill/PurplePill";
import TitleText from "../../components/titleText/TitleText";
import {BondsIcon, EcoFriendlyIcon, UnforgetableIcon} from "../../svgIcons/icons";
import ContactForm from "../../components/contactForm/ContactForm";
import WhatEmployeesGet from "./WhatEmployeesGet";
import ReviewsReel from "../../components/reviewsReel/ReviewsReel";

export default function HRPage(){
  const videoDemo = "https://storage.googleapis.com/prod-website-images-rlink/videos/hr-solution.mp4";
  const navigate = useNavigate();

  function goCreateCard() {
    navigate(linkPaths.create.path)
  }

  const highlights = [
    'Ideal for remote and overseas employees',
    'Seamlessly add monetary rewards, wherever they are',
    'Automated group card creation for work anniversaries and birthdays',
    'No extra steps required for managers or employees',
  ]
  return (<>
    <Box sx={{
      backgroundImage: `url(https://storage.googleapis.com/prod-website-images-rlink/blue-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/red-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/orange-vector.svg)`,
      backgroundPosition: 'top right, top 20% left, bottom right',
      backgroundRepeat: 'no-repeat'
    }}>
      <Box sx={{
        width:'100%',
        background: `url('https://storage.googleapis.com/prod-website-images-rlink/hero_blue_blur_top_right.svg'), 
      url('https://storage.googleapis.com/prod-website-images-rlink/hero_red_blur.svg'), 
      url('https://storage.googleapis.com/prod-website-images-rlink/hero_orange_blur.svg')`,
        backgroundSize: {
          xs:'280px, 200px,100px',
          sm:'160px, 160px,0',
          md:'240px, 200px,80px',
          lg:'240px, 250px,200px',
        },
        backgroundPosition: {
          xs: `top -20px right,  bottom   left,  bottom right`,
          sm: `top  right,bottom left,bottom right`,
          md: `top  right,bottom left,bottom right`,
          lg: `top  right,bottom left,bottom right`,

        },
        backgroundRepeat: 'no-repeat',
      }}>
        <Grid maxWidth="xl" container sx={{
          margin: '0 auto',
          padding: {
            xs: '20px 17px 20px 17px',
            sm: '40px 17px 0 17px',
            md: '30px 17px 40px 17px',
            xl: '60px 20px 60px 20px'
          },
        }}>
          <Grid item xs={12} sm={7} md={7} lg={6}>
            {/*<Typography variant="h1" sx={{ marginBottom: '20px', letterSpacing:{ xs: '-0.72px', md: '-0.96px'}, fontSize:{ xs: '36px', md: '48px'}, lineHeight:{ xs: '44px', md: '60px'} }}> Unite and Celebrate <Box component="span" sx={{ color: '#7F56D9'}}>Joyful Moments</Box> with Group Cards</Typography>*/}
            <Box sx={{padding:{sm:'0px 20px 0 30px', md:'40px 20px 0 30px', lg:'60px 20px 0 80px' }  }}>
              <Typography variant="h1" sx={{ marginBottom: '20px', letterSpacing:{ xs: '-0.72px', md: '-0.96px'}, fontSize:{ xs: '36px', md: '48px'}, lineHeight:{ xs: '50px', md: '60px'} }}>  Effortless <Box component="span" sx={{ color: '#7F56D9'}}>Rewards and Recognition</Box> for Global Teams</Typography>
              <Typography variant="subtitle2" sx={{ margin:{ xs: '24px 0 28px 0', sm: '15px 0 20px 0'} }}>Plug-and-Play Solution to Celebrate, Recognize, and Reward Your Distributed Workforce</Typography>
              <Box sx={{ display: {xs:'block', sm:'none'}, margin: '0 auto 30px auto', width: { xs: '300px' }, position: 'relative' }}>
                <VideoPlayer
                  coverImage={true}
                  width="300px"
                  height="534px"
                  src={videoDemo}/>
              </Box>
              <HighlightsHome highlights={highlights} />
              {/*<Box component="div" sx={{ display: 'flex', flexDirection:{ xs: 'column', md: 'row'}, marginTop:'20px'  }}>*/}
              {/*  <Button onClick={() => { window.open(`${getCurrentDomain()}/card/66385f00e5448b718021fc6b/demo`, "_self")}} variant="outlined" color="secondary" sx={{ display:'flex', gap: '5px', maxWidth:{xs: '100%', sm: '200px'}, width:{ xs: '100%'}, height:{ sm: '48px'}, marginRight:{ sm: '16px'}, padding: {xs: '12px 20px', sm: '12px 20px'},*/}
              {/*  }}><PlayCircleOutlineIcon  /> View Demo Card</Button>*/}
              {/*  /!*<Button*!/*/}
              {/*  /!*  id="create-group-card-hero-button"*!/*/}
              {/*  /!*  onClick={goCreateCard} variant="contained" color="primary"*!/*/}
              {/*  /!*  sx={{*!/*/}
              {/*  /!*    fontWeight: 700,*!/*/}
              {/*  /!*    padding: {xs: '12px 20px', sm: '12px 20px'},*!/*/}
              {/*  /!*    maxWidth: { xs: '100%', sm: '200px'},*!/*/}
              {/*  /!*    marginBottom: '48px' }}>Create group card</Button>*!/*/}
              {/*</Box>*/}
            </Box>
          </Grid>
          <Grid item sm={5} md={5} lg={6}>
            <Box sx={{ display: {xs:'none', sm:'block'}, margin: '0 auto 30px auto', width: { xs: '300px', sm:'100%' }, maxWidth:'300px', position: 'relative' }}>
              <VideoPlayer
                coverImage={true}
                width="300px"
                height="534px"
                src={videoDemo}/>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
    <Grid maxWidth="xl" container sx={{
      margin: '0 auto',
      padding: {
        xs: '20px 20px 20px 20px',
        sm: '40px 17px 0 17px',
        md: '60px 40px 40px 40px',
        xl: '60px 40px 60px 40px',
        textAlign: 'center'
      },
    }}>
      <Grid item xs={12}>
        <Typography variant="subtitle2"  >In today’s competitive business environment, employee morale and engagement are critical to success. Our Digital Group Cards are designed to help businesses celebrate achievements, recognize hard work, and connect teams, fostering a positive and productive workplace culture.</Typography>
      </Grid>
    </Grid>
    <HowItWorksHR />
    <WhatEmployeesGet />
    <Grid container maxWidth="xl" sx={{padding: { xs: '40px 17px'}, margin:{xs: '36px auto 0 auto', md: '33px auto 0 auto'} }}>
      <Grid item xs={12} sx={{margin: '0 0 30px 0'}}>
        <PurplePill>Benefits</PurplePill>
        <TitleText align="center">Why Digital Group Cards</TitleText>
        <Box component="div" sx={{width: {xs: '100%', sm: '90%', md: '80%', lg: '60%'}, margin: '0 auto' }}>
          <Typography variant="subtitle2" sx={{ textAlign:{ xs: 'center'} }}>Digital group cards are accepted in all cultures, easy to use, and they become an eternal shared experience without physical barriers. You can allow everyone to express their heartfelt messages from anywhere they might be.</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display:{ md: 'none'}, width: '100%', maxWidth: '500px', margin: '50px auto'}}>
          <img width="100%" src="https://storage.googleapis.com/prod-website-images-rlink/home-page/small-ipad-view.png" alt="group card in ipad"/>
        </Box>
        <Box sx={{
          background: `url('https://storage.googleapis.com/prod-website-images-rlink/left_red_arrow.png'),url('https://storage.googleapis.com/prod-website-images-rlink/right_blue_arrow.png'),url('https://storage.googleapis.com/prod-website-images-rlink/home-page/small-ipad-view.png')`,
          backgroundSize: {xs: '100px, 100px, 100%', md: 'auto, 188px, 580px'},
          backgroundPosition:'bottom left 65px, bottom right 10px, center bottom',
          backgroundRepeat: 'no-repeat',
          width: '900px',
          height: '460px',
          margin: '0 auto 20px',
          display: {xs: 'none', md:'block'},
        }}
        ></Box>
      </Grid>
      <Grid item xs={12} sm={6}  sx={{ textAlign: 'center', marginBottom:'30px', padding:{xs: '10px', sm: '20px', md: '30px'} }}>
        <UnforgetableIcon />
        <Typography variant="subtitle1" sx={{ fontWeight: 500}}>1. Celebrate Achievements</Typography>
        <Box sx={{ width:{ xs: '100%', lg: '80%' }, margin: '0 auto' }}>
          <Typography variant="body2">Recognize work anniversaries, birthdays, and milestones with personalized messages, photos, and videos. Show your employees that you value their contributions and care about their special moments.</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}  sx={{ textAlign: 'center', marginBottom:'30px', padding:{xs: '10px', sm: '20px', md: '30px'} }}>
        <UnforgetableIcon />
        <Typography variant="subtitle1" sx={{ fontWeight: 500}}>2. Strengthen Team Bonds</Typography>
        <Box sx={{ width:{ xs: '100%', lg: '80%' }, margin: '0 auto' }}>
          <Typography variant="body2">Encourage team members to share their thoughts and well-wishes, creating a stronger sense of community and belonging. Our digital group cards make it easy for everyone to participate, regardless of location.</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}  sx={{ textAlign: 'center', marginBottom:'30px', padding:{xs: '10px', sm: '20px', md: '30px'} }}>
        <UnforgetableIcon />
        <Typography variant="subtitle1" sx={{ fontWeight: 500}}>3. Enhance Employee Engagement</Typography>
        <Box sx={{ width:{ xs: '100%', lg: '80%' }, margin: '0 auto' }}>
          <Typography variant="body2">Engaged employees are more productive and committed to their work. By regularly recognizing and celebrating your team, you can boost overall engagement and satisfaction.</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}  sx={{ textAlign: 'center', marginBottom:'30px', padding:{xs: '10px', sm: '20px', md: '30px'} }}>
        <UnforgetableIcon />
        <Typography variant="subtitle1" sx={{ fontWeight: 500}}>4. Streamline Recognition Processes</Typography>
        <Box sx={{ width:{ xs: '100%', lg: '80%' }, margin: '0 auto' }}>
          <Typography variant="body2">Our platform automates the creation and distribution of digital group cards for work anniversaries and birthdays, making it easier for managers to recognize their teams without adding extra steps to their routine.</Typography>
        </Box>
      </Grid>
    </Grid>


    <Grid container maxWidth="xl" sx={{padding: { xs: '40px 17px'}, margin:{xs: '56px auto 0 auto', md: '53px auto 0 auto'} }}>
      <Grid item xs={12} sx={{margin: '0 0 30px 0'}}>
        <PurplePill>Pricing</PurplePill>
        <TitleText align="center">Invest in Employee Recognition and Reap the Rewards</TitleText>
        <Box component="div" sx={{width: {xs: '100%', sm: '90%', md: '80%', lg: '60%'}, margin: '0 auto' }}>
          {/*<Typography variant="subtitle2" sx={{ textAlign:{ xs: 'center'}, marginBottom: '30px' }}>Invest in Employee Recognition and Reap the Rewards.</Typography>*/}
          <Typography variant="subtitle2" sx={{ textAlign:{ xs: 'center'} }}>Our pricing is designed to be flexible and scalable to fit the size of your company.  Ensure that every member of your company feels valued and appreciated.</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ContactForm subject="HR tooling" />
      </Grid>
    </Grid>
  </>)
}