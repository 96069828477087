import Box from "@mui/material/Box";
import TextMessageArea from "./messageBlocks/TextMessageArea";
import AuthorArea from "./messageBlocks/AuthorArea";
import Media from "./messageBlocks/Media";
import EditMessageMenu from "./messageBlocks/EditMessageMenu";
import PushPinIcon from '@mui/icons-material/PushPin';
import {ensureReadableTextColor} from "../../utils/functions";
import {useMemo} from "react";

export default function Message({fake = false,message, preview = false, card, messages = []}){
  const textColor = useMemo(() => ensureReadableTextColor(card.template.messageBackgroundColor, card.template.messageTextColor),
    [card.template.messageBackgroundColor, card.template.messageTextColor]);

  if(!message) return null;
  /****
   Menu:
      - If you created the message, show the EditMessageMenu
     - If you did create the message, but created the card, show the EditMessageAsCardCreatorMenu
     - If you are not the creator of the message and not the creator of the card, return nothing
     ***/
  function getMenu() {
    if(preview) return null;
    if(fake) return null;

    if(message?.canEdit || card?.canEdit) {
      return <EditMessageMenu messages={messages} message={message} canEditCard={card?.canEdit} />
    }


    return null;
  }


  return (
  <Box sx={{ width:'100%', background:card.template.messageBackgroundColor, borderRadius: '8px', boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)', position: 'relative'}}>
    {message?.pinned && card?.canEdit &&<Box  sx={{ position: 'absolute', zIndex: 1, right: '56px', top: '-10px'}}><Box sx={{background: '#FFF', borderRadius: '50%', width:'20px', height: '20px', textAlign:'center'}}><PushPinIcon sx={{ color: '#53389E', width: '16px'}} /></Box></Box>}
    { getMenu() }
    <Media message={message}/>
    <TextMessageArea card={card} message={message} textColor={textColor} />
    <AuthorArea card={card} message={message} textColor={textColor} />
  </Box>);
}