import Box from "@mui/material/Box";
import * as React from "react";
import TransitionComponent from "../transitionComponent/TransitionComponent";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import UpgradeBox from "../upgradeBox/UpgradeBox";
import Typography from "@mui/material/Typography";
import {CloseIcon} from "../../svgIcons/icons";


export default function UpgradeAccountDialog({open,closeDialog}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  function handleClose() {
    closeDialog();
  }


  return(
    <Dialog
      TransitionComponent={TransitionComponent}
      fullScreen={fullScreen}
      maxWidth="lg"
      onClose={handleClose} open={open} >
      <>
        <Box sx={{ padding: '30px', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{display:'flex', justifyContent:'flex-end'}}><CloseIcon cb={handleClose} color="#9E77ED" /></Box>
          <Typography variant="h3">Upgrade to Create Unlimited Cards</Typography>
          <Typography variant="body2" sx={{ margin: '10px 0'}}>This is a yearly subscription that allows you to create unlimited cards, with all the features and with up to 250 messages per card. Ideal for leaders and managers.</Typography>
          <UpgradeBox planCategorySelected='subscription' />
        </Box>
      </>
    </Dialog>)
}