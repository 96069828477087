import Button from "@mui/material/Button";

export default function AddMediaButton({icon, text, onClick, disabled}) {
  return (
    <Button
      disabled={disabled}
      onClick={() => onClick(true)}
      variant='contained'
      color="secondary">
      <span style={{paddingRight: '0', paddingTop: '5px'}}>{icon}</span> {text}
    </Button>
  )
}