import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { colorsPallete } from '../../../utils/colors';

export default function ConfirmDelete({closeModal}) {
  return(
    <Box sx={{textAlign: 'center'}}>
      <Typography variant='h3'>Are you sure you want to delete?</Typography>
      <Typography variant='body2'>Reminders are a great way to not miss important occasions.</Typography>
      <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', gap: '20px', marginTop: '20px'}}>
        <Button onClick={closeModal} variant='outlined' color="secondary">Cancel</Button>
          <Button variant='contained' sx={{ 
            background: colorsPallete.errorText,
            '&:hover': {
              background: colorsPallete.errorText,
            }
            }}>Delete</Button>
      </Box>
    </Box>
  )
}