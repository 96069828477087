import {Alert} from "@mui/material";
import {useEffect, useState} from "react";

export default function InfoAlert({severity, message, callback}){
  const [show, setShow] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false)
      if(callback) {
        callback()
      }
    },3000)

    return () => {
      clearTimeout(timer)
    }
  }, []);

  if(!message) return null;

  if(show) {
    return <Alert sx={{marginBottom: '20px'}} severity={severity}>{message}</Alert>
  } else {
    return null
  }
}