import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TransitionComponent from "../../../components/transitionComponent/TransitionComponent";
import DialogContent from "@mui/material/DialogContent";
import InviteContributors from "../InviteContributors";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {CloseIcon, closeMessageIcon, PremiumCrown} from "../../../svgIcons/icons";
import {useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CardSettingsDetails from "./CardSettingsDetails";
import CardSettingsAccess from "./CardSettingsAccess";
import CardAdministration from "./CardAdministration";

export default function CardSettingsDialog({card, open, closeDialog}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [tab, setTab] = useState(0);
  const chooseTab = (event, newValue) => {
    setTab(newValue);
  };

  function handleClose() {
    closeDialog();
  }

  return(
    <Dialog
      TransitionComponent={TransitionComponent}
      fullScreen={fullScreen}
      maxWidth="md"
      onClose={handleClose} open={open} >
      <DialogContent>
        <Box sx={{display: 'flex', flexDirection:{ xs: 'column', md: 'row'}, justifyContent: 'space-between', marginBottom: '20px' }}>
          <Box onClick={() => {closeDialog()}} sx={{display: { xs: 'block', md: 'none'}, textAlign: 'right', '&:hover':{ cursor: 'pointer'} }} >
            {closeMessageIcon}
          </Box>
          <Typography variant="h4" sx={{ padding: '0', fontSize: '30px', lineHeight: '38px'}}>Card Settings</Typography>
          <Box onClick={() => {closeDialog()}} sx={{display: { xs: 'none', md: 'block'},  float:'right', textAlign: 'right', '&:hover':{ cursor: 'pointer'} }} >
            {closeMessageIcon}
          </Box>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile value={tab} onChange={chooseTab} aria-label="basic tabs example">
            <Tab className='dashboardTab' label="Details" {...a11yProps(0)} />
            <Tab className='dashboardTab'  label={<Box sx={{display:'flex', gap: '5px', flexDirection: 'row', alignItems: 'center'}}>Access <PremiumCrown card={card} width="16px" height="16px" /></Box>} {...a11yProps(1)} />
            <Tab className='dashboardTab'  label={<Box sx={{display:'flex', gap: '5px', flexDirection: 'row', alignItems: 'center'}}>Administration <PremiumCrown card={card} width="16px" height="16px" /></Box>} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tab} index={0}>
          <CardSettingsDetails card={card} closeDialog={closeDialog} />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          <CardSettingsAccess card={card} closeDialog={closeDialog} />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={2}>
          <CardAdministration card={card} closeDialog={closeDialog} />
        </CustomTabPanel>

      </DialogContent>
    </Dialog>
  )

}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div

      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{marginTop: '40px'}}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}