export const aiSamples = [
  {id:'ai-1',url:'https://storage.googleapis.com/dev-rl-custom-backgrounds-users/1717284065351-w8d1oe.jpg', description: 'rocket flying out of earth'},
  {id:'ai-2',url:'https://storage.googleapis.com/dev-rl-custom-backgrounds-users/1717282873629-igslxb.jpg', description: 'relaxing beach'},
  {id:'ai-9',url:'https://storage.googleapis.com/dev-rl-custom-backgrounds-users/1717280126855-t1luwy.jpg', description: 'cat playing under the rain'},
  {id:'ai-6',url:'https://storage.googleapis.com/dev-rl-custom-backgrounds-users/1717270881964-h0yzsx.jpg', description: 'man wearing black suit flying over austin texas'},
  {id:'ai-3',url:'https://storage.googleapis.com/prod-website-images-rlink/ai-image-samples/cars_and_dogs.jpg', description: 'dogs in flying cars'},
  {id:'ai-4',url:'https://storage.googleapis.com/prod-website-images-rlink/ai-image-samples/cats_and_dogs_small.jpg', description: 'cats and dogs playing in the forest'},
  {id:'ai-5',url:'https://storage.googleapis.com/prod-website-images-rlink/ai-image-samples/chicken_nuggets_small.jpg', description: 'funny cartoon chicken nuggets dancing and wearing glasses'},
  {id:'ai-7', url:'https://storage.googleapis.com/dev-rl-custom-backgrounds-users/1717291279926-f3265u.jpg', description: 'race cars'},
  {id:'ai-8',url:'https://storage.googleapis.com/prod-website-images-rlink/ai-image-samples/baby_elephant_small.jpg', description: 'baby elephant eating tacos'},
];