import { Outlet } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { datadogRum } from '@datadog/browser-rum';

import AppToBar from './components/appBar/AppBar';
import './App.css';
import { useEffect } from "react";
import {useSelector} from "react-redux";
import {getCurrentDomain} from "./utils/functions";
import Footer from "./components/footer/Footer";
import ConsentBanner from "./components/consentBanner/ConsentBanner";

const recognitionLinkTheme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif'
  },
  palette: {
    primary: {
      main: '#53389E',
    },
    secondary: {
      main: '#101828',
    },
  },
  components: {
    MuiAccordion:{
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:before': {
            height: 0,
          }
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        primary: {
          width: '100%',
          padding: '5px 30px',
          background: 'linear-gradient(90deg, #5D9AF3 0%, #5E19B7 100%)',
          lineHeight: '20px',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '14px',
          // boxShadow: 'box-shadow: 0px 1px 2px 0px #18280D',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          borderRadius: '38px',
          textTransform: 'capitalize',
          marginBottom: '16px',
          transition: 'none',
          color: '#FFF',
          '&:hover': {
            // background: 'linear-gradient(90deg, #5E19B7 0%, #5D9AF3 100%)',
            background: '#53389E',
          },
          "&:active": {
            backgroundColor: 'linear-gradient(90deg, #5D9AF3 0%, #5E19B7 100%)'
          }
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          width: '100%',
          padding: '12px 20px',
          background: 'linear-gradient(90deg, #5D9AF3 0%, #5E19B7 100%)',
          lineHeight: '24px',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '16px',
          // boxShadow: 'box-shadow: 0px 1px 2px 0px #18280D',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          borderRadius: '8px',
          textTransform: 'capitalize',
          marginBottom: '16px',
          transition: 'none',
          '&:hover':{
            // background: 'linear-gradient(90deg, #5E19B7 0%, #5D9AF3 100%)',
            background: '#53389E',
          },
          "&:active": {
            backgroundColor: 'linear-gradient(90deg, #5D9AF3 0%, #5E19B7 100%)',

          },
          ":disabled": {
            color: '#D6BBFB',
            background: '#FCFAFF',
          }
        },
        containedSecondary: {
          display: 'flex',
          padding: '12px 20px',
          marginBottom: '16px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          color: '#6941C6',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '24px',
          textTransform: 'capitalize',
          borderRadius: '8px',
          border: '1px solid #F9F5FF',
          background: '#F9F5FF',
          boxShadow: 'none',
          '&:hover':{
            background: '#F4EBFF',
            border: '1px solid #F9F5FF',
            color: '#6941C6',
            boxShadow: 'none',
          },
        },
        outlinedSecondary: {
          border: '1px solid #D0D5DD',
          marginBottom: '16px',
          width: '100%',
          padding: '12px 20px',
          borderRadius: '8px',
          textTransform: 'capitalize',
          lineHeight: '24px',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '16px',
          color: 'rgba(52, 64, 84, 1)',
          background: '#FFFFFF',
          transition: 'background 0.5s',
          '&:hover':{
            background: '#F9FAFB',
            border: '1px solid #D0D5DD',
          },
        },
        textPrimary: {
          display: 'flex',
          padding: '12px 20px',
          marginBottom: '16px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          color: '#6941C6',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '24px',
          textTransform: 'capitalize',
          '&:hover': {
            color: '#53389E',
            background: 'none'
          },
          '&:active': {
            color: '#6941C6',
            background: 'none'
          }
        },
        textSecondary:{
          display: 'flex',
          padding: '12px 20px',
          marginBottom: '16px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          color: '#667085',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '24px',
          textTransform: 'capitalize',
          borderRadius: '8px',
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: '600',
          color:'#101828',
          fontSize: '48px',
          lineHeight: '60px'

        },
        h2: {
          fontSize: '30px',
          fontWeight: '600',
          lineHeight: '38px',
          color:'#101828'
        },
        h3: {
          fontSize: '24px',
          fontWeight: '400',
          lineHeight: '32px',
          color:'#101828',

        },
        h4: {
          fontSize: '30px',
          fontWeight: '700',
          lineHeight: '38px',
          color: '#7F56D9',
        },
        h5: {
          fontSize: '18px',
          fontWeight: '700',
          lineHeight: '28px',
          color: '#6941C6',
        },
        subtitle1: {
          fontSize: '20px',
          fontWeight: '700',
          lineHeight: '30px',
          color: 'rgba(16, 24, 40, 1)',
        },
        subtitle2: {
          fontWeight: '400',
          fontSize: '20px',
          lineHeight: '30px',
          color: '#667085',
        },
        body1: {
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '24px',
          color: 'rgba(16, 24, 40, 1)',
        },
        body2: {
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#667085',
        },
        caption: {
          color: '#667085'
        },
        button: {},
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          fontWeight: 500,
          '&:hover': {
            textDecoration: 'underline'
          }
        }
      }
    },
    MuiInputBase:{
      styleOverrides:{
        root: {
          fontSize: '16px',
          lineHeight: '24px',
        },
        input: {
          color: '#344054'
        }
      }
    },
    MuiOutlinedInput:{
      styleOverrides:{
        root:{

        },
        input: {
          padding: '12px 16px',
        },
        notchedOutline: {
          border: '1px solid #D0D5DD',
          borderRadius: '8px'
        }
      }
    },
    MuiInputLabel:{
      styleOverrides:{
        root: {
          top: '-4px',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#667085'
        },
      }
    },
    MuiTextField:{
      styleOverrides:{
        root: {
          marginTop: '12px',
          marginBottom: '12px'
        },
      }
    },
    MuiCheckbox:{
      styleOverrides: {
        root: {
          color: '#D0D5DD',
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          left: '-2px',
        },
        root: {
          padding: '8px 9px',
          // padding: '9px 11px',
        },
        track: {
          borderRadius: '12px',
          backgroundColor: '#F2F4F7',
          opacity: 1,
        },
        thumb: {
          width: '16px',
          height: '16px',
          marginTop: '2px',
          marginLeft: '4px',
          paddingRight: '2px'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label : {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: '500',
          color: '#344054',
          marginTop: 0,
        }
      }
    },
    MuiDialog: {
      styleOverrides:{
        paper: {
          borderRadius: '8px'
        },
        backdrop: {
          backgroundColor: 'rgba(0, 0, 0, 0.6)'
        }
      }
    }
  }
});



//TODO: quitar el comment del Footer
function App() {

  const user = useSelector(state => state.auth.user);
  const isUserLoggedIn = !!user?.id;

  useEffect(() => {

    let config = {}
    if(getCurrentDomain().includes('dev-')) {
      config = {
        applicationId: '91887df3-2da7-48f6-96bf-dd1ec9a63fe7',
        clientToken: 'pub28adee2dead78b5b3d371c554a108070',
        site: 'us5.datadoghq.com',
        service: 'recognitionlink-web-app---rue',
        env: 'dev',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
      }
    } else if(getCurrentDomain().includes('recognitionlink.com')){
      config = {
        applicationId: '698440d9-3c74-409b-9497-e34856fe01a8',
        clientToken: 'pubc5c450fcffd0155a49186bb7aef552c1',
        site: 'us5.datadoghq.com',
        service: 'recognitionlink-web-app----prod',
        env: 'prod',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
      }
    } else if(getCurrentDomain().includes('local')) {

      config = {
        applicationId: '9654637a-ea72-4f6b-bd8f-eae23ca1baec',
        clientToken: 'pub1bf999f30b1b0c448d34bf3987eaf7ab',
        site: 'us5.datadoghq.com',
        service: 'recognitionlink-local',
        env: 'local',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
      }
    }

    if(config !== {}) {
      datadogRum.init(config);
      if(isUserLoggedIn) {
        datadogRum.setUser({
          id: user?.id,
          name: user?.fullName,
          email: user?.email,
        })
      }
    }

  }, []);

  return (
    <ThemeProvider theme={recognitionLinkTheme}>
      <div className="App">
       <AppToBar />
       <Outlet />
       <Footer />
       <ConsentBanner />
      </div>
    </ThemeProvider>
  );
}




export default App;
