import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { clearMessageCreation} from "../../slices/messageSlice";
import { useCreateMessageMutation } from "../../slices/api/apiSlice";
import { useGetCardQuery } from "../../slices/api/cardApiSlice";
import InfoSnackbar from "../../components/infoSnackbar/InfoSnackbar";
import MessageCreateLogic from "./blocks/MessageCreateLogic";

export default function AddMessagePage() {
  const { cardId } = useParams();
  const navigate = useNavigate();

  function navigateToCard() {
    navigate(`/card/${cardId}`)
  }

  const {
    data: cardResponse,
    isLoading: getCardIsLoading,
    isSuccess: getCardIsSuccess,
    isError: getCardIsError,
    error: cardError,
  } = useGetCardQuery(cardId)

  const [
    createMessage,
    { isLoading: isCreatingMessage,
      error: errorCreatingMessage,
      isSuccess: successCreatingMessage
    }] = useCreateMessageMutation();

  async function handleCreateMessage(newMessage) {
    try {
      await createMessage(newMessage).unwrap()
      navigateToCard();
    } catch (e){
      console.log('error creating message',e)
    }
  }

  const card = cardResponse?.card;

  return(
    <Box component='div' className="contentContainer" sx={{ padding:{ xs: '20px 20px', md: '20px 20px', lg: '30px 20px'}, background: 'none' }}>

      {errorCreatingMessage &&
        <InfoSnackbar severity="error" message={errorCreatingMessage?.data?.error || 'Oops something went wrong, please try again'} /> }

      {getCardIsError &&
        <InfoSnackbar severity="error" message={cardError} /> }

      {getCardIsSuccess &&
        <MessageCreateLogic
          isLoading={isCreatingMessage}
          card={card}
          confirmAction={handleCreateMessage} />
      }
    </Box>
  )
}
