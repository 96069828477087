export default [
  {
    "media": {
      "type": "video_embed",
      "data": [
        {
          "fileUrl": "https://www.youtube.com/watch?v=kt9n5E71EPI",
          "_id": "658b345f708520261f17a43f"
        }
      ]
    },
    "creatorName": "Jackson Foster",
    "cardId": "658b2a59e4ae288d1fc27ece",
    "message": "Happy Birthday! May the coming year bring you prosperity, good health, and all the incredible moments that life has to offer. Enjoy your special day to the fullest!",
    "position": 1703621659331,
    "createdAt": "2023-12-26T20:14:19.332Z",
    "updatedAt": "2023-12-26T20:15:27.069Z",
    "id": "658b341b708520261f17a430",
    "canEdit": false
  },
  {
    "media": {
      "type": "giphy_gif",
      "data": [
        {
          "fileUrl": "https://media4.giphy.com/media/chzz1FQgqhytWRWbp3/giphy.gif?cid=a477b6a0ffn4orl30akegm4xe1ihqc3cqk9ekldobunv09il&ep=v1_gifs_search&rid=giphy.gif&ct=g",
          "_id": "658b33f0708520261f17a423"
        }
      ]
    },
    "creatorName": "Isabella Nguyen",
    "cardId": "658b2a59e4ae288d1fc27ece",
    "message": "Today is the day to celebrate the wonderful gift of you! Happy Birthday to someone who makes the world a better place with their presence. Here's to a day filled with love and laughter!",
    "position": 1703621584358,
    "createdAt": "2023-12-26T20:13:04.360Z",
    "updatedAt": "2023-12-26T20:13:36.122Z",
    "id": "658b33d0708520261f17a411",
    "canEdit": false
  },
  {
    "media": {
      "type": "user_image",
      "data": [
        {
          "fileUrl": "https://storage.googleapis.com/dev-rl-message-images-by-user/1703621428637-hvqo7if7.jpg",
          "_id": "658b337a708520261f17a404"
        },
        {
          "fileUrl": "https://storage.googleapis.com/dev-rl-message-images-by-user/1703621470962-265lg1xu.jpg",
          "_id": "658b337a708520261f17a405"
        },
        {
          "fileUrl": "https://storage.googleapis.com/dev-rl-message-images-by-user/1703621761288-hgg2mlmv.webp",
          "_id": "658b3485708520261f17a457"
        }
      ]
    },
    "creatorName": "Noah Santiago",
    "cardId": "658b2a59e4ae288d1fc27ece",
    "message": "Happy Birthday! Another year, another adventure awaits. May your journey ahead be as bright and exciting as the candles on your cake!",
    "position": 1703621498811,
    "createdAt": "2023-12-26T20:11:38.814Z",
    "updatedAt": "2023-12-26T20:16:05.645Z",
    "id": "658b337a708520261f17a402",
    "canEdit": false
  },
  {
    "media": {
      "type": "giphy_gif",
      "data": [
        {
          "fileUrl": "https://media0.giphy.com/media/26n7bsnO12aGScCSk/giphy.gif?cid=a477b6a0entz7rlzfuu3mvb5hgjq19ybiq7ucsgxmzumtt3t&ep=v1_gifs_search&rid=giphy.gif&ct=g",
          "_id": "658b32d5708520261f17a3ec"
        }
      ]
    },
    "creatorName": "Ava Reynolds",
    "cardId": "658b2a59e4ae288d1fc27ece",
    "message": "May this birthday be just the beginning of a year filled with happy moments, cherished memories, and all the success you deserve. Cheers to you!",
    "position": 1703621333667,
    "createdAt": "2023-12-26T20:08:53.668Z",
    "updatedAt": "2023-12-26T20:08:53.668Z",
    "id": "658b32d5708520261f17a3eb",
    "canEdit": false
  },
  {
    "media": {
      "type": "video_embed",
      "data": [
        {
          "fileUrl": "https://www.youtube.com/watch?v=uyWt8NV53r0",
          "_id": "658b3299708520261f17a3e3"
        }
      ]
    },
    "creatorName": "Mason Blackwell",
    "cardId": "658b2a59e4ae288d1fc27ece",
    "message": "Wishing you a day that's as sweet as the cake you'll be cutting and a year that's as amazing as the person you are. Happy Birthday!",
    "position": 1703621273282,
    "createdAt": "2023-12-26T20:07:53.284Z",
    "updatedAt": "2023-12-26T20:07:53.284Z",
    "id": "658b3299708520261f17a3e2",
    "canEdit": false
  },
  {
    "media": {
      "type": "",
      "data": []
    },
    "creatorName": "Sophia Chang",
    "cardId": "658b2a59e4ae288d1fc27ece",
    "message": "Sending you a day full of laughter, a year full of joy, and a lifetime full of love. Happy Birthday to someone who deserves all the happiness in the world!",
    "position": 1703621225886,
    "createdAt": "2023-12-26T20:07:05.887Z",
    "updatedAt": "2023-12-26T20:07:05.887Z",
    "id": "658b3269708520261f17a3db",
    "canEdit": false
  },
  {
    "media": {
      "type": "giphy_gif",
      "data": [
        {
          "fileUrl": "https://media1.giphy.com/media/xSlDYEXknFwY4ucrZV/giphy.gif?cid=a477b6a0q8jq8kbx2pr626yr2vflpe3swje5icnt81cehd43&ep=v1_gifs_search&rid=giphy.gif&ct=g",
          "_id": "658b3249708520261f17a3d4"
        }
      ]
    },
    "creatorName": "Ethan Thompson",
    "cardId": "658b2a59e4ae288d1fc27ece",
    "message": "Happy Birthday! Today is all about celebrating you – your kindness, your warmth, and the incredible person you've become. May this year be your best one yet!",
    "position": 1703621193573,
    "createdAt": "2023-12-26T20:06:33.575Z",
    "updatedAt": "2023-12-26T20:06:33.575Z",
    "id": "658b3249708520261f17a3d3",
    "canEdit": false
  },
  {
    "media": {
      "type": "user_image",
      "data": [
        {
          "fileUrl": "https://storage.googleapis.com/dev-rl-message-images-by-user/1703621134263-4q5jsc89.jpg",
          "_id": "658b3214708520261f17a3cd"
        }
      ]
    },
    "creatorName": "Maya Patel",
    "cardId": "658b2a59e4ae288d1fc27ece",
    "message": "Another year older, wiser, and more fabulous! May your birthday be as fantastic as you are. Cheers to a year filled with exciting adventures and endless happiness!",
    "position": 1703620996549,
    "createdAt": "2023-12-26T20:03:16.552Z",
    "updatedAt": "2023-12-26T20:05:40.287Z",
    "id": "658b3184708520261f17a3c2",
    "canEdit": false
  },
  {
    "media": {
      "type": "video_embed",
      "data": [
        {
          "fileUrl": "https://www.youtube.com/watch?v=8xy8qIjdC3g",
          "_id": "658b3148708520261f17a3bd"
        }
      ]
    },
    "creatorName": "Liam Rodriguez",
    "cardId": "658b2a59e4ae288d1fc27ece",
    "message": "On your special day, I hope the sun shines as brightly as your smile. Happy Birthday to someone who brings so much joy into the world!",
    "position": 1703620936667,
    "createdAt": "2023-12-26T20:02:16.669Z",
    "updatedAt": "2023-12-26T20:02:16.669Z",
    "id": "658b3148708520261f17a3bc",
    "canEdit": false
  },
  {
    "media": {
      "type": "giphy_gif",
      "data": [
        {
          "fileUrl": "https://media3.giphy.com/media/tsX3YMWYzDPjAARfeg/giphy.gif?cid=a477b6a0xbq3bdfnaizt8mawc7v5athca9n7bkuxb5stos9q&ep=v1_gifs_trending&rid=giphy.gif&ct=g",
          "_id": "658b32e6708520261f17a3f9"
        }
      ]
    },
    "creatorName": "Olivia Morgan",
    "cardId": "658b2a59e4ae288d1fc27ece",
    "message": "Happy Birthday! May your day be filled with love, laughter, and all the things that make you smile. Wishing you a year ahead as wonderful as you are!",
    "position": 1703619917322,
    "createdAt": "2023-12-26T19:45:17.332Z",
    "updatedAt": "2023-12-26T20:09:10.403Z",
    "id": "658b2d4d708520261f17a3b1",
    "canEdit": false
  }
];