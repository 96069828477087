import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import React from "react";
import dayjs from "dayjs";

export default function YourSubscriptions({subscriptions = []}) {

  function visitPortal(manageUrl) {
    return window.open(manageUrl, '_blank').focus();
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px'}}>
      {subscriptions.map(sub => {
        const startDate = dayjs(sub.startDate).format('MM/DD/YYYY')
        const endDate = dayjs(sub.endDate).format('MM/DD/YYYY')

        return (
          <Box key={sub.id} sx={{ margin: '20px 0', padding: '20px', display: 'flex', flexDirection: {xs:'column', md:'row'},  alignItems: 'center', justifyContent:'space-between',
            borderRadius: '8px', border: '1px solid #EAECF0', background: '#FFF',
            boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)'}}>
            <Typography variant="body1" sx={{fontWeight:700}}>{sub.planName}</Typography>
            <Typography variant="body2" sx={{textTransform: 'capitalize'}}>{sub.status}</Typography>
            <Typography variant="body2">Start Date {startDate}</Typography>
            {sub.cancelAt && <Typography variant="body2">End date {dayjs(sub.cancelAt).format('MM/DD/YYYY')}</Typography>}
            {!sub.cancelAt && <Typography variant="body2">Renews on {dayjs(sub.currentPeriodEnd).format('MM/DD/YYYY')}</Typography>}

            <Button disabled={!sub.manageUrl} onClick={(e) => { e.preventDefault(); visitPortal(sub.manageUrl)}} sx={{marginBottom:0}}>Manage Subscription</Button>
          </Box>
        )
      })}
    </Box>
  )
}