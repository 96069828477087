import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {useUpdateUserMutation} from "../../slices/api/userSlice";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";

export default function YourInfo({user}){
  const [name, setName] = useState(user.fullName);
  const [nameChanged, setNameChanged] = useState(false)
  const [updateUser, {isLoading: isUpdating, isError, error, isSuccess}] = useUpdateUserMutation();
  async function handleUpdate() {
    await updateUser({ ...user, fullName: name});
    setNameChanged(false)
  }

  return(
    <>
      {isSuccess && <InfoSnackbar message="Updated" severity="success" /> }
      {isError && <InfoSnackbar message={error.error} severity="error" /> }
      <Box sx={{ display: 'flex', flexDirection:{ xs: 'column', md: 'row'}, justifyContent: 'space-between', alignItems: { xs: 'left', md:'center'} }}>
        <Typography  variant="body1" sx={{ color: '#344054', fontWeight: 500, fontSize: '14px', lineHeight: '20px', flexGrow:1 }}>
          Your Name
        </Typography>
        <Box sx={{ flexGrow:2, textAlign: {xs: 'left', md: 'right'} }}>
          <TextField
            sx={{margin: {xs: '20px 0', md: '20px auto'}}}
            margin="normal"
            required
            id="yourName"
            name="name"
            fullWidth
            value={name}
            onChange={(e) => {setName(e.target.value); setNameChanged(true)}}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection:{ xs: 'column', md: 'row'}, justifyContent: 'space-between', alignItems: { xs: 'left', md:'center'} }}>
        <Typography  variant="body1" sx={{ color: '#344054', fontWeight: 500, fontSize: '14px', lineHeight: '20px', flexGrow:1 }}>
          Your Email
        </Typography>
        <Box sx={{ flexGrow:2, textAlign: {xs: 'left', md: 'right'} }}>
          <TextField
            disabled
            sx={{margin: {xs: '20px 0', md: '20px auto' }}}
            margin="normal"
            id="recipientEmail"
            name="email"
            fullWidth
            value={user?.email}
          />
        </Box>
      </Box>
      <Box sx={{textAlign: 'right', marginTop: '-10px', marginBottom: '20px'}}>
        <Typography variant="caption">Your email can not be changed</Typography>
      </Box>
      <Box sx={{display:'flex', justifyContent: 'flex-end'}}>
        <Button onClick={handleUpdate} disabled={!nameChanged || isUpdating} variant='contained' sx={{ maxWidth: '200px'}}>Update</Button>
      </Box>
    </>
  )
}