import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import mediaTypes from "../../../utils/mediaTypes";
import {removeFromArray} from "../../../utils/functions";

import MessageContentInputFields from "./MessaContentInputField";
import {useSelector} from "react-redux";
import InfoSnackbar from "../../../components/infoSnackbar/InfoSnackbar";
import {useTrackDownloadMutation} from "../../../slices/api/unsplashApiSlice";
export default function MessageCreateLogic({isLoading, card = {},  confirmAction, edit = false}){
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user)

  useEffect(() => {
    if(user?.email && user?.fullName){
      setCreatorName(user.fullName);
      setCreatorEmail(user.email)
    }
  }, []);

  const [creatorName, setCreatorName] = useState('');
  const [creatorEmail, setCreatorEmail] = useState('');
  const [messageText, setMessageText] = useState('');
  const [media, setMedia] = useState({ type: null, data: []});

  const [showImagesModal, setShowImagesModal] = useState(false)
  const [showGifModal, setShowGifModal] = useState(false)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [showMusicModal, setShowMusicModal] = useState(false)
  const [showUnsplashModal, setShowUnsplashModal] = useState(false)

  const [trackDownload] = useTrackDownloadMutation();

  const [error, setError] = useState('')

  const cardId = card.id

  function navigateToCard() {
    navigate(`/card/${card.id}`)
  }
  function updateName(e) {
    if(e.target.value.length >= 120) {
      setError('Names can only be up to 120 characters log');
      return setCreatorName(e.target.value.substring(0,120))
    }
    setCreatorName(e.target.value)
  }

  function updateEmail(e){
    if(e.target.value.length >= 254) {
      setError('Emails can only be up to 254 characters log');
      return setCreatorEmail(e.target.value.substring(0,254))
    }
    setCreatorEmail(e.target.value)
  }

  function updateMessage(e){
    if(e.target.value.length >= 2000) {
      setError('Your reached the characters limit, please use a maximum of 2000 characters');
      return setMessageText(e.target.value.substring(0,2000))
    }
    setMessageText(e.target.value)
  }

  //Handle confirmantion handles the callback, this could be update or create a message
  function handleConfirmation(){
    confirmAction({cardId, creatorName, creatorEmail, message: messageText, media})
  }

  function updateMedia(mediaLoad){
    setMedia(mediaLoad)
  }

  function addImage(image) {
    setMedia(prevState => {
      return {
        type: prevState.type || mediaTypes.USER_IMAGE,
        data: [
          ...prevState.data,
          image
        ]
      }
    })
  }

  function removeImage(image) {
    const newImages = removeFromArray(media.data, image);
    if(newImages.length > 0) {
      setMedia(prevState => {
        return {
          type: prevState.type || mediaTypes.USER_IMAGE,
          data: newImages
        }
      })
    } else {
      setMedia(prevState => {
        return {
          type: '',
          data: []
        }
      })
    }
  }

  function addUnsplashImage(image) {
    //trigger download request
    setMedia({
      type: mediaTypes.UNSPLASH_IMAGE,
      data: [{fileUrl: image.photoRaw + "&w=600&dpr=2", unsplash: image}]
    })
    setShowUnsplashModal(false)
    try {
      trackDownload(image);
    }catch (e) {
      console.error('error updating downloads in Unsplash',e)
    }
  }

  function removeUnsplashImage(image) {
    setMedia({
      type: null,
      data: []
    })
    setShowUnsplashModal(false)
  }

  return(
    <>
      {error && <InfoSnackbar message={error} callback={() => setError('')} onClick={() => setError('') } severity="error" />}
      <MessageContentInputFields
        isLoading={isLoading}
        navigateToCard={navigateToCard}
        card={card}
        creatorName={creatorName}
        updateName={updateName}
        creatorEmail={creatorEmail}
        updateEmail={updateEmail}
        messageText={messageText}
        updateMessage={updateMessage}
        setShowGifModal={setShowGifModal}
        showGifModal={showGifModal}
        media={media}
        updateMedia={updateMedia}
        setShowImagesModal={setShowImagesModal}
        showImagesModal={showImagesModal}
        addImage={addImage}
        removeImage={removeImage}
        addUnsplashImage={addUnsplashImage}
        removeUnsplashImage={removeUnsplashImage}
        showUnsplashModal={showUnsplashModal}
        setShowUnsplashModal={setShowUnsplashModal}
        setShowVideoModal={setShowVideoModal}
        showVideoModal={showVideoModal}
        setShowMusicModal={setShowMusicModal}
        showMusicModal={showMusicModal}
        handleConfirmation={handleConfirmation}
      />
    </>

  )
}