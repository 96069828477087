import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

export default function AvatarCategory({category, handleSelection, avatarSelected}){
  return(
    <Box sx={{ marginBottom: '20px'}}>
      <Typography variant="body1" sx={{ fontSize:{xs:'14px',md:'16px'}, fontWeight: '500', marginBottom:{xs: '20px', md: '30px'} }}>{category.name}</Typography>
      <Box sx={{ display: 'flex', flexDirection:'row', flexWrap: 'wrap', gap:{ xs: '6px', md: '12px'} }}>
        {category.avatars.map(avatar => <Avatar
          key={avatar.image}
          onClick={() => handleSelection(avatar)}
          sx={{
            width:{xs: '100px', md: '120px'},
            height: {xs: '100px', md: '120px'},
            '&:hover':{ cursor: 'pointer'},
            border: avatarSelected?.avatarImage === avatar.image ? '3px solid #7F56D9' : 'none',
        }}
          src={avatar.image} />)}
      </Box>
    </Box>)
}