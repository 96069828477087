import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
} from "@mui/material";
import {useSendContactFormMutation} from "../../slices/api/contactApiFormSlice";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";

const ContactForm = ({subject = 'Contact form'}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [employees, setEmployees] = useState("");
  const [planUsage, setPlanUsage] = useState("");
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("")
  const [successMessage,setSuccessMessage] = useState("");

  const [sendContactForm] = useSendContactFormMutation();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formErrors = {};

    if (firstName.length < 2) {
      formErrors.firstName = "First name must be at least 2 characters long.";
    }

    if (lastName.length < 2) {
      formErrors.lastName = "Last name must be at least 2 characters long.";
    }

    if(planUsage.length < 2) {
      formErrors.planUsage = "Please let us know how you would like to use RecognitionLink";
    }

    if (!validateEmail(email)) {
      formErrors.email = "Invalid email address.";
    }

    if (isNaN(employees) || employees <= 0) {
      formErrors.employees = "Total number of employees must be a positive number.";
    }

    if (Object.keys(formErrors).length === 0) {
      // If no errors, submit the form (e.g., send data to API)
      try {
        await sendContactForm({
          firstName,
          lastName,
          email,
          employees: Number(employees),
          planUsage,
          subject,
        }).unwrap();
        // Reset the form
        setFirstName("");
        setLastName("");
        setEmail("");
        setEmployees("");
        setPlanUsage("");
        setErrors({});
        setSuccessMessage("Information sent, looking forward to chat with you. Thanks!")

      }catch (e) {
        setError(error?.data?.error || 'Error sending contact form, please try again')
      }

    } else {
      setErrors(formErrors);
    }
  };

  return (
    <Container maxWidth="sm">
      {!!successMessage && <InfoSnackbar message={successMessage} severity="success" callback={() => {setSuccessMessage("")}}  /> }
      {!!error && <InfoSnackbar message={error} severity="error" callback={() => {setError("")}} /> }
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" gutterBottom>
          Contact us for a demo and pricing
        </Typography>
        <form onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
          <TextField
            margin="normal"
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            margin="normal"
            fullWidth
            id="employees"
            label="Aproximate Number of Employees"
            name="employees"
            type="number"
            value={employees}
            onChange={(e) => setEmployees(e.target.value)}
            error={!!errors.employees}
            helperText={errors.employees}
          />
          <TextField
            margin="normal"
            fullWidth
            multiline
            rows={4}
            id="planUsage"
            label="How do you plan using RecognitionLink?"
            name="planUsage"
            error={!!errors.planUsage}
            value={planUsage}
            onChange={(e) => setPlanUsage(e.target.value)}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
            Submit
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default ContactForm;
