import Box from "@mui/material/Box";
import * as React from "react";
import TransitionComponent from "../transitionComponent/TransitionComponent";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import UpgradeBox from "../upgradeBox/UpgradeBox";
import Typography from "@mui/material/Typography";
import {CloseIcon} from "../../svgIcons/icons";
import Divider from "../divider/Divider";
import Button from "@mui/material/Button";


export default function CardUpgradeOptionsDialog({card, open,closeDialog}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  function handleClose() {
    closeDialog();
  }


  return(
    <Dialog
      TransitionComponent={TransitionComponent}
      fullScreen={fullScreen}
      maxWidth="md"
      onClose={handleClose} open={open} >
      <>
        <Box sx={{ padding: '30px', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{display:'flex', justifyContent:'flex-end'}}><CloseIcon cb={handleClose} color="#9E77ED" /></Box>
          <Typography variant="h4" sx={{ padding: '0', fontSize: '30px', lineHeight: '38px'}}>Upgrade options</Typography>
          <Divider small={true} />
          <UpgradeBox card={card} cb={closeDialog} />
        </Box>
      </>
    </Dialog>)
}