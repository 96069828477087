import React, { useState} from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';

import {
  smallCrownIcon,
  smallCheckMark,
  favoriteIcon,
  cloneIcon,
  deleteIcon,
  shareIcon,
  grayPersonIcon
} from "../../svgIcons/icons";
import GradientButton from "../../components/gradientButton/GradientButton";
import {useNavigate} from "react-router-dom";
import DeleteCardModal from "../../components/deleteCardModal/DeleteCardModal";

function Row({title, component}) {
  return (
    <Box sx={{
      height: '50px',
      display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', textAlign: 'left', alignItems: 'center',
      borderBottom: '1px solid #EAECF0', padding: '10px 20px'}}>
      <Box sx={{width: '50%', textAlign: 'left'}}><Typography variant="body2" sx={{fontSize: '14px'}}>{title}</Typography></Box>
      <Box sx={{width: '50%', textAlign: 'left'}}>{component}</Box>
    </Box>
  )
}

function MenuFeature({card}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{width:'6%', textAlign: 'right', height:'100%'}}>
      <MoreVertIcon
        onClick={handleClick}
        sx={{color: 'rgba(152, 162, 179, 1)', '&:hover': {color: 'primary.main', cursor: 'pointer'}}} />
      <Menu
        sx={{borderRadius: '16px'}}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/*<MenuItem onClick={handleClose}><Box sx={{paddingRight: '10px', marginTop: '5px'}}>{favoriteIcon}</Box> Mark as Favorite</MenuItem>*/}
        {/*<MenuItem onClick={handleClose}><Box sx={{paddingRight: '10px', marginTop: '5px'}}>{cloneIcon}</Box> Clone</MenuItem>*/}
        {/*<MenuItem onClick={handleClose}><Box sx={{paddingRight: '10px', marginTop: '5px'}}>{shareIcon}</Box> Share</MenuItem>*/}
        <MenuItem onClick={() => { setShowDeleteWarning(true)}}><Box sx={{paddingRight: '10px', marginTop: '5px'}}>{deleteIcon}</Box> Delete</MenuItem>
      </Menu>
      <DeleteCardModal card={card} open={showDeleteWarning} closeDialog={() => { setShowDeleteWarning(false); handleClose()}} cb={() => { setShowDeleteWarning(false); handleClose() }} />
    </Box>
  )
}
export default function CardListItem({card}) {
  const navigate = useNavigate();

  const getCardTierMessage = () => {
    switch (card.tier){
      case 'PREMIUM_50':
        return <Typography variant="body2" sx={{ fontSize: '14px', lineHeight: '16px', color: '#667085'}} >Premium</Typography>
      case 'PREMIUM':
        return <Typography variant="body2" sx={{ fontSize: '14px', lineHeight: '16px', color: '#667085'}} >Premium</Typography>
      case 'PREMIUM_SUB':
        return <Typography variant="body2" sx={{ fontSize: '14px', lineHeight: '16px', color: '#667085'}} >Premium</Typography>
      case 'PREMIUM_GALA':
        return <Typography variant="body2" sx={{ fontSize: '14px', lineHeight: '16px', color: '#667085'}} >Premium</Typography>
      default:
        return <Typography variant="body2" sx={{ fontSize: '14px', lineHeight: '16px', color: '#667085'}} >Free</Typography>
    }
  }

  function getDeliveryStatus() {
    switch (card.deliveryStatus) {
      case 'PENDING':
        return <Chip label={<Typography variant="body1" sx={{fontSize: '12px'}}>Not Sent</Typography>} />;
      case 'DELIVERED':
        if(card.viewedByRecipient){
          return <Box sx={{ background:'rgba(249, 245, 255, 1)', padding: '4px 8px', borderRadius: '11px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Box sx={{ padding: '0 5px 0 0'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path d="M10 3L4.5 8.5L2 6" stroke='#53389E' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </Box>
            <Box>
              <Typography variant="body1" sx={{fontSize: '12px', lineHeight: '16px', fontWeight: 500, color: '#53389E'}}>Viewed by Recipient</Typography>
              {/*<Typography variant="body1" sx={{fontSize: '12px', lineHeight: '16px', color: '#027A48'}}>{dayjs(card.deliveryDate).format('MMMM D, YYYY')}</Typography>*/}
            </Box>
          </Box>;
        }
        return <Box sx={{ background:'#ECFDF3', padding: '4px 12px', borderRadius: '11px', display: 'flex', flexDirection: 'row'}}>
          {/*<Box sx={{ padding: '0 5px 0 0'}}>*/}
          {/*  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">*/}
          {/*    <path d="M10 3L4.5 8.5L2 6" stroke="#12B76A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>*/}
          {/*  </svg>*/}
          {/*</Box>*/}
          <Box>
            <Typography variant="body1" sx={{fontSize: '12px', lineHeight: '16px', fontWeight: 500, color: '#027A48'}}>Delivered</Typography>
            <Typography variant="body1" sx={{fontSize: '12px', lineHeight: '16px', color: '#027A48'}}>{dayjs(card.deliveryDate).format('MMMM D, YYYY')}</Typography>
          </Box>
        </Box>;
    }
  }

  function getLastPostDate() {
    if(card.lastPostDate) return <Typography variant="body2" sx={{ fontSize: '14px', lineHeight: '16px', color: '#667085'}} >{dayjs(card.lastPostDate).format('MMMM D, YYYY')}</Typography>
    return <Typography variant="body2" sx={{ fontSize: '14px', lineHeight: '16px', color: '#667085'}} >No messages yet</Typography>;
  }

  return(
    <>
      <Box sx={{
        display: 'flex',
        width: '100%',
        maxWidth: {md: '46%',lg: '32%'},
        flexDirection:{xs: 'column'},
        borderRadius: '8px',
        border: '1px solid #EAECF0',
        background: '#FFF',
        boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
        marginBottom: '20px'
      }}>
        <Box sx={{ paddingTop: '10px', paddingBottom: '10px', paddingLeft:'10px',display:'flex', flexDirection: 'row', justifyContent: 'space-between', background: '#F9FAFB',borderRadius: '8px 8px 0 0', borderBottom: '1px solid #EAECF0'}}>
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%', gap: '10px'}}>
            { card?.template?.avatarImage && <Box sx={{width: '40px', textAlign: 'center'}}><img src={card.template.avatarImage} width="100%" alt="Card's avatar" /> </Box>}
            {/*{ !card.template.avatarImage && <Box sx={{width: '40px', textAlign: 'center'}}>{grayPersonIcon}</Box>}*/}
            <Box>
              <Typography variant="body1" sx={{fontSize: '14px'}} >{card.recipients[0] ? card.recipients[0].fullName : ''}</Typography>
              <Typography variant="body1" sx={{fontWeight: 700, fontSize: '14px'}} >{card.title}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', paddingTop:'4px', paddingRight:'10px'}}><MenuFeature card={card} /></Box>
        </Box>
        <Row title="Created" component={<Typography variant="body2" sx={{ fontSize: '14px', lineHeight: '16px', color: '#667085'}} >{dayjs(card.createdAt).format('MMMM D, YYYY')}</Typography>} />
        <Row title="Messages" component={<Chip sx={{backgroundColor: 'rgba(249, 245, 255, 1)'}} label={`${card.totalMessages}/${card.maxMsg}`}/>} />
        <Row title="Type" component={getCardTierMessage()} />
        <Row title="Status" component={getDeliveryStatus()}/>
        <Row title="Last Message" component={getLastPostDate()}/>
        <Box sx={{padding:'10px 20px 0px 20px'}}><GradientButton onClick={() => navigate(`/card/${card.id}`)} text="View Group Card" /></Box>
      </Box>
    </>
 )
}


