import Box from '@mui/material/Box';
import { happyFaceSvg, penIcon, inviteIcon, smallCrownIcon, cogIcon, trashIcon } from '../../../svgIcons/icons';
import  Typography  from '@mui/material/Typography';
import Divider from '../../../components/divider/Divider';

export default function LeftPanel(){
  return(
    <Box component="div">
      <Box component="div" className='editCardTileContainer'>
                    {happyFaceSvg}
                    <Typography sx={{ marginLeft: '10px'}}component="p" variant='subtitle2'><b>Free</b> Group Card</Typography>
                </Box>
                <Box component="div" className='cardDetailsContainer'>
                    <Box component="div" sx={{ float:'right'}}>{cogIcon}</Box>
                    <Box component="div" className='detailsGroupContainer'>
                      <Typography sx={{ marginLeft: '10px', fontWeight: 600}}component="p" variant='subtitle2'>Recipient</Typography>
                      <Typography sx={{ marginLeft: '10px', fontWeight: 400}}component="p" variant='subtitle2'>Max Cabrera</Typography>
                    </Box>
                    <Box component="div" className='detailsGroupContainer'>
                      <Typography sx={{ marginLeft: '10px', fontWeight: 600}}component="p" variant='subtitle2'>Group Card Title</Typography>
                      <Typography sx={{ marginLeft: '10px', fontWeight: 400}}component="p" variant='subtitle2'>Happy Birthday!!</Typography>
                    </Box>
                    <Box component="div" className='detailsGroupContainer'>
                      <Typography sx={{ marginLeft: '10px', fontWeight: 600}}component="p" variant='subtitle2'>Sender or Team Name</Typography>
                      <Typography sx={{ marginLeft: '10px', fontWeight: 400}}component="p" variant='subtitle2'>Alessandra</Typography>
                    </Box>
                </Box>
                <Divider/>
                <Box component="div" className='editCardTileContainer'>
                    {penIcon}
                    <Typography sx={{ marginLeft: '10px'}}component="p" variant='subtitle2'>Design</Typography>
                </Box>
                <Box component="div" className='editCardTileContainer'>
                    {inviteIcon}
                    <Typography sx={{ marginLeft: '10px'}}component="p" variant='subtitle2'>Invite Contributors</Typography>
                </Box>
                <Box component="div" className='editCardTileContainer'>
                    {smallCrownIcon}
                    <Typography sx={{ marginLeft: '10px'}}component="p" variant='subtitle2'>Upgrade to <b>Premium</b></Typography>
                </Box>
                <Box component="div" className='editCardTileContainer'>
                    {trashIcon}
                    <Typography sx={{ marginLeft: '10px'}}component="p" variant='subtitle2'>Delete Group Card</Typography>
                </Box>
                <Divider />
    </Box>
  )
}