import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {CreateGroupCardGraph, InviteOtherGraph, ShareWithOthersGraph} from "../../svgIcons/icons";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import PurplePill from "../../components/purplePill/PurplePill";
import TitleText from "../../components/titleText/TitleText";
import VideoPlayer from "../../components/videoPlayer/VideoPlayer";

import {resetCardState} from "../../slices/cardSlice";
import {linkPaths} from "../../utils/linkPaths";
import Divider from "../../components/divider/Divider";
import React from "react";


export default function WhatEmployeesGet(){
  const navigate = useNavigate();
  const dispatch = useDispatch()

  function goCreateCard() {
    dispatch(resetCardState())
    navigate(linkPaths.create.path)
  }
  return (
    <Box sx={{ paddingBottom: 0, width: '100%'}}>
      <Grid container maxWidth="lg" sx={{margin:'50px auto 0 auto',padding: { xs: '40px 17px'}}}>
        <Grid item xs={12}>
          <PurplePill maxWidth='180px' >Rewards</PurplePill>
          <TitleText align="center">What your employees get</TitleText>
          <Typography variant="subtitle2" sx={{ textAlign:{ xs: 'center'}, marginBottom:{xs:'30px', md: '60px'} }}>Digital group cards filled with support and celebration messages from team members.</Typography>
        </Grid>
        <Grid item xs={12} >
          <Box sx={{width:'300px', margin: '0 auto'}}>
            <img width="100%" src="https://storage.googleapis.com/prod-website-images-rlink/videos/scrolling-2.gif" alt="recognition" />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ paddingTop:{xs:'40px', md: '40px'}, width:{xs:'80%', sm:'60%', md:'80%'}, margin: '0 auto', textAlign:{ xs:'center'} }}>
            <Typography variant="subtitle1">1. Digital Group Cards</Typography>
            <Typography variant="body2" sx={{marginTop: '20px'}}>Employees receive digital group cards with heartfelt messages from their team. Celebrate birthdays, work anniversaries, and any other occasion. These messages promote unity and joy, making someone’s day truly special, regardless of distance.</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ paddingTop:{xs:'40px', md: '40px'}, width:{xs:'80%', sm:'60%', md:'80%'}, margin: '0 auto', textAlign:{ xs:'center'} }}>
            <Typography variant="subtitle1" >2. Gift Cards</Typography>
            <Typography variant="body2" sx={{marginTop: '20px'}}>Enhance your appreciation by assigning monetary rewards to some of your cards. Employees can choose from over 1,000 gift cards, prepaid cards, charitable donations, or cash out via PayPal. Supported in 80+ countries and 20+ currencies, this flexibility ensures a meaningful reward for everyone.</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ paddingTop:{xs:'40px', md: '40px'}, width:{xs:'80%', sm:'60%', md:'80%'}, margin: '0 auto', textAlign:{ xs:'center'} }}>
            <Typography variant="subtitle1" >3. Connect with Team Members</Typography>
            <Typography variant="body2" sx={{marginTop: '20px'}}>Employees receiving the group card have the option to reply to those who contributed their messages. This fosters connections between the celebrated individual and their supportive colleagues, strengthening team bonds and enhancing the sense of community.</Typography>
          </Box>
        </Grid>

      </Grid>
    </Box>
  )
}