import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { useGetMessageQuery, useUpdateMessageMutation} from "../../slices/api/apiSlice";
import {useGetCardQuery} from "../../slices/api/cardApiSlice";
import InfoSnackbar from "../../components/infoSnackbar/InfoSnackbar";
import {useEffect, useState} from "react";
import MessageContentInputFields from "../addMessage/blocks/MessaContentInputField";
import mediaTypes from "../../utils/mediaTypes";
import {removeFromArray} from "../../utils/functions";
import {useTrackDownloadMutation} from "../../slices/api/unsplashApiSlice";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export default function EditMessagePage() {
  const { cardId, messageId } = useParams();
  const [trackDownload] = useTrackDownloadMutation();

  const {
    data: cardResponse,
    isSuccess: cardIsSuccess,
  } = useGetCardQuery(cardId)


  const {
    data: messageResponse,
    isSuccess: messageIsSuccess,
    isError,
    error: errorMessage
  } = useGetMessageQuery(messageId)

  const [creatorName, setCreatorName] = useState('');
  const [creatorEmail, setCreatorEmail] = useState('');
  const [messageText, setMessageText] = useState('');
  const [media, setMedia] = useState({ type: null, data: []});

  const [showImagesModal, setShowImagesModal] = useState(false);
  const [showGifModal, setShowGifModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showMusicModal, setShowMusicModal] = useState(false);
  const [showUnsplashModal, setShowUnsplashModal] = useState(false);

  const [error, setError] = useState('');

  useEffect(() => {
    if(messageIsSuccess) {
      const message = messageResponse.message;
      setCreatorName(message.creatorName);
      setCreatorEmail(message.creatorEmail);
      setMessageText(message.message);
      setMedia(message.media);
    }
  }, [messageIsSuccess, messageId]);




  const navigate = useNavigate();

  const [updateMessage,{isLoading: updatingMessage, error:updateError, isSuccess: messageUpdated}] = useUpdateMessageMutation();

  function updateName(e) {
    setCreatorName(e.target.value)
  }

  function updateEmail(e){
    setCreatorEmail(e.target.value)
  }

  function updateMessageInput(e){
    if(e.target.value.length >= 2000) {
      setError('Your reached the characters limit, please use a maximum of 2000 characters');
      return setMessageText(e.target.value.substring(0,2000))
    }
    setMessageText(e.target.value)
  }



  async function handleUpdateMessage(e) {
    if(e) {
      e.preventDefault();
    }
    try {
      await updateMessage({messageId,cardId, creatorName, message: messageText, media}).unwrap()

      navigateToCard();
    } catch (e){
      console.log('error updating message',e)
    }
  }

  function updateMedia(mediaLoad){
    setMedia(mediaLoad)
  }

  function addImage(image) {
    setMedia(prevState => {
      return {
        type: prevState.type || mediaTypes.USER_IMAGE,
        data: [
          ...prevState.data,
          image
        ]
      }
    })
  }

  function removeImage(image) {
    const newImages = removeFromArray(media.data, image);
    if(newImages.length > 0) {
      setMedia(prevState => {
        return {
          type: prevState.type || mediaTypes.USER_IMAGE,
          data: newImages
        }
      })
    } else {
      setMedia(prevState => {
        return {
          type: '',
          data: []
        }
      })
    }

  }

  function navigateToCard() {
    navigate(`/card/${cardResponse.card.id}`)
  }

  function addUnsplashImage(image) {
    setMedia({
      type: mediaTypes.UNSPLASH_IMAGE,
      data: [{fileUrl: image.photoRaw + "&w=600&dpr=2", unsplash: image}]
    })
    setShowUnsplashModal(false)

    try {
      trackDownload(image);
    }catch (e) {
      console.error('error updating downloads in Unsplash',e)
    }
  }

  function removeUnsplashImage(image) {
    setMedia({
      type: null,
      data: []
    })
    setShowUnsplashModal(false)
  }


  return(
    <Box component='div' className="contentContainer" sx={{ padding:{ xs: '30px 20px', md: '30px 20px', lg: '50px 20px'}, background: 'none' }}>
      {error && <InfoSnackbar message={error} callback={() => setError('')} onClick={() => setError('') } severity="error" />}
      {errorMessage && <InfoSnackbar message={errorMessage.data.error} severity="error" />}
      {errorMessage &&
        <Box sx={{ display: 'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', margin: '0 auto'}}>
          <Typography>It seems you don't have permissions to edit this message.</Typography>
          <Button onClick={navigateToCard}>Go back to card</Button>
        </Box>
      }
      { messageIsSuccess && cardIsSuccess &&
        <MessageContentInputFields
          isLoading={updatingMessage}
          navigateToCard={navigateToCard}
          card={cardResponse.card}
          creatorName={creatorName}
          updateName={updateName}
          creatorEmail={creatorEmail}
          updateEmail={updateEmail}
          messageText={messageText}
          updateMessage={updateMessageInput}
          setShowGifModal={setShowGifModal}
          showGifModal={showGifModal}
          media={media}
          updateMedia={updateMedia}
          setShowImagesModal={setShowImagesModal}
          showImagesModal={showImagesModal}
          addImage={addImage}
          removeImage={removeImage}
          addUnsplashImage={addUnsplashImage}
          removeUnsplashImage={removeUnsplashImage}
          showUnsplashModal={showUnsplashModal}
          setShowUnsplashModal={setShowUnsplashModal}
          setShowVideoModal={setShowVideoModal}
          showVideoModal={showVideoModal}
          setShowMusicModal={setShowMusicModal}
          showMusicModal={showMusicModal}
          handleConfirmation={handleUpdateMessage}
          edit={true}
        />
      }


    </Box>
  )
}

