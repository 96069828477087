import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Box from "@mui/material/Box";

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;

  video, img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
`;

const PlayIcon = styled(PlayCircleIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 134px;
  font-size: 134px !important;
  height: 134px;
  color: #7F56D9;
  cursor: pointer;
  background-color: #FFFFFF;
  border-radius: 50%;
  z-index: 1;
`;

const VideoPlayer = ({ src, width, height, coverImage = false }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
console.log('cover',coverImage)
console.log('isPlaying',isPlaying)

  return (
    <>
      {!width && !height &&
        <VideoContainer>
          {!isPlaying && coverImage && <img src="https://storage.googleapis.com/prod-website-images-rlink/videos/scrolling-2.gif" onClick={handlePlayPause} alt="cover" />}
          {!isPlaying && <PlayIcon onClick={handlePlayPause} />}
          <video ref={videoRef} controls onClick={handlePlayPause} style={{ display: isPlaying ? 'block' : 'none' }}>
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </VideoContainer>
      }
      {width &&
        <div style={{ position: 'relative', width, height }}>
          {!isPlaying  &&  <img width={width} src="https://storage.googleapis.com/prod-website-images-rlink/videos/scrolling-2.gif" onClick={handlePlayPause} alt="cover" />}
          {/*{!isPlaying && coverImage &&<Box sx={{ height: '100%' }}><img width={width} src="https://storage.googleapis.com/prod-website-images-rlink/videos/scrolling-2.gif" onClick={handlePlayPause} alt="cover" /></Box>}*/}
          {!isPlaying && <PlayIcon onClick={handlePlayPause} />}
          <video ref={videoRef} width={width} height={height} controls onClick={handlePlayPause} style={{ display: isPlaying ? 'block' : 'none' }}>
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      }
    </>
  );
};

export default VideoPlayer;
