import Box from "@mui/material/Box";
import TitleText from "../../components/titleText/TitleText";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Loader} from "@giphy/react-components";
import {useGetReplyQuery} from "../../slices/api/replyApiSlice";
import {Card} from "@mui/material";
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";
import Media from "../../components/message/messageBlocks/Media";
import TextMessageArea from "../../components/message/messageBlocks/TextMessageArea";
import AuthorArea from "../../components/message/messageBlocks/AuthorArea";
import Button from "@mui/material/Button";


export default function GotMessagePage(){
  const {replyId} = useParams();
  const {
    data: reply,
    isLoading,
    isSuccess,
  } = useGetReplyQuery(replyId)

  const navigate = useNavigate();


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return(
    <Box sx={{minHeight: {xs:'calc(100vh - 70px)', md: 'calc(100vh - 100px)'} }}>
      <Box sx={{
        backgroundImage: `url(https://storage.googleapis.com/prod-website-images-rlink/blue-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/red-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/orange-vector.svg)`,
        backgroundPosition: 'top right, top 20% left, bottom right',
        backgroundRepeat: 'no-repeat',
        minHeight: {xs:'calc(100vh - 70px)', md: 'calc(100vh - 100px)'}
      }}>

        {isLoading &&
          <>
            <TitleText align="center">Loading message...</TitleText>
            <Loader />
          </>}
        {isSuccess &&
          <Box sx={{ wordBreak:'break-word', width:'100%', minHeight:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', padding: {xs: '20px 0', md:'30px'} }}>
            <Box sx={{textAlign:'center'  }}>
              <Typography variant="h4" sx={{marginBottom:'20px'}}>Message from {reply.creatorName}</Typography>
            </Box>
            <Card variant="outlined" sx={{width:{xs:'92%', md:'600px'} }}>
              <CardContent>

                <Media message={reply}/>
                <TextMessageArea message={reply} />
                <AuthorArea  message={reply}  />
              </CardContent>
            </Card>
            <Button onClick={() => navigate(`/card/${reply.cardId}`)} sx={{ maxWidth:'200px', margin:'40px auto'}} variant="outlined" color="secondary">Go to card</Button>
          </Box>
        }
      </Box>
    </Box>

  )
}