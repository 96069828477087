import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useCreateReplyMutation} from "../../slices/api/replyApiSlice";
import Dialog from "@mui/material/Dialog";
import TransitionComponent from "../transitionComponent/TransitionComponent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Divider from "../divider/Divider";
import Grid from "@mui/material/Grid";
import ReplyForm from "./ReplyForm";
import Box from "@mui/material/Box";
import {previewImageIcon} from "../../svgIcons/icons";
import Message from "../message/Message";
import {useEffect, useState} from "react";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";
import {clearReply} from "../../slices/replySlice";

export default function ReplyDialog({ open = false, setOpen = () => {}, card }) {
  const dispatch = useDispatch();
  const replyForm = useSelector(state => state.replyForm);
  const mediaIsSet = replyForm.media.type !== '' && replyForm.media.data.length > 0;
  const [sendReply, {isError, error: errorReply, isSuccess}] = useCreateReplyMutation();
  const[successMessage, setSuccessMessage] = useState('');
  const [localSuccess, setLocalSuccess] = useState(false);

  useEffect(()=>{
    if(isSuccess) {
      setLocalSuccess(true)
    }
  },[isSuccess])
  const handleClose = () => {
    setOpen(false);
    setLocalSuccess(false);
  };


  const handleSend = async () => {
    try {
      const reply = await sendReply(replyForm).unwrap();
      setSuccessMessage(reply.message)
      // handleClose();
      dispatch(clearReply());
    } catch (e) {
      console.log(e)
    }
  }

  console.log('isSuccess',isSuccess)

  return (
    <>
      {errorReply && <InfoSnackbar severity="error" message={errorReply.data.error} />}
      {successMessage && <InfoSnackbar message={successMessage} callback={()=>setSuccessMessage('')} severity="success"/> }
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={TransitionComponent}
      >
        {localSuccess &&
        <>
          <DialogTitle>
            <Typography sx={{
              fontSize: '30px',
              fontWeight: '700',
              lineHeight: '38px',
              color: '#7F56D9',
              marginBottom:'10px',
            }} >Almost there</Typography>
          </DialogTitle>
          <Divider small={true} />
          <DialogContent sx={{ paddingTop: 0 }}>
            <Grid container maxWidth="lg" sx={{margin: '0 auto'}}  >
              <Grid item xs={12}>
                <Box sx={{paddingTop:'20px',width: '100%', maxWidth:'420px', margin: '0 auto', textAlign:'center'}}>

                 <Typography variant="h2" sx={{marginBottom: '20px'}}>Please Check your email for next steps</Typography>
                  <Typography variant="body" >Look for our email in your inbox and click on Confirm to send your reply</Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider small={true} />
          <DialogActions sx={{ justifyContent:'center'}}>
            <Button sx={{maxWidth:{md:'200px'} }} variant="outlined" color="secondary" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </>
        }
        {!localSuccess && <>
          <DialogTitle>
            <Typography sx={{
              fontSize: '30px',
              fontWeight: '700',
              lineHeight: '38px',
              color: '#7F56D9',
              marginBottom:'10px',
            }} >Reply</Typography>
            <Typography variant="body1">
              Write a reply for those who added messages to your card, include a video to make it extra personal
            </Typography>
          </DialogTitle>
          <Divider small={true} />
          <DialogContent sx={{ paddingTop: 0 }}>
            <Grid container maxWidth="lg" sx={{margin: '0 auto'}}  >
              <Grid item xs={12} md={6} >
                <ReplyForm />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{paddingTop:'20px',width: '100%', maxWidth:'420px', margin: '0 auto'}}>
                  {!mediaIsSet &&
                    <Box component="div" sx={{ padding: '120px 0', width: '100%', marginBottom: '0', borderRadius: '8px 8px 0 0', backgroundColor: '#F4F4F4', textAlign:'center' }}>
                      {previewImageIcon}
                      <Typography variant="body2">Add media for preview</Typography>
                    </Box> }
                  <Message
                    preview={true}
                    message={{creatorName: replyForm.creatorName, creatorEmail: replyForm.creatorEmail, message:replyForm.message, media: replyForm.media}}
                    card={card} />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider small={true} />
          <DialogActions sx={{ justifyContent:'center'}}>
            <Button sx={{maxWidth:{md:'200px'} }} variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button sx={{maxWidth:{md:'200px'} }} variant="contained" onClick={handleSend}>
              Send reply
            </Button>
          </DialogActions>
        </>}
      </Dialog>
    </>
  );
}
