import { apiSlice } from "./apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    register: builder.mutation({
      query: userInfo => ({
        url: '/users',
        method: 'POST',
        body: { ...userInfo }
      }),
      invalidatesTags:['Card','MyCards','Messages'],
    }),
    login: builder.mutation({
      query: credentials => ({
        url: '/auth/login-local',
        method: 'POST',
        body: {...credentials}
      }),
      invalidatesTags:['Card','MyCards','Messages', 'Subscriptions'],
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
        body: {}
      }),
      invalidatesTags:['Card','MyCards','Messages','Subscriptions'],
    }),
    getTokens: builder.query({
      query: () => `/auth/tokens`,
      invalidatesTags:['Card','MyCards','Messages'],
    }),
    getTempCreds: builder.mutation({
      query: credentials => ({
        url: '/auth/temp-creds',
        method: 'POST',
        body: {...credentials}
      }),
      invalidatesTags:['Card','MyCards','Messages'],
    }),
    resetPasswordRequest: builder.mutation({
      query: body => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body: {...body}
      }),
      invalidatesTags:['Card','MyCards','Messages'],
    }),
    resetPassword: builder.mutation({
      query: body => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: {...body}
      }),
      invalidatesTags:['Card','MyCards','Messages'],
    }),
  })
})

export const { useRegisterMutation, useLoginMutation, useLogoutMutation, useGetTokensQuery, useGetTempCredsMutation, useResetPasswordRequestMutation, useResetPasswordMutation } = authApiSlice;