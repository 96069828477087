import ImagesByUser from "./ImagesByUser";
import "react-image-gallery/styles/css/image-gallery.css";
import "./media.css"
import mediaTypes from "../../../utils/mediaTypes";
import GifByGiphy from "./GifByGiphy";
import VideoDisplay from "./VideoDisplay";
import VideoWithUrl from "./VideoWithUrl";
import GifImage from "./GifImage";


export default function Media({message}){
  if(!message.media) return null;

  if(message.media.type === mediaTypes.USER_IMAGE) return <ImagesByUser message={message} />;
  if(message.media.type === mediaTypes.UNSPLASH_IMAGE) return <ImagesByUser message={message} />;
  // if(message.media.type === mediaTypes.GIPHY_IMAGES) return <GifByGiphy message={message} />;
  if(message.media.type === mediaTypes.GIPHY_IMAGES) return <GifImage message={message} />;
  if(message.media.type === mediaTypes.USER_VIDEO) return <VideoDisplay message={message} />;
  if(message.media.type === mediaTypes.VIDEO_EMBED) return <VideoWithUrl message={message} />;

}




