import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {CloseIcon, goodCheckMark} from "../../svgIcons/icons";
import {useLocation, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import {isDefaultBackground} from "../../utils/functions";

const CardReadyMessage = ({card, action = () =>{}}) => {
  // State to manage whether the component is visible or not
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation()
  const cardId = card.id;
  // Check local storage on component mount to see if it should be visible
  useEffect(() => {
    const isClosed = localStorage.getItem(`isClosed-${cardId}`);
    if (isClosed !== 'true') {
      setIsVisible(true);
    }
  }, [cardId, location.search]);

  // Handler for closing the component
  const handleClose = () => {
    localStorage.setItem(`isClosed-${cardId}`, 'true');
    setIsVisible(false);
  };

  const openBackgroundOptions = () => {
    handleClose();
    action();
  }

  if (!isVisible) return null;

  function getActionButtons() {
    if(card && card?.template?.cardBackgroundImage) {
      if (isDefaultBackground(card.template.cardBackgroundImage)) {
        return <Box sx={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
        <Button sx={{marginTop:'20px'}} variant="contained" onClick={openBackgroundOptions}>Customize Background</Button>
        <Button sx={{padding: '8px', marginTop:'10px'}} variant="outlined" color="secondary" onClick={handleClose}>Ok</Button>
          </Box>
      }
    }
    return <Button sx={{padding: '5px', marginTop:'20px'}} variant="outlined" color="secondary" onClick={handleClose}>Got it</Button>;
  }

  return (
      <Grid id="you-card-is-ready-message" data-testid="your-card-is-ready-message" container sx={{
        maxWidth: '260px',
        marginTop: 0,
        marginLeft: 'auto', marginRight: 'auto',
        backgroundColor:'#F9F5FF',
        padding: {xs:'20px'},
        borderRadius: '8px', boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.5)'}}>
        <Grid item xs={12} sx={{textAlign:'center'}}>
            <Typography variant="h4" sx={{ fontSize:{ xs: '18px', sm: '24px',md: '24px'}, lineHeight:{ xs: '24px', sm:'28px', md:'30px'}, marginBottom:{xs: '10px', sm: '12px'} }}>Congrats! </Typography>
            <Typography variant="body1" >Your card has been created</Typography>
          <Box>
            {getActionButtons()}
          </Box>
        </Grid>
      </Grid>

  );
};

export default CardReadyMessage;