import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";

export default function Divider({small = false, label = null, lineColor = '#EAECF0' }){
  let style = {
    height: '1px', position: 'relative',
    borderBottom: `1px solid ${lineColor}`,
    margin: { xs: '15px auto', md: '15px auto', lg: small ? '15px auto' : '30px auto 35px'  },
    width: { xs: '96%', sm:'100%' }, }
  return(
    <Box component="div" sx={style}>
      {label && <Box component="span" sx={{ position: 'absolute', top: '-12px', left:'45%', background: '#FFF', padding: '0 10px'}}><Typography variant="body2" sx={{fontSize: '14px'}}>{label}</Typography></Box>}
    </Box>
  )
}