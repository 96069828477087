import Masonry from '@mui/lab/Masonry';
import Grid from "@mui/material/Grid";
import Message from "../message/Message";
import AddMessageButton from "../../pages/groupCard/AddMessageButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";


export default function ShowMessages({fake = false,messages, card, showAddMessageButton = true}){
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.only('xs'));

  return(<Grid container maxWidth='xl' sx={{ marginLeft: 'auto', marginRight: 'auto'}}>
    <Grid item xs={12}>
      <Masonry sx={{marginTop: '20px'}} columns={{
        xs:1,
        sm: 2,
        md: 3,
      }} spacing={xsScreen ? 0 : 2}>
        {showAddMessageButton && <AddMessageButton cardId={card.id} />}
        {messages.map((message) => (
          <Box key={message.id} sx={{background:' none', paddingBottom:{xs:'20px', sm: '0'}}}>
            <Message fake={fake} card={card}  message={message} messages={messages} />
          </Box>
        ))}
      </Masonry>

    </Grid>
  </Grid>)
}