import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import {useEffect, useState} from "react";
import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MediaButtons from "./MediaButtons";
import {useDispatch, useSelector} from "react-redux";

import {
  creatorNameUpdated,
  creatorEmailUpdated,
  messageUpdated,
  setShareMyEmail,
  setMessageIds,
  setCardId } from "../../slices/replySlice";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";
import {useParams} from "react-router-dom";
import {useGetMessagesQuery} from "../../slices/api/apiSlice";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ReplyForm(){
  const { cardId } = useParams();
  const dispatch = useDispatch();
  const replyForm = useSelector(state => state.replyForm);
  const user = useSelector(state => state.auth.user);
  const [error, setError] = useState('');
  const [queryInfo, setQueryInfo] = useState({
    cardId,
    page: 1,
    limit: 250  // Example: 10 messages per page
  });

  const {
    data: messages,
    loading: getMessagesIsLoading,
    isSuccess: gettingMessagesIsSuccess,
    isFetching: getMessagesIsFetching,
    isError: getMessagesIsError,
  } = useGetMessagesQuery(queryInfo, {
    keepPreviousData: false,
  });

  useEffect(() => {
    if(user?.email) {
      dispatch(creatorEmailUpdated(user.email))
    }

    if(user?.fullName) {
      dispatch(creatorNameUpdated(user.fullName))
    }

    if(cardId) {
      dispatch(setCardId(cardId))
    }
  }, []);

  function handleCreatorNameUpdate(e) {
    if(e.target.value.length >= 80) {
      dispatch(creatorNameUpdated(e.target.value.substring(0,80)))
      return setError('Names can only be up to 80 characters log');
    }
    return dispatch(creatorNameUpdated(e.target.value))
  }

  function handleCreatorEmailUpdate(e) {
    if(e.target.value.length >= 254) {
      dispatch(creatorEmailUpdated(e.target.value.substring(0,254)))
      return setError('Emails can only be up to 254 characters log');
    }
    return dispatch(creatorEmailUpdated(e.target.value))
  }

  function handleMessageUpdate(e) {
    if(e.target.value.length >= 750) {
      dispatch(messageUpdated(e.target.value.substring(0,750)))
      return setError('Message can contain up to 2000 characters');
    }
    return dispatch(messageUpdated(e.target.value))
  }

  function handleShareMyEmail(e) {
    dispatch(setShareMyEmail(e.target.checked))
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.includes("all")) {
      if (replyForm.messageIds.length === messages.length) {
        dispatch(setMessageIds([]));
      } else {
        dispatch(setMessageIds(messages.map((message) => message.id)));
      }
    } else {
      const newValue = Array.isArray(value) ? value : value.split(",");
      dispatch(setMessageIds(newValue));
    }
  };

  const isChecked = (id) => replyForm?.messageIds?.includes(id);

  const handleItemChange = (id) => {
    if (id === "all") {
      if (replyForm.messageIds.length === messages.length) {
        dispatch(setMessageIds([]))
      } else {
        dispatch(setMessageIds(messages.map((message) => message.id)))
      }
    } else {
      if (isChecked(id)) {
        dispatch(setMessageIds(()=> replyForm.messageIds.filter((messageId) => messageId !== id)))
      } else {
        dispatch(setMessageIds(()=>[...replyForm.messageIds, id]))
      }
    }
  };
  return(
    <>
      {error && <InfoSnackbar severity="error" message={error} callback={()=> setError('')}  />}
      <Typography
        variant="body1"
        sx={{
          paddingTop: "0px",
          lineHeight: "20px",
          fontSize: "14px",
          color: "rgba(52, 64, 84, 1)",
          fontWeight: 500,
        }}
      >
        Your Name
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        onChange={handleCreatorNameUpdate}
        value={replyForm.creatorName}
        id="name"
        name="name"
      />

      <Typography
        variant="body1"
        sx={{
          paddingTop: "0px",
          lineHeight: "20px",
          fontSize: "14px",
          color: "rgba(52, 64, 84, 1)",
          fontWeight: 500,
        }}
      >
        Your Email*
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        name="email"
        autoComplete="email"
        type="email"
        onChange={handleCreatorEmailUpdate}
        value={replyForm.creatorEmail}
      />
      <Typography variant="caption">
        You'll need to confirm your email address before the reply goes out
      </Typography>

      <FormGroup>
        <FormControlLabel control={<Checkbox onChange={handleShareMyEmail} checked={replyForm.shareEmail} />} label="Share my email with recipients" />
      </FormGroup>

      <Typography
        variant="body1"
        sx={{
          marginTop: '20px',
          paddingTop: "0px",
          lineHeight: "20px",
          fontSize: "14px",
          color: "rgba(52, 64, 84, 1)",
          fontWeight: 500,
        }}
      >
        Who is getting the reply?
      </Typography>
      {gettingMessagesIsSuccess &&
        <FormControl sx={{ m: 1, minWidth: 300, marginBottom: "20px" }}>
          <Select
            displayEmpty
            label="For"
            id="messageIds"
            multiple
            value={replyForm.messageIds}
            onChange={handleChange}
            input={<OutlinedInput label="For" />}
            renderValue={(selected) => {
              if (replyForm.messageIds.length === messages.length) {
                return 'All';
              }

              const messagesList = selected.map((item) => {
                const found = messages.find((message) => message.id === item);
                return found ? found.creatorName : "";
              });

              if (messagesList.length > 2) {
                const results = messagesList.slice(0, 2);
                results.push("...");
                return results.join(", ");
              } else {
                return messagesList.slice(0, 2).join(", ");
              }
            }}
            MenuProps={MenuProps}
          >
            <MenuItem value="all">
              <Checkbox
                checked={replyForm.messageIds.length === messages.length}
                onChange={() => handleItemChange("all")}
              />
              <ListItemText primary="All" />
            </MenuItem>
            {messages.map((message) => (
              <MenuItem key={message.id} value={message.id}>
                <Checkbox
                  checked={isChecked(message.id)}
                  onChange={() => handleItemChange(message.id)}
                />
                <ListItemText primary={message.creatorName} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }

      <Typography
        variant="body1"
        sx={{
          paddingTop: "0px",
          lineHeight: "20px",
          fontSize: "14px",
          color: "rgba(52, 64, 84, 1)",
          fontWeight: 500,
        }}
      >
        Your reply
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="message"
        name="message"
        sx={{
          background: "#FFFFFF",
          borderRadius: "8px",
          marginTop: "10px",
          padding: "0",
        }}
        multiline
        rows={3}
        onChange={handleMessageUpdate}
        value={replyForm.message}
      />
      <Box sx={{textAlign:'right', marginBottom:'20px'}}>
        <Chip size="small" label={`${replyForm.message.length}/750`}  />
      </Box>
      <MediaButtons />
    </>
  )
}