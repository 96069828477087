import Box from "@mui/material/Box";
import {Loader} from "@giphy/react-components";

export default function Uploading({isLoading}){
  if(!isLoading) return null;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', margin: '20px auto'}}>
      <Loader/>
    </Box>
  )
}