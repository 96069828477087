import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const stepCompletedIcon = <svg style={{ marginLeft: '3px'}} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_389_4398)">
        <rect width="32" height="32" rx="16" fill="#6941C6"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M22.7952 9.85322L13.2485 19.0666L10.7152 16.3599C10.2485 15.9199 9.51517 15.8932 8.98184 16.2666C8.46184 16.6532 8.31517 17.3332 8.63517 17.8799L11.6352 22.7599C11.9285 23.2132 12.4352 23.4932 13.0085 23.4932C13.5552 23.4932 14.0752 23.2132 14.3685 22.7599C14.8485 22.1332 24.0085 11.2132 24.0085 11.2132C25.2085 9.98655 23.7552 8.90655 22.7952 9.83989V9.85322Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_389_4398">
        <rect width="32" height="32" rx="16" fill="white"/>
        </clipPath>
        </defs>
        </svg>;

    const currentStepIcon = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_389_4407)">
        <g clipPath="url(#clip0_389_4407)">
        <rect x="4" y="4" width="32" height="32" rx="16" fill="#F4EBFF"/>
        <rect x="5" y="5" width="30" height="30" rx="15" fill="#F9F5FF"/>
        <circle cx="20" cy="20" r="5" fill="#7F56D9"/>
        <rect x="5" y="5" width="30" height="30" rx="15" stroke="#7F56D9" strokeWidth="2"/>
        </g>
        </g>
        <defs>
        <filter id="filter0_d_389_4407" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feMorphology radius="4" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_389_4407"/>
        <feOffset/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.956863 0 0 0 0 0.921569 0 0 0 0 1 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_389_4407"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_389_4407" result="shape"/>
        </filter>
        <clipPath id="clip0_389_4407">
        <rect x="4" y="4" width="32" height="32" rx="16" fill="white"/>
        </clipPath>
        </defs>
        </svg>;        

     const inactiveStep = <svg style={{marginTop: '2px', marginLeft: '2px'}} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="5" fill="#EAECF0"/>
        <rect x="1" y="1" width="30" height="30" rx="15" stroke="#EAECF0" strokeWidth="2"/>
        </svg>;
            
        /* Connectors */
     const activeConnector = <svg width="2" height="94" viewBox="0 0 2 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="2" height="94" rx="1" fill="#7F56D9"/>
        </svg>;

     const inactiveConnector = <svg width="2" height="94" viewBox="0 0 2 94" fill="none" xmlns="http://www.w3.org/2000/svg">
     <rect width="2" height="94" rx="1" fill="#EAECF0"/>
     </svg>;


export default function Step({step}){
    const { status = 'inactive', lastStep = false, cb, title, description} = step;

    let icon = inactiveStep;
    let connector = inactiveConnector;

    switch(status) {
       case 'active':
         icon = currentStepIcon;
         connector = activeConnector;
         break;
       case 'completed':
          icon = stepCompletedIcon;
          connector = activeConnector;
          break;

       default:
         icon = inactiveStep;
         connector = inactiveConnector;
    }

    if(lastStep) { connector = null }

    return (
    <Box component="div" sx={{
        // width: '100%',
        maxWidth: '267px',
        height: {
          xs: '30px',
          md: '134px'
        },
        display: 'flex',
        flexDirection: {
          xs: 'row',
        },
        alignItems: {
          xs: 'top',
        },
      flexGrow:{
        xs: step.status === 'active' ? 6 : 0,
        md: 0
      },
      cursor: cb ? 'pointer': 'cursor',
      }} onClick={() => { if(cb) { cb()} else  { return null }}}>

      <Box sx={{
              display: 'flex',
              flexDirection: {
                xs: 'row',
                md: 'column'
              },
              alignItems: {
                xs: 'top',
                md: 'center'
              },
              marginLeft: 0
          }}>
              <Box component="div" sx={{ width: '40px', height: '40px',  paddingTop: { xs: step.status === 'active' ? '0' : '4px', md: 0} }}>{icon}</Box>
              <Box sx={{display: {xs: 'none', md: 'block'}}}>{connector}</Box>
      </Box>

      <Box sx={{ marginLeft: '12px'}}>
              <Typography sx={{
                paddingTop: {
                  xs: '7px',
                  md: '0px'
                },
                display: {
                  xs: step.status === 'active' ? 'block' : 'none',
                  md: 'block',
                },
                color: step.status === 'active' ? 'primary.main' : '#344055',
                fontWeight: '500',
              }} component="p" variant="body2" >
                {title}
              </Typography>
              <Typography sx={{
                display: {
                  xs: 'none',
                  md: 'block',
                }
              }} component="p" variant="body2">
                {description}
              </Typography>
      </Box>
    </Box>)
}