import { useState } from "react";
import  Typography  from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Divider from "../../../components/divider/Divider";
import { closeMessageIcon } from "../../../svgIcons/icons";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete from '@mui/material/Autocomplete';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const occasions = [
  'Birthday', 'Congratulations', 'Graduation', 'Thank You', 'Wedding Anniversary', 'Work Anniversary',   
]

export default function AddEditReminder({closeModal}) {
  const [value, setValue] = useState(null); // date

  const [remindMeIn, setRemindMeIn] = useState(''); // Remind in

  const handleRemindMeInChange = (event) => {
    setRemindMeIn(event.target.value);
  };

  return(
    <Box component='div' sx={{background: '#FFFFFF', minHeight: '520px'}}>
      <Box component="div" sx={{ float:'right', cursor: 'pointer'}} onClick={closeModal}>{closeMessageIcon}</Box>
      <Typography variant="h4">
        Create reminder
      </Typography>
      <Typography variant="body2">Upcoming occasion? Create a reminders to create a Group Card.</Typography>
      <Divider />
      <Box component='form'>
        {/* Recipient's name row */}
        <Grid container>
          <Grid item xs={12} md={3}>
            <Typography variant="body2" 
            sx={{  
              paddingTop: '13px',
              lineHeight: '20px', 
              fontSize: '14px', 
              color: 'rgba(52, 64, 84, 1)', 
              fontWeight: 500}}>
                Recipient's name</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
          <TextField
                margin="normal"
                required
                fullWidth
                id="recipientName"
                name="recipientName"
                autoFocus
              />
          </Grid>
        </Grid>
        {/* Email row */}
        <Grid container>
          <Grid item xs={12} md={3}>
            <Typography variant="body2" 
            sx={{  
              paddingTop: '13px',
              lineHeight: '20px', 
              fontSize: '14px', 
              color: 'rgba(52, 64, 84, 1)', 
              fontWeight: 500}}>
                Recipient's Email Address</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
          <TextField
                margin="normal"
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
              />
          </Grid>
        </Grid>
        {/* end email row */}
        {/* Occasion  and date*/}
        <Grid container>
          <Grid item xs={12} md={3}>
            <Typography variant="body2" 
            sx={{  
              paddingTop: '13px',
              lineHeight: '20px', 
              fontSize: '14px', 
              color: 'rgba(52, 64, 84, 1)', 
              fontWeight: 500}}>Occasion</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
          <Box sx={{display:'flex', gap: '20px', marginTop: '20px'}}>
              
              <FormControl fullWidth>
                <Autocomplete
                  id="occasion-selector"
                  freeSolo
                  options={occasions.map((option) => option)}
                  renderInput={(params) => <TextField {...params}  />}
                />
              </FormControl>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={params?.inputProps?.placeholder} />
                  )}
                />
              </LocalizationProvider>

          </Box>
          </Grid>
        </Grid>
        { /* End occasion and date */}
        {/* Reminde me in */}
        <Grid container>
          <Grid item xs={12} md={3}>
            <Typography variant="body2" 
            sx={{  
              paddingTop: '13px',
              lineHeight: '20px', 
              fontSize: '14px', 
              color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>
                Remind me
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <FormControl fullWidth sx={{ marginTop: '30px'}}>
                <InputLabel id="demo-simple-select-label">Remind me</InputLabel>
                <Select
                  labelId="remindme-in"
                  id="remindme-in"
                  value={remindMeIn}
                  label="Remind me in"
                  onChange={handleRemindMeInChange}
                >
                  <MenuItem value={7}>7 days before</MenuItem>
                  <MenuItem value={14}>14 days before</MenuItem>
                  <MenuItem value={30}>30 days before</MenuItem>
                </Select>
              </FormControl>
            
            
            <Box
                  sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width:'100%'
                  }}>
                      <FormControlLabel sx={{flex: 1}}
                          control={<Checkbox value="remember" color="primary"  />}
                          label="Remember me again next year"
                      />
            </Box>
          </Grid>
        </Grid>
        { /* End remind me in */}
        <Divider/>
        <Box component='div' sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: '10px'
        }}>
          <Button onClick={closeModal} variant='outlined' color="secondary">Cancel</Button>
          <Button variant='contained'>Create reminder</Button>
        </Box>  
      </Box>
    </Box>
  )
}