import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import React, {useState} from "react";
import InfoSnackbar from "../../../components/infoSnackbar/InfoSnackbar";
import {useUpdateCardMutation} from "../../../slices/api/cardApiSlice";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Divider from "../../../components/divider/Divider";
import IOSSwitch from "../../../components/iosSwitch/IOSSwitch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {PremiumCrown} from "../../../svgIcons/icons";

export default function CardSettingsAccess({card, closeDialog}){
  const [updateCard, { isLoading, isError}] = useUpdateCardMutation();
  const [pwd, setPwd] = useState(card.password);
  const [pwdMsg, setPwdMsg] = useState(card.passwordMessage);
  const [checked, setChecked] = useState(card.openToMessages)
  const [error, setError] = useState('');
  const [showPwd, setShowPwd] = useState(false);
  const [showPwdMsg, setShowPwdMsg] = useState(false);


  function handleClickShowPassword(){
    setShowPwd(prevState => !prevState)
  }

  function handleOpenToMessages(e) {
    setChecked(e.target.checked)
  }

  function handleClickShowPasswordMsg(){
    setShowPwdMsg(prevState => !prevState)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  async function handleSave() {
    if(pwd !== '') {
      if(pwd.length < 6)
      return setError("Passwords must be at least 6 character's long");
    }

    if(pwdMsg !== '') {
      if(pwdMsg.length < 6)
        return setError("Passwords must be at least 6 character's long");
    }

    try {
      const cardUpdate = {
        ...card,
        password: pwd,
        passwordMessage: pwdMsg,
        openToMessages: checked,
      }
      await updateCard(cardUpdate).unwrap();
      closeDialog()
    } catch (e) {
      if (e.originalStatus === 401) {
        setError('Not authorized to update the card. Login or register.')
      } else if (e.originalStatus === 403) {
        setError('Not authorized to update the card. Login or register.')
      } else if(e.originalStatus === 404) {
        setError('Card not found')
      } else {
        setError(`Oops, something went wrong on our end... Please try again`)
      }
    }
  }
  return(<>
    {error && <InfoSnackbar onClick={() => setError('') } message={error} severity="error" /> }
  <Grid container>
    <Grid item xs={12} md={5}>
      <Typography variant="body1" sx={{fontWeight: 500}}>New messages</Typography>
      <Typography variant="body2" >Choose if you want to allow or stop receiving new messages</Typography>
    </Grid>
    <Grid item xs={12} md={7}>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center', padding: '30px 20px 30px 20px' }}>
        <FormControlLabel
          control={<IOSSwitch
            checked={!!checked}
            onChange={handleOpenToMessages}
            inputProps={{ 'aria-label': 'controlled' }}
            label={<Typography variant="body1" sx={{ fontWeight: 700}}>Open to new messages</Typography> }
          />}
          label="Open to new messages"
        />


      </Box>
    </Grid>
    <Grid item xs={12} sx={{marginBottom: {xs: '30px', md: '10px'}  }}>
      <Divider />
    </Grid>
    <Grid item xs={12} md={5} sx={{ marginBottom: {md: '40px'} }}>
      <Typography variant="body1" sx={{fontWeight: 500}}>Password to view card <PremiumCrown card={card} width='16px' height='16px'/> </Typography>
      <Typography variant="body2" >The card  will request a password to open up and show messages. Password must be at least 6 characters long. </Typography>

    </Grid>
    <Grid item xs={12} md={7}>
      <OutlinedInput
        id="outlined-adornment-password"
        sx={{ marginBottom: '40px'}}
        fullWidth
        type={showPwd ? 'text' : 'password'}
        value={pwd}
        onChange={(e) => {setPwd(e.target.value)}}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPwd ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label=""
      />
    </Grid>
    {/*<Grid item xs={12} md={5}>*/}
    {/*  <Typography variant="body1" sx={{fontWeight: 500}}>Password to add messages</Typography>*/}
    {/*  <Typography variant="body2" >The card  will request a password to add a message. Password must be at least 6 characters long</Typography>*/}
    {/*</Grid>*/}
    {/*<Grid item xs={12} md={7}>*/}
    {/*  <OutlinedInput*/}
    {/*    sx={{ marginBottom: {xs: '14px'} }}*/}
    {/*    fullWidth*/}
    {/*    id="outlined-adornment-password-msg"*/}
    {/*    type={showPwdMsg ? 'text' : 'password'}*/}
    {/*    value={pwdMsg}*/}
    {/*    onChange={(e) => {setPwdMsg(e.target.value)}}*/}
    {/*    endAdornment={*/}
    {/*      <InputAdornment position="end">*/}
    {/*        <IconButton*/}
    {/*          aria-label="toggle password visibility"*/}
    {/*          onClick={handleClickShowPasswordMsg}*/}
    {/*          onMouseDown={handleMouseDownPassword}*/}
    {/*          edge="end"*/}
    {/*        >*/}
    {/*          {showPwd ? <VisibilityOff /> : <Visibility />}*/}
    {/*        </IconButton>*/}
    {/*      </InputAdornment>*/}
    {/*    }*/}
    {/*    label=""*/}
    {/*  />*/}
    {/*</Grid>*/}

    <Grid item xs={12} sx={{ margin: { xs: '0px 0 20px 0', md: '15px 0 16px 0'}  }}>
      <Divider />
    </Grid>
    <Grid item xs={12}>
      <Box sx={{ display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, gap: '10px', justifyContent: 'flex-end' }}>
        <Button disabled={isLoading} onClick={closeDialog} variant="outlined" color="secondary" sx={{ maxWidth: { xs: '100%', sm:'200px'} }}>Cancel</Button>
        <Button onClick={handleSave} disabled={isLoading} variant="contained" sx={{ maxWidth: { xs: '100%', sm:'200px'} }}>Save</Button>
      </Box>
    </Grid>
  </Grid>
  </>)
}