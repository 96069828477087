import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import {useBuySubscriptionMutation, useGetSubscriptionsQuery, useUpgradeCardMutation} from "../../slices/api/userSlice";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";
import {
  getCurrentDomain,
  getRelevantUpgradePlans,
  hasActiveSubscription
} from "../../utils/functions";
import {useSelector} from "react-redux";
import {useUpgradeToPremiumMutation} from "../../slices/api/cardApiSlice";
import {useNavigate} from "react-router-dom";
import {useGetPlansQuery} from "../../slices/api/apiSlice";
import PlanOption from "../planOption/PlanOption";
import {Loader} from "@giphy/react-components";

export default function UpgradeBox({card, cb,isPricingPage = false, planCategorySelected = 'single'}) {
  const user = useSelector(state => state.auth.user);
  const navigate = useNavigate();
  const [upgradeToPremium, {isLoading: premiumIsLoading, isError: isPremiumError, error: premiumError}] = useUpgradeToPremiumMutation();
  const {
    data: subscriptions,
    isSuccess: fetchedSubscriptions,
  } = useGetSubscriptionsQuery();

  const {
    data: plans,
    isSuccess: getPlansIsSuccess,
    isLoading: getPlansIsLoading,
  } = useGetPlansQuery()


  async function handleUpgradeToPremium() {
    try {
      await upgradeToPremium({cardId: card.id}).unwrap();
      if(cb) {
        return cb()
      }
      navigate(`/card/${card.id}`);
    }catch (e) {
      console.log(`error upgrading tier for card`,e)
    }
  }


  const showUpgradeButton = () => {
    if(card) {
      if(user?.id) {
        return hasActiveSubscription(subscriptions)
      } else {
        return false
      }
    } else {
      return false;
    }
  }

  const relevantPlans = getRelevantUpgradePlans(plans, card, planCategorySelected);



  return (
    <>

      {isPremiumError && <InfoSnackbar message={premiumError?.error || premiumError?.data?.error} severity="error" /> }
      {getPlansIsLoading && <Loader />}
      {fetchedSubscriptions && showUpgradeButton() &&
        <>
          <Typography variant="body1" sx={{marginBottom:'20px'}}>You have an active subscription, select continue to upgrade card.</Typography>
          <Button disabled={premiumIsLoading} variant="contained" onClick={handleUpgradeToPremium}>Continue</Button>
          {isPremiumError && <InfoSnackbar message={premiumError?.error || premiumError?.data?.error || 'Error upgrading the card, please try again'} severity="error" /> }
        </>
      }
      { getPlansIsSuccess &&
        <Box sx={{
          display:'flex',
          width: '100%',
          alignItems: 'flex-start',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          gap: {
            xs: '26px',
            sm: '6px',
            md: '12px'
          },
          padding: {
            xs: '10px',
            md: '30px',
          },
        }}>
          { relevantPlans.map(plan => {
            return <PlanOption
              card={card}
              key={plan.id}
              planSelected={card?.tier }
              plan={plan}
              isPricingPage={isPricingPage}
              upgradeText={true}
            />
          })}
        </Box>
      }

    </>
  )
}