import { createSlice } from "@reduxjs/toolkit";
const LOCAL_STORAGE_VIEW_CARD_TOKEN = 'viewCardToken'

let initialState = { viewCardToken: null };
const localViewCardData = window.localStorage.getItem(LOCAL_STORAGE_VIEW_CARD_TOKEN);
if(localViewCardData) {
  try {
    initialState = JSON.parse(localViewCardData)
  } catch (e) {
    console.log('error parsing token to view card',e)
  }
}

const viewCardTokenSlice = createSlice({
  name: 'viewCardToken',
  initialState: initialState,
  reducers: {
    setViewCardToken: (state, action) => {
      const { viewCardToken } = action.payload;
      state.viewCardToken = viewCardToken;

      try {
        window.localStorage.setItem(LOCAL_STORAGE_VIEW_CARD_TOKEN, JSON.stringify({viewCardToken: viewCardToken}));
      } catch (e) {
        console.log('Error setting the local storage for viewCardToken')
      }
    }
  }
})

export const { setViewCardToken } = viewCardTokenSlice.actions;

export default viewCardTokenSlice.reducer;