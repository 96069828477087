import { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { copyToClipboardIcon } from '../../../svgIcons/icons';
import { copyTextToClipboard } from '../../../components/utils/utils';
import Box from "@mui/material/Box";

export default function DirectLink({url, closeModal}) {
  const [copied, setCopied] = useState(false)
  
  function handleCopyToClipboard(url) {
    copyTextToClipboard(url)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    },3000)
  }
  return(
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body2"
                    sx={{  paddingTop: '13px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Share this link by slack,  emails, social media, text messages</Typography>
        <Box sx={{marginTop: '10px', overflowWrap: 'break-word', borderRadius: '8px', padding: '10px 14px', border: '1px solid #D0D5DD', background: '#F9FAFB', boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'  }}>
          <Typography variant="body2">{url}</Typography>
        </Box>
        <Button 
        onClick={() => { handleCopyToClipboard(url)}}
        sx={{marginTop: { xs: '20px', md: '40px'} }}
        variant='contained' 
        fullWidth><span style={{paddingRight: '10px', paddingTop: '5px'}}>{copyToClipboardIcon}</span> {copied ? 'Copied' : 'Copy link to clipboard'}</Button>
      </Grid>
    </Grid>
  )
}