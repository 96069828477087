import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {linkPaths} from "../../../utils/linkPaths";
import {useDispatch} from "react-redux";
import {resetCardState} from "../../../slices/cardSlice";



export default function BlackSection({title, subtitle}) {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  function goCreateCard() {
    dispatch(resetCardState())
    navigate(linkPaths.create.path)
  }

  return (
    <Box sx={{padding: { xs: '40px 17px 40px 17px', md: '40px 17px 80px 17px'}, marginTop:{xs: '13px', md: '30px'}, width: '100%' }}>
      <Grid container maxWidth="xl" sx={{
        margin: '0 auto',
        background: '#212529', borderRadius:'24px',
        padding: { xs: '64px 30px 400px 30px', sm: '64px 30px 20px 30px', md: '58px 104px', lg:'90px 120px', xl: '100px 200px'},
        backgroundImage: `url('https://storage.googleapis.com/prod-website-images-rlink/man-working-table.png'), url('https://storage.googleapis.com/prod-website-images-rlink/black_section_bottom_right.svg'), url('https://storage.googleapis.com/prod-website-images-rlink/black_section_top_left.svg'), url('https://storage.googleapis.com/prod-website-images-rlink/black_section_orange_top_right.svg')`,
        backgroundPosition: { xs: 'left bottom, right bottom, top left, top right', sm:'right 40px bottom, right bottom, top left, top right', md:'right 180px bottom, right bottom, top left, top right'  },
        backgroundRepeat: 'no-repeat',
        backgroundSize: { xs: '313px, 271px, 132px, 0', sm: '280px, 271px, 132px, 221px', md: '346px, 271px, 132px, 221px', lg: '330px, 271px, 132px, 221px', xl: '380px, 291px, 232px, 291px' },
      }}>
        <Grid item xs={12} sm={8} md={6} xl={6}>
          <Typography
            variant="h1"
            sx={{ color: "white", marginBottom: '20px', letterSpacing:{ xs: '-0.72px'}, fontSize:{ xs: '30px', md: '36px'}, lineHeight:{ xs: '44px'} }}>
            {title ? title : <>Try it with a  <Box component="span" sx={{ color: '#5D9AF3'}}>free card</Box>.</>}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 400}}>{subtitle ? subtitle : "Start with a free card, no registration required, upgrade anytime."}</Typography>
          <Box sx={{ textAlign: {xs: 'center', sm: 'left'} }}>
            <Button id="create-group-card-button-black-section" onClick={goCreateCard} sx={{ maxWidth: { xs: '100%', sm: '200px'}, margin: '30px 0', fontWeight: 700}} variant="contained" color="primary">Create Group Card</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}