import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid, // our UI Component to display the results
  Carousel,
  SearchBar, // the search bar the user will type into
  SearchContext, // the context that wraps and connects our components
  SearchContextManager, // the context manager, includes the Context.Provider
  SuggestionBar, // an optional UI component that displays trending searches and channel / username results
} from '@giphy/react-components'
import Typography from "@mui/material/Typography";

import { userSelectedGif, userRemovedGif } from "../../slices/messageSlice";
import GifByGiphy from "../message/messageBlocks/GifByGiphy";
import Box from "@mui/material/Box";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import mediaTypes from "../../utils/mediaTypes";
import GifImage from "../message/messageBlocks/GifImage";

// the search experience consists of the manager and its child components that use SearchContext
const SearchExperience = ({closeModal, updateMedia, media}) => (
  <SearchContextManager shouldFetchChannels={false} mode="light"  apiKey="iFFui2nU3J2sBDXWWL0sqCGKivq0RapM">
    <Components media={media} updateMedia={updateMedia} closeModal={closeModal} />
  </SearchContextManager>
)

// define the components in a separate function so we can
// use the context hook. You could also use the render props pattern
const Components = ({closeModal, updateMedia, media}) => {
  const dispatch = useDispatch();
  const message = useSelector(state => state.message);

  const { fetchGifs, searchKey } = useContext(SearchContext);

  function handleClickOnGif(gif) {
    if(gif?.images?.downsized_medium?.url) {

      updateMedia({
        type: mediaTypes.GIPHY_IMAGES,
        data: [{fileUrl: gif.images.downsized_medium.url}],
      })
      closeModal()
      // dispatch(userSelectedGif(gif.images.downsized_medium.url))
    }

  }

  function handleDeleteGif() {
    // dispatch(userRemovedGif())
    updateMedia({
      type: null,
      data: [],
    })
    closeModal()
  }


  return (
    <>
      { !media.type &&
        <>
          <Typography variant="body1" sx={{ marginBottom: '10px'}}>Type any search for fun results</Typography>
          <SearchBar />
          <Carousel
            onGifClick={handleClickOnGif}
            noLink={true}
            hideAttribution={true}
            gifHeight={200}
            gutter={6}
            fetchGifs={fetchGifs}
            key={searchKey}
          />
          <Typography variant="caption" sx={{ marginBottom: '10px'}}>Trending searches</Typography>
          <SuggestionBar />
        </>
      }
      { media.type && media.type === mediaTypes.GIPHY_IMAGES &&
        <Box sx={{ position: 'relative', width:'50%', margin: '0 auto'}}>
          <GifImage message={{media:media}} />
          <Box
            onClick={handleDeleteGif}
            sx={{
              '&:hover':{ cursor: 'pointer'},
              width: '30px',
              height: '30px',
              position: 'absolute',
              top: 0,
              right: 0,
              backgroundColor: 'rgba(255,255,255,0.8)',
              borderRadius: '50%',
              marginRight: '5px',
              marginTop: '5px', textAlign:'center'}}>
            <DeleteForeverIcon sx={{ color: 'primary.main', marginTop: '2px'}} />
          </Box>
        </Box>
      }
      {media.type && media.type !== mediaTypes.GIPHY_IMAGES && <div>Need to remove other media first</div>}
    </>
  )
}

export default SearchExperience;