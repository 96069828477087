import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Chip from '@mui/material/Chip';
import CardsList from './CardsList';
import Reminders from './reminders/Reminders';
import Account from './account/Account';
import {useGetTotalCardsQuery} from "../../slices/api/cardApiSlice";
import MyUserBlock from "../../components/myUserBlock/MyUserBlock";
import Typography from "@mui/material/Typography";
import {PremiumCrown, premiumCrown} from "../../svgIcons/icons";
import Button from "@mui/material/Button";
import {useState} from "react";
import UpgradeAccountDialog from "../../components/upgradeAccountDialog/UpgradeAccountDialog";
import {useGetSubscriptionsQuery} from "../../slices/api/userSlice";
import {hasActiveSubscription} from "../../utils/functions";
import InfoSnackbar from "../../components/infoSnackbar/InfoSnackbar";
import {useNavigate} from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{marginTop: '40px'}}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function DashboardPage(){
  const [showUpgrade, setShowUpgrade] = useState(false);
  const navigate = useNavigate();
  const {
    data: total,
    isSuccess: getTotalCardsIsSuccess,
    isError: getTotalCardsIsError,
    error: getTotalCardsError,
  } = useGetTotalCardsQuery();


  const {
    data: subscriptions,
    isSuccess: fetchedSubscriptions,
    isError: errorFetchingSubs,
  } = useGetSubscriptionsQuery();

  if(getTotalCardsIsError) {
    console.log('getTotalCardsError', getTotalCardsError)
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let groupCardsTabName = null;
  if(getTotalCardsIsSuccess) {
    groupCardsTabName = <Box sx={{ fontSize: '14px'}}>
      Group Cards <Chip label={total.cardsCreated} sx={{color: 'primary.main', background: 'rgba(249, 245, 255, 1)', padding: '5px 0', margin:0, fontSize: '13px', height: 'auto'}}/>
    </Box>
  }


  return(
    <Box sx={{
      width: '100%',
      backgroundImage: `url(https://storage.googleapis.com/prod-website-images-rlink/blue-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/red-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/orange-vector.svg)`,
      backgroundPosition: 'top right, top 100px left, bottom right',
      backgroundRepeat: 'no-repeat',
      padding:{xs: '14px'},
      minHeight:{xs: 'calc(100% - 70px)', sm: 'calc(100% - 80px)'}
    }}>
      <Grid container sx={{margin: 'auto', maxWidth: '1040px', backgroundColor: {xs: "rgba(255,255,255,0.8)", lg:"rgba(255,255,255,0)"}, borderRadius: '16px', padding:{xs: '8px'} }}>
        <Box sx={{ display:'flex', flexDirection: {xs: 'column', md: 'row'}, justifyContent: 'space-between', width: '100%', margin: {xs:'30px auto 10px 16px', md:'30px 0 10px 14px'} }} >
          <MyUserBlock big={true} />
          <>
            {errorFetchingSubs && <InfoSnackbar message="Error getting subscriptions, please try again" severity="error" /> }
            {fetchedSubscriptions && !hasActiveSubscription(subscriptions) && <Box sx={{ marginTop: '10px', textAlign: {xs: 'left', md: 'center'} }}><Button startIcon={<PremiumCrown width="16px" height="16px" />} variant="outlined" onClick={() => setShowUpgrade(!showUpgrade)} sx={{backgroundColor: '#FFF', fontSize: {xs:'12px', md:'14px'} }}> Save with our Subscriptions</Button></Box>}
            {fetchedSubscriptions && hasActiveSubscription(subscriptions) &&
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '12px', padding: '8px'}}>
                <Box>{premiumCrown}</Box>
                <Typography variant="body1" sx={{ fontWeight: 500, color: '#53389E'}}>Premium member</Typography>
              </Box>}
            <UpgradeAccountDialog open={showUpgrade} closeDialog={() => setShowUpgrade(false)} />
          </>
        </Box>
        <Grid item xs={12}>
          <Box sx={{display:'flex', justifyContent:'flex-end'}}>
            <Button  onClick={() => navigate(`/create/card`)} variant="outlined" color="secondary"  sx={{maxWidth: '220px'}}>Create group card</Button>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '0' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {/* <Tab className='dashboardTab' label={<Box>Group cards <Chip label="26" sx={{background: 'rgba(249, 245, 255, 1)', color:'primary.main', padding: '5px 0', margin:0, fontSize: '13px', height: 'auto'}}/></Box>} {...a11yProps(0)} /> */}
            <Tab className='dashboardTab' label={groupCardsTabName} {...a11yProps(0)} />
            <Tab className='dashboardTab'  label="Account" {...a11yProps(1)} />
            {/*<Tab className='dashboardTab'  label="Reminders" {...a11yProps(1)} />*/}
          </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <CardsList total={getTotalCardsIsSuccess ? total.cardsCreated : '-'} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Account />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Reminders />
          </CustomTabPanel>

        </Grid>
      </Grid>
    </Box>
  )

}