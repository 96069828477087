import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {ImageIcon, LupaIcon, VideoIcon} from "../../svgIcons/icons";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import * as React from "react";
import {useState} from "react";
import GiphySearch from "../giphySearch/GiphySearch";
import ActionModal from "../actionModal/ActionModal";
import {useDispatch, useSelector} from "react-redux";
import {mediaUpdated, userUploadedImage} from "../../slices/replySlice";
import UnsplashSearch from "../unSplashSearch/UnsplashSearch";
import mediaTypes from "../../utils/mediaTypes";
import {useTrackDownloadMutation} from "../../slices/api/unsplashApiSlice";
import AddMessageImages from "../addMessageImages/AddMessageImages";
import {addToArray, removeFromArray} from "../../utils/functions";
import UploadVideo from "../addMessageVideo/UploadVideo";
import EmbedVideo from "../addMessageVideo/EmbedVideo";

export default function MediaButtons(){
  const replyForm = useSelector(state => state.replyForm)
  const media = replyForm.media;
  const [anchorVideos, setAnchorVideos] = useState(null);
  const [anchorImages, setAnchorImages] = useState(null);
  const [showGifModal, setShowGifModal] = useState(false);
  const [showUnsplashModal, setShowUnsplashModal] = useState(false);
  const [showUploadImages, setShowUploadImages] = useState(false);
  const [showUploadVideo, setShowUploadVideo] = useState(false);
  const [showEmbedVideo,setShowEmbedVideo] = useState(false);
  const [trackDownload] = useTrackDownloadMutation();
  const dispatch = useDispatch();
  const videosMenuOpen = Boolean(anchorVideos);
  const imagesMenuOpen = Boolean(anchorImages);



  const openVideosMenu = (event) => {
    setAnchorVideos(event.currentTarget);
  };
  const closeVideosMenu = () => {
    setAnchorVideos(null);
  };

  const openImagesMenu = (event) => {
    setAnchorImages(event.currentTarget);
  };
  const closeImagesMenu = () => {
    setAnchorImages(null);
  };

  const updateMedia = (mediaLoad) =>{
    dispatch(mediaUpdated(mediaLoad))
  }

  function addUnsplashImage(image) {
    //trigger download request
    updateMedia({
      type: mediaTypes.UNSPLASH_IMAGE,
      data: [{fileUrl: image.photoRaw + "&w=600&dpr=2", unsplash: image}]
    })
    setShowUnsplashModal(false)
    try {
      trackDownload(image);
    }catch (e) {
      console.error('error updating downloads in Unsplash',e)
    }
    closeImagesMenu();
  }

  function removeUnsplashImage(image) {
    updateMedia({
      type: null,
      data: []
    })
    setShowUnsplashModal(false)
    closeImagesMenu();
  }

  const closeUploadImages = () => {
    setShowUploadImages(false)
    closeImagesMenu();
  }

  function addImage(image) {
    dispatch(userUploadedImage(image))
  }

  function removeImage(image) {
    const newImages = removeFromArray(replyForm.media.data, image);
    if(newImages.length > 0) {
      updateMedia({
        type: mediaTypes.USER_IMAGE,
        data: newImages,
      })
    } else {
      updateMedia({
        type: '',
        data: [],
      })
    }
  }

  const handleCloseUploadVideo = () => {
    setShowUploadVideo(false);
    closeVideosMenu();
  }

  function handleCloseEmbedVideo() {
    setShowEmbedVideo(false);
    closeVideosMenu();
  }

  const isButtonDisabled = (mediaTypeToCheck) => {
    if(media.type === null) return false;
    if(media.type === '') return false;
    if(media.type === mediaTypeToCheck) return false;
    return true;
  }

  const isVideoDisabled = () => {
    if(media.type === null) return false;
    if(media.type === '') return false;
    let returnThis = true;
    if (media.type === mediaTypes.USER_VIDEO) { returnThis = false}
    if (media.type === mediaTypes.VIDEO_EMBED) { returnThis = false}
    return returnThis;
  }

  function isImageDisable() {
    if(media.type === null) return false;
    if(media.type === '') return false;
    if(media.type === mediaTypes.UNSPLASH_IMAGE)  return false;
    if(media.type === mediaTypes.USER_IMAGE) return false;
    return true;
  }

  const videoText = () => {
    if(media.type === null) return 'Add video';
    if(media.type === '') return 'Add video';
    let returnThis = 'Add video';
    if (media.type === mediaTypes.USER_VIDEO) { returnThis = 'Edit video'}
    if (media.type === mediaTypes.VIDEO_EMBED) { returnThis = 'Edit video'}
    return returnThis;
  }


  return(
<>
  <Box sx={{display:'flex', flexDirection:{xs: 'column', md: 'row', gap:'12px'} }}>
    <Button
      disabled={isImageDisable()}
      onClick={openImagesMenu}
      sx={{ width:'100%'}}
      variant='contained'
      endIcon={<KeyboardArrowDownIcon />}
      color="secondary">
      <span style={{paddingRight: '0px', paddingTop: '5px'}}>{<ImageIcon stroke={isButtonDisabled(mediaTypes.USER_IMAGE) ? 'rgba(0, 0, 0, 0.26)' : undefined } />}</span>
      {(media.type === mediaTypes.USER_IMAGE || media.type === mediaTypes.UNSPLASH_IMAGE) ? 'Edit images' : 'Add images'}
    </Button>
    <Menu
      id="images-menu"
      anchorEl={anchorImages}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={imagesMenuOpen}
      onClose={closeImagesMenu}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      sx={{paddingTop:0, paddingBottom: 0}}
    >
      <MenuItem onClick={() => setShowUnsplashModal(prevState => !prevState)} >From the Internet</MenuItem>
      <MenuItem onClick={() => setShowUploadImages(prevState => !prevState)} >Upload your own</MenuItem>
    </Menu>
    <ActionModal
      showActionButtons={false}
      confirm={() => {setShowUnsplashModal(false)}}
      cancel={() => {
        setShowUnsplashModal(false)
        closeImagesMenu();
      }}
      open={showUnsplashModal}
      title="Images from the Internet">
      <UnsplashSearch media={replyForm.media} handleAddUnsplash={addUnsplashImage} removeUnsplashImage={() => {
        removeUnsplashImage();
        closeImagesMenu();
      }} handleCloseEmbedImage={() => {setShowUnsplashModal(false)}}  />
    </ActionModal>
    <ActionModal
      showActionButtons={replyForm.media.type !== mediaTypes.UNSPLASH_IMAGE}
      confirm={closeUploadImages}
      cancel={closeUploadImages}
      open={showUploadImages}
      title="Upload your images">
      <AddMessageImages media={replyForm.media}  addImage={addImage} removeImage={removeImage} />
    </ActionModal>



    <Button
      disabled={isVideoDisabled()}
      onClick={openVideosMenu}
      sx={{ width:'100%'}}
      variant='contained'
      endIcon={<KeyboardArrowDownIcon />}
      color="secondary">
      <span style={{paddingRight: '0px', paddingTop: '5px'}}>{<VideoIcon stroke={isVideoDisabled() ? 'rgba(0, 0, 0, 0.26)' : undefined } />}</span>
      {videoText()}
    </Button>
    <Menu
      id="videos-menu"
      anchorEl={anchorVideos}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={videosMenuOpen}
      onClose={closeVideosMenu}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      sx={{paddingTop:0, paddingBottom: 0}}
    >
      <MenuItem onClick={() => setShowEmbedVideo(prevState => !prevState)} >From YouTube</MenuItem>
      <MenuItem onClick={() => setShowUploadVideo(prevState => !prevState)} >Upload your own</MenuItem>
    </Menu>

    <ActionModal
      confirm={handleCloseUploadVideo}
      cancel={handleCloseUploadVideo}
      open={showUploadVideo}
      title="Upload your video">
      <UploadVideo subtitle={"Send a video reply, up to 1 minute long or 150MB"} media={replyForm.media} updateMedia={updateMedia} closeModal={() => { setShowUploadVideo(false)}} />
    </ActionModal>

    <ActionModal
      confirm={handleCloseEmbedVideo}
      cancel={handleCloseEmbedVideo}
      open={showEmbedVideo}
      title="Video from YouTube">
      <EmbedVideo media={replyForm.media} updateMedia={updateMedia} closeModal={() => { setShowEmbedVideo(false)}}  />
      {/*<AddMessageVideo media={media} updateMedia={updateMedia}  closeModal={() => { setShowVideoModal(false)}} />*/}
    </ActionModal>

    <Button
      disabled={isButtonDisabled(mediaTypes.GIPHY_IMAGES)}
      onClick={()=> setShowGifModal(prevState => !prevState)}
      sx={{ width:'100%'}}
      variant='contained'
      color="secondary">
      <span style={{paddingRight: '0px', paddingTop: '5px'}}>{<LupaIcon stroke={isButtonDisabled(mediaTypes.GIPHY_IMAGES) ? 'rgba(0, 0, 0, 0.26)' : undefined } />}</span>
      { (media.type === mediaTypes.GIPHY_IMAGES) ? 'Edit Gif' : 'Add Gif'}
    </Button>
    <ActionModal
      confirm={() => {setShowGifModal(false)}}
      open={showGifModal}
      cancel={() => {setShowGifModal(false)}}
      title="Make it fun with GIFs">

      <GiphySearch media={replyForm.media} updateMedia={updateMedia}  closeModal={() => { setShowGifModal(false)}} />
    </ActionModal>
  </Box>
</>
  )
}