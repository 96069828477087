import Box from "@mui/material/Box";
import Step from "../step/Step";

export default function CreationSteps({ steps }){
  if(!steps) return null;

  let width = '50%';
  let finalStep = false;

  for(let i = 0; i < steps.length; i++){
    if(steps[i].status === 'active' && steps[i].id === 'step2') {
      width =  '100%';
      finalStep = true;
    }
  }

  return (
    <Box sx={{ height: '100%', width: '100%', zIndex: '10',
      background: `url('https://storage.googleapis.com/prod-website-images-rlink/steps_left_blur.svg'), url('https://storage.googleapis.com/prod-website-images-rlink/steps_bottom_right.svg'), #F9FAFB`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom left, bottom right',
      backgroundSize: {xs: '0, 0', md: '150px, 150px', xl: '150px, 200px'},
    }}>
      <Box sx={{
        height: {
          xs: '46px',
          md: '100%'
        },
        padding: {
          xs: '2px 17px',
          md: '30px',
          lg: '48px'
        },
        display: 'flex',
        justifyContent: {
          xs: (finalStep) ? 'unset' : 'space-between',
          md: 'unset',
        },
        flexDirection: {
          xs: 'row',
          md: 'column'
        }
      }}>
        {steps.map(step => { return <Step key={step.id} step={step}/>})}
      </Box>

      <Box component="div" sx={{backgroundColor: 'white', height: '3px', width: '100%', display:{md: 'none'}  }}>
        <Box component="div" sx={{backgroundColor: 'primary.main', height: '3px', width: width}}></Box>
      </Box>
    </Box>
  )

}