import { createSlice } from '@reduxjs/toolkit';
import mediaTypes from "../utils/mediaTypes";
import {addToArray, removeFromArray} from "../utils/functions";

const initialState = {
  cardId: '',
  creatorEmail: '',
  creatorName: '',
  shareEmail: true,
  message: '',
  media:{
    type: null,
    data: []
  },
  messageIds:[],
};


export const replySlice = createSlice({
  name: 'replyForm',
  initialState,
  reducers:{
    setCardId(state,action){
      state.cardId = action.payload;
    },
    creatorEmailUpdated(state, action) {
      state.creatorEmail = action.payload
    },
    creatorNameUpdated(state, action) {
      state.creatorName = action.payload
    },
    setShareEmail(state,action){
      state.shareEmail = action.payload
    },
    mediaUpdated(state, action) {
      state.media = {
        type: action.payload.type,
        data: action.payload.data
      }
    },
    messageUpdated(state, action){
      state.message = action.payload
    },
    userUploadedImage(state, action){
      state.media = {
        type: mediaTypes.USER_IMAGE,
        data: addToArray(state.media.data, action.payload)
      }
    },
    userRemovedImage(state, action){
      state.media = {
        type: null, //TODO: fix this bug, there might be more items in the array
        data: removeFromArray(state.media.data, action.payload)
      }
    },
    userSelectedGif(state,action){
      state.media = {
        type: mediaTypes.GIPHY_IMAGES,
        data: [{fileUrl: action.payload}],
      }
    },
    userRemovedGif(state,action){
      state.media = {
        type: null,
        data: []
      }
    },
    userAddedVideo(state,action){
      state.media = {
        type: mediaTypes.USER_VIDEO,
        data: [{...action.payload}],
      }
    },
    userRemovedVideo(state,action){
      state.media = {
        type: null,
        data: []
      }
    },
    addMessageId(state, action){
      state.messageIds =  addToArray(state.messageIds, action.payload)
    },
    removeMessageId(state, action){
      state.messageIds =  removeFromArray(state.messageIds, action.payload)
    },
    setMessageIds(state,action){
      state.messageIds = action.payload;
    },
    setShareMyEmail(state,action){
      state.shareEmail = action.payload
    },
    clearReply(state,action){
      return initialState;
    }
  }
});

export const {
  setCardId,
  creatorEmailUpdated,
  creatorNameUpdated,
  mediaUpdated,
  messageUpdated,
  userUploadedImage,
  userRemovedImage,
  userSelectedGif,
  userRemovedGif,
  userAddedVideo,
  userRemovedVideo,
  addMessageId,
  removeMessageId,
  setMessageIds,
  setShareMyEmail,
  clearReply
} = replySlice.actions;