import { apiSlice } from "./apiSlice";

export const replyApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createReply: builder.mutation({
      query: (body) => {
        return {
          url: '/replies/send-reply',
          method: 'POST',
          body: {...body}
        }
      }
    }),
    confirmReply: builder.query({
      query: (token) => `/replies/confirm-reply/${token}`,
    }),
    getReply: builder.query({
      query: (replyId) => `/replies/reply/${replyId}`,
    }),
  })
})

export const {
  useCreateReplyMutation,
  useConfirmReplyQuery,
  useGetReplyQuery,
} = replyApiSlice;