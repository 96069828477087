import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import * as React from "react";
import {useState} from "react";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import {useCreateAiImageMutation} from "../../slices/api/aiApiSlice";
import {Loader} from "@giphy/react-components";
import SendIcon from '@mui/icons-material/Send';
import IconButton from "@mui/material/IconButton";
import {InputAdornment} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import CustomerReviews from "../cardSettings/blocks/CustomerReviews";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useUpdateCardMutation} from "../../slices/api/cardApiSlice";
import PurchaseAiCredits from "../purchaseAiCredits/PurchaseAiCredits";
import Alert from "@mui/material/Alert";
import {PremiumCrown} from "../../svgIcons/icons";
import CardUpgradeOptionsDialog from "../cardUpgradeOptionsDialog/CardUpgradeOptionsDialog";

export default function AddAiImage({card, closeDialog}) {
  const [imageDescription, setImageDescription] = useState('')
  const [error, setError] = useState('');
  const [createAiImage, {isLoading, isError}] = useCreateAiImageMutation();
  //upgrades
  const [showUpgradeOptions, setShowUpgradeOptions] = useState(false);
  const [updateCard] = useUpdateCardMutation();


  const updateImageDescription = (e) => {
    if(e.target.value.length >= 250) {
      setImageDescription(e.target.value.substring(0,250))
      return setError('Image description can be up to 250 characters log');
    } else {
      return setImageDescription(e.target.value)
    }
  }

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      await handleGeneration();
    }
  }

  const handleGeneration = async () => {
    if(imageDescription.length === 0) return setError('Image description needed');
    if(imageDescription.length < 10) return setError('Image description must have at least 10 characters');

    try {
      await createAiImage({
        cardId: card.id,
        prompt: imageDescription,
      }).unwrap();

    } catch (e) {
      console.log('error generating image',e)
      setError(e.data?.error || 'Error generating image, please try again')
    }
  }

  const setBackgroundImage = async (image) => {
    let update = {
      ...card,
      template: {
        ...card.template,
        cardBackgroundImage: image.imageUrl,
        cardBackgroundImageFullSize: true,
        isCustomBackgroundImage: false,
      }
    }

    try {
      await updateCard(update).unwrap();
      if(closeDialog) {
        return closeDialog();
      }
    } catch (e) {
      console.log('error setting ai background image',e)
      setError(e.data?.error || 'Error setting image as background, please try again')
    }
  }

  if(!card) return null;

  return (
    <Box>
      {error && <InfoSnackbar message={error} callback={() => setError('')} /> }
      {card.aiCredits > 0 &&
        <>
          <Typography variant="body2" sx={{  paddingTop: '0px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Describe how you want the background image to look like. </Typography>
          <Typography variant="body2" sx={{  paddingTop: '0px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Pro tip: avoid the word card, instead use something like "pink elephant eating tacos"</Typography>
        </>
      }
      {!isLoading && card.aiCredits > 0 &&
        <Box>
        <OutlinedInput
          required
          fullWidth
          id="ai-image-prompt"
          name="ai-image-prompt"
          onChange={updateImageDescription}
          onKeyDown={handleKeyDown}
          value={imageDescription}
          sx={{ marginBottom: {xs: '8px'}, marginTop:'12px' }}
          endAdornment={<InputAdornment position="end">
            <IconButton
              aria-label="send description"
              onClick={handleGeneration}
              edge="end"
            >
              <SendIcon sx={{fill: imageDescription.length >= 10 ? '#7F56D9' : 'gray'}} />
            </IconButton>
          </InputAdornment>
            }
        />
        <Box sx={{textAlign: 'right', marginBottom: '8px'}}>
          <Chip sx={{fontSize: '12px'}} label={`${imageDescription.length} / 250`} />
        </Box>


      </Box>}

      {isLoading && <Box sx={{padding: '30px', width: '100%', textAlign:'center'}}>
        <Loader />
        <Box sx={{marginTop:'20px'}}>
          <Typography sx={{margin: '20px'}} variant="caption">Creating image with AI 🤖, it might take a minute...</Typography>
        </Box>
      </Box>}

      {card.aiImages?.length > 0 &&
        <Box sx={{width: '100%'}}>
          <Typography variant="body1" sx={{marginBottom:'12px'}}>Images you have generated:</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'no-wrap', overflow: 'scroll', gap: '20px'}}>
            {
              card.aiImages.map(image => {
                const isSelected = image.imageUrl === card.template.cardBackgroundImage;

                return <Box key={image.imageUrl} sx={{minWidth:'90%', position:'relative'}}>
                  {!isSelected && !isLoading &&<IconButton onClick={() => {setBackgroundImage(image)}} sx={{borderRadius:0}}><RadioButtonUncheckedIcon /> <Typography variant="caption">Set as background</Typography></IconButton>}
                  {isSelected && <IconButton sx={{borderRadius:0, color:'#7F56D9', fontSize:'12px'}}><CheckCircleIcon /> Selected</IconButton>}
                  <img src={image.imageUrl} alt={image.description} width="100%" />
                </Box>
              })
            }
          </Box>
        </Box>
      }

      {!isLoading && <Typography variant="body2" sx={{  paddingTop: '0px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500, marginBottom:'8px'}}>Remaining AI images for this card: {card.aiCredits}</Typography>}
      {card.aiCredits > 0 && !isLoading && <Typography variant="caption">More available for purchase when reaching 0</Typography>}

      {card.aiCredits === 0 && card.tier !== 'FREE_PLAN' &&
        <PurchaseAiCredits card={card} />
      }
      {card.aiCredits === 0 && card.tier === 'FREE_PLAN' &&
        <>
          <Alert
            id="upgrade-message-warning"
            data-testid="upgrade-message-warning"
            icon={<PremiumCrown width="16px" height="16px" /> }
            sx={{ borderRadius: '8px', backgroundColor:'#F9F5FF', color: '#6941C6', fontFamily:"'Inter', sans-serif", paddingBottom:'10px'}}
            severity="info">
            <Typography variant="h4" sx={{fontSize:'18px', lineHeight:'22px', marginBottom:'8px'}}>Create the must fun and personalized design for your card!</Typography>
            <Typography variant="body2">Nobody else will have the same design. Just describe what you want and AI will take care of the rest. </Typography>
            <Button onClick={() => setShowUpgradeOptions(true)}  sx={{marginTop: '20px'}} variant="contained">Upgrade card</Button>
            <Typography variant="caption">Multiple options for upgrade</Typography>
          </Alert>
          <CardUpgradeOptionsDialog card={card} open={showUpgradeOptions} closeDialog={() => setShowUpgradeOptions(false)} />
        </>
      }
    </Box>
  )
}