import { apiSlice } from "./apiSlice";

export const contactApiFormSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    sendContactForm: builder.mutation({
      query: body => ({
        url: '/contact-form/contact',
        method: 'POST',
        body: {...body}
      })
    }),
  })
});

export const { useSendContactFormMutation } = contactApiFormSlice ;