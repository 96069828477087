import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {checkUrlForCreateCard, isCheckoutPage} from "../../utils/functions";

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import IconButton from "@mui/material/IconButton";

export default function Footer() {
  const navigate = useNavigate();
  return(
    <Box sx={{backgroundColor: '#212529', padding: {xs:'10px 0', sm: '12px', lg: '18px'} }}>
      <Grid container maxWidth="xl" sx={{margin: '0 auto'}}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection:{xs:'column', md: 'row'}, justifyContent: 'space-between', alignItems: 'center' }}>
            <Box onClick={() => navigate(`/`)} sx={{ width: '100%', maxWidth: '200px', margin:{ xs: '0 auto', md: '0'} }}>
              <img alt="Logo Recognition Link" width='100%' src="https://storage.googleapis.com/prod-website-images-rlink/logos/logo-transparent-white-letters.png"/>
            </Box>
            <Box>
              <Box sx={{ display: 'flex', flexDirection:{xs: 'row'}, justifyContent: {xs: 'space-between'} }}>
                <Button onClick={() => navigate(`/`)} variant="text" sx={{padding:{xs:'6px',md:'10px'},fontSize: { xs:'12px', md:'14px'}, marginBottom:'6px',color:'#EAECF0'}}>Home</Button>
                <Button onClick={() => navigate(`/business`)} variant="text" sx={{padding:{xs:'6px',md:'10px'},fontSize: { xs:'12px', md:'14px'}, marginBottom:'6px',color:'#EAECF0'}}>For Businesses</Button>
                <Button onClick={() => navigate(`/occasions`)} variant="text" sx={{padding:{xs:'6px',md:'10px'},fontSize: { xs:'12px', md:'14px'}, marginBottom:'6px',color:'#EAECF0'}}>Occasions</Button>
                <Button onClick={() => navigate(`/pricing`)} variant="text" sx={{padding:{xs:'6px',md:'10px'},fontSize: { xs:'12px', md:'14px'}, marginBottom:'6px',color:'#EAECF0'}}>Pricing</Button>
                <Button onClick={() => navigate(`/support`)} variant="text" sx={{padding:{xs:'6px',md:'10px'},fontSize: { xs:'12px', md:'14px'}, marginBottom:'6px',color:'#EAECF0'}}>Support</Button>
                <Button onClick={() => navigate(`/terms-of-service`)} variant="text" sx={{display:{xs: 'none', md:'block'},padding:{xs:'6px',md:'10px'},fontSize: { xs:'12px', md:'14px'}, marginBottom:'6px',color:'#EAECF0'}}>Terms of service</Button>
                <Button onClick={() => navigate(`/privacy-policy`)} variant="text" sx={{display:{xs: 'none', md:'block'},padding:{xs:'6px',md:'10px'},fontSize: { xs:'12px', md:'14px'}, marginBottom:'6px',color:'#EAECF0'}}>Privacy Policy</Button>
              </Box>
              <Box sx={{ display: {xs:'flex', md: 'none'}, flexDirection: 'row', justifyContent: 'center'}}>
                <Button onClick={() => navigate(`/terms-of-service`)} variant="text" sx={{padding:{xs:'6px',md:'10px'},fontSize: { xs:'12px', md:'14px'}, marginBottom:'6px',color:'#EAECF0'}}>Terms of service</Button>
                <Button onClick={() => navigate(`/privacy-policy`)} variant="text" sx={{padding:{xs:'6px',md:'10px'},fontSize: { xs:'12px', md:'14px'}, marginBottom:'6px',color:'#EAECF0'}}>Privacy Policy</Button>
              </Box>
              {/*{!checkUrlForCreateCard() && !isCheckoutPage() &&*/}
              {/*  <Box sx={{ textAlign:{xs:'center', md: 'right'}, display:'flex', justifyContent: 'flex-end', paddingRight:'20px' }}>*/}
              {/*    <Button onClick={() => navigate(`/create/card`)} variant="contained" color="secondary" sx={{maxWidth: '220px'}}>Create group card</Button>*/}
              {/*  </Box>*/}
              {/*}*/}
              <Box sx={{ textAlign:{xs: 'center', md: 'right'}, paddingRight:{xs: 0, sm: '20px'} }}>
                <Typography variant="body2" sx={{color:'#EAECF0', fontSize: { xs:'12px', md:'14px'}}}>5900 Balcones Dr. Ste 100, Austin, TX 78731</Typography>
                <Box>
                  <IconButton onClick={() => window.location.href = `https://www.linkedin.com/company/recognitionlink` } sx={{color:'#FFF'}} aria-label="linkedIn">
                    <LinkedInIcon />
                  </IconButton>
                  <IconButton onClick={() => window.location.href = `https://www.facebook.com/profile.php?id=61557963152888` }  sx={{color:'#FFF'}}  aria-label="Facebook">
                    <FacebookIcon />
                  </IconButton>
                  <IconButton onClick={() => window.location.href = `https://x.com/recognitionlink` }  sx={{color:'#FFF'}}  aria-label="X social media">
                    <XIcon />
                  </IconButton>
                  <IconButton onClick={() => window.location.href = `https://www.instagram.com/recognitionlink` }  sx={{color:'#FFF'}}  aria-label="Instragram">
                    <InstagramIcon />
                  </IconButton>

                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}