import Grid from "@mui/material/Grid";
import PurplePill from "../../../components/purplePill/PurplePill";
import TitleText from "../../../components/titleText/TitleText";
import Typography from "@mui/material/Typography";
import {BondsIcon, EcoFriendlyIcon, UnforgetableIcon} from "../../../svgIcons/icons";
import Box from "@mui/material/Box";
import ReviewsReel from "../../../components/reviewsReel/ReviewsReel";

export default function Features() {
  return (
    <Grid container maxWidth="xl" sx={{padding: { xs: '40px 17px'}, margin:{xs: '56px auto 0 auto', md: '53px auto 0 auto'} }}>
      <Grid item xs={12} sx={{margin: '0 0 30px 0'}}>
        <PurplePill>Benefits</PurplePill>
        <TitleText align="center">Why a Digital Group Card</TitleText>
        <Box component="div" sx={{width: {xs: '100%', sm: '90%', md: '80%', lg: '60%'}, margin: '0 auto' }}>
          <Typography variant="subtitle2" sx={{ textAlign:{ xs: 'center'} }}>It's more than just a card; it's an eternal shared experience without physical barriers. You can allow everyone to express their heartfelt messages from anywhere they might be .</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display:{ md: 'none'}, width: '100%', maxWidth: '500px', margin: '50px auto'}}>
          <img width="100%" src="https://storage.googleapis.com/prod-website-images-rlink/home-page/small-ipad-view.png" alt="group card in ipad"/>
        </Box>
        <Box sx={{
          background: `url('https://storage.googleapis.com/prod-website-images-rlink/left_red_arrow.png'),url('https://storage.googleapis.com/prod-website-images-rlink/right_blue_arrow.png'),url('https://storage.googleapis.com/prod-website-images-rlink/home-page/small-ipad-view.png')`,
          backgroundSize: {xs: '100px, 100px, 100%', md: 'auto, 188px, 580px'},
          backgroundPosition:'bottom left 65px, bottom right 10px, center bottom',
          backgroundRepeat: 'no-repeat',
          width: '900px',
          height: '460px',
          margin: '0 auto 20px',
          display: {xs: 'none', md:'block'},
        }}
        ></Box>
      </Grid>
      <Grid item xs={12} sm={4} sx={{ textAlign: 'center'}}>
        <BondsIcon />
        <Typography variant="subtitle1" sx={{ fontWeight: 500}}>Strengthen Bonds</Typography>
        <Box sx={{ width:{ xs: '100%', lg: '80%' }, margin: '0 auto' }}>
          <Typography variant="body2">Bring people together regardless where they might be geographically, invite contributors from all around the world.</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} sx={{ textAlign: 'center'}}>
        <UnforgetableIcon />
        <Typography variant="subtitle1" sx={{ fontWeight: 500}}>Unforgettable Moments</Typography>
        <Box sx={{ width:{ xs: '100%', lg: '80%' }, margin: '0 auto' }}>
          <Typography variant="body2">Relive the joy, laughter, and warmth of your shared photos and videos, something that paper cards can't.</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} sx={{ textAlign: 'center'}}>
        <EcoFriendlyIcon />
        <Typography variant="subtitle1" sx={{ fontWeight: 500}}>Eco-Friendly</Typography>
        <Box sx={{ width:{ xs: '100%', lg: '80%' }, margin: '0 auto' }}>
          <Typography variant="body2">Embrace sustainability by reducing waste – a single shared card is more meaningful and environmentally conscious.</Typography>
        </Box>
      </Grid>
    </Grid>
  )
}