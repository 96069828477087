export default [
  {
    question:"What's the difference between a digital group card and an e-card?",
    answer:"A digital group card lets multiple people add their messages, making it wonderfully personal. Unlike e-cards, which are usually signed by just one person and have limited customization options."
  },
  {
    question:"What are the pricing options?",
    answer: "We offer three choices:\n" +
      "Free cards: $0, perfect for basic needs with a limit of 10 messages per card.\n" +
      "Premium options:\n" +
      "Community cards: $2.99, packed with all features and room for up to 50 messages.\n" +
      "Grand gathering cards: $4.99, packed with all features and room for up to 100 messages.\n" +
      "Subscriptions:\n" +
      "Community Connector Subscription: $29.99/year. Create up to 50 cards annually, each card can hold up to 50 messages!.\n" +
      "Grand Gathering Subscription: $59.99/year. Our subscription model lets you create unlimited cards annually, each capable of holding up to 250 messages!.\n" +
      "Grand Gala Subscription: $99.99/year. Our subscription model lets you create unlimited cards annually, each capable of holding up to 250 messages!.\n"
  },
  {
    question:"Can I start with a free card?",
    answer: "Absolutely! Our free option has everything you need to celebrate special moments with a small group. Plus, you get to experiment with all the premium features. Upgrading to Premium is entirely optional."
  },
  {
    question:"Can I upgrade a free card to a premium option?",
    answer: "Yes! As the card's creator, you'll always have the option to upgrade it to other tiers."
  },
  {
    question:"Is upgrading to a premium option necessary?",
    answer: "Not at all! Our free cards are fully equipped for celebrating those special occasions."
  },
  {
    question:"What if I need multiple group cards?",
    answer: "If you're planning to create many cards per year, choose one of our subscriptions for big savings."
  },
  {
    question:"Should I buy a single card or subscribe?",
    answer: "It depends on how much you love sending cards, collaborating with teams, or spreading appreciation. If that sounds like you, one of our subscription is the way to go!"
  },
  {
    question:"How do I purchase a subscription?",
    answer: "There are a few ways:.\n" +
      "1. From the pricing page:\n" +
      "Enter email for the subscription and click on checkout\n" +
      "2. When creating a card:\n" +
      " After configuring you card,select the subscription option, and follow the prompts to complete the payment.\n" +
      "3. Alternatively: log in or register for free, then head to your Dashboard to find the \"Save with our Subscriptions\" link."
  },
  {
    question:"How do I manage my subscriptions?",
    answer: "You can easily view and manage your subscriptions under your account settings."
  },
  {
    question:"Is my data secure?",
    answer: "Absolutely! We take every precaution to ensure your data is protected and never shared without your consent."
  },
  {
    question:"Can group cards be private?",
    answer: "Yes, you can add a password to restrict access to your card. Only those with the password can view it."
  },
  {
    question:"Are there limits on the number of messages in the group card?",
    answer: "Depending on your card type:\n" +
      "Free cards: Up to 10 messages.\n" +
      "Premium cards: Up to 100 messages.\n" +
      "Premium cards with subscription: Up to 250 messages."
  },
  {
    question:"Are there any discounts available?",
    answer: "Yes! Our current prices come with discounts:\n" +
      "Simple cards from $5.99 to $4.99.\n" +
      "Unlimited cards subscription from $119.88 to $59.88."
  },
  {
    question:"How easy is it to customize and manage my group cards?",
    answer: "Customization is a breeze! While all cards are ready to go with just a few clicks, we've made it simple for everyone to personalize them. Once created, head to the Design menu for options like background images, text colors, avatars, animations, and font choices."
  },
  {
    question:"Who uses group cards?",
    answer: "Everyone celebrating special occasions as a group, from managers, leaders, coaches, VPs, CEOs to influencers!"
  },
  {
    question:"What is the maximum number of messages a card can have?",
    answer: "A group card can hold up to 200 messages with all the upgrades or with one of our subscriptions."
  },
  {
    question:"Can a group card make you look like super hero?",
    answer: "Yes! You can create a card in minutes and save the day!"
  },

];