export const linkPaths = {
    home: { path: '/'},
    login: { path: '/login'},
    register: { path: '/register'},
    viewCard: { path: '/card/:cardId'},
    create: { path: '/create/card' }, // pick a category
    createCardCategory: { path: '/create/card/:friendlyUrl' }, //show designs for category
    createCardSettings: { path: '/create/card/settings' }, //show settings for card
    dashboard: { path: '/dashboard' },
    support: { path: '/support' },
    admin: { path: '/admin'}
}