import {useLocation, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import Box from '@mui/material/Box';
import CardHeader from '../groupCard/CardHeader';

import { useGetMessagesQuery } from "../../slices/api/apiSlice";
import { useGetCardQuery } from "../../slices/api/cardApiSlice";
import {Alert} from "@mui/material";
import Uploading from "../../components/customBackgroundUploader/blocks/Uploading";
import {useEffect, useRef, useState} from "react";
import ShowMessages from "../../components/showMessages/ShowMessages";


import Grid from "@mui/material/Grid";
import {getBackgroundImage, getUrlParamValue, showCard} from "../../utils/functions";
import RequireCardPassword from "../groupCard/settings/RequireCardPassword";
import fakeMessages from "../../utils/fakeMessages";
import {useIntersectionObserver} from "../../hooks/useIntersectionObserver";


export default function ViewAsRecipient() {
  const showFakeMessages = useSelector(state => state.showFakeMessages)
  const  { cardId } = useParams();
  const [queryInfo, setQueryInfo] = useState({
    cardId,
    page: 1,
    limit: 250  // Example: 10 messages per page
  });

  let location = useLocation();
  const password = getUrlParamValue('password', location);
  let urlParams = `${cardId}`
  if(password) {
    urlParams = `${cardId}?password=${password}`
  }

  const {
    data: cardResponse,
    isLoading: getCardIsLoading,
    isSuccess: getCardIsSuccess,
    isError: getCardIsError,
  } = useGetCardQuery(urlParams)


  let card = {}
  if(getCardIsSuccess) {
    card = cardResponse.card
  }

  const {
    data: newMessages,
    loading: getMessagesIsLoading,
    isSuccess: gettingMessagesIsSuccess,
    isFetching: getMessagesIsFetching,
    isError: getMessagesIsError,
  } = useGetMessagesQuery(queryInfo, {
    keepPreviousData: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <Box sx={{ width: '100%', height:'auto', minHeight:'100%', backgroundImage: `url(https://storage.googleapis.com/prod-website-images-rlink/blue-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/red-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/orange-vector.svg)`,
      backgroundPosition: 'top right, top 20% left, bottom right',
      backgroundRepeat: 'no-repeat'  }}>
      { getCardIsLoading && <Uploading /> }
      { getCardIsError &&
        <Alert severity="error">Sorry... there was an error getting this card, please try refreshing the page</Alert>
      }
      { getCardIsSuccess && card.requirePassword &&
        <RequireCardPassword card={card} />
      }
      { getCardIsSuccess && showCard(card) &&
        <Box component='div' className='groupCardContainer' sx={{
          padding: {xs:'12px', md:'30px'},
          backgroundImage: `url(${getBackgroundImage(card)})`,
          backgroundPosition: 'top',
          // backgroundSize: 'cover',
          backgroundSize: {
            xs: 'cover',
            sm: card.template.cardBackgroundImageFullSize ? 'cover': '400px',
          },
          backgroundRepeat: {
            xs: 'repeat',
            md: card.template.cardBackgroundImageFullSize ? 'repeat' : 'repeat',
          },
          width: '100%',
          minHeight: '100vh',
          // position: 'fixed',
          overflow:'scroll'
        }}>
          <CardHeader card={card} inviteContributors={false} messages={newMessages} />
          { showFakeMessages && <ShowMessages fake={true} showAddMessageButton={false} messages={fakeMessages} card={card} />}
          {gettingMessagesIsSuccess && !showFakeMessages &&
            <ShowMessages showAddMessageButton={false} messages={newMessages} card={card} />
          }
        </Box>
      }
    </Box>
  )
}