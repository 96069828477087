import { createSlice } from '@reduxjs/toolkit';
import {addToArray, removeFromArray} from "../utils/functions";
import mediaTypes from "../utils/mediaTypes";

const initialState = {
  id: '',
  cardId: '',
  creatorEmail: '',
  creatorName: '',
  message: '',
  media:{
    type: null,
    data: []
  }
};
export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    messageFetched(state, action){
      const { id, media, creatorEmail, creatorName, message, cardId } = action.payload
      if(id) state.id = id;
      if(media) state.media = action.payload.media;
      if(creatorEmail) state.creatorEmail = creatorEmail;
      if(creatorName) state.creatorName = creatorName;
      if(message) state.message = message;
      if(cardId) state.cardId = cardId;
    },
    creatorEmailUpdated(state, action) {
      state.creatorEmail = action.payload
    },
    creatorNameUpdated(state, action) {
      state.creatorName = action.payload
    },
    mediaUpdated(state, action) {
      state.media = {
        type: action.payload.type,
        data: action.payload.data
      }
    },
    messageUpdated(state, action){
      state.message = action.payload
    },
    userUploadedImage(state, action){
      state.media = {
        type: mediaTypes.USER_IMAGE,
        data: addToArray(state.media.data, action.payload)
      }
    },
    userRemovedImage(state, action){
      state.media = {
        type: null, //TODO: fix this bug, there might be more items in the array
        data: removeFromArray(state.media.data, action.payload)
      }
    },
    userSelectedGif(state,action){
      state.media = {
        type: mediaTypes.GIPHY_IMAGES,
        data: [{fileUrl: action.payload}],
      }
    },
    userRemovedGif(state,action){
      state.media = {
        type: null,
        data: []
      }
    },
    userAddedVMessageVideo(state,action){
      state.media = {
        type: mediaTypes.USER_VIDEO,
        data: [{...action.payload}],
      }
    },
    userRemovedMessageVideo(state,action){
      state.media = {
        type: null,
        data: []
      }
    },
    clearMessageCreation(state,action){
      state.creatorName = '';
      state.creatorEmail = '';
      state.message = '';
      state.media = {
        type: null,
          data: []
      }
    }
  }
})

export const {
  messageFetched,
  userAddedVMessageVideo,
  userRemovedMessageVideo,
  clearMessageCreation,
  userSelectedGif,
  userRemovedGif,
  userUploadedImage,
  userRemovedImage,
  creatorEmailUpdated,
  creatorNameUpdated,
  mediaUpdated,
  messageUpdated,
} = messageSlice.actions;