import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";

export default function PrivacyPolicyPage() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const returnAsLink = (text, link) => {
    return (
      <Button variant="text" onClick={() => {
        navigate(link);
      }}>
        {text}
      </Button>
    )
  };
  const cssOptions = {
    m: '16px 0px 16px 0px'
  }

  return(
    <Grid container maxWidth="lg" sx={{mr: 'auto', ml:'auto'}}>
      <Grid xs={12} sx={ {
        textAlign: 'left', padding: '30px'
      }}>
        <Typography variant="h1">Privacy Statement</Typography>


        <Typography variant="body1" sx={cssOptions}> This Privacy Statement (the "Statement") describes certain privacy practices of The Recognition Link, LLC. ("RecognitionLink.com," "we," or "us"). The Statement is intended to explain how we collect, use, and share your information in connection with our website at https://www.RecognitionLink.com and any affiliated subdomains (the "Website"), and the services we offer through the Website (collectively, the "Services").</Typography>
        <Typography variant="body1" sx={cssOptions}>The Statement covers information collected from our Services, from our business partners and service providers, and from other sources. We may also have other privacy policies, set forth separately in writing, specific to certain categories of information or individuals, such as information relating to residents or citizens of certain jurisdictions. Please also refer to our Terms of Service at  {returnAsLink('View Terms of Service','/terms-of-service')} </Typography>


        <Typography variant="h2">What Information We Collect</Typography>
        <Typography variant="body1" sx={cssOptions}>Included below are the types of information collected on RecognitionLink.com:</Typography>


        <Typography variant="body1" sx={cssOptions}>Contact information, such as your name, address, email address, and telephone number.</Typography>
        <Typography variant="body1" sx={cssOptions}> Account login or verification information (including third-party account-verification information if you choose to sign in with Google, Facebook).</Typography>
        <Typography variant="body1" sx={cssOptions}>When you sign in to RecognitionLink.com using third-party account verification services such as Google or Facebook, we may collect certain information from these third-party services to facilitate your account login and verification process. This information may include your name, email address, and profile photo, when available, from your third-party account.</Typography>

        <Typography variant="body1" sx={cssOptions}>By choosing to sign in with Google, Facebook, or other third-party services, you authorize RecognitionLink.com to access and store this information as part of your account profile. This allows us to verify your identity and provide you with a seamless login experience.</Typography>

          <Typography variant="body1" sx={cssOptions}>Please note that we only collect and store this information to the extent necessary for the purpose of account verification and login authentication. We do not use this information for any other purpose without your explicit consent.</Typography>

            <Typography variant="body1" sx={cssOptions}>If you have any concerns about the information collected during the account login or verification process, please contact us at support@recognitionlink.com for further assistance.</Typography>
        <Typography variant="body1" sx={cssOptions}> Financial information, such as payment card information (e.g. credit or debit card number, CVV code, expiration date) or other financial account information. (Note that this information is stored through our payment processor, Stripe, rather than on RecognitionLink.com servers. Stripe's privacy policy can be found here: <a href="https://stripe.com/privacy" target="_BLANK">https://stripe.com/privacy</a> .)</Typography>

        <Typography variant="body1" sx={cssOptions}>Internet or other network or device activity, such as information about the browsers and devices you use to access our Services, emails, and advertisements (e.g. device type, browser type and language, hardware model, operating system version, unique device identifiers, and IP address) and how you interact with our Services, emails, and advertisements (e.g. features used, content viewed, addresses of referring websites, and the dates and times of interactions).</Typography>

        <Typography variant="body1" sx={cssOptions}>Location information, including geolocation when using our Services.</Typography>

        <Typography variant="body1" sx={cssOptions}> Electronic, audio, visual, or similar information, such as your communications with us or photos, videos, or other content you submit to us or publish in connection with our Services.</Typography>

        <Typography variant="body1" sx={cssOptions}> Other information that identifies or can be reasonably associated with you.</Typography>

        <Typography variant="h2">How We Collect Information</Typography>

        <Typography variant="body1" sx={cssOptions}> We collect the types of information described above in three ways: (1) when you provide it to us; (2) when we collect it automatically; and (3) and when we receive it from other sources.</Typography>

        <Typography variant="body1" sx={cssOptions}><b>Information you provide to us</b></Typography>
        <Typography variant="body1" sx={cssOptions}>You provide information to us when you register for an account, use our Services, communicate with us, or otherwise submit or publish your information. For example, when you register for an account, you may provide contact information, account login or verification information, demographic information, and financial information. When using our Services, you may provide demographic information or other information about yourself or others, including photos or other user-generated content. You might also provide information when communicating with us (for example, regarding our Services or when seeking technical support) or linking to us or our Services on social media or other websites.</Typography>

        <Typography variant="body1" sx={cssOptions}><b>Information we collect automatically</b></Typography>
        <Typography variant="body1" sx={cssOptions}>We automatically collect internet or other network or device activity information about your interactions with our Services, emails, and advertisements, even if you are not logged into an account with us. This information may be collected as follows:</Typography>


        <Typography variant="body1" sx={cssOptions}>Cookies and similar technologies.
          Cookies and similar technologies are pieces of information stored directly on the device you are using.
          Cookies allow us to collect information such as browser type, time spent on our Services, pages visited, and language preferences.</Typography>

        <Typography variant="body1" sx={cssOptions}>We and our service providers use the information for security purposes, to facilitate navigation, to display information more effectively, and to personalize your online experience and our marketing and advertising efforts. In addition, we use cookies to gather statistical information about usage of our Services in order to improve their design and functionality, understand how they are used, and assist us with resolving questions about them.</Typography>

        <Typography variant="body1" sx={cssOptions}> You can choose to accept or decline cookies through your web browser. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. If you would prefer not to accept cookies, most browsers will allow you to: (i) change your browser settings to notify you when you receive a cookie, which lets you choose whether or not to accept it; (ii) disable existing cookies; or (iii) set your browser to automatically reject any cookies. If you set your browser to reject cookies, parts of our Services may not work for you.</Typography>


        <Typography variant="body1" sx={cssOptions}> <b>Analytics and advertising.</b></Typography>

        <Typography variant="body1" sx={cssOptions}>To better understand how users engage with our Services, we use third-party analytics services that use cookies and similar technologies, including services provided by Google. Please visit <a href="https://www.google.com/policies/privacy/partners/">https://www.google.com/policies/privacy/partners/</a> for more information about how these third parties collect and use data.</Typography>

        <Typography variant="body1" sx={cssOptions}> We may also use third-party advertising services to provide advertisements for our services. These third parties may collect information about your online activities over time and across third-party websites, and they may be members of industry self-regulatory groups such as the Network Advertising Initiative (NAI), Digital Advertising Alliance (DAA), and European Digital Advertising Alliance (eDAA). The websites for these groups provide more information on interest-based online advertising and how to opt out of receiving interest-based online ads from participating companies.</Typography>

        <Typography variant="body1" sx={cssOptions}> Do Not Track requests.</Typography>

        <Typography variant="body1" sx={cssOptions}>Some browsers may transmit "Do Not Track" or "DNT" signals to the websites you visit. Because there is not common agreement about how to interpret DNT signals, we do not take action in response to them.</Typography>

        <Typography variant="body1" sx={cssOptions}> Location information. We may collect information about the location of your device, including precise geolocation information from your device and imprecise location from, for example, your IP address.
          You may be able to adjust the location settings of your device to control whether it communicates location information. See your device settings for more information.</Typography>
        <Typography variant="body1" sx={cssOptions}> Purchase information and history. We may collect information about your transactions with us.</Typography>
        <Typography variant="body1" sx={cssOptions}>
          Information we receive from other sources. We may collect information from other sources, including from other users who might post information about you on our Services, or from business partners and service providers with whom we work. For example, another user may upload your name, e-mail address, photos, or other information in connection with a digital group card created by that user.</Typography>


        <Typography variant="h2">How We Use The Information</Typography>
        <Typography variant="body1" sx={cssOptions}>We will not sell your information to data brokers or other third parties for money. We may use and process the types of information described above to:</Typography>

        <Typography variant="body1" sx={cssOptions}><b>Provide our Services to you and other users.</b></Typography>
        <Typography variant="body1" sx={cssOptions}> For example, a user may create a digital group card and upload other individuals' contact information so that those individuals may view and/or contribute to the board. We may use that information to allow those individuals to view and/or contribute to the Recognition Link or to contact one another regarding the Recognition Link.  Users may also post photos of themselves or other individuals, and we may include those photos or similar user-generated content within the users' respective Recognition Link.</Typography>

        <Typography variant="body1" sx={cssOptions}><b>Maintain, protect, and improve our Services or other products.</b> </Typography>
        <Typography variant="body1" sx={cssOptions}>This may include, for example, customizing our Services to your preferences or interests, making them more compatible with your technology, or otherwise making them easier to use; analyzing how they are used; and evaluating, maintaining, and improving their performance, effectiveness, security, and safety.</Typography>

        <Typography variant="body1" sx={cssOptions}><b>Process payments and transactions through a 3rd payment processor.</b></Typography>
        <Typography variant="body1" sx={cssOptions}>Communicate and manage our relationship with you. This may include, for example, providing you information about our Services or other products or services or about your transactions with us; responding to your questions, inquiries, or requests; customizing and improving our communications with you and analyzing their effectiveness.</Typography>
        <Typography variant="body1" sx={cssOptions}><b>Develop new applications or other products or services.</b></Typography>
        <Typography variant="body1" sx={cssOptions}>Promote our Services or other products. This may include, for example, developing and providing promotional and advertising materials about RecognitionLink.com that may be of interest to you; analyzing the effectiveness of those materials; and customizing and improving them.</Typography>
        <Typography variant="body1" sx={cssOptions}><b>Address legal issues.</b></Typography>
        <Typography variant="body1" sx={cssOptions}>This may include, for example, obtaining legal advice and establishing, exercising, or defending legal claims; complying with laws, regulatory requirements, contractual obligations, court orders, other legal process or obligations, or lawful requests from public and government authorities; detecting, preventing, and responding to fraud, intellectual property infringement, security issues, violations of our agreements, violations of law, or other misuse of our Services or other products or services; and protecting the safety, rights, welfare, or property of RecognitionLink.com, you, or others.</Typography>
        <Typography variant="body1" sx={cssOptions}>We may also use your information for other internal RecognitionLink.com business operations, as permitted by applicable law, and other uses consistent with the context in which the information was collected or with your consent.</Typography>

        <Typography variant="body1" sx={cssOptions}>We may anonymize or aggregate any of the information we collect and use it for any purpose, including for research and product-development purposes. Such aggregated information will not identify you individually or your organization.</Typography>

        <Typography variant="h2">How We Share The Information</Typography>
        <Typography variant="body1" sx={cssOptions}>We will not sell your information to data brokers or other third parties for money. We may, however, share your information as follows:</Typography>

        <Typography variant="body1" sx={cssOptions}>In connection with our Services.</Typography>
        <Typography variant="body1" sx={cssOptions}>The information that you or other users submit to us while using our Services may be seen by other visitors to our Services, potentially including the public. For example, depending on the privacy settings of a digital group card, users may share content from a board on social media.</Typography>
        <Typography variant="body1" sx={cssOptions}>Service providers, vendors, and other business partners.</Typography>
        <Typography variant="body1" sx={cssOptions}>We may share your information with third parties with whom we work to provide, improve, or develop our Services or other products; to perform other Website-related services; or to assist us in analyzing how our Services or other products are used.</Typography>
        <Typography variant="body1" sx={cssOptions}>For example, we may work with vendors providing website hosting; data analysis; other technology services; payment processing; fraud monitoring; services related to product development and maintenance; and communications, marketing, and online and offline advertising services.</Typography>
        <Typography variant="body1" sx={cssOptions}>More specifically, our service providers may include:</Typography>
        <ul>
          <Typography variant="body1" sx={cssOptions}>Google Cloud – to maintain servers and run our application</Typography>

          <Typography variant="body1" sx={cssOptions}>Cloudinary – to store media files</Typography>
          <Typography variant="body1" sx={cssOptions}>Google Analytics - to analyze the behavior of our Website visitors</Typography>
          <Typography variant="body1" sx={cssOptions}>DataDog - to analyze the behavior of our Website visitors</Typography>
        </ul>
        <Typography variant="body1" sx={cssOptions}>Legal reasons.</Typography>
        <Typography variant="body1" sx={cssOptions}>We may share your information with third parties when we have a good faith belief that disclosure is necessary to: obtain legal advice or establish, exercise, or defend legal claims; comply with laws, regulatory requirements, contractual obligations, court orders, or other legal process or obligations, or lawful requests from public and government authorities; detect, prevent, or respond to fraud, intellectual property infringement, security issues, violations of our agreements or law, or other misuse of our Applications or other products or services; protect the safety, rights, welfare, or property of RecognitionLink.com, you, or others, as required or permitted by applicable law.</Typography>
        <Typography variant="body1" sx={cssOptions}>Business transfers.</Typography>
        <Typography variant="body1" sx={cssOptions}>In the future, we may share your information with third parties in connection with any reorganization, merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of our business, assets, or stock (including in connection with any bankruptcy or similar proceedings).</Typography>
        <Typography variant="body1" sx={cssOptions}>Otherwise with your consent.</Typography>
        <Typography variant="body1" sx={cssOptions}> We may share your information with third parties when we have your consent to do so.</Typography>
        <Typography variant="body1" sx={cssOptions}> Anonymized or aggregated information.</Typography>
        <Typography variant="body1" sx={cssOptions}> We may share anonymized or aggregated information internally and with third parties for any purpose. Such information will not identify you individually or your organization.</Typography>

        <Typography variant="h2">How You Can Manage Your Information</Typography>
        <Typography variant="body1" sx={cssOptions}> If you have an account with us, our Services allow you to:</Typography>

        <Typography variant="body1" sx={cssOptions}>Change the password associated with your account;</Typography>


        <Typography variant="body1" sx={cssOptions}>You may also contact us at support@RecognitionLink.com to request updates in your account, as name r email or the full deletion of your account data.</Typography>

        <Typography variant="h2">Where We Process Information</Typography>
        <Typography variant="body1" sx={cssOptions}>We may process your information in the United States and other locations around the world. The countries in which we process information may not provide the same level of protection for your information as your country. By using our Services or otherwise providing us your information, you acknowledge and understand that your information will be transferred to and processed in the United States and other countries.</Typography>

        <Typography variant="h2">EU Data Subject Rights</Typography>
        <Typography variant="body1" sx={cssOptions}>If you are a natural person residing within the European Union, you have the following rights:</Typography>

        <Typography variant="body1" sx={cssOptions}>The right to access. You have the right to request copies of your personal data.</Typography>
        <Typography variant="body1" sx={cssOptions}> The right to rectification. You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete information you believe is incomplete.</Typography>
        <Typography variant="body1" sx={cssOptions}>The right to erasure. You have the right to request that we erase your personal data, under certain conditions.</Typography>
        <Typography variant="body1" sx={cssOptions}>The right to restrict processing. You have the right to request that we restrict the processing of your personal data, under certain conditions.</Typography><Typography variant="body1" sx={cssOptions}>The right to object to processing. You have the right to object to our processing of your personal data, under certain conditions.</Typography><Typography variant="body1" sx={cssOptions}>The right to data portability. You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</Typography>
        <Typography variant="body1" sx={cssOptions}>If you would like to exercise any of these rights, please contact us at support@RecognitionLink.com.</Typography>

        <Typography variant="body1" sx={cssOptions}>If you make a request, we will respond within the time required by law. In connection with such a request, we may require that you provide us with information necessary to confirm your identity before responding. In addition, please note that certain information may be exempt from such requests in some circumstances. For example, we may need to keep processing your information for our legitimate interests or to comply with a legal obligation.</Typography>

        <Typography variant="h2">California Consumer Rights</Typography>
        <Typography variant="body1" sx={cssOptions}>In this Statement, we have described the types of information we collect, the sources from which we collect the information, and the ways in which we use the information. For purposes of the California Consumer Privacy Act of 2018 ("CCPA"), the types of information we collect fall into the following categories:</Typography>

        <Typography variant="body1" sx={cssOptions}>Identifiers (e.g. name, mailing address, email address, phone number, credit/debit card number).</Typography>
        <Typography variant="body1" sx={cssOptions}>Characteristics of protected classifications (e.g. gender, age).</Typography>
        <Typography variant="body1" sx={cssOptions}>Commercial information (e.g. products or services purchased, purchase history).</Typography>
        <Typography variant="body1" sx={cssOptions}>Internet or other electronic network activity (e.g. browser or search history).</Typography>
        <Typography variant="body1" sx={cssOptions}>Geolocation data (e.g. latitude or longitude).</Typography>
        <Typography variant="body1" sx={cssOptions}>Audio, electronic, visual, or similar information (e.g. recording of guest service calls).</Typography>
        <Typography variant="body1" sx={cssOptions}>Professional or employment related information (e.g., occupation, job title, or professional association membership.</Typography>
        <Typography variant="body1" sx={cssOptions}> Inferences drawn from any of the above (e.g. preferences or characteristics).</Typography>
        <Typography variant="body1" sx={cssOptions}>As described in this Statement, we collect this information from our consumers (through our Services or our customer or technical support services) and from our vendors and other service providers (including data analytics providers, advertising networks, and third-party account verification services), and we use this information for the business or commercial services described above.</Typography>

        <Typography variant="body1" sx={cssOptions}>We do not sell your personal information to third parties, and we have not done so in the twelve months prior to the date of this Statement. We also do not share your personal information with third parties for their own direct marketing purposes.</Typography>

        <Typography variant="body1" sx={cssOptions}>In addition, RecognitionLink.com does not have actual knowledge that it sells or discloses the personal information of minors under 16 years of age. To the extent that we become aware that such sale or disclosure might be occurring, we will take steps to prevent such events, and/or we will modify this Statement to describe those events and the related rights and choices of our consumers.</Typography>

        <Typography variant="body1" sx={cssOptions}> If you are a California "consumer," as defined by the CCPA, you may request that we:</Typography>

        <Typography variant="body1" sx={cssOptions}> Provide access to and/or a copy of certain personal information we have about you.</Typography>
        <Typography variant="body1" sx={cssOptions}> Provide information (up to twice in a 12-month period) about: the categories of personal information we collect, disclose, or sell about you; the categories of sources of such information; the business or commercial purpose for collecting or selling your personal information; and the categories of third parties with whom we have shared personal information during the past 12 months.</Typography>
        <Typography variant="body1" sx={cssOptions}> Delete certain personal information we have about you.</Typography>
        <Typography variant="body1" sx={cssOptions}>You may exercise these rights by submitting a request to us at support@RecognitionLink.com. In reviewing and responding to such requests, we will undertake reasonable efforts to verify your identity by, for example, matching any information you provide in the request with information we maintain.</Typography>

        <Typography variant="body1" sx={cssOptions}>Please also note that most of these rights are not absolute, and our response may be limited as permitted by the CCPA. For example, the right to have personal information deleted is subject to a number of exceptions set out in the CCPA, such as when we need to maintain the information to provide products or services you requested or to comply with our legal obligations.</Typography>

        <Typography variant="body1" sx={cssOptions}>Under the CCPA, California consumers also have the right not to receive discriminatory treatment for exercising their other rights. RecognitionLink.com will not discriminate against you because you exercise your rights. For example, RecognitionLink.com will not deny you goods or services or charge you a different price or rate for goods or services if you make an access, deletion, or do not sell request.</Typography>

        <Typography variant="h2">Data Security</Typography>
        <Typography variant="body1" sx={cssOptions}>We maintain technical and organizational security measures designed to help protect your personal information from unauthorized access, disclosure, alteration, or destruction. Unfortunately, no data transmission or storage system is 100% secure or error free, and we cannot guarantee the security of the information we collect.</Typography>

        <Typography variant="h3">Data Retention</Typography>
        <Typography variant="body1" sx={cssOptions}>We retain your information as long as necessary to fulfill the purposes outlined in this Statement, unless a longer retention period is required or allowed by law.</Typography>

        <Typography variant="h3">Links to Other Sites</Typography>
        <Typography variant="body1" sx={cssOptions}>Our Services may contain links to other sites, mobile applications, or third-party services, or plug-ins from social-media platforms or other third parties. Please note that these third-party sites and plug-ins are not operated by us. Therefore, we strongly advise you to review the third parties' privacy policies. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</Typography>

        <Typography variant="h3">Children's Privacy</Typography>
        <Typography variant="body1" sx={cssOptions}>Our Services are not intended for anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we will delete such information from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us at legal @RecognitionLink.com.</Typography>

        <Typography variant="h3">Changes to This Privacy Statement</Typography>
        <Typography variant="body1" sx={cssOptions}>We may update our Privacy Statement from time to time. Thus, we advise you to review this page periodically for any changes. Any changes to this Statement are effective immediately after they are posted on this page.</Typography>

        <Typography variant="h3">Contact Us</Typography>
        <Typography variant="body1" sx={cssOptions}>If you have any questions or suggestions about our Privacy Statement, do not hesitate to contact us at support@RecognitionLink.com</Typography>
        <Typography variant="body1" sx={cssOptions}>LAST UPDATED: April 2th, 2024</Typography>
      </Grid>
    </Grid>
  )
}