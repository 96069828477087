import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import Divider from "../../../components/divider/Divider";
import UpgradeBox from "../../../components/upgradeBox/UpgradeBox";
import YourSubscriptions from "../../../components/yourSubscriptions/YourSubscriptions";
import YourInfo from "../../../components/yourInfo/YourInfo";
import {useGetSubscriptionsQuery, useGetUserQuery} from "../../../slices/api/userSlice";
import {hasActiveSubscription} from "../../../utils/functions";

export default function Account(){
  const {
    data: user,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetUserQuery();

  const {
    data: subscriptions,
    isLoading: fetchingSubscriptions,
    isSuccess: fetchedSubscriptions,
    isError: errorFetchingSubs,
    error: errorSubscriptions,
  } = useGetSubscriptionsQuery();




  return(
  <Box sx={{padding: '20px'}}>
    <Typography variant="h3">Your info</Typography>
    {isSuccess && <YourInfo user={user} /> }
    <Divider />
    {fetchedSubscriptions && hasActiveSubscription(subscriptions) &&
    <>
      <Typography variant="h3">Your Subscriptions</Typography>
      <YourSubscriptions subscriptions={subscriptions} />

    </>}

    {/*{fetchedSubscriptions && !hasActiveSubscription(subscriptions) &&*/}
    {/*  <>*/}
    {/*  <Typography variant="h3">Upgrade to Create  Cards</Typography>*/}
    {/*  <Typography variant="body2" sx={{ margin: '10px 0'}}>This is a yearly subscription that allows you to create unlimited cards, with all the features and with up to 250 messages per card. Ideal for leaders and managers.</Typography>*/}
    {/*  <UpgradeBox subscriptions={subscriptions} />*/}
    {/*  </>*/}
    {/*}*/}
  </Box>)
}