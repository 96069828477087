import { useEffect } from "react";
import { useSelector} from "react-redux";
import { useNavigate, redirect } from "react-router-dom";
import Grid from '@mui/material/Grid';
import CardSettings from '../../components/cardSettings/CardSettings';
import CreationSteps from "../../components/creationSteps/CreationSteps";
import {linkPaths} from "../../utils/linkPaths";

export default function CreateCardSettings() {
  const navigate = useNavigate();
  const cardTemplate = useSelector(state => state.card.template);

  useEffect(() => {
    if(!cardTemplate.cardBackgroundImage) {
      navigate(linkPaths.create.path)
    }
  }, [cardTemplate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const steps = [
    {id: 'step1', screen: 'pickDesign', status: 'completed', lastStep: false, title: 'Pick your design', description: 'Choose a card from the catalogue or use AI', cb:() => { navigate(linkPaths.create.path)} },
    {id: 'step2', screen: 'cardSettings', status: 'active', lastStep: false, title: 'Fill card details', description:'Who is receiving it', cb:() => {navigate(linkPaths.createCardSettings.path)} },
    {id: 'step3', screen: 'sendCard', status: 'notStarted', lastStep: true, title: 'Add messages and send it', description:'Send the card to contributors and recipient' },
  ]

  return(
    <Grid  container sx={{ minHeight: { xs: 'calc(100% - 70px)', sm: 'calc(100% - 80px)'} }}>
      <Grid item xs={12} md={3} >
        <CreationSteps steps={steps} />
      </Grid>
      <Grid item xs={12} md={9} sx={{
        // minHeight: '100%', maxHeight: '100vh', overflowY: 'auto',
        padding: {
          xs: '10px 10px',
          md: '30px 10px',
        }
      }}>
        <CardSettings />
      </Grid>
    </Grid>
  )
}