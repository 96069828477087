import { createSlice } from '@reduxjs/toolkit';
import { addToArray, removeFromArray } from "../utils/functions";

const initialState = {
  category: {},
  contributors: [],
  creatorEmail: '',
  creatorName: '',
  orgId: null,
  recipients: [{ fullName: '', email: ''}],
  schedule: null,
  deliveryStatus: 'PENDING',
  deliveryDate: null,
  previouslyInvitedByEmail: [],
  viewedByRecipient:false,
  tier: 'FREE_PLAN',
  maxMsg: 10,
  title: '',
  password: '',
  passwordMessage: '',
  openToMessages: true,
  createdAt: null,
  updatedAt: null,
  canEdit: false,
  template: {
    recipientsNameColor: '#FFF',
    cardTitleColor: '#FFF',
    messageTextColor: '#FFF',
    messageBackgroundColor: '#FFF',
    cardBackgroundColor: '#FFF',
    cardBackgroundImage: null, // { file: File, url: local url}
    cardBackgroundImageFullSize: true,
    isCustomBackgroundImage: false,
    titleShadowColor: '#FFF',
    titleXShadow: 4,
    titleYShadow: 4,
    titleBlur: 4,
    titleFontFamily: "'Inter', sans-serif",
    titleFontSize: 40,
    titleLetterSpacing: 1,
    titleFontWeight: '400',
    avatarImage: null,
    isCustomAvatar: false,
    showAnimation:false,
    animation: null,
  },
  settings: {
    'background-music': false,
    'schedule-delivery': false,
    'slide-show': false,
    'animated-intro': false,
    'video-in-messages': false,
    'custom-background-image': false,
    'logo-photo-avatar': false,
    'gift-card': false,
    'video-card-download': false,
    'admin-tools': false,
    'multiple-card-admins': false,
  },
  music: null ,

};


export const cardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    titleUpdated(state, action) {
      state.title = action.payload
    },
    creatorNameUpdated(state, action) {
      state.creatorName = action.payload
    },
    avatarImageUpdated(state, action) {
      state.template.avatarImage = action.payload;
      state.template.isCustomAvatar = true;
    },
    avatarSelected(state,action){
      state.template.avatarImage = action.payload.avatarImage;
      state.template.isCustomAvatar = action.payload.isCustomAvatar;
    },
    avatarImageDeleted(state, action) {
      state.template.avatarImage = null;
      state.template.isCustomAvatar = false;
      state.settings['logo-photo-avatar'] = false;
    },
    creatorEmailUpdated(state, action) {
      state.creatorEmail = action.payload
    },
    recipientNameUpdated(state, action){
      state.recipients[0].fullName = action.payload
    },
    recipientEmailUpdated(state, action){
      state.recipients[0].email = action.payload
    },
    recipientAdded(state, action) {
      state.recipients = addToArray(state.recipients, action.payload);
    },
    categoryUpdated(state,action) {
      state.category = action.payload
    },
    recipientRemoved(state, action) {
      state.recipients = removeFromArray(state.recipients, action.payload)
    },
    templateSelected(state, action) {
      state.template = {
        ...state.template,
        ...action.payload,
      }
    },
    scheduleSet(state, action) {
      state.schedule = action.payload
    },
    removeSchedule(state, action){
      state.settings['schedule-delivery'] = false;
      state.schedule = null;
    },
    settingUpdated(state, action) {
      state.settings = {
        ...state.settings,
        ...action.payload,
      }
    },
    musicUpdated(state, action) {
      state.music = action.payload
    },
    customBackgroundImageAddedByUser(state, action) {
      state.template.cardBackgroundImage = action.payload;
      state.template.isCustomBackgroundImage = true;
    },
    backgroundSizeSetByUser(state, action) {
      state.template.cardBackgroundImageFullSize = action.payload;
    },
    deleteBackgroundImageSetByUser(state, action) {
      state.template.cardBackgroundImage = null;
      state.template.isCustomBackgroundImage = false;
      state.settings['custom-background-image'] = false;
    },
    passwordUpdated(state, action) {
      state.password = action.payload;
    },
    animationUpdated(state, action) {
      state.template.animation = action.payload.animation;
      state.template.showAnimation = action.payload.showAnimation;
    },
    removePasswordSettings(state, action) {
      state.password = null;
      state.settings['admin-tools'] = false;
    },
    resetCardState() {
      return initialState
    },
    updateCardData(state,action) {
      return action.payload;
    },
  }
})

export const {
  avatarSelected,
  avatarImageUpdated,
  avatarImageDeleted,
  categoryUpdated,
  creatorNameUpdated,
  creatorEmailUpdated,
  titleUpdated,
  recipientNameUpdated,
  recipientEmailUpdated,
  musicUpdated,
  customBackgroundImageAddedByUser,
  backgroundSizeSetByUser,
  deleteBackgroundImageSetByUser,
  recipientAdded,
  recipientRemoved,
  templateSelected,
  scheduleSet,
  settingUpdated,
  passwordUpdated,
  resetCardState,
  removeSchedule,
  removePasswordSettings,
  updateCardData,
  animationUpdated,
} = cardSlice.actions;