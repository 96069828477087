import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions, TextField, Button, Typography } from '@mui/material';
import { createTheme, useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import {isValidEmail} from "../../utils/functions";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";
import {useSignupToNewsletterMutation} from "../../slices/api/newsletterApiSlice";

const theme = createTheme();

const NewsletterSignUpDialog = () => {
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [signUpToNewsletter, {isLoading, isSuccess, isError, error: newsletterError}] = useSignupToNewsletterMutation();

  useEffect(() => {
    const showDialog = () => {
      const lastCancel = localStorage.getItem('newsletterCancelDate');
      const subscribed = localStorage.getItem('newsletterSubscribed');
      const now = new Date();

      if (!subscribed && (!lastCancel || (now - new Date(lastCancel)) / (1000 * 60 * 60 * 24) > 10)) {
        setOpen(true);
      }
    };

    const timer = setTimeout(showDialog, 20000);

    const handleMouseLeave = (event) => {
      if (event.clientY < 0) {
        showDialog();
      }
    };

    document.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      clearTimeout(timer);
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  const handleSubscribe = async () => {
    if(!isValidEmail(email)) {
      return setError('Valid email required')
    }

   try {
     const subscription = await signUpToNewsletter({
       firstName,
       lastName,
       email,
     }).unwrap();

     localStorage.setItem('newsletterSubscribed', 'true');
     setTimeout(() =>{ setOpen(false);},2000)

   } catch (e) {
    console.log('error subscribing')
   }
  };

  const handleCancel = () => {
    if(isLoading) return null;

    localStorage.setItem('newsletterCancelDate', new Date().toISOString());
    setOpen(false);
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  console.log('newsletterError',newsletterError)

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleCancel}>
      {error && <InfoSnackbar severity="error" message={error} callback={() => setError('')} />}
      {isError && <InfoSnackbar severity="error" message={newsletterError.data.error} />}
      {isSuccess && <InfoSnackbar severity="success" message="Thanks for subscribing to our newsletter!" />}
      <DialogContent>
        <Typography variant="h4">Get 20 Team Tactics to Grow an Inclusive and Effective Team</Typography>
        <Typography variant="body1" sx={{ margin: '10px auto 20px auto' }}>
          Subscribe to our newsletter to unlock proven strategies to enhance team dynamics and performance.
        </Typography>
        <Typography variant="body2" sx={{paddingTop: '0px', lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500 }}>
          First name
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          type="text"
          fullWidth
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Typography variant="body2" sx={{ paddingTop: '0px', lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500 }}>
          Last name
        </Typography>
        <TextField
          margin="dense"
          type="text"
          fullWidth
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <Typography variant="body2" sx={{ paddingTop: '0px', lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500 }}>
          Email
        </Typography>
        <TextField
          required
          margin="dense"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading || isSuccess} onClick={handleCancel} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button disabled={isLoading || isSuccess} onClick={handleSubscribe} variant="contained" color="primary">
          Subscribe
        </Button>
      </DialogActions>
      <Box sx={{ margin: '10px auto', width: '100%', maxWidth: { xs: '130px' } }}>
        <img className="logoTopBar" alt="RecognitionLink.com" src="https://storage.googleapis.com/prod-website-images-rlink/logo-recognition-link.png" />
      </Box>
    </Dialog>
  );
};

export default NewsletterSignUpDialog;
