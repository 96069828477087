import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DirectLink from './shareTabs/DirectLink';
import EmailShare from './shareTabs/EmailShare';
import SocialShare from './shareTabs/SocialShare';
import QrCodeShare from './shareTabs/QrCode';
import {closeMessageIcon} from '../../svgIcons/icons';
import * as React from "react";
import {removeQueryString} from "../../utils/functions";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div

      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{marginTop: '40px'}}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function InviteContributors({card, closeModal}) {
  const {title} = card;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function getShareUrl() {
    let url = removeQueryString(window.location.href)
    if(card.password) {
      url = url + `?password=${card.password}`
    }
    return url;
  }

  const url = getShareUrl();

  return (
    <Box>
      <Box sx={{display: 'flex', flexDirection:{ xs: 'column', md: 'row'}, justifyContent: 'space-between', }}>
        <Box onClick={() => {closeModal()}} sx={{display: { xs: 'block', md: 'none'}, textAlign: 'right', '&:hover':{ cursor: 'pointer'} }} >
          {closeMessageIcon}
        </Box>
        <Typography variant="h4" sx={{ fontSize: '30px', lineHeight: '38px'}}>
          Invite Contributors
        </Typography>
        <Box onClick={() => {closeModal()}} sx={{display: { xs: 'none', md: 'block'},  float:'right', textAlign: 'right', '&:hover':{ cursor: 'pointer'} }} >
          {closeMessageIcon}
        </Box>
      </Box>
      <Typography variant="subtitle2">Use this option to invite others to add their messages to {card.recipients[0].fullName}'s card by choosing one of the options below:</Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '30px' }}>
        <Tabs variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab className='dashboardTab' label="Direct Link" {...a11yProps(0)} />
          <Tab className='dashboardTab'  label="Email" {...a11yProps(1)} />
          <Tab className='dashboardTab'  label="Social" {...a11yProps(2)} />
          {/*<Tab className='dashboardTab'  label="QR Code" {...a11yProps(2)} />*/}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <DirectLink url={url} closeModal={closeModal} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <EmailShare card={card} url={url} closeModal={closeModal} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <SocialShare url={url} title={title} closeModal={closeModal}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <QrCodeShare url={url} closeModal={closeModal} />
      </CustomTabPanel>
    </Box>
  )
}