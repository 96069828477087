import Box from "@mui/material/Box";

const stickyStyle = {
  position: 'sticky',
  top: 0, // Change this value to adjust the offset from the top
  backgroundColor: 'white', // Ensures the text is readable
  zIndex: 1000, // Make sure it stays on top of other content
};

const StickyDiv = ({ children, sx = {} }) => {
  return (
    <Box sx={{
      position: 'sticky',
      top: 0, // Change this value to adjust the offset from the top
      backgroundColor: 'white', // Ensures the text is readable
      zIndex: 1000, // Make sure it stays on top of other content
      ...sx
    }}>
      {children}
    </Box>
  );
};

export default StickyDiv;