export default [
  {id: 'bday-animations-all', name: 'Birthday', animations:[
      {name: 'Birthday Cake', animation:'https://storage.googleapis.com/prod-rl-animations/bday/bday_cake_ilustration.lottie'},
      {name: 'Birthday cake', animation:'https://storage.googleapis.com/prod-rl-animations/bday/birthday_cake_ilustration.lottie'},
      {name: 'Birthday friends', animation:'https://storage.googleapis.com/prod-rl-animations/bday/birthday_friends.lottie'},
      {name: 'Cupcake', animation:'https://storage.googleapis.com/prod-rl-animations/bday/cupcake_ballon.lottie'},
      {name: 'Enjoy your day', animation:'https://storage.googleapis.com/prod-rl-animations/bday/enjoy_your_day.lottie'},
      {name: 'Happy Birthday', animation:'https://storage.googleapis.com/prod-rl-animations/bday/happy_birthday.lottie'},
      {name: 'Happy Birthday', animation:'https://storage.googleapis.com/prod-rl-animations/bday/happy_birthday_(1).lottie'},
      {name: 'Make a wish', animation:'https://storage.googleapis.com/prod-rl-animations/bday/make_a_wish.lottie'},
      {name: 'Party face', animation:'https://storage.googleapis.com/prod-rl-animations/bday/party_face.lottie'},
      {name: 'Presents', animation:'https://storage.googleapis.com/prod-rl-animations/bday/presents_hat.lottie'},
    ]},
  {id: 'thank-you-all', name: 'Thank You', animations:[
      {name: 'Thank you', animation:'https://storage.googleapis.com/prod-rl-animations/thank-you/thank_sticker.lottie'},
      {name: 'Thank you', animation:'https://storage.googleapis.com/prod-rl-animations/thank-you/thank_you.lottie'},
      {name: 'Thank you', animation:'https://storage.googleapis.com/prod-rl-animations/thank-you/thank_you_(1).lottie'},
      {name: 'Thank you', animation:'https://storage.googleapis.com/prod-rl-animations/thank-you/thank_you_(2).lottie'},
      {name: 'Thank you', animation:'https://storage.googleapis.com/prod-rl-animations/thank-you/thank_you_gift.lottie'},
      {name: 'Thank you', animation:'https://storage.googleapis.com/prod-rl-animations/thank-you/thank_you_hand_gesture.lottie'},
      {name: 'Thank you', animation:'https://storage.googleapis.com/prod-rl-animations/thank-you/thank_you_letter.lottie'},
      {name: 'Thank you', animation:'https://storage.googleapis.com/prod-rl-animations/thank-you/thank_you_mail.lottie'},
    ]},
  {id: 'farewell-retirement-animations', name: 'Farewell/Retirement', animations:[
      {name: 'Airplane ticket', animation:'https://storage.googleapis.com/prod-rl-animations/farewell-retirement-vacations/airplane_ticket.lottie'},
      {name: 'Beach chair', animation:'https://storage.googleapis.com/prod-rl-animations/farewell-retirement-vacations/beach_chair.lottie'},
      {name: 'Good luck', animation:'https://storage.googleapis.com/prod-rl-animations/farewell-retirement-vacations/good_luck.lottie'},
      {name: 'Good luck', animation:'https://storage.googleapis.com/prod-rl-animations/farewell-retirement-vacations/good_luck_(1).lottie'},
      {name: 'Holiday schedule', animation:'https://storage.googleapis.com/prod-rl-animations/farewell-retirement-vacations/holiday_schedule.lottie'},
      {name: 'In the pool', animation:'https://storage.googleapis.com/prod-rl-animations/farewell-retirement-vacations/lady_on_pool_float_loader.lottie'},
      {name: 'At the beach', animation:'https://storage.googleapis.com/prod-rl-animations/farewell-retirement-vacations/man_sunbathing_on_beach.lottie'},
      {name: 'Ski', animation:'https://storage.googleapis.com/prod-rl-animations/farewell-retirement-vacations/playing_ski_during_winter.lottie'},
      {name: 'Travel destination', animation:'https://storage.googleapis.com/prod-rl-animations/farewell-retirement-vacations/travel_destination.lottie'},
      {name: 'Travel tickets', animation:'https://storage.googleapis.com/prod-rl-animations/farewell-retirement-vacations/travel_tickets.lottie'},
      {name: 'Vacation', animation:'https://storage.googleapis.com/prod-rl-animations/farewell-retirement-vacations/vacation.lottie'},
      {name: 'Vacation', animation:'https://storage.googleapis.com/prod-rl-animations/farewell-retirement-vacations/vacation_(1).lottie'},
      {name: 'Vacation', animation:'https://storage.googleapis.com/prod-rl-animations/farewell-retirement-vacations/vacation_(2).lottie'},
    ]},
  {id: 'new-baby-animations', name: 'New baby', animations:[
      {name: 'Baby bear', animation:'https://storage.googleapis.com/prod-rl-animations/new-baby/baby_bear.lottie'},
      {name: 'Baby boy', animation:'https://storage.googleapis.com/prod-rl-animations/new-baby/baby_boy_crawl.lottie'},
      {name: 'Baby clothes', animation:'https://storage.googleapis.com/prod-rl-animations/new-baby/baby_clothes.lottie'},
      {name: 'Baby elephant', animation:'https://storage.googleapis.com/prod-rl-animations/new-baby/baby_elephant.lottie'},
      {name: 'Baby giraffe', animation:'https://storage.googleapis.com/prod-rl-animations/new-baby/baby_giraffe.lottie'},
      {name: 'Baby hippo', animation:'https://storage.googleapis.com/prod-rl-animations/new-baby/baby_hippo.lottie'},
      {name: 'Baby lion', animation:'https://storage.googleapis.com/prod-rl-animations/new-baby/baby_lion.lottie'},
      {name: 'Baby panda', animation:'https://storage.googleapis.com/prod-rl-animations/new-baby/baby_panda.lottie'},
      {name: 'Baby socks', animation:'https://storage.googleapis.com/prod-rl-animations/new-baby/baby_socks.lottie'},
      {name: 'Baby Stroller', animation:'https://storage.googleapis.com/prod-rl-animations/new-baby/baby_stroller.lottie'},
      {name: 'Baby Stroller', animation:'https://storage.googleapis.com/prod-rl-animations/new-baby/baby_stroller_2.lottie'},
      {name: 'Baby Sweater', animation:'https://storage.googleapis.com/prod-rl-animations/new-baby/baby_sweater.lottie'},
      {name: 'Toys', animation:'https://storage.googleapis.com/prod-rl-animations/new-baby/baby_toys.lottie'},
      {name: 'Baby zebra', animation:'https://storage.googleapis.com/prod-rl-animations/new-baby/baby_zebra.lottie'},
      {name: 'Pacifier', animation:'https://storage.googleapis.com/prod-rl-animations/new-baby/pacifier.lottie'},
    ]},
  {id: 'congratulation-animations', name: 'Congratulations', animations:[
      {name: 'Congrats', animation:'https://storage.googleapis.com/prod-rl-animations/congratulations/congrats.lottie'},
      {name: 'Eggs hatching', animation:'https://storage.googleapis.com/prod-rl-animations/congratulations/egg_hatching.lottie'},
      {name: 'Excellent', animation:'https://storage.googleapis.com/prod-rl-animations/congratulations/excellent_word.lottie'},
      {name: 'Good job', animation:'https://storage.googleapis.com/prod-rl-animations/congratulations/good_job.lottie'},
      {name: 'Great job', animation:'https://storage.googleapis.com/prod-rl-animations/congratulations/great_job.lottie'},
      {name: 'Keep it up', animation:'https://storage.googleapis.com/prod-rl-animations/congratulations/keep_it_up.lottie'},
      {name: 'Octopus', animation:'https://storage.googleapis.com/prod-rl-animations/congratulations/octopus_emoji.lottie'},
      {name: 'Star medal', animation:'https://storage.googleapis.com/prod-rl-animations/congratulations/star_medal.lottie'},
      {name: 'Sunglasses', animation:'https://storage.googleapis.com/prod-rl-animations/congratulations/sunglasses.lottie'},
      {name: 'Rocket', animation:'https://storage.googleapis.com/prod-rl-animations/congratulations/toy_rocket.lottie'},
      {name: 'Trophy', animation:'https://storage.googleapis.com/prod-rl-animations/congratulations/trophy_with_confetti.lottie'},
    ]},
  {id: 'wedding-love-animations', name: 'Wedding & Love', animations:[
      {name: 'Cinema', animation:'https://storage.googleapis.com/prod-rl-animations/love/cinema.lottie'},
      {name: 'Dove', animation:'https://storage.googleapis.com/prod-rl-animations/love/dove.lottie'},
      {name: 'Love duck', animation:'https://storage.googleapis.com/prod-rl-animations/love/duck_love.lottie'},
      {name: 'Love birds', animation:'https://storage.googleapis.com/prod-rl-animations/love/love_birds.lottie'},
      {name: 'Love birds', animation:'https://storage.googleapis.com/prod-rl-animations/love/love_birds_(1).lottie'},
      {name: 'Love hearts', animation:'https://storage.googleapis.com/prod-rl-animations/love/love_hearts.lottie'},
      {name: 'Honey Bee', animation:'https://storage.googleapis.com/prod-rl-animations/love/love_honey_bee.lottie'},
      {name: 'Love target', animation:'https://storage.googleapis.com/prod-rl-animations/love/love_target.lottie'},
      {name: 'Romantic moon', animation:'https://storage.googleapis.com/prod-rl-animations/love/romantic_moon.lottie'},
      {name: 'Valentine drink', animation:'https://storage.googleapis.com/prod-rl-animations/love/valentine_drink.lottie'},
      {name: 'Valentine', animation:'https://storage.googleapis.com/prod-rl-animations/love/valentine_heart.lottie'},
      {name: 'Love', animation:'https://storage.googleapis.com/prod-rl-animations/love/valentine_love.lottie'},
      {name: 'Valentine love', animation:'https://storage.googleapis.com/prod-rl-animations/love/valentine_love_(1).lottie'},
      {name: 'Wedding', animation:'https://storage.googleapis.com/prod-rl-animations/love/wedding.lottie'},
      {name: 'Wedding cake', animation:'https://storage.googleapis.com/prod-rl-animations/love/wedding_cake.lottie'},
      {name: 'Wedding car', animation:'https://storage.googleapis.com/prod-rl-animations/love/wedding_car.lottie'},
    ]},
  {id: 'get-well-animation', name: 'Get well soon', animations:[
      {name: 'Bandage', animation:'https://storage.googleapis.com/prod-rl-animations/get-well/bandage.lottie'},
      {name: 'Cake', animation:'https://storage.googleapis.com/prod-rl-animations/get-well/cake_heart_with_love_word.lottie'},
      {name: 'Cat', animation:'https://storage.googleapis.com/prod-rl-animations/get-well/cat_doing_weightlifting_workout.lottie'},
      {name: 'Health', animation:'https://storage.googleapis.com/prod-rl-animations/get-well/health_care.lottie'},
      {name: 'Wellness', animation:'https://storage.googleapis.com/prod-rl-animations/get-well/health_wellness.lottie'},
      {name: 'Training', animation:'https://storage.googleapis.com/prod-rl-animations/get-well/hiit_training.lottie'},
      {name: 'Home workout', animation:'https://storage.googleapis.com/prod-rl-animations/get-well/home_exercise.lottie'},
      {name: 'Women doing yoga', animation:'https://storage.googleapis.com/prod-rl-animations/get-well/women_doing_yoga.lottie'},
      {name: 'Workout time', animation:'https://storage.googleapis.com/prod-rl-animations/get-well/workout_time.lottie'},
    ]},
  {id: 'confetti-animations', name: 'Confetti', animations:[
      {name: 'Confetti 1', animation:'https://storage.googleapis.com/prod-rl-animations/confetti/confetti.lottie'},
      {name: 'Confetti 2', animation:'https://storage.googleapis.com/prod-rl-animations/confetti/confetti_1.lottie'},
      {name: 'Confetti 3', animation:'https://storage.googleapis.com/prod-rl-animations/confetti/confetti_2.lottie'},
      {name: 'Confetti 4', animation:'https://storage.googleapis.com/prod-rl-animations/confetti/confetti_3.lottie'},
    ],
  },
  {id: 'office-animations', name: 'Office', animations:[
      {name: 'Welcome sign', animation:'https://storage.googleapis.com/prod-rl-animations/office/hanging_welcome_sign.lottie'},
      {name: 'Super welcome', animation:'https://storage.googleapis.com/prod-rl-animations/office/super_welcome.lottie'},
      {name: 'Welcome', animation:'https://storage.googleapis.com/prod-rl-animations/office/welcome.lottie'},
      {name: 'Welcome to the team', animation:'https://storage.googleapis.com/prod-rl-animations/office/welcome_to_the_team.lottie'},
    ],
  },
]