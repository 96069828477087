import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React, {useMemo, useState} from "react";
import AppBar from "@mui/material/AppBar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {useNavigate} from "react-router-dom";
import {useGetCategoriesQuery} from "../../slices/api/apiSlice";
import {sortCategoriesDeepCopy} from "../../utils/functions";

export default function CategoriesDropdown({categoryPage}){
  const [anchorCategories, setAnchorCategories] = useState(null);
  const openCategories = Boolean(anchorCategories);
  const navigate = useNavigate();

  const {
    data: categories,
    isSuccess: getCategoriesIsSuccess,
  } = useGetCategoriesQuery()

  const openDropdown = (event) => {
    setAnchorCategories(event.currentTarget);
  };
  const closeDropdown = () => {
    setAnchorCategories(null);
  };

  const handleCategorySelection = (category) => {
    closeDropdown();
    if(!category) {
      return navigate('/create/card')
    }
    return navigate(`/create/card/${category.friendlyUrl}`)
  }

  const sortedCategories = useMemo(() => {
    if(getCategoriesIsSuccess) {
      return sortCategoriesDeepCopy(categories);
    }
  },[categories]);

  return (
    <>
      <AppBar position="sticky" sx={{ background: 'white', boxShadow: 'none'}}>
        <Box component="div" sx={{
          display: 'flex',
          flexDirection: { xs: 'row', sm: 'row'},
          alignItems:'center',
          justifyContent: {xs:'space-between', sm: 'flex-start'},
          borderBottom: 'solid 1px #EAECF0',
          padding:{ xs: '5px 10px', sm: '10px 15px', xl: '16px 0 16px 15px'},
          textAlign: {xs: 'left', md: 'center'},
          gap: {xs: '4px', sm: '10px'}
          // marginBottom: '40px'
        }}>
          <Typography component="h2" variant='h3' sx={{  fontSize: { xs:'10px', md: '14px'}, lineHeight:{ xs: '16px', md: '20px'}  }}>Choose a category to view Group Cards</Typography>
          <Button
            sx={{  fontSize: { xs:'12px', md: '14px'}, lineHeight: {xs:'16px', md: '20px'} }}
            endIcon={<KeyboardArrowDownIcon />}
            variant="outlined"
            onClick={openDropdown}
          >{categoryPage ? categoryPage.title : "Categories"}</Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorCategories}
            open={openCategories}
            onClose={closeDropdown}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem key="show-all" onClick={() => handleCategorySelection(null)}  ><Typography sx={{color: '#6941C6', fontWeight: 600}}>All Categories</Typography></MenuItem>
            {getCategoriesIsSuccess && sortedCategories.map(category => {
              return (
                <Box component="span" key={category.id}>
                  <MenuItem  onClick={() => handleCategorySelection(category)} >
                    <Typography sx={{color: '#6941C6', fontWeight: 600}}>All {category.title}</Typography>
                  </MenuItem>
                  {category.subcategories?.length > 0 && category.subcategories.map((subcategory) => {
                    return(<MenuItem sx={{marginLeft: '20px'}} key={subcategory.id} onClick={() => handleCategorySelection(subcategory)} >{subcategory.title}</MenuItem>)
                  })}
                </Box>
              )
            })}
          </Menu>
        </Box>
      </AppBar>
    </>
  )
}