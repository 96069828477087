import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import OutlinedInput from "@mui/material/OutlinedInput";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React, {useState} from "react";
import Box from "@mui/material/Box";
import {addToArray, isValidEmail, removeFromArray} from "../../../utils/functions";
import {colorsPallete} from "../../../utils/colors";
import {useUpdateCardMutation} from "../../../slices/api/cardApiSlice";
import InfoSnackbar from "../../../components/infoSnackbar/InfoSnackbar";
import * as PropTypes from "prop-types";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Divider from "../../../components/divider/Divider";
import Alert from "@mui/material/Alert";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {PremiumCrown} from "../../../svgIcons/icons";

export default function CardAdministration({card, closeDialog}){
  const [updateCard, { isLoading}] = useUpdateCardMutation();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [contributors, setContributors] = useState(card.contributors);
  const [error, setError] = useState('');

  const administratorsAllowed = () => {
    if(card.tier === 'PREMIUM_GALA') return true;
    if(card.tier === 'PREMIUM') return true;
    return false;
  }

  const handleEmail = (e) => {
    setEmail(e.target.value)

    if(!isValidEmail(e.target.value)) {
      setEmailError('It must be a valid email')
    } else {
      setEmailError('')
    }
    if(e.target.value === '') {
      setEmailError('')
    }
  }

  const addContributor = () => {
    if(!isValidEmail(email)) {
      return setEmailError('It must be a valid email')
    }
    if(contributors.length === 4) {
      return setEmailError('You already have 4 administrators.')
    } else{
      setEmailError('')
    }

    const newContributors =  addToArray(contributors, email)
    setContributors(newContributors);
    setEmail('');
  }

  const removeContributor = (emailToRemove) => {
    const newContributors = removeFromArray(contributors, emailToRemove)
    setContributors(newContributors)
  }

  const handleSave = async () => {
    const cardUpdate = {
      ...card,
      contributors
    }
    try {
      await updateCard(cardUpdate).unwrap();
      closeDialog()
    } catch (e) {
      if (e.originalStatus === 401) {
        setError('Not authorized to update the card. Login or register.')
      } else if (e.originalStatus === 403) {
        setError('Not authorized to update the card. Login or register.')
      } else if(e.originalStatus === 404) {
        setError('Card not found')
      } else {
        setError(`Oops, something went wrong on our end... Please try again`)
      }
    }

  }

  return(<>
    {error && <InfoSnackbar severity="error" onClick={() => { setError('')}} message={error} callback={() => setError('')} /> }
    {administratorsAllowed() &&
      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography variant="body1" sx={{fontWeight: 500, paddingTop: '0'}}>Administrators</Typography>
          <Typography variant="body2" >Add up to 4 more administrators to allow updating this card settings, design and delivery.</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="body1" sx={{fontWeight: 500, paddingTop:0, marginTop:{xs: '30px', md: 0} }}>Administrator's email</Typography>
          <OutlinedInput
            id="outlined-adornment-password"
            sx={{  marginTop: '12px'}}
            fullWidth
            value={email}
            onChange={handleEmail}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Add contributor"
                  edge="end"
                  onClick={addContributor}
                >
                  <AddCircleIcon color="primary" />
                </IconButton>
              </InputAdornment>
            }
            label=""
          />
          <Box sx={{ textAlign: 'right', height: '30px'}}>
            {emailError && <Typography variant="caption" sx={{ color: colorsPallete.errorText}}>{emailError}</Typography>}
          </Box>

          {contributors.length > 0 &&
            <Typography variant="body2">Administrators for this card:</Typography>
          }

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px'}}>
            {contributors.map((contributor) => <Chip key={contributor} onClick={() => { removeContributor(contributor)}} sx={{margin: '5px 2px'}} variant="outlined" icon={<DeleteForeverIcon fontSize="small" variant="outlined" />} label={contributor} color="primary" />)}
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ margin: { xs: '0px 0 16px 0', md: '15px 0 16px 0'}  }}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, gap: '10px', justifyContent: 'flex-end' }}>
            <Button disabled={isLoading} onClick={closeDialog} variant="outlined" color="secondary" sx={{ maxWidth: { xs: '100%', sm:'200px'} }}>Cancel</Button>
            <Button onClick={handleSave} disabled={isLoading} variant="contained" sx={{ maxWidth: { xs: '100%', sm:'200px'} }}>Save</Button>
          </Box>
        </Grid>
      </Grid>
    }

    {!administratorsAllowed() &&
      <Grid container>
        <Grid item xs={12} sx={{marginBottom:'20px'}}>
          <Typography variant="body1" sx={{fontWeight: 500, paddingTop: '0'}}>Administrators</Typography>
          <Typography variant="body2" >Add up to 4 more administrators to allow updating this card settings, design and delivery.</Typography>
        </Grid>
        <Grid item xs={12} sx={{paddingBottom: '30px'}}>
          <Alert
            icon={<InfoOutlinedIcon sx={{color: '#6941C6'}} />}
            sx={{backgroundColor:'#F9F5FF', color: '#6941C6', fontFamily:"'Inter', sans-serif" }}
            severity="info">
            Upgrade card if you would like to use this feature
          </Alert>
        </Grid>
      </Grid>
    }

  </>)
}