
import Grid from "@mui/material/Grid";
import HeroSection from "./blocks/HeroSection";
import HowItWorksSection from "./blocks/HowItWorksSection";
import Occasions from "./blocks/Occasions";
import Features from "./blocks/Features";
import BlackSection from "./blocks/BlackSection";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {resetCardState} from "../../slices/cardSlice";
import Box from "@mui/material/Box";
import PurplePill from "../../components/purplePill/PurplePill";
import TitleText from "../../components/titleText/TitleText";
import CardPlanSelection from "../../components/cardSettings/blocks/CardPlanSelection";
import ReviewsReel from "../../components/reviewsReel/ReviewsReel";
import FAQ from "../../components/faq/FAQ";
import AIDesigns from "./blocks/AIDesigns";
import NewsletterSignUpDialog from "../../components/newsletterSignUpDialog/NewsletterSignUpDialog";
import Typography from "@mui/material/Typography";

export default function Home() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetCardState())
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return(
    <Grid  container className="contentWrapper" sx={{ margin: '0 auto' }}>
      <HeroSection />
      <HowItWorksSection />

      <Occasions />
      <AIDesigns />
      <Features />
      <Box sx={{
        paddingBottom: '90px',
        width: '100%',
        background: 'url(https://storage.googleapis.com/prod-website-images-rlink/occasions_top_right.svg), url(https://storage.googleapis.com/prod-website-images-rlink/occasions_bottom_left.svg), #FFF',
        backgroundRepeat:'no-repeat',
        backgroundPosition:'top right,bottom left',
      }}>
        <Grid maxWidth="xl" container sx={{padding: 0,margin: '30px auto'}}>
          <Grid item xs={12}>
            <PurplePill>Pricing</PurplePill>
            <TitleText align="center">Choose between Single Card or Subscriptions</TitleText>
            <Typography variant="subtitle2" sx={{ textAlign:{ xs: 'center'} }}>Choose a subscription to start your 7 day free trial and the best deal on group cards.</Typography>
            {/*<Typography variant="subtitle2" sx={{ textAlign:{ xs: 'center'} }}> Choose between multiple options.</Typography>*/}
            <CardPlanSelection isPricingPage={true} />
          </Grid>
        </Grid>
      </Box>

      <ReviewsReel />
      <Grid maxWidth="xl" container sx={{padding: 0,margin: '30px auto'}}>
        <Grid item xs={12}>
          <PurplePill>FAQ</PurplePill>
          <TitleText align="center">Frequently asked questions</TitleText>
          <Box sx={{ padding: '20px', maxWidth: '800px', margin: '0 auto'}}>
            <FAQ />
          </Box>

        </Grid>
      </Grid>

      <BlackSection />


      {/*<NewsletterSignUpDialog />*/}
    </Grid>
  )

}