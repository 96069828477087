import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useNavigate} from "react-router-dom";
import {isCardPage} from "../../utils/functions";

export default function ConsentBanner() {
  const [showConsent, setShowConsent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const consent = localStorage.getItem('cookiesConsent');
    if (!consent) {
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesConsent', 'accepted');
    setShowConsent(false);
  };

  if (!showConsent) {
    return null;
  }

  if(isCardPage()) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#fff',
        boxShadow: '0px -2px 5px rgba(0,0,0,0.1)',
        padding: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 1000,
        gap: 1,
      }}
    >
      <Typography variant="body1">
        This site uses cookies, by proceeding, you are agreeing to our <Box sx={{color: '#6941C6'}} component="span" onClick={() =>  navigate('/privacy-policy')}>Privacy Policy </Box> and <Box sx={{color: '#6941C6'}} component="span" onClick={() => navigate('/terms-of-service')}>Terms of Service</Box>.
      </Typography>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAccept}
          sx={{ marginRight: 1, marginBottom:0 }}
        >
          Ok
        </Button>
      </Box>
    </Box>
  );
}
