import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TitleText from "../../../components/titleText/TitleText";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {linkPaths} from "../../../utils/linkPaths";
import Box from "@mui/material/Box";
import CategoriesDisplay from "../../../components/categoriesDisplay/CategoriesDisplay";
import {useDispatch} from "react-redux";
import {resetCardState} from "../../../slices/cardSlice";
import PurplePill from "../../../components/purplePill/PurplePill";

export default function Occasions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function goCreateCard() {
    dispatch(resetCardState());
    navigate(linkPaths.create.path);
  }

  function showTemplates(friendlyUrl) {
    navigate(`/create/card/${friendlyUrl}`);
  }

  return(
    <Box sx={{
      minHeight: '100%',
      width:'100%',
      marginTop:0,  padding: { xs: '64px 5px 40px 5px', sm: '64px 15px 20px 15px', md: '58px 104px', lg:'90px 120px', xl: '100px 200px'},
      background: `url('https://storage.googleapis.com/prod-website-images-rlink/occasions_top_right.svg'), url('https://storage.googleapis.com/prod-website-images-rlink/occasions_bottom_left.svg'), #212529`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top right, bottom left'
    }}>
      <Grid container maxWidth="xl" sx={{margin: '0 auto'}}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          {/*<Typography sx={{*/}
          {/*  color: 'white',*/}
          {/*  fontSize: '16px',*/}
          {/*  marginBottom: '12px',*/}
          {/*  marginTop: '42px',*/}
          {/*  fontWeight: 500,}}>Choose your category</Typography>*/}
          <PurplePill maxWidth='180px' >Card Categories</PurplePill>
          <TitleText align="center" color="white">Find cards for every occasion</TitleText>
          <Box component="div" sx={{width: {xs: '100%', sm: '90%', md: '80%', lg: '60%'}, margin: '0 auto' }}>
            <Typography variant="body1" sx={{ color: '#98A2B3'}}>Whether it's a birthday, anniversary, retirement, or any moment worth celebrating, bring people closer with memories, laughter, and heartfelt messages.</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ margin: '60px 0 0 0'}}>
          <Box sx={{maxWidth: '1000px', margin: 'auto'}}>
            <CategoriesDisplay keepSpaceBetween={true} />
          </Box>
        </Grid>
        <Grid container>
          <Grid item xs={12} sx={{ textAlign: 'center'}}><Button id="create-group-card-occasions-button" onClick={goCreateCard} sx={{ maxWidth: { xs: '100%', sm: '200px'}, margin: '30px 0', fontWeight: 700}} variant="contained" color="primary">Create Group Card</Button></Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

