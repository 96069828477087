import { apiSlice } from "./apiSlice";

export const cardApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createCard: builder.mutation({
      query: (body) => {
        return {
          url: '/cards',
          method: 'POST',
          body: {...body}
        }
      },
      providesTags:['Card'],
      invalidatesTags:['MyCards'],
    }),
    getCard: builder.query({
      query: (cardId) => `/cards/${cardId}`,
      providesTags:['Card']
    }),
    updateCard: builder.mutation({
      query: (body) => {
        return {
          url:`/cards/${body.id}`,
          method: 'PATCH',
          body:{...body}
        }
      },
      invalidatesTags: ['Card','MyCards']
    }),
    deleteCard:  builder.mutation({
      query:(cardId) => {
        return {
          url: `/cards/${cardId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['MyCards']
    }),
    sendCard: builder.mutation({
      query: (body) => {
        return {
          url: `/cards/send/`,
          method: 'POST',
          body: {...body}
        }
      },
      invalidatesTags: ['Card','MyCards']
    }),
    viewCard: builder.mutation({
      query: (body) => {
        return {
          url: `/cards/view-card/`,
          method: 'POST',
          body: {...body}
        }
      },
    }),
    inviteByEmail: builder.mutation({
      query: (body) => {
        return {
          url: `/cards/email-invite`,
          method: 'POST',
          body: {...body}
        }
      },
    }),
    getCards: builder.query({
      query: (query) => `/cards/user${query}`,
      providesTags:['MyCards']
    }),
    getTotalCards: builder.query({
      query: () => `/cards/user/total`,
      providesTags: ['MyCards']
    }),
    upgradeToPremium: builder.mutation({
      query: (body) => {
        return {
          url: `/cards/update-to-premium`,
          method: 'POST',
          body: {...body}
        }
      },
      invalidatesTags: ['Card','MyCards']
    }),
    validateCardPassword: builder.mutation({
      query: (body) => {
        const {cardId} = body
        return ({
          url: `/cards/${cardId}/verify-password`,
          method: 'POST',
          body: {...body}
        })
      },
      invalidatesTags: ['Card','MyCards']
    }),
  })
})

export const {
  useCreateCardMutation,
  useUpdateCardMutation,
  useDeleteCardMutation,
  useGetCardQuery,
  useSendCardMutation,
  useGetCardsQuery,
  useGetTotalCardsQuery,
  useInviteByEmailMutation,
  useViewCardMutation,
  useUpgradeToPremiumMutation,
  useValidateCardPasswordMutation,
} = cardApiSlice;