import Grid from "@mui/material/Grid";
import AddMessageImageDescription from "./blocks/AddMessageImageDescription";
import AddMessageImageUploader from "./blocks/AddMessageImageUploader";
import {useState} from "react";
import {addToArray} from "../../utils/functions";
import Typography from "@mui/material/Typography";
import mediaTypes from "../../utils/mediaTypes";

export default function AddMessageImages({media, addImage, closeModal, removeImage}){
  const images = media.data;

  return(
    <Grid container sx={{ padding:{ xs:'10px 0 20px 0', sm: '10px 0', md: '20px 0'}  }}>
      {media.type !== mediaTypes.UNSPLASH_IMAGE &&
      <>
        <Grid item xs={12} md={4}>
          <AddMessageImageDescription />
        </Grid>
        <Grid item xs={12} md={8}>
          <AddMessageImageUploader addImage={addImage} images={images}  removeImage={removeImage} />
        </Grid>
      </>
      }
      {media.type === mediaTypes.UNSPLASH_IMAGE &&
        <Grid item xs={12}>
          <Typography variant="body2">Please remove the image under "From the Internet" if you would like to upload your own</Typography>
        </Grid>}
    </Grid>
  )

}