import {useParams, useNavigate} from "react-router-dom";
import {useGetCategoriesQuery, useGetCategoryQuery} from "../../slices/api/apiSlice";
import Grid from '@mui/material/Grid';
import ViewTemplates from "../../components/viewTemplates/ViewTemplates";
import Typography from "@mui/material/Typography";
import {linkPaths} from "../../utils/linkPaths";
import {goBackArrow} from "../../svgIcons/icons";
import CreationSteps from "../../components/creationSteps/CreationSteps";
import CategoriesDropdown from "../../components/categoriesDropdown/CategoriesDropdown";
import {useEffect} from "react";


export default function CreateCardShowTemplates() {
  const  { friendlyUrl } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {
    data: category,
    isSuccess: getCategoryIsSuccess,
    isError: getCategoryIsError,
    error: getCategoryError,
  } = useGetCategoryQuery(friendlyUrl)

  const {
    data: categories,
    isSuccess: getCategoriesIsSuccess,
  } = useGetCategoriesQuery()

  const steps = [
    {id: 'step1', screen: 'pickDesign', status: 'active', lastStep: false, title: 'Pick your design', description: 'Choose a card from the catalogue or use AI', cb:() => {navigate(linkPaths.create.path)} },
    {id: 'step2', screen: 'cardSettings', status: 'notStarted', lastStep: false, title: 'Fill card details', description:'Who is receiving it', cb:() => {navigate(linkPaths.createCardSettings.path)} },
    {id: 'step3', screen: 'sendCard', status: 'notStarted', lastStep: true, title: 'Add messages and send it', description:'Send the card to contributors and recipient' },
  ]

  function navigateToAllCategories() {
    return navigate(linkPaths.create.path);
  }

  return(
    <Grid container sx={{minHeight: { xs: 'calc(100% - 70px)', sm: 'calc(100% - 80px)'}}} >
      <Grid item xs={12} md={3} >
        <CreationSteps steps={steps} />
      </Grid>
      { getCategoryIsSuccess && getCategoriesIsSuccess &&
        <Grid item xs={12} md={9} sx={{
          padding: {
            xs: '0px 12px',
            sm: '0 12px',
            md: '0px 3%'
          },
        }}>
          <CategoriesDropdown categoryPage={category} />
          <ViewTemplates category={category} categories={categories}/>
        </Grid>
      }
      { getCategoryIsError &&
        <Grid item xs={12} md={9} sx={{ height: '100%', maxHeight: '100vh', overflowY: 'auto', padding: '20px'}}>
          <Typography variant="body1">
            Oops... {getCategoryError?.data?.message || 'Error loading the templates. Please try again'}
          </Typography>
          <Typography
            onClick={navigateToAllCategories}
            component="span"
            variant='subtitle2'
            sx={{ cursor: 'pointer', color:'primary.main', fontSize: '14px'}}>{goBackArrow} Go back to view all categories</Typography>
        </Grid>
      }
    </Grid>
  )
}