import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Avatar from "@mui/material/Avatar";
import { useDeleteMessageImageMutation } from '../../../slices/api/apiSlice'
import { userRemovedImage } from "../../../slices/messageSlice";
import {getImageFileNameFromURL} from "../../../utils/functions";
import InfoSnackbar from "../../infoSnackbar/InfoSnackbar";
import {useState} from "react";
export default function ImagePreview({image, removeFromState}) {
  const dispatch = useDispatch();
  const [deleteMessageImage, {isLoading: isDeleting}] = useDeleteMessageImageMutation();
  const [error, setError] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  async function handleDeleteImage(){

    const fileName = getImageFileNameFromURL(image.fileUrl)
    try{
      await deleteMessageImage(fileName).unwrap()
      removeFromState(image)
      // dispatch(userRemovedImage(image))
    } catch (e) {
      setError('Error uploading one of your images')
      console.log('error deleting image', e)
    }
  }

  return (
    <div>
      <Box sx={{ position: 'relative', width:120, margin: '0 auto'}}>
        <Avatar sx={{ width: 120, height: 120, margin: '0 auto', borderRadius: '8px' }} src={image.fileUrl} />
        <Box
          onClick={handleDeleteImage}
          sx={{
            '&:hover':{ cursor: 'pointer'},
            width: '30px',
            height: '30px',
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: 'rgba(255,255,255,0.8)',
            borderRadius: '50%',
            marginRight: '5px',
            marginTop: '5px', textAlign:'center'}}>
          <DeleteForeverIcon sx={{ color: 'primary.main', marginTop: '2px'}} />
        </Box>
      </Box>
      {error && <InfoSnackbar severity="error" message={error} callback={() => setError('')} />}
    </div>
  )
}