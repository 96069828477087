import {useEffect, useState, useRef} from "react";
import Box from "@mui/material/Box";
import {colorsPallete} from "../../utils/colors";
import Typography from "@mui/material/Typography";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";

import { pickColorIcon} from "../../svgIcons/icons";
import Popover from "@mui/material/Popover";

export default function PickColorButton({ title = 'Enter title', cb, defaultColor = '#FFF'}){
  const [color, setColor] = useColor(defaultColor);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [divPosition, setDivPosition] = useState({ top: 0, left: 0 });

  const componentRef = useRef(null);

  function openColorPicker(e) {
    const buttonRect = e.target.getBoundingClientRect();

    // Calculate the position for the div
    const divTop = buttonRect.bottom; // Align with the bottom of the button
    const divLeft = buttonRect.left; // Position at the left corner of the button

    setDivPosition({ top: divTop, left: divLeft });
    setShowColorPicker(true);
  }

  const handleClose = () => {
    setShowColorPicker(false);
  };

  function updateColor(colorSelected) {
    setColor(colorSelected)
    if(cb) {
      //return the selected color
      // cb(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`);
      cb(`rgba(${colorSelected.rgb.r},${colorSelected.rgb.g},${colorSelected.rgb.b},${colorSelected.rgb.a})`);
    }
  }

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setShowColorPicker(false);
      // if(cb) {
      //   //return the selected color
      //   cb(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`);
      // }
    }
  };

  useEffect(() => {
    if (showColorPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showColorPicker]);

  return (
    <Box>
      <Box sx={{
        textAlign: 'center',
        width: '100px',
      }}>
        <Box
          onClick={openColorPicker}
          sx={{
          border: `1px solid #EAECF0`,
          width: '100%',
          height: '89px',
          borderRadius: '15px',
          backgroundColor: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`,
          textAlign: 'center',
          paddingTop: '19px',
          '&:hover': {
            cursor: 'pointer',
          }
        }}>
          {pickColorIcon}
        </Box>
        <Typography variant="body1" >{title}</Typography>
      </Box>
      { showColorPicker &&
        <Box ref={componentRef}
             sx={{ position: 'fixed', top: divPosition.top, left: divPosition.left, padding: '10px', zIndex: 100}}
        ><ColorPicker  color={color} onChange={updateColor} /></Box>}
    </Box>
  )
}