import { useState } from "react";
import Box from "@mui/material/Box";
import {closeMessageIcon} from "../../svgIcons/icons";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Divider from "../divider/Divider";
import {truncateString} from "../../utils/functions";
import {useUpdateCardMutation} from "../../slices/api/cardApiSlice";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";
import animations from "../../utils/animations";
import AnimationCategories from "../animationCategories/AnimationCategories";


export default function AnimationSelector({card, closeModal}) {
  const [updateCard] = useUpdateCardMutation();
  const animationSelected = card.template.animation;
  const [error, setError] = useState('')

  const handleSelection = async (animation) => {
    let update = {
      ...card,
      template: {
        ...card.template,
        animation: card.template.animation === animation ? null : animation,
        showAnimation: card.template.animation !== animation,
      }
    }

    try {
      await updateCard(update).unwrap();
      closeModal();
    } catch (e) {
      if (e.originalStatus === 401) {
        setError('Not authorized to update the card. Login or register.')
      } else if (e.originalStatus === 403) {
        setError('Not authorized to update the card. Login or register.')
      }else if(e.originalStatus === 404) {
        setError('Card not found')
      }else {
        setError(`Oops, something went wrong on our end... Please try again`)
      }
    }

  }

  return(<>
    <Box sx={{ justifyContent: 'space-between',  marginTop: '30px', display: 'flex', gap: { xs: '15px', md: '25px'}, flexDirection: 'column', flexWrap: 'wrap'}}>
      { animations.map(animation => <AnimationCategories key={animation.id} handleSelection={handleSelection} animationSelected={animationSelected} category={animation} /> ) }
    </Box>
  </>)
}