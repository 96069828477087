import Box from "@mui/material/Box";

export default function MemorialPage(){
  return (<>
    <Box sx={{
      backgroundImage: `url(https://storage.googleapis.com/prod-website-images-rlink/blue-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/red-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/orange-vector.svg)`,
      backgroundPosition: 'top right, top 20% left, bottom right',
      backgroundRepeat: 'no-repeat'
    }}>
      Memorials
    </Box>
  </>)
}