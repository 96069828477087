import {useGetCategoryQuery, useGetTemplatesQuery} from "../../slices/api/apiSlice";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useUpdateCardMutation} from "../../slices/api/cardApiSlice";
import {useState} from "react";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";
import CircularProgress from "@mui/material/CircularProgress";

export default function BrowseCardDesigns({category, card, closeDialog}) {
  const[updateCard, {isLoading: isCardUpdating, isError: isErrorCardUpdate, error: cardUpdateError}] = useUpdateCardMutation();
  const [error, setError] = useState('');
  const {
    data: templates,
    isLoading: getTemplatesIsLoading,
    isSuccess: getTemplatesIsSuccess,
    isError: getTemplatesIsError,
    error: getTemplatesError,
  } = useGetTemplatesQuery(category.id);

  async function handleTemplateSelection(template) {
    //TODO: CHECK IF THERE IS A CUSTOM IMAGE AND DELETE IT AFTER THE UPDATE
    const update = {
      ...card,
      template: {
        ...card.template,
        // ...template,
        cardBackgroundImage: template.cardBackgroundImage,
        isCustomBackgroundImage: false,
      }
    }
    try {
      await updateCard(update).unwrap();
      closeDialog()
    } catch (e) {
      console.log('e',e)
      if (e.originalStatus === 401) {
        setError('Not authorized to update the card. Login or register.')
      } else if (e.originalStatus === 403) {
        setError('Not authorized to update the card. Login or register.')
      }else if(e.originalStatus === 404) {
        setError('Card not found')
      }else {
        setError(`Oops, something went wrong on our end... Please try again`)
      }
    }
  }

  function isSelected(template, card){
    if(card?.template?.cardBackgroundImage) {
      if(card.template.cardBackgroundImage === template.cardBackgroundImage) {
        return true
      }
    } return false
  }

  return(<Box>
    { getTemplatesIsLoading &&
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', padding: '60px'}}>
        <CircularProgress />
        <Typography variant="body2">Getting all the templates for {category.title}</Typography>
      </Box>
    }
    { getTemplatesIsSuccess &&
      <Box
        component="div"
        sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', textAlign: 'center', justifyContent: 'center', gap: '12px'}}>
        {templates.map(template => {
          return (
            <Box
              key={template.id}
              onClick={() => { handleTemplateSelection(template) }}
              component="div"
              sx={{
                width: {
                  xs: '140px',
                  sm: '180px',
                  md: '180px',
                  lg: '220px'
                },
                height: {
                  xs: '140px',
                  sm: '172px',
                  md: '170px',
                  lg: '210px'
                },
                borderRadius: '14px',
                backgroundColor: template.cardBackgroundColor,
                backgroundImage: `url(${template.cardBackgroundImage})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                border: isSelected(template, card) ? '4px solid #7F56D9' : 'none',
                textAlign: 'center',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}>
              {/*<Typography component="p" variant="caption" sx={{color: template.cardTitleColor, paddingTop: '50%', fontWeight: '700', fontSize:'80%'}}>{template.title}</Typography>*/}
            </Box>
          )
        })}
      </Box>
    }
    {!!error && <InfoSnackbar callback={() => setError('')} onClick={() => {setError('')}} message={error} /> }
    </Box>)

}