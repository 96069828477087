import { apiSlice } from "./apiSlice";

export const unsplashApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    searchImage: builder.mutation({
      query: ( textPage ) => ({
        url: `/unsplash/search/${textPage}`,
      })
    }),
    trackDownload: builder.mutation({
      query: ( image ) => {
        return {
          url: '/unsplash/track-download',
          method: 'POST',
          body: {
            image
          }
        }
      }
    }),
  })
});

export const { useSearchImageMutation, useTrackDownloadMutation } = unsplashApiSlice;