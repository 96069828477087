import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {StretchIcon, TileIcon} from "../../../svgIcons/icons";
import {colorsPallete} from "../../../utils/colors";
import ButtonGroup from "@mui/material/ButtonGroup";

export default function BackgroundSizeButtons({cardBackgroundImageFullSize, handleBackgroundImageSize, isLoading, imgUrl, isCustomBackground}){
  if(isLoading) return null;
  // if(!imgUrl) return null;
  if(!isCustomBackground) return null;

  return(
    <Box sx={{ textAlign: 'center', margin: '20px auto 0 auto'}}>
      <ButtonGroup  aria-label="outlined primary button group" fullWidth sx={{maxWidth: '360px'}}>
        <Button
          onClick={() => {handleBackgroundImageSize(true)}}
          variant={cardBackgroundImageFullSize ? 'contained' : 'outlined'}
          color={cardBackgroundImageFullSize ? 'primary' : 'secondary'}
          sx={{
            color: cardBackgroundImageFullSize ? '#FFF' : '#98A2B3',
            padding: cardBackgroundImageFullSize ? '12px 20px' : '9px 20px',
            backgroundColor: cardBackgroundImageFullSize ? 'inherit': '#F2F4F7',
            fontSize: {xs: '12px', sm: '14px'}
          }}
        >
          <StretchIcon color={cardBackgroundImageFullSize ? '#fff' : '#98A2B3'} /> Stretch image
        </Button>
        <Button
          // className={!cardBackgroundImageFullSize ? 'active-selection-button' : 'selection-button'}
          onClick={() => {handleBackgroundImageSize(false)}}
          variant={!cardBackgroundImageFullSize ? 'contained' : 'outlined'}
          color={!cardBackgroundImageFullSize ? 'primary' : 'secondary'}
          sx={{
            color: !cardBackgroundImageFullSize ? '#FFF' : '#98A2B3',
            padding: !cardBackgroundImageFullSize ? '12px 20px' : '9px 20px',
            backgroundColor: !cardBackgroundImageFullSize ? 'inherit': '#F2F4F7',
            fontSize: {xs: '12px', sm: '14px'}
          }}

        >
          <TileIcon color={!cardBackgroundImageFullSize ? '#fff' : '#98A2B3'} /> Tile image
        </Button>

      </ButtonGroup>
    </Box>

  )
}