import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import PurplePill from "../../components/purplePill/PurplePill";
import TitleText from "../../components/titleText/TitleText";
import Typography from "@mui/material/Typography";
import CardPlanSelection from "../../components/cardSettings/blocks/CardPlanSelection";
import Button from "@mui/material/Button";
import {PremiumCrown, ratingStar} from "../../svgIcons/icons";
import FAQ from "../../components/faq/FAQ";
import BlackSection from "../home/blocks/BlackSection";
import React, {useEffect} from "react";
import ReviewsReel from "../../components/reviewsReel/ReviewsReel";
import {Alert} from "@mui/material";
import {useNavigate} from "react-router-dom";

export default function PricingPage(){
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate();
  return(
    <Box >
      <Box sx={{
        backgroundImage: `url(https://storage.googleapis.com/prod-website-images-rlink/blue-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/red-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/orange-vector.svg)`,
        backgroundPosition: 'top right, top 20% left, bottom right',
        backgroundRepeat: 'no-repeat'
      }}>

        <Grid maxWidth="xl" container sx={{padding: { xs: '64px 30px 40px 30px', sm: '64px 30px 20px 30px', md: '58px 104px', lg:'90px 120px', xl: '100px 200px'},margin: '0 auto'}}>
          <Grid item xs={12}>
            <PurplePill>Pricing</PurplePill>
            <TitleText align="center">Easily Create Lasting Memories</TitleText>
            <Typography variant="subtitle2" sx={{ textAlign:{ xs: 'center'} }}> Group Cards are the perfect companion for your special occasions and cherished gatherings.</Typography>
            <Alert
              onClick={() => navigate('/business')}
              id="business-pricing"
              data-testid="business-pricing-alert"
              icon={<PremiumCrown width="16px" height="16px" />}
              sx={{backgroundColor:'#F9F5FF', color: '#6941C6', fontFamily:"'Inter', sans-serif", margin:{xs: '20px 0'}, '&:hover':{ cursor: 'pointer'} }}
              severity="info">
              Pricing for businesses? Click here
            </Alert>
            <CardPlanSelection isPricingPage={true} />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: '100%',
        backgroundImage: `url(https://storage.googleapis.com/prod-website-images-rlink/blue-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/red-vector.svg)`,
        backgroundPosition: 'top right, center  left',
        backgroundRepeat: 'no-repeat'}}>
        <Grid container maxWidth="xl" sx={{margin: '0 auto',padding: { xs: '64px 30px 40px 30px', sm: '64px 30px 60px 30px'}}} spacing={0}>
          <Grid item xs={12} md={4}>
            <Typography variant="body1" sx={{ textAlign:{ xs: 'left'}, color:'#6941C6', fontWeight: 600 }}> Support</Typography>
            <Typography variant="h2" sx={{fontSize: '36px', lineHeight:'44px'}}>FAQs</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box sx={{marginTop: {xs:'40px', md:0}}}>
              <FAQ />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ReviewsReel />
      <BlackSection />
    </Box>

  )
}