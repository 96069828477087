
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {messageIntact} from "../../../utils/functions";
export default function AuthorArea({message, textColor = '#000'}){
  if(!message) return null;
  const userHasntStarted = messageIntact(message);

  return(
    <Box sx={{ padding: '25px', textAlign: 'right'}}>
      {userHasntStarted && <Typography variant="body2" sx={{
        fontWeight: 700,
        color: textColor,
      }}>~ Your name will be here</Typography>}
      {!userHasntStarted && <Typography variant="body2" sx={{ fontWeight: 700,
        color: textColor,
      }}>~ {message.creatorName}</Typography>}
    </Box>
  )
}