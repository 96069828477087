import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function AddMessageImageDescription() {
  return (
    <Box component="div">
      <Typography component="p" variant="body2" sx={{marginBottom: '15px'}}>
        Make your message extra personal by uploading up to 4 images.
      </Typography>
    </Box>
  )
}