import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import TransitionComponent from "../transitionComponent/TransitionComponent";
import DialogContent from "@mui/material/DialogContent";
import TitleFont from "../titleFont/TitleFont";
import * as React from "react";
import {closeMessageIcon} from "../../svgIcons/icons";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import EditBackgroundOptions from "./EditBackgroundOptions";

export default function EditBackgroundDialog({card, closeDialog, open}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  function handleClose() {
    closeDialog();
  }

  return(
    <Dialog
      TransitionComponent={TransitionComponent}
      fullScreen={fullScreen}
      // fullScreen={true}
      maxWidth="md"
      onClose={handleClose} open={open} >
      <DialogContent sx={{ padding:{ xs: '5px', md: '50px 40px'} }}>
        <>
          <Box sx={{display: 'flex', flexDirection:{ xs: 'column', md: 'row'}, justifyContent: 'space-between', }}>
            <Box onClick={() => {closeDialog()}} sx={{display: { xs: 'block', md: 'none'}, textAlign: 'right', '&:hover':{ cursor: 'pointer'} }} >
              {closeMessageIcon}
            </Box>
            <Typography variant="h4" sx={{ padding: '0', fontSize: {xs:'20px', md:'30px'}, lineHeight:{xs: '24px', md:'36px'} }}>Colors & Background</Typography>
            <Box onClick={() => {closeDialog()}} sx={{display: { xs: 'none', md: 'block'},  float:'right', textAlign: 'right', '&:hover':{ cursor: 'pointer'} }} >
              {closeMessageIcon}
            </Box>
          </Box>
          <EditBackgroundOptions closeDialog={closeDialog} card={card}/>
        </>
      </DialogContent>
    </Dialog>
  )
}