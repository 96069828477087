import Box from "@mui/material/Box";
import UploadWidget from "../customBackgroundUploader/blocks/UploadWidget";
import BackgroundSizeButtons from "../customBackgroundUploader/blocks/BackgroundSizeButtons";
import BackgroundImagePreview from "../customBackgroundUploader/blocks/BackgroundImagePreview";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Typography from "@mui/material/Typography";

export default function BackgroundUploader({card, handleBackgroundImageSize, handleFiles, handleDelete, customBackgroundByUserUploading}) {

  return(<Box>

    {card.template.isCustomBackgroundImage &&
      <Box sx={{ position: 'relative', maxWidth: '200px', margin: '0 auto'}}>
        <Box
          onClick={handleDelete}
          sx={{
            '&:hover':{ cursor: 'pointer'},
            position: 'absolute',
            border: '1px solid #EAECF0',
            zIndex: 50,
            width: '40px',
            height: '40px',
            backgroundColor: '#FFF',
            borderRadius: '50%',
            right: '5px',
            top: '5px', textAlign:'center'}}>
          <DeleteForeverIcon sx={{ color: 'primary.main', marginTop: '6px', fontSize: '26px'}} />
        </Box>
        <BackgroundImagePreview
          cardBackgroundImageFullSize={card.template.cardBackgroundImageFullSize}
          isLoading={false}
          isCustomBackground={card.template.isCustomBackgroundImage}
          imgUrl={card.template.cardBackgroundImage} />
      </Box>
    }

    {!card.template.isCustomBackgroundImage && !customBackgroundByUserUploading && <UploadWidget onChange={handleFiles} />}
    {customBackgroundByUserUploading && <Box sx={{ display: 'flex', width: '200px', height: '200px', margin: '10px auto', alignItems: 'center', textAlign: 'center'}}><Typography variant="subtitle2">Uploading...</Typography></Box> }

    <BackgroundSizeButtons
      cardBackgroundImageFullSize={card.template.cardBackgroundImageFullSize}
      handleBackgroundImageSize={handleBackgroundImageSize}
      isLoading={false}
      imgUrl={card.template.cardBackgroundImage}
      isCustomBackground={true}
    />
  </Box>)
}