import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {goodCheckMark} from "../../svgIcons/icons";

function Highlight({text}){
  return(<Box sx={{ display:'flex', flexDirection:'row', gap:'10px', marginBottom:'20px', alignItems:'center'}}>
    <Box sx={{width:'24px', height:'24px'}}>{goodCheckMark}</Box><Typography variant="body2">{text}</Typography>
  </Box>)
}

export default function HighlightsHome({ highlights = [
  'Easy to use',
  '100% Online',
  'No registration required',
  'Cards work everywhere: Desktop, Tablet and Mobile ready'
] }){

  return(
    <Box sx={{display:'flex', flexDirection:'column', alignItems:{xs:'flex-start', sm: 'flex-start'}, padding:{xs:'0 30px', sm:0} }}>
      {highlights.map(h => <Highlight key={h} text={h}/> )}
    </Box>
  )
}