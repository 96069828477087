// Creates Redux store instance
import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from "./slices/api/apiSlice";
import authSlice from "./slices/authSlice";
import viewCardTokenSlice from "./slices/viewCardTokenSlice";
import { templateSlice } from "./slices/templateSlice";
import { cardSlice} from "./slices/cardSlice";
import { messageSlice } from "./slices/messageSlice";
import { replySlice } from "./slices/replySlice";
import fakeMessagesSlice from "./slices/fakeMessagesSlice";

export default configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authSlice,
        viewCardToken: viewCardTokenSlice,
        [templateSlice.name]: templateSlice.reducer,
        [cardSlice.name]: cardSlice.reducer,
        [messageSlice.name]: messageSlice.reducer,
        [replySlice.name]: replySlice.reducer,
        showFakeMessages:fakeMessagesSlice,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true,
})