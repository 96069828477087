import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

export default function GradientButton({ text = 'Enter text', onClick, disabled, maxWidth = '100%'}) {
  return (
    <Box sx={{width:'100%', textAlign: 'center', maxWidth: maxWidth, margin: '0 auto'}}>
      <Button color='secondary' disabled={disabled} onClick={onClick}  sx={{
        width:'100%',
        background: disabled ? 'gray' : 'linear-gradient(90deg, #5D9AF3 0%, #5E19B7 100%)',
        textTransform: 'capitalize',
        borderRadius:'8px',
        padding: '2px',
        '&:hover': {
          cursor: disabled? 'not-allowed':'pointer'
        }
      }}>
        <Box component='span' sx={{
          width:'100%',
          padding: '8px 6px',
          background: 'white', borderRadius:'5px',
          color: disabled ? 'gray':'#101828',
          '&:hover': {
            background: 'linear-gradient(90deg, #5D9AF3 0%, #5E19B7 100%)',
            color: 'white',
            cursor: disabled ? 'not-allowed':'pointer',
          }
        }}>{text}</Box>

      </Button>
    </Box>
  )
}