import CustomerReviews from "../cardSettings/blocks/CustomerReviews";
import Box from "@mui/material/Box";

const reviews = [
  {id: 'review01', name: 'Emily R', review: 'Creating cards has never been easier! The selection is fantastic, and the prices are unbeatable.', jobTitle: 'Graphic Designer'},
  {id: 'review02', name: 'John D', review: 'Absolutely love the scheduling feature; it’s a lifesaver for someone who tends to forget dates!', jobTitle: 'Project Manager'},
  {id: 'review04', name: 'Liam S', review: 'Great value for money and the card designs are just beautiful. Highly recommend!', jobTitle: 'Marketing Specialist'},
  {id: 'review05', name: 'Olivia W', review: 'The card creation process is so smooth and simple. Plus, the cards are just lovely!', jobTitle: 'Event Coordinator'},
  {id: 'review06', name: 'Noah E', review: 'From design to delivery, everything is seamless. Also, the prices are very affordable.', jobTitle: 'Entrepreneur'},
  {id: 'review07', name: 'Ava P', review: 'Seeing the joy it brought to my grandmother made my year! Thank you for such a wonderful service.', jobTitle: 'Nurse Practitioner'},
  {id: 'review09', name: 'Mia T', review: 'Easy to use, affordable, and always a hit with the recipients. Perfect for any occasion.', jobTitle: 'Freelance Writer'},
  {id: 'review13', name: 'Amelia H', review: 'Every time we use this service, the recipient is thrilled. The design options are excellent.', jobTitle: 'Customer Service Rep'},
  {id: 'review14', name: 'Ethan J', review: 'Affordable, high-quality cards with a personal touch. Also, setting them up in advance is so easy!', jobTitle: 'Software Developer'},
  {id: 'review15', name: 'Charlotte N', review: 'I got emotional seeing how happy my friend was getting the card. Absolutely worth it.', jobTitle: 'Teacher'},
  {id: 'review16', name: 'Logan F', review: 'What a fantastic way to keep in touch with family and friends! Great prices, great designs.', jobTitle: 'Social Media Manager'},
  {id: 'review18', name: 'Aiden M', review: 'Every card I’ve sent has been met with great appreciation. Easy to create and send!', jobTitle: 'Financial Analyst'},
  {id: 'review20', name: 'Henry V', review: 'Using this service has been a joy! The reactions from friends and family have been overwhelmingly positive.', jobTitle: 'Chef'}
]
export default function ReviewsReel(){
  return(
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'no-wrap', overflow: 'scroll', gap: '20px'}}>
      {
        reviews.map(review => {
          return <CustomerReviews key={review.id} review={review} />
        })
      }
    </Box>
  )
}