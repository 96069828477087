import AppBar from '@mui/material/AppBar';
import Box from "@mui/material/Box";
import {linkPaths} from "../../utils/linkPaths";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import GradientButton from "../gradientButton/GradientButton";
import Typography from "@mui/material/Typography";

export default function DemoBar() {
  const navigate = useNavigate();
  function navigateHome() {
    return navigate(linkPaths.home.path)
  }

  function navigateCreateCard() {
    return navigate(linkPaths.create.path)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="relative" sx={{
        // height: {xs: '134px', sm: '154px', md: '96px'},
        padding: { xs: '2px 16px 12px 16px' ,md: '12px 20px 12px 20px'},
        boxShadow: 'none',
        borderBottom: 'none',
        display:'flex', flexDirection: {xs:'colum', md: 'row'}, justifyContent: 'space-between',
        alignItems:'center',
        gap:'0px',
        // maxWidth: 'xl',
        // margin: '0 auto',
      }} >
        <Box sx={{ width:{ xs: '200px', md: '220px', lg: '220px'} }}>
          <img
            onClick={navigateHome}
            className='logoTopBar'
            alt='RecognitionLink.com'
            src='https://storage.googleapis.com/prod-website-images-rlink/logos/logo-transparent-white-letters.png' />
        </Box>
       <Box sx={{ maxWidth:'600px',  textAlign: 'center', }}>
         <Typography sx={{color: '#FFF', marginBottom: '5px'  }} variant="subtitle1">Group Card Demo</Typography>
         <Typography sx={{color: '#FFF', marginBottom: '5px'  }} variant="body1">This is how the recipient will see the card.</Typography>
       </Box>
        <Box sx={{display:'flex', flexDirection:{xs:'row', sm: 'row'}, gap: '16px', width: {xs: '100%', sm: '420px'}, justifyContent: 'flex-end'}}>
          <Button sx={{marginBottom: 0, maxHeight: '40px', fontSize: {xs: '12px', sm: '14px'}, padding:{xs:'8px', sm:'8px', md: '12px'}}} onClick={navigateHome} variant="outlined" color="secondary">Close demo</Button>
          {/*<Button sx={{marginBottom: 0, maxHeight: '40px', fontSize: {xs: '12px', sm: '14px'}, padding:{xs:'0px', sm:'6px', md: '12px'} }} onClick={navigateCreateCard} variant="outlined" color="secondary">Create card</Button>*/}
        </Box>
      </AppBar>
    </Box>)
}