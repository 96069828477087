import { useUploadMessageVideoMutation, useDeleteMessageVideoMutation } from "../../slices/api/apiSlice";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import UploadWidget from "../customBackgroundUploader/blocks/UploadWidget";
import React, {useState} from "react";
import InfoAlert from "../infoAlert/InfoAlert";
import Uploading from "../customBackgroundUploader/blocks/Uploading";
import VideoDisplay from "../message/messageBlocks/VideoDisplay";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Box from "@mui/material/Box";
import {getImageFileNameFromURL} from "../../utils/functions";
import mediaTypes from "../../utils/mediaTypes";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";

export default function UploadVideo({media, updateMedia, closeModal, subtitle}){
  const [uploadVideo,{isLoading: isUploading, error, isSuccess: videoUploaded}] = useUploadMessageVideoMutation();
  const [deleteVideo, {isLoading: isDeleting, error: deleteError, isSuccess: videoDeleted}] = useDeleteMessageVideoMutation()
  const [dragActive, setDragActive] = useState(false);
  const [uploadError, setUploadError] = useState('');

  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e);
    }
  };

  const handleFiles = async function(e){
    let files = [];

    if(e.target.files) {
      files = e.target.files;
    } else if (e.dataTransfer) {
      files = e.dataTransfer.files;
    }

    if(files.length > 1) {
      setUploadError('Sorry, we only allow 1 video per message');
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // Check if the file size is greater than 50MB (50 * 1024 * 1024 bytes)
      if (file.size > 150 * 1024 * 1024) {
        setUploadError('The file size is larger than 50MB. Please select a smaller file.');
        return; // Exit the function to prevent uploading
      }
      const formData = new FormData();
      formData.append('video', file)

      try {

        const fileUploaded = await uploadVideo(formData).unwrap()

        // dispatch(userAddedVMessageVideo(fileUploaded))
        updateMedia({
          type: mediaTypes.USER_VIDEO,
          data: [{...fileUploaded}],
        })

        // const fileUploaded = () => {}
        // dispatch result

      } catch (e) {
        return console.log('error uploading file', e)
      }


    }
  }

  async function handleDeleteVideo(){
    const fileUrl = media.data[0].fileUrl;
    const fileName = getImageFileNameFromURL(fileUrl)

    try{
      await deleteVideo(fileName).unwrap();
      // dispatch(userRemovedMessageVideo())
      updateMedia({
        type: null,
        data: [],
      })
    } catch (e) {
      if(e.status === 404) {
        // dispatch(userRemovedMessageVideo())
        updateMedia({
          type: null,
          data: [],
        })
      }
      console.log('error deleting image', e)
    }
  }

  const isLoading = isUploading;

  return(
    <Grid container>
      {error && <InfoSnackbar message={error?.data?.error || 'Something went wrong'} severity="error" /> }
      <Grid item xs={12}>
        {/*<Typography component="h2" variant="subtitle2" sx={{marginBottom: '10px'}}>*/}
        {/*  Upload your video*/}
        {/*</Typography>*/}
        <Typography component="p" variant="body1" sx={{marginBottom: '15px'}}>
          {subtitle ? subtitle : "Uploading videos is a great gesture in any occasion, upload the video you want in your message."}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{padding: '0 20px'}}>
        {uploadError && <InfoAlert severity="error" message={uploadError} callback={() => setUploadError('')} /> }

        {media.data.length > 0 && media.type === mediaTypes.USER_VIDEO && <VideoDisplay message={{media:media}}/> }

        {media.data.length > 0 && media.type === mediaTypes.USER_VIDEO &&
          <Box component="div" sx={{ textAlign: 'center'}}>
            <Button
              onClick={handleDeleteVideo}
              sx={{ textTransform: 'capitalize', marginTop: '10px'}}
              variant="text">
              <DeleteForeverIcon sx={{marginRight: '5px'}} />Delete video</Button>
          </Box>
        }


        <Uploading isLoading={isLoading}/>

        { media.type === mediaTypes.VIDEO_EMBED &&
          <Typography component="p" variant="caption">Delete video under "From YouTube" if you would like to upload a video</Typography>
        }

        {!isLoading  && !media.type &&
          <UploadWidget
            formatsText="MOV, MP4 (Max. 150MB)"
            accept="video/*"
            onDragEnter={handleDrag}
            onChange={handleFiles}
            onDrop={handleDrop}
            dragActive={dragActive}  />
        }


      </Grid>
    </Grid>
  )
}