import { apiSlice } from "./apiSlice";

export const aiApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createAiImage: builder.mutation({
      query: body => ({
        url: '/ai/image',
        method: 'POST',
        body: {...body}
      }),
      invalidatesTags:['Card']
    }),
  })
});

export const { useCreateAiImageMutation } = aiApiSlice;