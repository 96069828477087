const fonts = [
  {name: 'Inter', fontFamily: "'Inter', sans-serif", fontWeight:'700', fontSize: 40},
  {name: 'Instrument Serif', fontFamily: "'Instrument Serif', serif", fontWeight:'400', fontSize: 40},
  {name: 'Josefin Sans', fontFamily: "'Josefin Sans', sans-serif", fontWeight:'700', fontSize: 40},
  {name: 'Lato', fontFamily: "'Lato', sans-serif", fontWeight:'700', fontSize: 40},
  {name: 'Lemon', fontFamily: "'Lemon', serif", fontWeight:'400', fontSize: 40},
  {name: 'Montserrat', fontFamily: "'Montserrat', sans-serif", fontWeight:'700', fontSize: 40},
  {name: 'Open Sans', fontFamily: "'Open Sans', sans-serif", fontWeight:'700', fontSize: 40},
  {name: 'Oswald', fontFamily: "'Oswald', sans-serif", fontWeight:'700', fontSize: 40},
  {name: 'Plus Jakarta Sans', fontFamily: "'Plus Jakarta Sans', sans-serif", fontWeight:'700', fontSize: 40},
  {name: 'Press Start 2P', fontFamily: "'Press Start 2P', system-ui", fontWeight:'400', fontSize: 24},
  {name: 'Roboto', fontFamily: "'Roboto', sans-serif", fontWeight:'700', fontSize: 40},
  {name: 'Raleway', fontFamily: "'Raleway', sans-serif", fontWeight:'700', fontSize: 40},
  {name: 'Salsa', fontFamily: "'Salsa', cursive", fontWeight:'400', fontSize: 40},
  {name: 'Bebas Neue', fontFamily: '"Bebas Neue", sans-serif', fontWeight:'400', fontSize: 40},
  {name: 'Caveat', fontFamily: '"Caveat", cursive', fontWeight:'600', fontSize: 40},
  {name: 'Concert One', fontFamily: '"Concert One", sans-serif', fontWeight:'400', fontSize: 40},
  {name: 'Dancing Script', fontFamily: '"Dancing Script", cursive', fontWeight:'600', fontSize: 40},
  {name: 'Elsie', fontFamily: '"Elsie", serif', fontWeight:'900', fontSize: 40},
  {name: 'Libre Caslon Text', fontFamily: '"Libre Caslon Text", serif', fontWeight:'700', fontSize: 40},
  {name: 'Merriweather', fontFamily: '"Merriweather", serif', fontWeight:'700', fontSize: 40},
  {name: 'Pixelify Sans', fontFamily: '"Pixelify Sans", sans-serif, serif', fontWeight:'700', fontSize: 40},
  {name: 'Protest Riot', fontFamily: '"Protest Riot", sans-serif', fontWeight:'400', fontSize: 40},
  {name: 'Quicksand', fontFamily: '"Quicksand", sans-serif', fontWeight:'500', fontSize: 40},
  {name: 'Sacramento', fontFamily: '"Sacramento", cursive', fontWeight:'400', fontSize: 40},
]

export default fonts;