import Box from "@mui/material/Box";

export default function ShowImage({image}) {
  return(
    <Box sx={{
      backgroundImage: `url(${image.fileUrl})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      height:{
        xs: '328px',
        lg: '500px'
      },
      width: '100%'
    }} >
    </Box>
  )
}