import Grid from '@mui/material/Grid';
import SenderDetails from "./blocks/SenderDetails";
import RecipientDetails from "./blocks/RecipientDetails";

import CardPreview from "../cardPreview/CardPreview";

import Divider from '../divider/Divider';
import SettingsSelection from "./blocks/SettingsSelection";
import CardPlanSelection from "./blocks/CardPlanSelection";
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import {useGetSubscriptionsQuery} from "../../slices/api/userSlice";
import {hasActiveSubscription} from "../../utils/functions";
import ContinueOption from "./blocks/ContinueOption";
import StickyDiv from "../stickyDiv/StickyDiv";
import ReviewsReel from "../reviewsReel/ReviewsReel";

export default function CardSettings() {
  const user = useSelector(state => state.auth.user);

  const {
    data: subscriptions,
    isSuccess: fetchedSubscriptions,
  } = useGetSubscriptionsQuery();
  const showDefaultOptions = () => {
    return !user?.id;
  }

  return(
    <Grid container>
      <Grid item xs={12} md={6} sx={{padding:{xs: '0 20px'}}}>
        <StickyDiv sx={{display:{xs: 'block', md: 'none'}, marginBottom: '20px'}}>
          <CardPreview />
        </StickyDiv>
        <SenderDetails />
        <Divider/>
        <RecipientDetails />
        {/*<Divider />*/}
        {/*<SettingsSelection hasActiveSubscriptions={fetchedSubscriptions && user?.id && hasActiveSubscription(subscriptions)} />*/}
      </Grid>

      <Grid item xs={12} md={6} sx={{padding:{xs: '0 20px'},minHeight: '100%', display:{xs: 'none', md: 'block'} }}>
        <StickyDiv>
          <CardPreview />
        </StickyDiv>
      </Grid>
      
      <Grid item xs={12}>
        <Divider />


        {!user?.id  && <CardPlanSelection />}
        {fetchedSubscriptions && user?.id && !hasActiveSubscription(subscriptions)  && <CardPlanSelection />}
        {fetchedSubscriptions && user?.id && hasActiveSubscription(subscriptions) && <ContinueOption />}
        <ReviewsReel/>
      </Grid>
    </Grid>
  )
}



