import { createSlice } from "@reduxjs/toolkit";

const fakeMessagesSlice = createSlice({
  name: 'showFakeMessages',
  initialState: false,
  reducers: {
    setVisibilityFakeMessages: (state, action) => {
      return action.payload;
    },
  }
})

export const { setVisibilityFakeMessages } = fakeMessagesSlice.actions;

export default fakeMessagesSlice.reducer;