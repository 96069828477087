import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import {uploadIcon} from "../../../svgIcons/icons";
import Typography from "@mui/material/Typography";
import {colorsPallete} from "../../../utils/colors";
import Box from "@mui/material/Box";


export default function UploadWidget({onChange, multiple = false, accept ='image/*', formatsText = "SVG, PNG, JPG, HEIC (Max. 20MB)" }){
  const [dragActive, setDragActive] = useState(false);
  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onChange(e);
    }
  };

  return(
    <Box sx={{ width:'100%', maxWidth:'400px', margin: '0 auto'}} component="form" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <TextField
        name='templateImage'
        onChange={onChange}
        fullWidth
        id="templateImage"
        type='file'
        sx={{ display: 'none'}}
        inputProps={{ multiple: multiple, accept: accept }}
      />
      <label style={{ border: '1px solid #EAECF0'}} id="labelFileUpload" className={dragActive ? "dragActive" : "" } htmlFor="templateImage">
        {uploadIcon}
        <Typography component="p" variant="caption">
          <span style={{ color: colorsPallete.primary}}><b>Click to upload</b></span> or drag and drop
        </Typography>
        <Typography component="p" variant="caption">
          {formatsText}
        </Typography>
      </label>
      { dragActive && <div id="dragFileElement" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
    </Box>
  )
}