import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import { onlyMainCategories } from "../../utils/categories";
import {useGetCategoriesQuery} from "../../slices/api/apiSlice";

export default function CategoriesDisplay({titleColor = '#FFF', keepSpaceBetween = false}) {
  const navigate = useNavigate();
  const {
    data: categories,
    isSuccess: isSuccessCategories,
    isError: isErrorCategories,
    error: errorCategories,
  } = useGetCategoriesQuery();


  function showTemplates(friendlyUrl) {
    navigate(`/create/card/${friendlyUrl}`);
  }
  return (
    <>
      { isSuccessCategories &&
        <Box  sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', textAlign: 'center', justifyContent:{xs:'space-between', sm:keepSpaceBetween ? 'space-evenly' : 'flex-start' }, padding:{xs:'10px'} }}>
          {categories.map((item, index) => {
            return <Box key={index} id={`category-${index}`} data-testid={`category-${index}`} sx={{width: {xs: '100px', md: '120px', xl: '160px'}, textAlign:'center','&:hover': {cursor: 'pointer'}, margin: {xs: '6px 6px 6px 6px', md: '6px 15px 24px 15px'} }} onClick={() => showTemplates(item.friendlyUrl)}>
              <Box sx={{
                // width:{ xs: '155px', md: '238px'},
                // height: { xs: '130px', md: '240px'},
                width:{ xs: '100px',md: '120px', xl: '155px'},
                height: { xs: '100px', md: '115px', xl: '130px'},
                background: `url('${item.image}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                borderRadius: '20px',
                margin: '0 auto',
              }}>
              </Box>
              <Typography variant="subtitle1" sx={{ fontSize: {xs: '12px', md: '14px', lg: '14px'}, color: titleColor, fontWeight: 500}}>{item.title}</Typography>
            </Box>
          })}
        </Box>
      }
    </>
  )
}