import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Typography from "@mui/material/Typography";
import  { linkPaths } from "../../../utils/linkPaths";
import {useState} from "react";

export default function BackgroundImagePreview({isLoading, imgUrl, deleteImage, isCustomBackground, cardBackgroundImageFullSize}){
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  if(isLoading) return null;
  if(!imgUrl) return null;
  if(!isCustomBackground) return null;

  function toggleDeleteWarning() {
    setShowDeleteWarning(prevState => !prevState)
  }

  return(
    <Box component="div" sx={{
      width: '100%',
      margin: 'auto',
      position: 'relative',
      maxWidth: {
        xs: '200px',
      },
    }}>
      {/*{ !showDeleteWarning &&*/}
      {/*  <Box*/}
      {/*    // onClick={deleteImage}*/}
      {/*    onClick={toggleDeleteWarning}*/}
      {/*    sx={{*/}
      {/*      '&:hover':{ cursor: 'pointer'},*/}
      {/*      width: '30px',*/}
      {/*      height: '30px', float: 'right',*/}
      {/*      backgroundColor: 'rgba(255,255,255,0.8)',*/}
      {/*      borderRadius: '50%',*/}
      {/*      marginRight: '10px',*/}
      {/*      marginTop: '10px', textAlign:'center'}}>*/}
      {/*    <DeleteForeverIcon sx={{ color: 'primary.main', marginTop: '2px'}} />*/}
      {/*  </Box>*/}
      {/*}*/}
      {/*{showDeleteWarning &&*/}
      {/*  <Box*/}
      {/*    onClick={deleteImage}*/}
      {/*    sx={{*/}
      {/*      '&:hover':{ cursor: 'pointer'},*/}
      {/*      width: '180px',*/}
      {/*      height: '60px', float: 'right',*/}
      {/*      marginRight: '10px',*/}
      {/*      marginTop: '10px', textAlign:'center'}}>*/}
      {/*    <Button variant="contained" color="primary" sx={{padding: {xs: '30px 20px', } }}>Delete and pick new group card</Button>*/}
      {/*    <DeleteForeverIcon sx={{ color: 'primary.main', marginTop: '2px'}} />*/}
      {/*  </Box>*/}
      {/*}*/}
      <Box className="card-preview" sx={{
        border: '1px solid #EAECF0',
        borderRadius: '14px',
        backgroundImage: `url(${imgUrl})`,
        backgroundPosition: 'center',
        backgroundRepeat: cardBackgroundImageFullSize ? 'no-repeat' : 'repeat',
        backgroundSize: cardBackgroundImageFullSize ? 'cover': '66px',
        width: '100%',
        margin:{
          xs: '10px auto'
        },
        minHeight:{
          xs: '200px'
        },
        maxWidth: {
          xs: '200px',
        },
      }}>

      </Box>
    </Box>
  )
}