import Grid from "@mui/material/Grid";
import Occasions from "../home/blocks/Occasions";
import {useEffect} from "react";
import AIDesigns from "../home/blocks/AIDesigns";

export default function OccasionsPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return(
    <>
      <Occasions />
      <AIDesigns />
    </>)
}