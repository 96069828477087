import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {mediaUpdated, userRemovedMessageVideo} from "../../slices/messageSlice";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { isValidURL } from "../../utils/functions";
import VideoWithUrl from "../message/messageBlocks/VideoWithUrl";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Box from "@mui/material/Box";
import mediaTypes from "../../utils/mediaTypes";
import VideoDisplay from "../message/messageBlocks/VideoDisplay";

export default function EmbedVideo({ updateMedia, media, closeModal}) {
  const dispatch = useDispatch();
  // const message = useSelector(state => state.message)
  const [userUrl, setUserUrl] = useState('')

  function handleInput(e) {
    e.preventDefault();
    setUserUrl(e.target.value)
    if(isValidURL(e.target.value)) {
      updateMedia({
        type: mediaTypes.VIDEO_EMBED,
        data: [{fileUrl: e.target.value}]
      })

      // dispatch(mediaUpdated({
      //   type: mediaTypes.VIDEO_EMBED,
      //   data: [{fileUrl: e.target.value}]
      // }))
    } else {
      updateMedia({
        type: null,
        data: []
      })
      // dispatch(userRemovedMessageVideo())
    }
  }

  function handleDeleteVideo() {
    setUserUrl('')
    // dispatch(userRemovedMessageVideo())
    updateMedia({
      type: null,
      data: []
    })
  }

  const validUrl = isValidURL(userUrl)


  return (
    <Grid container>
      <Grid item xs={12}>
        {/*<Typography component="h2" variant="subtitle2" sx={{marginBottom: '10px'}}>*/}
        {/*  Bring your favorite videos*/}
        {/*</Typography>*/}
        <Typography component="p" variant="body1" sx={{marginBottom: '15px'}}>
          Use your favorite videos from YouTube and Vimeo by providing their link.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{padding: '10px'}}>
        <Typography component="p" variant="subtitle1" sx={{marginBottom: '15px', display:{ md: 'none'}}}>
          Video's url
        </Typography>

        {!media.type &&
          <TextField
          sx={{marginBottom: '20px'}}
          onChange={handleInput}
          value={userUrl}
          fullWidth
          type="text" />
        }

        { media.type === mediaTypes.USER_VIDEO &&
          <Typography component="p" variant="caption">Delete uploaded video first in case you would like to bring a video from Youtube or Vimeo</Typography>
        }

        {!isValidURL(userUrl) && userUrl !== '' &&
          <Typography component="p" variant="caption" sx={{marginBottom: '15px'}}>
            It must be a valid url
          </Typography>
        }

        {media.data.length > 0 && media.type === mediaTypes.VIDEO_EMBED && <VideoWithUrl message={{media:media}}/> }

        { media.data.length > 0 && media.type === mediaTypes.VIDEO_EMBED &&
          <Box component="div" sx={{ textAlign: 'center'}}>
            <Button
              onClick={handleDeleteVideo}
              sx={{ textTransform: 'capitalize', marginTop: '10px'}}
              variant="text">
              <DeleteForeverIcon sx={{marginRight: '5px'}} />Delete video</Button>
          </Box>
        }

      </Grid>
    </Grid>
  )
}