import React from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import store from './store';

import ErrorPage from './pages/error-page';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import SignInSide from './pages/login';
import RegisterPage from './pages/register';
import CreateCardCategories from "./pages/create/CreateCardCategories";
import EditCardPage from './pages/groupCards/edit/EditCardPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import Admin from './pages/admin/Admin';
import GroupCard from './pages/groupCard/GroupCard';
import reportWebVitals from './reportWebVitals';

import { linkPaths } from "./utils/linkPaths";
import CreateCardShowTemplates from "./pages/create/CreateCardShowTemplates";
import CreateCardSettings from "./pages/create/CreateCardSettings";
import AddMessagePage from "./pages/addMessage/AddMessagePage";
import EditMessagePage from "./pages/editMessage/EditMessagePage";
import Home from "./pages/home/Home";
import PrivacyPolicyPage from "./pages/privacyPolicyPage/PrivacyPolicyPage";
import TermsOfService from "./pages/termsOfService/TermsOfService";
import RequireAuth from "./components/requireAuth/RequireAuth";
import LoadCredentials from "./pages/loadCreadentials/LoadCredentials";
import OccasionsPage from "./pages/occasions/OccasionsPage";
import SupportPage from "./pages/support/SupportPage";
import PricingPage from "./pages/pricing/PricingPage";
import ViewAsRecipient from "./pages/viewAsRecipient/ViewAsRecipient";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import MyCard from "./pages/myCard/MyCard";
import StripeCheckout from "./pages/checkout/StripeCheckout";
import PaymentConfirmationPage from "./pages/paymentConfirmation/PaymentConfirmationPage";
import ConfirmReplyPage from "./pages/confirmReply/ConfirmReplyPage";
import GotMessagePage from "./pages/gotMessage/GotMessagePage";
import UpgradeCardPage from "./pages/upgrade/UpgradeCardPage";
import MemorialPage from "./pages/enterprise/MemorialPage";
import HRPage from "./pages/enterprise/HRPage";

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: linkPaths.login.path,
        element: <SignInSide />,
      },
      {
        path: linkPaths.register.path,
        element: <RegisterPage  />,
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: '/reset-password/:token',
        element: <ResetPassword />,
      },
      {
        path: `/welcome`,
        element: <LoadCredentials />
      },
      {
        path: linkPaths.create.path,  //'create/card'
        element: <CreateCardCategories  />,
      },
      {
        path: linkPaths.createCardCategory.path, //'create/card/:friendlyUrl'
        element: <CreateCardShowTemplates />
      },
      {
        path: linkPaths.createCardSettings.path, //'create/card/settings
        element: <CreateCardSettings />
      },
      {
        path: `/checkout/:clientSecret`,
        element: <StripeCheckout />
      },
      {
        path: `/thanks`,
        element: <PaymentConfirmationPage />,
      },
      {
        path: "card/id/edit",
        element: <EditCardPage  />,
      },
      {
        path: "/occasions",
        element: <OccasionsPage />
      },
      {
        path: "/support",
        element: <SupportPage />
      },
      {
        path: "/pricing",
        element: <PricingPage />
      },
      {
        path: "/memorials",
        element: <MemorialPage/>
      },
      {
        path: "/business",
        element: <HRPage />
      },
      {
        path: "/upgrade/card/:cardId",
        element: <UpgradeCardPage/>
      },
      {
        path: "/confirm-reply/:token",
        element: <ConfirmReplyPage />
      },
      {
        path: "/got-message/:replyId",
        element: <GotMessagePage />
      },
      {
        element: <RequireAuth />,
        children: [
          {
            path: linkPaths.dashboard.path,
            element: <DashboardPage  />,
          },
          {
            path: '/card/:cardId/message/:messageId',
            element: <EditMessagePage />
          },
          // {
          //   path: linkPaths.admin.path,
          //   element: <Admin />,
          // },
          // {
          //   path: '/admin/templates',
          //   element: <TemplatesPage />,
          // },
          // {
          //   path: '/admin/templates/create',
          //   element: <CreateTemplatePage />,
          // },
          // {
          //   path: '/admin/templates/edit/:templateId',
          //   element: <EditTemplatePage />,
          // },
          // {
          //   path: '/admin/categories',
          //   element: <CategoriesPage />,
          // },
          // {
          //   path: '/admin/categories/create',
          //   element: <CreateCategoryPage />,
          // },
          // {
          //   path: '/admin/categories/edit/:categoryId',
          //   element: <EditCategoryPage />,
          // },
        ]
      },
      {
        path: linkPaths.viewCard.path,
        element: <GroupCard />,
      },
      {
        path: '/card/:cardId/mycard',
        element: <MyCard />,
      },
      {
        path: '/card/:cardId/asrecipient',
        element: <ViewAsRecipient />,
      },
      {
        path: '/card/:cardId/demo',
        element: <ViewAsRecipient />,
      },
      {
        path: '/card/:cardId/message',
        element: <AddMessagePage />
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicyPage />
      },
      {
        path: '/terms-of-service',
        element: <TermsOfService />
      },
    ],
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Provider store={store}>
        <CssBaseline enableColorScheme />
        <RouterProvider router={router} />
      </Provider>
    </LocalizationProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
