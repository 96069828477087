import { useNavigate } from "react-router-dom";
import { logOut } from "../../slices/authSlice";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import {closeMessageIcon, logOutIconGray, MenuIconSVG, signInIcon} from '../../svgIcons/icons';
import {TopNavigationLink} from "../topNavigationLink/TopNavigationLink";
import {linkPaths} from "../../utils/linkPaths";
import {colorsPallete} from "../../utils/colors";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {resetCardState} from "../../slices/cardSlice";
import {useLogoutMutation} from "../../slices/api/authApiSlice";

import Divider from "../divider/Divider";
import {
  checkUrlForAsRecipient,
  checkUrlForCreateCard,
  checkUrlForDemo,
  getCurrentRelativePath, isCardPage, isCheckoutPage,
  isPricingPage, isUpgradePage
} from "../../utils/functions";
import DemoBar from "../demoBar/DemoBar";
import AsrecipientBar from "../asrecipientBar/AsrecipientBar";

export default function AppToBar() {
  const auth = useSelector(state => state.auth)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logout] = useLogoutMutation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const isUserLoggedIn = !!auth?.user?.id;

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await logout().unwrap();
    } catch (e) {
      console.log('error login out',e)
    }
    dispatch(logOut());
    setMobileMenuOpen(false);
    navigate('/');
  }

  const mobileNavItems = [
    {title: 'Home', path:'/'},
    // {title: 'Group Cards', path:linkPaths.create.path },
    {title: 'For Businesses', path: '/business' },
    {title: 'Occasions', path: '/occasions' },
    {title: 'Pricing', path:'/pricing' },
    // {title: 'Support', path:'/support' },
    // {title: 'Register', path: '/register' },
  ];

  const loggedUserMobileNavItems = [
    // {title: 'Home', path:'/'},
    {title: 'Dashboard', path:linkPaths.dashboard.path },
    {title: 'For Businesses', path: '/business' },
    {title: 'Occasions', path: '/occasions' },
    // {title: 'Pricing', path:'/pricing' },
    {title: 'Support', path:'/support' },
  ];

  function createCard() {
    dispatch(resetCardState())
    setMobileMenuOpen(false)
    return navigate(linkPaths.create.path)
  }

  function navigateHome() {
    if(isUserLoggedIn) {
      return navigate('/dashboard')
    }
    return navigate(linkPaths.home.path)
  }

  function goTo(e, item) {
    e.preventDefault();
    //TODO: Here is where we might be able to push the history and add the from
    navigate(item.path,{ state: { from: getCurrentRelativePath()}});
    setMobileMenuOpen(false)
  }

  function showFullMenu() {
    //returs true or false
    if(isUpgradePage()) return false;
    if(isPricingPage()) return false;
    if(isCheckoutPage()) return false;
    return true;
  }

  function whatTopElement() {

  }

if(isCardPage()){
  return null;
}

  return(
    <Box sx={{ flexGrow: 1 }}>
      {checkUrlForAsRecipient() && !checkUrlForDemo() && <AsrecipientBar />}
      { checkUrlForDemo() && !checkUrlForAsRecipient() &&   <DemoBar /> }
      { !checkUrlForDemo() && !checkUrlForAsRecipient() &&
        <AppBar
          className='appTopBar'
          component="nav"
          sx={{
            height: {xs: '70px', sm: '80px'},
            padding: { xs: '0 16px' ,md: '0px 20px'},
            boxShadow: 'none',
            background: 'rgba(255, 255, 255, 1)',
            borderBottom: 'solid 1px rgba(242, 244, 247, 1)',
            display:'flex', flexDirection: 'row', justifyContent: 'space-between',
            // maxWidth: 'xl',
            // margin: '0 auto',
          }}
          position="static"
        >
          <Box sx={{ width:{ xs: '200px', md: '180px', lg: '220px'}, marginTop: { xs: '5px', md: '10px'} }}>
            <img
              onClick={navigateHome}
              className='logoTopBar'
              alt='RecognitionLink.com'
              src='https://storage.googleapis.com/prod-website-images-rlink/logo-recognition-link.png' />
          </Box>
          {/*Mobile menu icon*/}
          <Box onClick={() => setMobileMenuOpen(true)} sx={{display:{ xs: 'block', md: 'none'},width: '36px', height: '37px', marginTop:'14px', '&:hover':{ cursor: 'pointer'} }}>
            <MenuIconSVG color="rgba(16, 24, 40, 1)" />
          </Box>
          <Drawer
            anchor="top"
            open={mobileMenuOpen}
            onClose={()=>{ setMobileMenuOpen(false)}}
            sx={{padding: '10px'}}
          >
            <Box sx={{ display:'flex', flexDirection: 'column', padding: '10px 10px', textAlign: 'center'}}>
              <Box sx={{ textAlign: 'right', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '30px'}} onClick={() => { setMobileMenuOpen(false) }}>
                <Box sx={{ width:{ xs: '200px', md: '220px', lg: '240px'}, marginTop: '5px'  }}>
                  <img
                    onClick={navigateHome}
                    className='logoTopBar'
                    alt='RecognitionLink.com'
                    src='https://storage.googleapis.com/prod-website-images-rlink/logo-recognition-link.png' />
                </Box>
                {closeMessageIcon}
              </Box>
              {!isUserLoggedIn &&
                <TopNavigationLink  >
                  <Box onClick={(e) => goTo(e,{ path: '/login'})}>{signInIcon} Sign in</Box>
                </TopNavigationLink>
              }
              {isUserLoggedIn &&
                <TopNavigationLink>
                  <Box onClick={handleLogout} sx={{ display: 'flex', alignItems: 'center', gap: '5px', justifyContent: 'center'}}>
                    {logOutIconGray} Sign out
                  </Box>
                </TopNavigationLink>
              }

               <Button  id="create-group-card-top-menu-button-1"   onClick={createCard} variant="contained" sx={{ fontWeight: '700',  marginTop: '10px', maxWidth: '343px', margin: '20px auto 10px auto' }}>Create group card</Button>
              <Divider />
              {!isUserLoggedIn &&
                mobileNavItems.map((item) => (
                  <TopNavigationLink key={item.title} >
                    <Box onClick={(e) => goTo(e,item)}>{item.title}</Box>
                  </TopNavigationLink>
                ))
              }

              {isUserLoggedIn &&
                loggedUserMobileNavItems.map((item) => (
                  <TopNavigationLink key={item.title} >
                    <Box onClick={(e) => goTo(e,item)}>{item.title}</Box>
                  </TopNavigationLink>
                ))
              }
            </Box>
          </Drawer>
          {/*Here the mobile menu ends*/}


          <Box sx={{
            marginTop: '12px',
            display: { xs: 'none', md: 'flex'},
            flexDirection: 'row',
            flexGrow: 1,
            justifyContent: 'flex-end',
            alignItems: 'center'
          }} >
            {!isUserLoggedIn && showFullMenu() && mobileNavItems.map((item) => (
              <Button key={item.title} sx={{ color: colorsPallete.grayText, padding: {md: '12px 8px', lg: '12px 16px'}, fontSize:{md: '14px', lg: '16px'}  }} className='menuButton' onClick={(e) => goTo(e,item)}>
                {item.title}
              </Button>
            ))}
            {isUserLoggedIn && showFullMenu() && loggedUserMobileNavItems.map((item) => (
              <Button key={item.title} sx={{ color: colorsPallete.grayText, padding: {md: '12px 8px', lg: '12px 16px'}, fontSize:{md: '14px', lg: '16px'}  }} className='menuButton' onClick={(e) => goTo(e,item)}>
                {item.title}
              </Button>
            ))}
            {/*<Button className='menuButton' sx={{ borderLeft: '1px solid rgba(208, 213, 221, 1)', borderRadius: 0}}>*/}
            {/*  {bellIcon}*/}
            {/*</Button>*/}
            {isUserLoggedIn && showFullMenu() &&
              <Button sx={{ color: colorsPallete.grayText, padding: {md: '12px 8px', lg: '12px 16px'}, fontSize:{md: '14px', lg: '16px'} }} className='menuButton' onClick={handleLogout}>
                {logOutIconGray} Sign out
              </Button>}
            {!isUserLoggedIn && showFullMenu() &&
              <Button sx={{ color: colorsPallete.grayText, padding: {md: '12px 8px', lg: '12px 16px'}, fontSize:{md: '14px', lg: '16px'} }} className='menuButton' onClick={(e) => goTo(e,{path: '/login'})}>
                {signInIcon} Sign in
              </Button>}

            {!checkUrlForCreateCard() && showFullMenu() && !isUpgradePage() &&   <Button  id="create-group-card-top-menu-button-2"  onClick={createCard} variant="contained" sx={{ fontWeight: '700', maxWidth: '200px' }}>Create group card</Button> }
            {!showFullMenu() && !isCheckoutPage() && !isUpgradePage() &&
              <Button id="create-group-card-top-menu-button-3" sx={{ maxWidth: '220px'}} variant="contained" onClick={createCard}>
              Create Group Card
            </Button>}
          </Box>
        </AppBar>
      }
    </Box>
  )
}