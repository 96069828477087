import Box from "@mui/material/Box";

export default function PurplePill({children , maxWidth  = '113px'}){
  return <Box component='div' sx={{
    display: 'flex',
    padding: '4px 12px',
    justifyContent:'center',
    alignItems: 'center',
    borderRadius: '16px',
    backgroundColor: '#F9F5FF',
    margin: '0 auto 16px auto',
    maxWidth: maxWidth,
    color: '#6941C6',
    fontSize: '14px',
    fontWeight: 500,
  }}>{children}</Box>
}