import Grid from '@mui/material/Grid';
import LeftPanel from './LeftPanel';
import GroupCard from '../../groupCard/GroupCard';

export default function EditCardPage() {
    return(
        <Grid container sx={{height: '100%'}}>
            <Grid item xs={12} md={3} sx={{ background: '#F9FAFB', height: '100vh', padding: '60px 2%'}}>
                <LeftPanel />
            </Grid>
            <Grid item xs={12} md={9} >
                <GroupCard />
            </Grid>
        </Grid>
    )
}