import { createSlice } from '@reduxjs/toolkit';
import { addToArray, removeFromArray } from "../utils/functions";

const initialState = {
  title: "Card's title",
  categories: [],
  recipientsNameColor: '#FFF',
  cardTitleColor: '#FFF',
  messageTextColor: '#FFF',
  messageBackgroundColor: '#FFF',
  cardBackgroundColor: '#FFF',
  cardBackgroundImage: null, // { file: File, url: local url}
  cardBackgroundImageFullSize: true,
};

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    templateLoaded(state, action) {
      state = Object.assign({}, action.payload);
    },
    titleUpdated(state, action) {
      state.title = action.payload;
    },
    categoryAdded(state, action) {
      state.categories = addToArray(state.categories,action.payload);
    },
    categoryRemoved(state, action) {
      state.categories = removeFromArray(state.categories,action.payload);
    },
    recipientsNameColorSelected(state,action){
      state.recipientsNameColor = action.payload;
    },
    cardTitleColorSelected(state,action){
      state.cardTitleColor = action.payload;
    },
    messageTextColorSelected(state, action){
      state.messageTextColor = action.payload;
    },
    messageBackgroundColorSelected(state, action) {
      state.messageBackgroundColor = action.payload;
    },
    cardBackgroundColorSelected(state, action) {
      state.cardBackgroundColor = action.payload;
    },
    cardBackgroundImageAdded(state, action) {
      state.cardBackgroundImage = action.payload;
    },
    cardBackgroundImageRemoved(state) {
      state.cardBackgroundImage = null;
    },
    cardBackgroundImageFullSizeUpdated(state, action) {
      state.cardBackgroundImageFullSize = action.payload;
    }
  }
})

export const { templateLoaded, titleUpdated, categoryAdded, categoryRemoved, recipientsNameColorSelected, cardTitleColorSelected, messageTextColorSelected, messageBackgroundColorSelected,
cardBackgroundColorSelected, cardBackgroundImageAdded, cardBackgroundImageRemoved, cardBackgroundImageFullSizeUpdated} = templateSlice.actions
