import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {useNavigate} from "react-router-dom";
import {linkPaths} from "../../../utils/linkPaths";
import {getCurrentDomain} from "../../../utils/functions";
import HighlightsHome from "../../../components/highlights/HighlightsHome";
import VideoPlayer from "../../../components/videoPlayer/VideoPlayer";

export default function HeroSection(){
  const navigate = useNavigate();

  function goCreateCard() {
    navigate(linkPaths.create.path)
  }

  return (
    <Box sx={{
      width:'100%',
      background: `url('https://storage.googleapis.com/prod-website-images-rlink/hero_blue_blur_top_right.svg'), 
      url('https://storage.googleapis.com/prod-website-images-rlink/hero_red_blur.svg'), 
      url('https://storage.googleapis.com/prod-website-images-rlink/hero_orange_blur.svg')`,
      backgroundSize: {
        xs:'280px, 200px,100px',
        sm:'160px, 160px,0',
        md:'240px, 200px,80px',
        lg:'240px, 250px,200px',
      },
      backgroundPosition: {
        xs: `top -20px right,  bottom   left,  bottom right`,
        sm: `top  right,bottom left,bottom right`,
        md: `top  right,bottom left,bottom right`,
        lg: `top  right,bottom left,bottom right`,

      },
      backgroundRepeat: 'no-repeat',
    }}>
      <Grid maxWidth="xl" container sx={{
        margin: '0 auto',
        padding: {
          xs: '20px 17px 20px 17px',
          sm: '40px 17px 0 17px',
          md: '30px 17px 40px 17px',
          xl: '60px 20px 60px 20px'
        },
      }}>
        <Grid item xs={12} sm={7} md={7} lg={6}>
          {/*<Typography variant="h1" sx={{ marginBottom: '20px', letterSpacing:{ xs: '-0.72px', md: '-0.96px'}, fontSize:{ xs: '36px', md: '48px'}, lineHeight:{ xs: '44px', md: '60px'} }}> Unite and Celebrate <Box component="span" sx={{ color: '#7F56D9'}}>Joyful Moments</Box> with Group Cards</Typography>*/}
          <Box sx={{padding:{sm:'0px 20px 0 30px', md:'40px 20px 0 30px', lg:'60px 20px 0 80px' }  }}>
            <Typography variant="h1" sx={{ marginBottom: '20px', letterSpacing:{ xs: '-0.72px', md: '-0.96px'}, fontSize:{ xs: '36px', md: '48px'}, lineHeight:{ xs: '44px', md: '60px'} }}> <Box component="span" sx={{ color: '#7F56D9'}}>Digital Group Cards</Box> for Every Occasion</Typography>
            <Typography variant="subtitle2" sx={{ margin:{ xs: '24px 0 28px 0', sm: '15px 0 20px 0'} }}>Make someone's day with a group card that collects messages, photos, and videos from friends and colleagues</Typography>
            <Box sx={{ display: {xs:'block', sm:'none'}, margin: '0 auto 30px auto', width: { xs: '300px' }, position: 'relative' }}>
              <VideoPlayer
                coverImage={true}
                width="300px"
                height="534px"
                src="https://storage.googleapis.com/prod-website-images-rlink/videos/how_it_works_1.mp4"/>
            </Box>
            <HighlightsHome />
            <Box component="div" sx={{ display: 'flex', flexDirection:{ xs: 'column', md: 'row'}, marginTop:'20px'  }}>
              <Button onClick={() => { window.open(`${getCurrentDomain()}/card/66385f00e5448b718021fc6b/demo`, "_self")}} variant="outlined" color="secondary" sx={{ display:'flex', gap: '5px', maxWidth:{xs: '100%', sm: '200px'}, width:{ xs: '100%'}, height:{ sm: '48px'}, marginRight:{ sm: '16px'}, padding: {xs: '12px 20px', sm: '12px 20px'},
              }}><PlayCircleOutlineIcon  /> View Demo Card</Button>
              <Button
                id="create-group-card-hero-button"
                onClick={goCreateCard} variant="contained" color="primary"
                sx={{
                  fontWeight: 700,
                  padding: {xs: '12px 20px', sm: '12px 20px'},
                  maxWidth: { xs: '100%', sm: '200px'},
                  marginBottom: '48px' }}>Create group card</Button>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={5} md={5} lg={6}>
          <Box sx={{ display: {xs:'none', sm:'block'}, margin: '0 auto 30px auto', width: { xs: '300px', sm:'100%' }, maxWidth:'300px', position: 'relative' }}>
            <VideoPlayer
              coverImage={true}
              width="300px"
              height="534px"
              src="https://storage.googleapis.com/prod-website-images-rlink/videos/how_it_works_1.mp4"/>
          </Box>
        </Grid>
      </Grid>
    </Box>

  )
}

