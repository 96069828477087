import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {messageIntact} from "../../../utils/functions";

export default function TextMessageArea({message, textColor = '#000'}){
  if(!message) return null;

  const paragraphs = message.message.split(/\n\n/);
  const userHasntStarted = messageIntact(message);

  return(
    <Box className="messageContainer" sx={{ padding: '25px'}}>
      {userHasntStarted &&
        <Box sx={{ textAlign: 'left'}}>
        <Typography variant="body1"><i>Message preview...</i></Typography>
        </Box>
      }
      {paragraphs.map((paragraph,index) => (
        <Typography key={`${index}-${paragraph}`} variant="body1" sx={{
          color: textColor,
        }}>{paragraph}</Typography>
      ))}
    </Box>)
}