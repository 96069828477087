import {useState} from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import UploadWidget from "../customBackgroundUploader/blocks/UploadWidget";
import {useDeleteAvatarImageMutation, useUploadAvatarImageMutation} from "../../slices/api/apiSlice";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";
import {useUpdateCardMutation} from "../../slices/api/cardApiSlice";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {compressImage, getImageFileNameFromURL} from "../../utils/functions";
import DialogContent from "@mui/material/DialogContent";
import {colorsPallete} from "../../utils/colors";
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import avatars from '../../utils/avatars'
import AvatarCategory from "./AvatarCategory";
import DialogActions from "@mui/material/DialogActions";
import AppBar from "@mui/material/AppBar";
import AnimationOptions from "../editBackgroundDialog/AnimationOptions";
import AnimationSelector from "../animationSelector/AnimationSelector";

export default function AvatarSelector({card, closeDialog}){
  const [value, setValue] = useState(0);
  const [error, setError] = useState('');
  const [avatar, setAvatar] = useState({ avatarImage:card.template.avatarImage, isCustomAvatar: card.template.isCustomAvatar});
  const [showDeleteWarning, setShowDeleteWarning] = useState(false)
  const [uploadAvatar, {isLoading: avatarIsUploading}] = useUploadAvatarImageMutation();
  const [deleteAvatar, {isLoading: isDeleting}] = useDeleteAvatarImageMutation();
  const [updateCard] = useUpdateCardMutation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFiles = async function(e){
    let files = [];

    if(e.target.files) {
      files = e.target.files;
    } else if (e.dataTransfer) {
      files = e.dataTransfer.files;
    }

    if(files.length > 1) {
      return setError('Only one image allowed for the avatar.');
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > 20 * 1024 * 1024) {
        setError('The file size is larger than 20MB. Please select a smaller file.');
        return; // Exit the function to prevent uploading
      }
      const formData = new FormData();
      try {
        const compressedFile = await compressImage({
          file,
          maxWidth: 420,
          maxHeight: 420,
        })
        formData.append('image', compressedFile);
      } catch (errorCompressing) {
        console.error('error compressing avatar image', errorCompressing)
        formData.append('image', file);
      }
      // formData.append('image', file)

      try {
        const image = await uploadAvatar(formData).unwrap();

        setAvatar({ avatarImage: image.fileUrl, isCustomAvatar: true})
      } catch (e) {
        console.log('error uploading file', e)
        return setError('Error uploading avatar. Please try again.')
      }
    }
  }

  async function handleUpdateCard(e,options) {

    let update = {
      ...card,
      template: {
        ...card.template,
        avatarImage: avatar.avatarImage,
        isCustomAvatar: avatar.isCustomAvatar
      }
    }

    if(options) {
      update = {
        ...card,
        template: {
          ...card.template,
          avatarImage: options.avatarImage,
          isCustomAvatar: options.isCustomAvatar
        }
      }
    }


    try {
      await updateCard(update).unwrap();
      closeDialog();
    } catch (e) {
      console.log('e',e)
      if (e.originalStatus === 401) {
        setError('Not authorized to update the card. Login or register.')
      } else if (e.originalStatus === 403) {
        setError('Not authorized to update the card. Login or register.')
      }else if(e.originalStatus === 404) {
        setError('Card not found')
      }else {
        setError(`Oops, something went wrong on our end... Please try again`)
      }
    }
  }

  const handleDeleteAvatar = async () => {
    try {
      const fileToDelete = getImageFileNameFromURL(avatar.avatarImage);
      // const currentImage = getImageFileNameFromURL(card.template.avatarImage);

      await deleteAvatar(fileToDelete).unwrap()
      await updateCard({
        ...card,
        template: {
          ...card.template,
          avatarImage: null,
          isCustomAvatar: false,
        }
      }).unwrap();

      // if(fileToDelete === currentImage) {
      //   await updateCard({
      //     ...card,
      //     template: {
      //       ...card.template,
      //       avatarImage: null,
      //       isCustomAvatar: false,
      //     }
      //   }).unwrap();
      // }
      setShowDeleteWarning(false)
      setAvatar({ avatarImage: null, isCustomAvatar: false});

    } catch (e) {
      console.log('error deleting avatar',e)
      setError('Error deleting avatar, please try again.')
    }
  }

  const clearAvatar = async () => {
    try {
      if(card.template.isCustomAvatar) {
        await handleDeleteAvatar();
      } else if(card.template.avatarImage === null) {
        return closeDialog();
      } else {
        await updateCard({
          ...card,
          template: {
            ...card.template,
            avatarImage: null,
            isCustomAvatar: false,
          }
        }).unwrap();
      }
      setAvatar({ avatarImage: null, isCustomAvatar: false});
      closeDialog();
    } catch (e) {
      console.log(e)
      setError("Error clearing the avatar, please try again");
    }
  }

  const handleAvatarSelection = async (avatarSelected) => {
    let image  = avatarSelected.image
    if(card.template.avatarImage === avatarSelected.image) {
      image = null
    }
    setAvatar({ avatarImage:image, isCustomAvatar: false});
    await handleUpdateCard(null, { avatarImage:image, isCustomAvatar: false});
  }


  return(
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '30px' }}>
        <Tabs variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab className='dashboardTab' label="Avatars" {...a11yProps(0)} />
          <Tab className='dashboardTab'  label="Recipient's photo" {...a11yProps(1)} />
          <Tab className='dashboardTab'  label="Remove avatar" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Grid container>
          <Grid item xs={12}>
            {/*<Typography variant="body1" sx={{fontWeight: '500',  marginBottom:'8px' }}>Choose one of our icons</Typography>*/}
            <Typography variant="body2" sx={{ marginBottom:{ xs: '20px', md: '30px'} }}>The avatars help celebrate the occasion, you can also upload  a photo of the recipient to make it extra personal.</Typography>
          </Grid>
          <Grid item xs={12}>
            {avatars.map(avatarCategory => {return <AvatarCategory avatarSelected={avatar} handleSelection={handleAvatarSelection} key={avatarCategory.id} category={avatarCategory} />})}
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <Box sx={{background: '#FFF', padding:'20px 10px', width: '100%', display: 'flex', justifyContent:'flex-end', margin:0, gap: '20px' }}>*/}
          {/*    <Button onClick={handleUpdateCard} variant="contained" sx={{maxWidth: '200px'}}>Save</Button>*/}
          {/*  </Box>*/}
          {/*</Grid>*/}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{fontWeight: '500',  marginBottom:'8px' }}>Upload recipient's photo</Typography>
            <Typography variant="body2" sx={{ marginBottom:{ xs: '20px', md: '30px'} }}>Make it extra personal by adding a photo of the recipient.</Typography>
          </Grid>
          <Grid item xs={12}>

            {avatar.avatarImage && avatar.isCustomAvatar &&
              <Box sx={{ position: 'relative', width: '200px', margin: '20px auto'}}>
                <Box
                  onClick={() => {setShowDeleteWarning(true)}}
                  sx={{
                    '&:hover':{ cursor: 'pointer'},
                    position: 'absolute',
                    border: '1px solid gray',
                    zIndex: 50,
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#FFF',
                    borderRadius: '50%',
                    right: '5px',
                    top: '5px', textAlign:'center'}}>
                  <DeleteForeverIcon sx={{ color: 'primary.main', marginTop: '6px', fontSize: '26px'}} />
                </Box>
                <Avatar sx={{ width: 120, height: 120, margin: { xs: '0 auto', sm:'0'} }} src={avatar.avatarImage} />
                {/*<img style={{ borderRadius: '50%'}} width="100%" src={avatar.avatarImage} alt="custom avatar image" />*/}
              </Box>}
            {!avatar.isCustomAvatar && !avatarIsUploading && <UploadWidget onChange={handleFiles} />}
            {avatarIsUploading && <Typography sx={{ margin: '30px auto', width: '220px'}} variant="subtitle2">Uploading....</Typography>}


          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent:'flex-end', margin:{ xs: '30px 0', md: '40px 0'} }}>
              <Button disabled={avatarIsUploading} onClick={handleUpdateCard} variant="contained" sx={{maxWidth: '200px'}}>Save</Button>
            </Box>
          </Grid>
        </Grid>
      </CustomTabPanel>


      <CustomTabPanel value={value} index={2}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{fontWeight: '500',  marginBottom:'8px' }}>Remove Avatar</Typography>
            <Typography variant="body2" sx={{ marginBottom:{ xs: '20px', md: '30px'} }}>Use this option if you don not want a avatar or icon on the card</Typography>
            <Button onClick={clearAvatar} sx={{ maxWidth: '200px', marginBottom: '40px'}} variant="outlined" color="secondary">Remove Avatar</Button>
          </Grid>
        </Grid>
      </CustomTabPanel>
      {error && <InfoSnackbar onClick={() => {setError('')}} severity="error" message={error}/> }
      <Dialog onClose={() => { setShowDeleteWarning(false)}} open={showDeleteWarning} fullWidth={true}  maxWidth='sm'>
        <DialogContent>
          <Box sx={{textAlign: 'center'}}>
            <Typography variant='h3' sx={{ margin: '30px'}}>Are you sure you want to delete the custom avatar?</Typography>
            {isDeleting && <Typography variant="body2">Deleting...</Typography> }
            <Box sx={{ display: 'flex', alignItems: 'top', gap: '20px', marginTop: '20px', justifyContent: 'flex-end'}}>
              <Button sx={{ height: '50px', maxWidth: '100px'}} onClick={() => { setShowDeleteWarning(false)}} variant='outlined' color="secondary">Cancel</Button>
              <Box>
                <Button variant='outlined'
                        onClick={handleDeleteAvatar}
                        color="secondary"
                        sx={{
                          background: colorsPallete.errorText,
                          maxWidth: '120px',
                          border: `1px solid ${colorsPallete.errorText}`,
                          color: '#FFF',
                          '&:hover': {
                            background: colorsPallete.errorText,
                          }
                        }}>Delete</Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div

      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{marginTop: '40px'}}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}