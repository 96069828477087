import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import {checkUrlForAsRecipient, checkUrlForDemo, checkUrlForMyCard, showPremiumFeature} from "../../utils/functions";
import IntroAnimation from "../../components/introAnimation/IntroAnimation";
import React, {useState} from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import EditIcon from '@mui/icons-material/Edit';
import InviteContributorsDialog from "../../components/inviteContributorsDialog/InviteContributorsDialog";
import CardEditOptions from "./settings/CardEditOptions";
import SendOrSchedule from "./settings/SendOrSchedule";
import Drawer from "@mui/material/Drawer";
import {useNavigate} from "react-router-dom";
import {sendIcon} from "../../svgIcons/icons";
import CardSettingsDialog from "./settings/CardSettingsDialog";
import AmazingImage from "../../components/amazingImage/AmazingImage";
import {useDispatch, useSelector} from "react-redux";
import {setVisibilityFakeMessages} from "../../slices/fakeMessagesSlice";
import ReplyDialog from "../../components/reply/ReplyDialog";
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

export default function CardHeader({card, inviteContributors = true, messages = []}) {
  const [showInvite, setShowInvite] = useState(false);
  const [showScheduler, setShowScheduler] = useState(false);
  const [showCustomize, setShowCustomize] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showAmazingImageFlag, setShowAmazingImageFlag] = useState(true);
  const showFakeMessages = useSelector(state => state.showFakeMessages);
  const [showReply, setShowReply] = useState(false);
  const dispatch = useDispatch();
  function setShowFakeMessages() {
    dispatch(setVisibilityFakeMessages(!showFakeMessages))
  }

  const navigate = useNavigate();

 function isViewAsRecipientPage() {
    const currentUrl = window.location.href;
    return currentUrl.includes('/asrecipient');
  }

  function isViewAsMyCard() {
    const currentUrl = window.location.href;
    return currentUrl.includes('/mycard');
  }

  const closeInvite = () => {
    setShowInvite(false);
  }

  function isCreatorEditing() {
    if(checkUrlForAsRecipient()) {
      return false
    }else if(checkUrlForMyCard()) {
      return false
    } else if(checkUrlForDemo()) {
      return false
    } else if(card.canEdit) {
      return true
    }
    return false;
  }

  function goToCard() {
   if(card?.id) {
     return navigate(`/card/${card.id}`)
   }
   return null;
  }

  function openSettings() {
   if(card.canEdit) {
     return setShowSettings(true)
   }
  }

  function showAmazingImage() {
    const url = window.location.href;
    const urlPattern = /\/card\/([0-9a-fA-F]{24})(\/asrecipient|\/mycard|\/demo)?$/;
    const match = url.match(urlPattern);
    if(card?.template?.cardBackgroundImage && card.template.cardBackgroundImage.includes(`default_background.png`)) {
      return false
    }
    if(!showAmazingImageFlag) return false;

    if (match) {
      const cardId = match[1];
      const allowedEndings = ["/asrecipient", "/mycard", "/demo"];

      for (const ending of allowedEndings) {
        if (url.endsWith(`/card/${cardId}${ending}`)) {
          return true;
        }
      }

      if (url.endsWith(`/card/${cardId}`)) {
        return false;
      }
    }

    return false;
  }


  return(
    <Grid container maxWidth="xl" sx={{
      margin: '0 auto',
      padding: '0',
      // background: { xs: `radial-gradient(50.05% 50.04% at 50.16% 49.96%, ${card.template.titleShadowColor} 0%, rgba(255, 255, 255, 0.00) 100%)`, sm:'none'  }
    }}>
      {showAmazingImage() && <AmazingImage imageUrl="https://storage.googleapis.com/prod-rl-templates/1718298920636-0tqp4f.jpg" callback={() => setShowAmazingImageFlag(false)} card={card} />}
      {!showAmazingImage() && showPremiumFeature(card) && card?.template?.animation && !isCreatorEditing() && <IntroAnimation card={card} />}
      <Grid item xs={12} sm={8} md={9}>
        <Box sx={{
          // background: {xs: 'none', sm: `radial-gradient(37.05% 49.04% at 49.16% 49.96%, ${card.template.titleShadowColor} 0%, rgba(255, 255, 255, 0.00) 100%)`},
          display: 'flex', flexDirection:{ xs: 'column', sm: 'row'}, alignItems: 'center' }}>
          { card.template.avatarImage && showPremiumFeature(card) && <Avatar sx={{ width: 120, height: 120, margin: { xs: '0 auto', sm:'0'} }} src={card.template.avatarImage} /> }
          <Box sx={{ paddingLeft: {xs: 0, sm: '10px', md: '20px'}, display: 'flex', flexDirection:'column', textAlign: {xs: 'center', sm: 'left', md: 'left'} }}>
            <Typography onClick={openSettings} variant='h3' sx={{ '&:hover':{cursor: card.canEdit ? 'pointer': 'default'}, textShadow: `${card.template.titleShadowColor} ${card.template.titleXShadow}px ${card.template.titleXShadow}px ${card.template.titleBlur}px`, marginBottom: '0px',color: card.template.recipientsNameColor,}}>
              { card.recipients.length > 0 ? card.recipients[0].fullName : ''},</Typography>
            <Typography
              variant='h3'
              onClick={openSettings}
              sx={{
                '&:hover':{cursor: card.canEdit ? 'pointer': 'default'},
                fontWeight: card.template.titleFontWeight,
                marginBottom: '10px',
                letterSpacing: card.template.titleLetterSpacing,
                color:card.template.cardTitleColor,
                fontFamily: card.template.titleFontFamily,
                fontStyle: card.template.titleFontStyle,
                fontSize: {xs:'30px', md:`${card.template.titleFontSize}px`},
                lineHeight:{ xs: '34px', md: `${Number(card.template.titleFontSize) + 12}px`},
                textShadow: `${card.template.titleShadowColor} ${card.template.titleXShadow}px ${card.template.titleYShadow}px ${card.template.titleBlur}px`,
              }}>{card.title}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} md={3} >
        <Box sx={{ textAlign: { xs: 'center', sm: 'right'}, margin: '0px 0px 10px 0px'}}>
          <Typography onClick={openSettings}  variant='body2' sx={{'&:hover':{cursor: card.canEdit ? 'pointer': 'default'}, textShadow: `${card.template.titleShadowColor} ${card.template.titleXShadow}px ${card.template.titleYShadow}px ${card.template.titleBlur}px`, color: card.template.recipientsNameColor}}>From: <b>{card.creatorName}</b></Typography>
        </Box>
        {card?.canEdit && !isViewAsRecipientPage() && !isViewAsMyCard() &&
          <Box sx={{ display:{ xs: 'block', md: 'none'} }}>
            <ButtonGroup sx={{width:'100%', background: 'linear-gradient(90deg, #5D9AF3 0%, #5E19B7 100%)'}} variant="contained" aria-label="Basic button group">
              <Button onClick={() => { setShowInvite(prevState => !prevState)}} sx={{fontSize:{xs:'14px', md:'16px'},display:'flex', flexDirection:'column', background: 'none', marginBottom: 0}}><GroupAddOutlinedIcon fontSize="small" /> Invite</Button>
              {/*{card.deliveryStatus !== 'DELIVERED' && <Button onClick={() => { setShowScheduler(prevState => !prevState)}}  sx={{fontSize:{xs:'14px', md:'16px'},display:'flex', flexDirection:'column', background: 'none', marginBottom: 0}}>{sendIcon}Send</Button>}*/}
              <Button onClick={() => { setShowScheduler(prevState => !prevState)}}  sx={{fontSize:{xs:'14px', md:'16px'},display:'flex', flexDirection:'column', background: 'none', marginBottom: 0}}>{sendIcon}Send</Button>
              <Button onClick={() => { setShowCustomize(prevState => !prevState)}}   sx={{fontSize:{xs:'14px', md:'16px'},display:'flex', flexDirection:'column', background: 'none', marginBottom: 0}}><EditIcon fontSize="small"/> More</Button>
            </ButtonGroup>
          </Box>
        }

        {isViewAsMyCard() && card?.tier !== 'FREE_PLAN' &&
          <Box sx={{textAlign:'right'}}>
            <Button
              startIcon={<ReplyAllIcon  />}
              variant="contained"
              onClick={()=> setShowReply(true)}
              sx={{maxWidth:{sm:'200px'} }}
            > Reply</Button>
            <ReplyDialog card={card} open={showReply} setOpen={setShowReply} />
          </Box>
        }

        {card.canEdit && isViewAsRecipientPage() &&
          <Box sx={{ display:'flex', flexDirection:'column',  alignItems:{xs:'center', sm:'end'} }}>
            <Button onClick={goToCard} sx={ {maxWidth:'200px'}} variant="contained" >Back to edit</Button>
            {messages.length === 0 && <Button onClick={setShowFakeMessages} sx={ {maxWidth:'200px'}} variant="outlined" color="secondary">{showFakeMessages ? 'Hide preview messages' : 'Preview with messages' }</Button>}
          </Box>
        }
      </Grid>
      <InviteContributorsDialog card={card} open={showInvite} closeDialog={closeInvite} />
      <SendOrSchedule card={card} open={showScheduler}  closeDialog={() => { setShowScheduler(false)}} />
      <Drawer  anchor="left" open={showCustomize} onClose={() => {setShowCustomize(false)}} >
        <CardEditOptions closeDrawer={() => {setShowCustomize(false)}} card={card} />
      </Drawer>
      <CardSettingsDialog card={card} open={showSettings} closeDialog={() => setShowSettings(false)}  />
    </Grid>
  )
}
