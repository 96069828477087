import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import {PremiumCrown} from "../../svgIcons/icons";
import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import {isValidEmail} from "../../utils/functions";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";
import {useCreateCheckoutMutation} from "../../slices/api/checkoutApiSlide";
import {useNavigate} from "react-router-dom";
import {aiSamples} from "../../utils/aiDesignsSamples";

export default function PurchaseAiCredits({card}) {
  const auth = useSelector(state => state.auth); //{ user: {}, token: null, refreshToken: null}
  const [error,setError] = useState('');
  const [createCheckout, {isLoading}] = useCreateCheckoutMutation();
  const navigate = useNavigate();

  async function handlePurchase(){
    let email = card?.creatorEmail;

    if(auth?.user?.id) {
      email = auth.user.email;
    }
    if(!isValidEmail(email)) {
      setError('Valid email required, update email in the card settings or login')
    }

    if(!card.id) {
      setError('Sorry, not card found')
    }

    const checkoutOptions = {
      email,
      planId: 'AI',
      cardId: card.id
    }

    try {
      const checkoutResult = await createCheckout(checkoutOptions).unwrap();
      if(checkoutResult.clientSecret){
        return navigate(`/checkout/${checkoutResult.clientSecret}?cardId=${card.id}`)
      } else {
        return;
      }
    }catch (e) {
      setError('Sorry...there was an error, please try again')
      console.log('error creating checkout for ai images', e)
    }
  }

  return(<Box sx={{ margin: '20px 0'}}>
    {error && <InfoSnackbar callback={()=> setError('')} message={error} /> }
    <Alert
      id="premium-message-warning"
      data-testid="premium-message-warning"
      icon={<PremiumCrown width="16px" height="16px" /> }
      sx={{ borderRadius: '8px', backgroundColor:'#F9F5FF', color: '#6941C6', fontFamily:"'Inter', sans-serif", paddingBottom:'10px'}}
      severity="info">
      <Typography variant="h4" sx={{fontSize:'18px', lineHeight:'22px', marginBottom:'8px'}}>Create the must fun and personalized designs!</Typography>
      <Box sx={{ margin:'20px 0', display: 'flex', flexDirection: 'row', flexWrap: 'no-wrap', overflow: 'scroll', gap: '20px'}}>
        {aiSamples.map(sample => {
          return <Box key={sample.id} sx={{minWidth:{xs:'110px', sm:'200px'} }}><img src={sample.url} alt={sample.description} width="100%" /> </Box>
        })}
      </Box>
      <Typography variant="body2">Create a unique images by describing in detail how you want the card background to look like, AI will take care of the rest. Nobody else will have the same design.</Typography>
      <Button disabled={isLoading} onClick={handlePurchase} sx={{marginTop: '20px'}} variant="contained">Purchase AI Generation</Button>
      <Typography variant="caption">One time payment of $1.99 let's you create up to 3 more images based on your descriptions</Typography>
    </Alert>

  </Box>)
}