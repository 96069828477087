import {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box'
import UploadWidget from "../../customBackgroundUploader/blocks/UploadWidget";
import { useUploadMessageImageMutation } from "../../../slices/api/apiSlice";
import { userUploadedImage } from "../../../slices/messageSlice";
import mediaTypes from "../../../utils/mediaTypes";
import ImagesPreview from "./ImagesPreview";

import InfoSnackbar from "../../infoSnackbar/InfoSnackbar";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Uploading from "../../customBackgroundUploader/blocks/Uploading";
import InfoAlert from "../../infoAlert/InfoAlert";
import {addToArray, compressImage, removeFromArray} from "../../../utils/functions";

export default function AddMessageImageUploader({images = [],addImage, removeImage}) {
  const dispatch = useDispatch();
  const [uploadMessageImage, { isLoading, errorUploading}] = useUploadMessageImageMutation();
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState('')

  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = async function(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      await  handleFiles(e);
    }
  };

  const handleRemoveImage = function(image){
    removeImage(image)
  }

  const handleFiles = async function(e){
    let files = [];

    if(e.target.files) {
      files = e.target.files;
    } else if (e.dataTransfer) {
      files = e.dataTransfer.files;
    }

    // if(files.length > 4) {
    if((files.length + images.length) > 4) {
      setError("Oops...you can have up to 4 images only.")
      return false;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // Check if the file size is greater than 50MB (50 * 1024 * 1024 bytes)
      if (file.size > 20 * 1024 * 1024) {
        setError('The file size is larger than 20MB. Please select a smaller file.');
        return; // Exit the function to prevent uploading
      }
      const formData = new FormData();
      try {
        const compressedFile = await compressImage({
          file,
          maxWidth: 800,
          maxHeight: 800,
        })
        formData.append('image', compressedFile);
      } catch (errorCompressing) {
        console.error('error compressing avatar image', errorCompressing)
        formData.append('image', file);
      }
      // formData.append('image', file)

      try {
        const fileUploaded = await uploadMessageImage(formData).unwrap();
        addImage(fileUploaded)

      } catch (e) {
        setError('Error uploading one of your images')
        console.log('error uploading file', e)
      }
    }
  }

  return (
    <Box>
      {error && <InfoAlert message={error} callback={() => { setError('')}} severity="error" /> }

      <ImagesPreview images={images} removeFromState={handleRemoveImage} />
      <Uploading isLoading={isLoading} />

      { !isLoading && images.length > 0 && images.length < 4 &&
        <Typography sx={{ margin: '20px 10px 10px 10px'}} component="p" variant="body2">You can upload {4 - images.length} more image{(4 - images.length) > 1 ? 's': ''}</Typography>
      }

      {/*{!isLoading  && images.length === 0 &&*/}
      {!isLoading  && images.length < 4 &&
        <UploadWidget
          formatsText="SVG, PNG, JPG, HEIC (Max. 20MB)"
          multiple={true}
          onDragEnter={handleDrag}
          onChange={handleFiles}
          onDrop={handleDrop}
          dragActive={dragActive}
        />
      }
    </Box>
  )
}