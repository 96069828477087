import { useState } from "react";
import Box from "@mui/material/Box";
import {closeMessageIcon} from "../../svgIcons/icons";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Divider from "../divider/Divider";
import {truncateString} from "../../utils/functions";
import {useUpdateCardMutation} from "../../slices/api/cardApiSlice";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";


import fonts from '../../utils/fonts';

export default function TitleFont({card, closeModal}) {
  return(<>
    <Box sx={{display: 'flex', flexDirection:{ xs: 'column', md: 'row'}, justifyContent: 'space-between', }}>
      <Box onClick={() => {closeModal()}} sx={{display: { xs: 'block', md: 'none'}, textAlign: 'right', '&:hover':{ cursor: 'pointer'} }} >
        {closeMessageIcon}
      </Box>
      <Typography variant="h4" sx={{ padding: '0', fontSize: '30px', lineHeight: '38px'}}>Title Font</Typography>
      <Box onClick={() => {closeModal()}} sx={{display: { xs: 'none', md: 'block'},  float:'right', textAlign: 'right', '&:hover':{ cursor: 'pointer'} }} >
        {closeMessageIcon}
      </Box>
    </Box>
    <Divider />
    <Typography sx={{margin: '10px auto 10px auto'}} variant="body2">Choose a font family for the card's title</Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', textAlign: 'center', justifyContent: 'center'}}>
      { fonts.map(font => {
        if(card?.template?.titleFontFamily) {
          if(font.fontFamily === card?.template?.titleFontFamily) {
            return (<FontPreview closeModal={closeModal} selected={true} card={card} key={font.fontFamily} font={font} />)
          } else {
            return (<FontPreview closeModal={closeModal} selected={false} card={card} key={font.fontFamily} font={font} />)
          }
        } else {
          if(font.fontFamily === "'Inter', sans-serif") {
            return (<FontPreview closeModal={closeModal} selected={true} card={card} key={font.fontFamily} font={font} />)
          } else {
            return (<FontPreview closeModal={closeModal} selected={false} card={card} key={font.fontFamily} font={font} />)
          }
        }
      }) }
    </Box>
  </>)
}

function FontPreview({font, card, selected, closeModal}) {
  const [updateCard,{isError}] = useUpdateCardMutation();
  const [error, setError] = useState('')
  async function updateFontTitle() {
    const cardUpdate = {
      ...card,
      template: {
        ...card.template,
        titleFontFamily: font.fontFamily,
        titleFontSize: font.fontSize,
        titleFontWeight: font.fontWeight,
      }
    }

    try {
      await updateCard(cardUpdate).unwrap();
      closeModal();
    } catch (e) {
      console.log('e',e)
      if (e.originalStatus === 401) {
        setError('Not authorized to update the card. Login or register.')
      } else if (e.originalStatus === 403) {
        setError('Not authorized to update the card. Login or register.')
      }else if(e.originalStatus === 404) {
        setError('Card not found')
      }else {
        setError(`Oops, something went wrong on our end... Please try again`)
      }
    }
  }

  return(
    <>
      <Box onClick={updateFontTitle} sx={{ textAlign: 'center', '&:hover':{ cursor: 'pointer'} }}>
        <Box sx={{
          display: 'flex', '&:hover':{ cursor: 'pointer'},
          alignItems: 'center',
          // width: {
          //   xs: '140px',
          // },
          // height: {
          //   xs: '140px',
          // },
          overflow: 'hidden',
          padding: '10px',
          marginBottom: '15px',
          // height:{xs: '80px', md:'130px'},
          borderRadius: '14.349px',
          border: selected ? '3px solid #7F56D9':'1px solid #D0D5DD',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}}>
          <Box sx={{
            fontFamily: font.fontFamily,
            fontSize: {xs:'16px', md: '24px', lg: '30px'},
            fontWeight: font.fontWeight,
          // }}>{truncateString(card.title)}</Box>
          }}>{font.name}</Box>
        </Box>
        {/*<Typography variant="body2" sx={{ margin: '5px 0 20px 0'}}>{font.name}</Typography>*/}
      </Box>
      {!!error && <InfoSnackbar message={error} severity="error" onClick={() =>  setError('')} callback={() => setError('')}/> }
    </>
  )
}