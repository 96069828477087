import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {ratingStar} from "../../../svgIcons/icons";

export default function CustomerReviews({review}) {
  return (
    <Box sx={{
      minWidth:'300px',
      maxWidth: '600px',
      width:'100%',
      margin: '20px auto',
      background: '#212529',
      padding: '30px',
      borderRadius: '20px',
    }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', marginBottom: '20px'}}>
        {ratingStar}
        {ratingStar}
        {ratingStar}
        {ratingStar}
        {ratingStar}
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Typography
          variant="h1"
          sx={{ color: "white", marginBottom: '20px', letterSpacing:{ xs: '-0.72px'}, fontSize:{xs: '14px', md: '18px'}, lineHeight:{ xs: '16px',md:'22px'}, fontStyle:'italic' }}>
          "{review.review}"
        </Typography>
        <Box sx={{textAlign:'right'}}>
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 400, fontSize:{xs:'12px', md: '14px'} }}>{review.name}</Typography>
          <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 400, fontSize:{xs:'12px', md: '14px'} }}>{review.jobTitle}</Typography>
        </Box>

      </Box>

    </Box>
  )
}