import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardListItem from './CardListItem';
import { useGetCardsQuery} from "../../slices/api/cardApiSlice";
import Button from "@mui/material/Button";
import {resetCardState} from "../../slices/cardSlice";
import {linkPaths} from "../../utils/linkPaths";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import InfoSnackbar from "../../components/infoSnackbar/InfoSnackbar";


export default function CardsList({total = 0}) {
  const [query, setQuery] = useState(``);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const {
    data: cards,
    isSuccess: getCardsIsSuccess,
    isError: getCardsIsError,
  } = useGetCardsQuery(query);


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [query])

  function handleNextPage(){
    setQuery(cards.length > 0 ? `?cursor=${cards[cards.length - 1].id}&next=up` : '')
  }

  function handlePrevPage(){
    setQuery(cards.length > 0 ? `?cursor=${cards[0].id}&next=down` : '')
  }

  function createCard() {
    dispatch(resetCardState())
    return navigate(linkPaths.create.path)
  }

  return (
    <React.Fragment>
    <Box sx={{padding: '20px',display: 'flex',flexWrap: 'wrap', justifyContent: {xs: 'space-between', sm: 'flex-start'}, flexDirection:{xs: 'column', md: 'row'}, gap: {xs: '10px', md: '10px'}  }}>
      {getCardsIsSuccess && cards.map((card, index)=> <CardListItem card={card} key={index} />)}
      {getCardsIsError && <InfoSnackbar message="Error getting the cards" severity="error"/> }
    </Box>
      {getCardsIsSuccess && total === 0 &&
        <Box sx={{ textAlign: 'center'}}>
          <Typography variant="body1" sx={{fontWeight: '500'}}>You haven't created a card yet</Typography>
          <Button onClick={createCard} variant="contained" sx={{ maxWidth: '220px', margin: '10px auto'}}>Create Group Card</Button>
        </Box>
      }
      {total > 12 &&
        <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
          <Button onClick={handlePrevPage} disabled={!query}>Previous page</Button>
          <Button onClick={handleNextPage}>Next page</Button>
        </Box>
      }
    </React.Fragment>
  );
}