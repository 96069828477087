import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {goodCheckMark, goodCheckMarkLight, xCheckMark} from "../../svgIcons/icons";
import {useNavigate} from "react-router-dom";
import {useCreateCardMutation} from "../../slices/api/cardApiSlice";
import {useDispatch, useSelector} from "react-redux";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";
import {useGetTempCredsMutation} from "../../slices/api/authApiSlice";
import {setCredentials} from "../../slices/authSlice";
import {useCreateCheckoutMutation} from "../../slices/api/checkoutApiSlide";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useState} from "react";
import RegisterOption from "./RegisterOption";
import {isCardCreationPage, isValidEmail} from "../../utils/functions";
import {HtmlTooltip} from "../htmlTooltip/HtmlTooltip";
import FeatureTitle from "../featureTitle/FeatureTitle";

export default function PlanOption({card, plan, planSelected, isPricingPage = false, upgradeText = false}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createCard, { isLoading: isCardCreating, error: errorCreatingCard }] = useCreateCardMutation();
  const [createCheckout] = useCreateCheckoutMutation();
  const [getTempCreds, {isLoading: gettingCreds}] = useGetTempCredsMutation();
  const [registrationEmail, setRegistrationEmail] = useState('');
  const [showRegisterOption,setShowRegisterOption] = useState(false)
  const auth = useSelector(state => state.auth); //{ user: {}, token: null, refreshToken: null}

  const planHasBeenSelected = planSelected === plan.id;

  let warningMessage = '';
  if(plan.id === 'FREE_PLAN') {
    if(!planHasBeenSelected) {
      warningMessage = "*Remove premium settings to continue with a free card.";
    }
  }

  async function createGroupCard () {
    //TODO: WE NEED TO CHECK IF THIS IS PART OF THE BUG WHEN PEOPLE HAVE AN EXPIRED SESSION
    if(auth?.user?.id) {
      return await createCard({
        ...card,
        planSelected: plan.id,
      }).unwrap();
    } else {
      try {
        const creds = await getTempCreds({email: card.creatorEmail}).unwrap();
        dispatch(setCredentials(creds))
      } catch (e){
        console.log('error getting temp creds', e)
      }
      return await createCard({
        ...card,
        planSelected: plan.id,
      }).unwrap();
    }

  }

  async function handleContinue() {
    if(isPricingPage) {
      return navigate((`/create/card`))
    }
    try {
      const cardCreated = await createGroupCard()
      if(cardCreated.checkoutSession) {
        if(cardCreated.checkoutSession.clientSecret){
          // if there is a clientSecret then it means we use the embedded checkout page
          // in our app instead of the hosted stripe checkout
          return navigate(`/checkout/${cardCreated.checkoutSession.clientSecret}?cardId=${cardCreated.id}`)
        } else {
          //Here we use the hosted stripe checkout page
          return window.location.replace(cardCreated.checkoutSession)
        }
      }
      navigate(`/card/${cardCreated.id}?success=free`)
    } catch (e) {
      console.log('error creating card',e)
    }
  }

  async function getCheckout(){
    console.log('calling get checkout')
    console.log('card?.creatorEmai',card?.creatorEmai)
    let email = card?.creatorEmail;
    let cardCreated, cardId;

    if(auth?.user?.id) {
      email = auth.user.email;
    }

    if(registrationEmail) {
      email = registrationEmail;
    }
    console.log('email',email)
    console.log('isCardCreationPage()',isCardCreationPage())

    //If no email, show the give me your email and return nothing
    if(!isValidEmail(email) && !isCardCreationPage()){
      console.log('aqui')
      setShowRegisterOption(true);
      // return;
    }

    if(isCardCreationPage()) {
      try {
        cardCreated = await createGroupCard();
      } catch (e) {
        console.log('error creating card',e)
        return;
      }
    }



    const checkoutOptions = {
      email,
      planId: plan.id
    }

    if(cardCreated?.id) {
      checkoutOptions.cardId = cardCreated.id
      cardId = cardCreated.id
    }

    if(card?.id && !cardCreated?.id) {
      checkoutOptions.cardId = card.id
      cardId = card.id
    }

    try {

      const checkoutResult = await createCheckout(checkoutOptions).unwrap();
      if(checkoutResult.clientSecret){
        if(cardId) {
          return navigate(`/checkout/${checkoutResult.clientSecret}?cardId=${cardId}`)
        }

        return navigate(`/checkout/${checkoutResult.clientSecret}`)
      }
    } catch (e) {
      console.log('error creating checkout',e)
    }
  }

  function getFontWeight(index, category) {
    if(index < 2 && category === 'subscription') return 700;
    if(index < 1 && category === 'single') return 700;
     return 400;
  }

  function closeRegistration() {
    setShowRegisterOption(false)
    setRegistrationEmail('')
  }

  const getSingleCardButtonText = () => {
    if(upgradeText) return "Pay and upgrade";
    if(isPricingPage) {
      return "Choose card design";
    }

    if(plan.id !== 'FREE_PLAN') {
      return "Buy Card"
    }

    return "Create Card"
  }

  return (
    // <Box sx={{ width: '100%', marginTop: {xs: 0, md: (plan.id === 'PREMIUM_50') ? '-28px':'0'} , flex: '1 1 0px', boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)', background: planHasBeenSelected && !isPricingPage? 'linear-gradient(90deg, #5D9AF3 0%, #5E19B7 100%)' : '#FFF', padding: planHasBeenSelected? '1px' : 0, border: planHasBeenSelected && !isPricingPage ? 'none': '1px solid #EAECF0', borderRadius: '16px'}}>
    <Box sx={{ width: '100%', maxWidth:'380px', marginTop: {xs: 0, md: (plan.id === 'PREMIUM_50' || plan.id === 'PREMIUM_SUB') ? '-28px':'0'} , flex: '1 1 0px', boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)', background: 'linear-gradient(90deg, #5D9AF3 0%, #5E19B7 100%)', padding: '1px', border: !isPricingPage ? 'none': '1px solid #EAECF0', borderRadius: '16px'}}>
      { plan.id === 'PREMIUM_50' &&
        <Box sx={{background: '#7F56D9', textAlign: 'center', padding: '4px 10px', borderRadius: '16px 16px 0px 0px'}}>
          <Typography variant="body2" sx={{color: '#FCFCFD', fontSize: '14px', fontWeight: 700, lineHeight: '20px'}}>Most picked</Typography>
        </Box>
      }
      { plan.id === 'PREMIUM_SUB' &&
        <Box sx={{background: '#7F56D9', textAlign: 'center', padding: '4px 10px', borderRadius: '16px 16px 0px 0px'}}>
          <Typography variant="body2" sx={{color: '#FCFCFD', fontSize: '14px', fontWeight: 700, lineHeight: '20px'}}>Most picked</Typography>
        </Box>
      }
      <Box sx={{ background: '#FFF', borderRadius: plan.id === 'PREMIUM_50' || plan.id === 'PREMIUM_SUB' ? '0' : '16px 16px 0 0', padding:{ xs: '12px 22px 0 22px', md: '32px 22px 0 22px'}  }}>
        <Box sx={{marginBottom: {xs: '20px'},
          height:{ md: '210px',lg:'150px'} }}>
          <Typography variant='h5' sx={{ marginBottom: '5px', fontSize: {xs: '20px', md: '20px'}, lineHeight: { xs: '24px', md:'30x'}, fontWeight: 600}}>{plan.name}</Typography>
          <Typography variant="body1" sx={{ fontSize: '14px'}}>{plan.description}</Typography>
        </Box>
        <Box sx={{marginBottom: {xs: '40px', md: '10px'}, height: { md: plan.category === 'single' ? '50px':'100px'  } }}>
          {/*{plan.yearlyPrice && <Typography variant="h2" sx={{ fontSize: '14px', lineHeight:'16px', color: '#98A2B3', textDecoration: 'line-through', fontWeight: 400}}>${plan.price} per month</Typography> }*/}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', alignItems: 'end'}}>
            <Typography variant="h2"> ${plan.price}</Typography>
            <Typography variant="body2" sx={{fontSize:{xs: '14px', xl: '16px'},color: '#98A2B3', fontWeight: 500}}> { plan.category === 'subscription' ? '/month' : ''}</Typography>
          </Box>
          {plan.yearlyPrice &&
            <Box sx={{ marginTop: '8px', display: 'flex', justifyContent: 'flex-start', gap: '2px', }}>
              <Typography
                variant="body2"
                sx={{ fontSize:{xs: '14px', xl: '16px'}, color: '#9E77ED', fontWeight: 500}}
              >paid yearly at ${plan.yearlyPrice}</Typography>
            </Box>
          }
        </Box>

        { plan.category === 'subscription' && !showRegisterOption &&
          <Box sx={{ height: isPricingPage ? '130px' : '90px' }}>
            <Button
              id={plan.id}
              data-testid={plan.id}
              disabled={isCardCreating}
              onClick={getCheckout}
              variant="contained"
              color="primary">
              {isPricingPage ? 'Start 7-Day Free Trial' : 'Start Free Trial & Create Card'}
            </Button>
            {/*{isPricingPage && <Button onClick={() =>{ setShowRegisterOption(true)}} variant="outlined" color="secondary"> Send as a gift</Button>}*/}
          </Box>
        }
        { plan.category === 'subscription' && showRegisterOption &&
          <RegisterOption closeRegistration={closeRegistration} email={registrationEmail} setEmail={setRegistrationEmail} callback={getCheckout} /> }

        { plan.category === 'single' && !showRegisterOption &&
        <Box sx={{ height: '90px'}}>
          <Button
            id={plan.id}
            data-testid={plan.id}
            onClick={upgradeText ? getCheckout : handleContinue}
            disabled={isCardCreating}
            variant={plan.id === 'PREMIUM_50' ? 'contained' : "outlined"}
            color={plan.id === 'PREMIUM_50' ? 'primary' : "secondary"}
            sx={{ marginBottom: 0}}>{getSingleCardButtonText()}</Button>
          {/*<Button onClick={handleContinue} disabled={!!warningMessage || isCardCreating} variant="outlined" color="secondary" sx={{ marginBottom: 0}}>Create Group Card</Button>*/}
          {/*{warningMessage && <Typography variant="caption" sx={{ fontSize: '16px'}}>{warningMessage}</Typography> }*/}
        </Box>
        }
        { plan.category === 'single' && showRegisterOption &&
          <RegisterOption title="Enter email for billing" closeRegistration={closeRegistration} email={registrationEmail} setEmail={setRegistrationEmail} callback={getCheckout} /> }

      </Box>
      <Box sx={{ background: '#FFF', padding: '5px 0', borderRadius:'0'}}>
        <Box sx={{ height: '1px', background: '#D0D5DD'}}></Box>
      </Box>
      <Box sx={{ background: '#FFF', padding:{ xs: '12px 12px 22px 12px', md: '32px 16px 32px 16px'}, borderRadius:'0 0 16px 16px'}}>
        <Box sx={{display:{xs:'block', md:'none'}  }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id={`${plan.id}-accordion`}
            >
              <Typography variant="body2" sx={{ margin:0, padding:0,fontWeight: 700, fontSize: '14px', lineHeight: '20px'}}>{plan.featuresTitle}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {plan.features.filter(feature => feature.allowed ).map((feature, index) => <FeatureTitle feature={feature} key={feature.id} fontWeight={getFontWeight(index, plan.category)} icon={index < 3 ? goodCheckMark : goodCheckMarkLight} /> )}
              {plan.features.filter(feature => !feature.allowed ).map((feature, index) =>
                <FeatureTitle feature={feature} key={feature.id} fontWeight={400} icon={xCheckMark} />
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box sx={{display: {xs: 'none', md:'block'} }}>
          <Typography variant="body2" sx={{ fontWeight: 700, fontSize: '14px', lineHeight: '20px'}}>{plan.featuresTitle}</Typography>
          {plan.features.filter(feature => feature.allowed ).map((feature, index) => <FeatureTitle feature={feature} key={feature.id} fontWeight={getFontWeight(index, plan.category)} icon={index < 3 ? goodCheckMark : goodCheckMarkLight} />)}
          {plan.features.filter(feature => !feature.allowed ).map((feature, index) => <FeatureTitle feature={feature} key={feature.id} fontWeight={400} icon={xCheckMark} />)}
        </Box>
      </Box>
      {errorCreatingCard &&
        <InfoSnackbar severity="error" message={errorCreatingCard.data.error} /> }
    </Box>
  )
}
