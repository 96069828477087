import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "../../components/divider/Divider";
import {useState} from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {useResetPasswordRequestMutation} from "../../slices/api/authApiSlice";
import {isValidEmail} from "../../utils/functions";
import InfoSnackbar from "../../components/infoSnackbar/InfoSnackbar";

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [resetPasswordRequest, {isLoading, isSuccess}] = useResetPasswordRequestMutation()
  const [error, setError] = useState('')

  const handleRequest = async (e) => {
    e.preventDefault();
    if(!isValidEmail(email)) {
      setError('Enter a valid email')
    }
    try {
      await resetPasswordRequest({email}).unwrap();
    } catch (e) {
      console.log('error requesting password reset',e)
    }

  }

  return(
    <Grid container maxWidth="lg" sx={{ margin: '0 auto'}}>
      {error && <InfoSnackbar message={error} onClick={() => setError('')} callback={() => setError('')} /> }
      <Grid item xs={12} sx={{ padding: '20px'}}>
        {isSuccess &&
          <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column', margin: '0 auto', maxWidth: '460px'}}>
            <Typography variant="h2" sx={{ marginBottom: '20px'}}>Check your email</Typography>
            <Typography variant="body">We have sent the instructions to your email address</Typography>
          </Box>
        }
        {!isSuccess &&
          <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column', margin: '0 auto', maxWidth: '460px'}}>
            <Typography variant="h2" sx={{ marginBottom: '20px'}}>Forgot your password?</Typography>
            <Typography variant="body">Enter your email to reset your password. We will send you an email with the instructions.</Typography>
            <Typography variant="body2" sx={{  paddingTop: '13px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Your email:</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              sx={{maxWidth: '400px'}}
              id="email"
              name="email"
              value={email}
              onChange={(e) => { setEmail(e.target.value)}}
              autoFocus
            />
            <Button onClick={handleRequest} disabled={isLoading} sx={{maxWidth: '200px'}} variant="contained">Reset Password</Button>
          </Box>
        }

      </Grid>
    </Grid>
  )

}