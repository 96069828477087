import React, {useEffect, useRef, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import Box from '@mui/material/Box';
import CardHeader from './CardHeader';
import Drawer from '@mui/material/Drawer';

import { useGetMessagesQuery } from "../../slices/api/apiSlice";
import { useGetCardQuery } from "../../slices/api/cardApiSlice";
import {Alert} from "@mui/material";

import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";

import ShowMessages from "../../components/showMessages/ShowMessages";
import MoreOptionsButton from "../../components/moreOptionsButton/MoreOptionsButton";
import CardEditOptions from "./settings/CardEditOptions";

import Grid from "@mui/material/Grid";
import {getBackgroundImage, getUrlParamValue, showCard, showPremiumFeature} from "../../utils/functions";
import RequireCardPassword from "./settings/RequireCardPassword";
import InfoSnackbar from "../../components/infoSnackbar/InfoSnackbar";
import CardReadyMessage from "../../components/cardReadyMessage/CardReadyMessage";
import {Loader} from "@giphy/react-components";
import EditBackgroundDialog from "../../components/editBackgroundDialog/EditBackgroundDialog";

export default function GroupCard() {
  const  { cardId } = useParams();
  const [queryInfo, setQueryInfo] = useState({
    cardId,
    page: 1,
    limit: 250  // Example: 10 messages per page
  });
  const [openBackgroundOptions,setOpenBackgroundOptions] =useState(false);

  const [messages, setMessages] = useState([]);  // State to hold messages
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const loadRef = useRef(null); // Reference to the scrolling anchor

  useIntersectionObserver({
    target: loadRef,
    onIntersect: fetchMoreMessages,
    enabled: hasMore,
  });

  function fetchMoreMessages() {
    if (getMessagesIsFetching || !hasMore) return;
    setQueryInfo(prev => ({
      ...prev,
      page: prev.page + 1
    }));
  }



  const [showDrawer, setShowDrawer] = useState(false)

  let location = useLocation();
  const password = getUrlParamValue('password', location);
  let urlParams = `${cardId}`
  if(password) {
    urlParams = `${cardId}?password=${password}`
  }

  const {
    data: cardResponse,
    isLoading: getCardIsLoading,
    isSuccess: getCardIsSuccess,
    isError: getCardIsError,
  } = useGetCardQuery(urlParams)

  let card = {}
  let user = {}
  if(getCardIsSuccess) {
    card = cardResponse.card
    // dispatch(updateCardData(card))
  }
  if(getCardIsSuccess) {
    user = cardResponse.user
  }


  const {
    data: newMessages,
    loading: getMessagesIsLoading,
    isSuccess: gettingMessagesIsSuccess,
    isFetching: getMessagesIsFetching,
    isError: getMessagesIsError,
  } = useGetMessagesQuery(queryInfo, {
    keepPreviousData: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  // useEffect(() => {
  //   if (gettingMessagesIsSuccess && newMessages && newMessages.length > 0) {
  //     const newUniqueMessages = newMessages.filter(newMsg =>
  //       !messages.some(existingMsg => existingMsg.id === newMsg.id)
  //     );
  //     setMessages(prevMessages => [...prevMessages, ...newUniqueMessages]);
  //     if (newMessages.length < queryInfo.limit) {
  //       setHasMore(false);  // No more messages to load
  //     }
  //   }
  // }, [newMessages, gettingMessagesIsSuccess, queryInfo.limit]);


  // useEffect(() => {
  //   if (newMessages?.length < queryInfo.limit) {
  //     setHasMore(false);  // Assume no more messages to fetch if the last fetch returned fewer items than the limit
  //   }
  // }, [newMessages, queryInfo.limit]);




  function openDrawer() {
    setShowDrawer(true)
  }

  function closeDrawer() {
    setShowDrawer(false)
  }



  return (
    // <Box sx={{ width: '100%', height:{xs: 'calc(100vh - 70px)', sm:'calc(100vh - 80px)'}  }}>
    <Box sx={{ width: '100%', height:'auto', minHeight:'100%', backgroundImage: `url(https://storage.googleapis.com/prod-website-images-rlink/blue-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/red-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/orange-vector.svg)`,
      backgroundPosition: 'top right, top 20% left, bottom right',
      backgroundRepeat: 'no-repeat'  }}>
      { getCardIsLoading && <Loader /> }
      {getMessagesIsError && <InfoSnackbar message="Erro getting the messages, please try again" severity="error" /> }
      { getCardIsError &&
        <Alert severity="error">Sorry... there was an error getting this card, please try refreshing the page</Alert>
      }
      { getCardIsSuccess && card.requirePassword && showPremiumFeature(card) &&
        <RequireCardPassword card={card} />
      }
      { getCardIsSuccess  && showCard(card) &&
        <Grid container sx={{minHeight: '100vh'}}>
          {user.canEdit && <Grid item xs={0} md={4} lg={3} xl={3}  sx={{ backgroundColor: '#F9FAFB', display: {xs: 'none', md: 'block'}, minHeight: '100vh', overflow: 'scroll'}}><CardEditOptions card={card} /></Grid>}
          <Grid item xs={12} md={user.canEdit ? 8 : 12 } lg={user.canEdit ? 9 : 12} xl={user.canEdit ? 9 : 12 }  sx={{minHeight: '100vh'}} >
            <Box component='div' className='groupCardContainer' sx={{
              padding: {xs:'12px', md:'30px'},
              // backgroundColor: card.template.cardBackgroundColor ? card.template.cardBackgroundColor : '#FFF',
              backgroundImage: `url(${getBackgroundImage(card)})`,
              backgroundPosition: 'top',
              // backgroundSize: 'cover',
              backgroundSize: {
                xs: 'cover',
                sm: card.template.cardBackgroundImageFullSize ? 'cover': '400px',
              },
              backgroundRepeat: {
                xs: 'repeat',
                md: card.template.cardBackgroundImageFullSize ? 'repeat' : 'repeat',
              },
              width: '100%',
              // minHeight: '100vh',
              // position: 'fixed',
              overflow:'scroll'
            }}>
              {/*{user?.canEdit &&*/}
              {/*  <>*/}
              {/*    <MoreOptionsButton callback={openDrawer} />*/}
              {/*    <Drawer  anchor="left" open={showDrawer} onClose={closeDrawer} >*/}
              {/*      <CardEditOptions closeDrawer={closeDrawer} card={card} />*/}
              {/*    </Drawer>*/}
              {/*  </>*/}
              {/*}*/}
              <CardHeader card={card} messages={newMessages} />
              {getMessagesIsLoading && <Loader />}
              {getMessagesIsFetching && <Loader />}
              {gettingMessagesIsSuccess &&
                <>
                  <ShowMessages messages={newMessages} card={card} />
                  {/*<div ref={loadRef} />/!* Invisible element for infinite scroll trigger *!/*/}
                  {newMessages.length === 0 && user?.canEdit &&
                    <CardReadyMessage card={card} action={() => setOpenBackgroundOptions(true)} />
                  }
                </>
              }
            </Box>
          </Grid>
          {/*<IntroAnimation card={card} />*/}
        </Grid>
      }
      <EditBackgroundDialog card={card} closeDialog={() => {setOpenBackgroundOptions(false)}} open={openBackgroundOptions} />
    </Box>
  )
}