import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import {CloseIcon} from "../../svgIcons/icons";
import Divider from "../divider/Divider";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ActionModal({showActionButtons = true, children, open, title, confirm, cancel, description}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleCancel = () => {
    cancel()
  };

  const handleConfirm = () => {
    confirm()
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth="sm"
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        open={open}
        onClose={handleCancel}
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={{ padding: '30px 30px 20px 30px' }}>
          <Box className="title-and-exit-container" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Typography variant="h4" sx={{ fontSize: '24px', lineHeight: '32px'}}>
              {title}
            </Typography>
            <CloseIcon cb={handleCancel} color="#9E77ED" />
          </Box>
          { description && <Typography variant="body2" sx={{ paddingRight:{xs: '30px', sm: '60px'}  }}>{description}</Typography>}
          <Divider  small={true}/>
          {children}
          {showActionButtons &&
            <DialogActions sx={{ padding: 0, marginTop: '10px'}}>
              <Button sx={{ width: '197px', marginBottom: 0}} variant="contained" onClick={handleConfirm}>
                Save
              </Button>
            </DialogActions>
          }
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
