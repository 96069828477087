import {associateTemplatesWithCategories, associateTemplatesWithSubcategories} from "../../utils/functions";
import {useGetCategoriesQuery} from "../../slices/api/apiSlice";
import Typography from "@mui/material/Typography";
import TemplatePreview from "../templatePreview/TemplatePreview";
import Box from "@mui/material/Box";
import UseAiButton from "../useAiButton/UseAiButton";

export default function ViewTemplatesWithSubcategories({templates, categorySelected}) {
  const {
    data: categories,
    isSuccess: getCategoriesIsSuccess,
  } = useGetCategoriesQuery()

  const templatesWithCategories = associateTemplatesWithSubcategories({templates,categories, categorySelected});


  return(<>
    {getCategoriesIsSuccess &&
      <>
        <Box sx={{display:'flex', flexDirection:'row', gap:'5px', marginBottom:'14px', marginTop:'14px'}}>
          <UseAiButton text="Create with AI" />
          <UseAiButton text="Upload your design" />
        </Box>
        {templatesWithCategories.subcategories.map(sub => {
          return(<Box key={sub.id} sx={{marginBottom: '20px'}}>
            <Typography>{sub.title}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'no-wrap', overflow: 'scroll', gap: '5px'}}>
              {sub.templates.map(template => {
                return <Box key={template.id}><TemplatePreview template={template} category={sub}/></Box>
              })}
            </Box>
          </Box>)
        })}
      </>}

    </>)


}