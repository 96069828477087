
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { addMessagePurpleIcon } from '../../svgIcons/icons';

export default function AddMessageButton({cardId}) {
  const navigate = useNavigate();

  function handleCreateMessage(){
    navigate(`/card/${cardId}/message`)
  }
  
  return(
    <Box>
      <Button sx={{
        maxWidth: '500px',
        borderRadius: '8px',
        background: 'rgba(255, 255, 255, 0.85)',
        '&:hover':{ background: 'rgba(255, 255, 255, 1)',},
        border:  'dotted 2px rgba(102, 112, 133, 1)',
        padding: '24px 24px',
        width: '100%'
      }} onClick={handleCreateMessage}>
          {addMessagePurpleIcon} 
          <Typography 
          variant='body2' 
          sx={{ 
            fontSize: '14px', 
            fontWeight: 600,
            color:'rgba(0, 0, 0, 1)',
            // color:'#667085',
            textTransform: 'none',
            paddingLeft: '10px'}}>ADD YOUR MESSAGE</Typography></Button>
    </Box>
  )
}