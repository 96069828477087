import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TransitionComponent from "../transitionComponent/TransitionComponent";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import {closeMessageIcon} from "../../svgIcons/icons";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import * as React from "react";

import AnimationSelector from "../animationSelector/AnimationSelector";
import Divider from "../divider/Divider";
import AnimationOptions from "../editBackgroundDialog/AnimationOptions";

export default function EditAnimationsDialog({card, closeDialog, open}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  function handleClose() {
    closeDialog();
  }

  return(
    <Dialog
      TransitionComponent={TransitionComponent}
      fullScreen={fullScreen}
      // fullScreen={true}
      maxWidth="sm"
      onClose={handleClose} open={open} >
      <DialogContent sx={{ padding:{ xs: '20px', md: '50px 40px'} }}>
        <>
          <Box sx={{display: 'flex', flexDirection:{ xs: 'column', md: 'row'}, justifyContent: 'space-between', }}>
            <Box onClick={() => {closeDialog()}} sx={{display: { xs: 'block', md: 'none'}, textAlign: 'right', '&:hover':{ cursor: 'pointer'} }} >
              {closeMessageIcon}
            </Box>
            <Typography variant="h4" sx={{ padding: '0', fontSize: '30px', lineHeight: '38px'}}>Edit Intro Animation</Typography>
            <Box onClick={() => {closeDialog()}} sx={{display: { xs: 'none', md: 'block'},  float:'right', textAlign: 'right', '&:hover':{ cursor: 'pointer'} }} >
              {closeMessageIcon}
            </Box>
          </Box>
          <Typography variant="subtitle2">Add delight by playing an intro animation when a recipient views the card.</Typography>
          <Divider />
          {/*<AnimationOptions card={card} closeDialog={closeDialog} />*/}
          <AnimationSelector card={card} closeModal={closeDialog} />
        </>
      </DialogContent>
    </Dialog>
  )
}