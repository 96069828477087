import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import {
  carrot, closeMessageIcon, deleteGroupCardIconEditOptions, editAnimationIcon,
  editBackgroundIcon, editIconIcon, editTitleIcon, eyeIcon, greenEyeIcon,
  happyFaceSvg,
  inviteIcon,
  penIcon, PremiumCrown,
  premiumCrown,
  sendIcon, settingsIconEditOptions } from "../../../svgIcons/icons";
import Button from "@mui/material/Button";
import Divider from '../../../components/divider/Divider';
import CloseIcon from '@mui/icons-material/Close';
import React, {useState} from "react";
import MyUserBlock from "../../../components/myUserBlock/MyUserBlock";
import dayjs from "dayjs";
import Chip from "@mui/material/Chip";
import SendOrSchedule from "./SendOrSchedule";
import InviteContributorsDialog from "../../../components/inviteContributorsDialog/InviteContributorsDialog";
import TitleFontDialog from "../../../components/titleFont/TitleFontDialog";
import EditBackgroundDialog from "../../../components/editBackgroundDialog/EditBackgroundDialog";
import EditAvatarDialog from "../../../components/editAvatarDialog/EditAvatarDialog";
import DeleteCardModal from "../../../components/deleteCardModal/DeleteCardModal";
import CardSettingsDialog from "./CardSettingsDialog";
import EditAnimationsDialog from "../../../components/editAnimationsDialog/EditAnimationsDialog";
import {copyTextToClipboard} from "../../../components/utils/utils";
import CardUpgradeOptionsDialog from "../../../components/cardUpgradeOptionsDialog/CardUpgradeOptionsDialog";
import Alert from "@mui/material/Alert";
import {useGetTotalNumberOfMessagesQuery} from "../../../slices/api/apiSlice";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {hasPremiumFeatures, listCurrentPremiumFeatures} from "../../../utils/functions";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";


export default function CardEditOptions({card, closeDrawer}){
  const [showScheduler, setShowScheduler] = useState(false);
  const [showInviteContributors, setShowInviteContributors] = useState(false);
  const [showCardDeleteWarning, setShowCardDeleteWarning] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showUpgradeOptions, setShowUpgradeOptions] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const successValue = queryParams.get('success');
  let showLoginInfo = false
  if(successValue) {
    switch (successValue) {
      case 'premium50subscription':
        showLoginInfo = true;
        break;
      case 'premiumsubscription':
        showLoginInfo = true;
        break
      case 'ultimatesubscription':
        showLoginInfo = true;
        break;
      default:
        showLoginInfo = false;
        break;
    }
  }


  const user = useSelector(state => state.auth.user)

  const navigate = useNavigate();

  const {
    data: totalMessages,
    isLoading: isLoadingTotal,
    isSuccess: isSuccessTotal,
    isError: isTotalError,
    error: totalError,
  } = useGetTotalNumberOfMessagesQuery(card.id)


  const openViewAsRecipient = () => {
    // window.open(window.location.origin + `/card/${card.id}/asrecipient`, '_blank').focus();
    navigate(`/card/${card.id}/asrecipient`)
  }

  return (
    <Box sx={{ padding: '16px', background: '#F9FAFB', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <Box>
        <Box sx={{display:'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          {getCardBadge(card)}
          <Box onClick={closeDrawer} sx={{display: { xs: 'block', md: 'none'}, textAlign: 'right', '&:hover':{ cursor: 'pointer'} }} >
            {closeMessageIcon}
          </Box>
        </Box>
        {isSuccessTotal &&
          <Box sx={{display:'flex', flexDirection: 'column', justifyContent:'flex-start'}}>
            <Chip id="total-messages-accepted" data-testid="total-messages-accepted" sx={{ marginLeft: '5px'}} label={<Typography variant="body1" sx={{fontSize: '12px'}}>{totalMessages.total}/{card.maxMsg} messages</Typography>} />
          </Box>}
        <ScheduleBlock card={card} showScheduler={() => setShowScheduler(true)}  />
        <Button onClick={() => {setShowInviteContributors(true)}} variant="contained" sx={{ margin: '20px 0', display: {xs: 'none', md:'flex'}, gap:'8px'}}><GroupAddOutlinedIcon fontSize="small" /> Invite contributors</Button>
        {/*{!card.scheduleId &&  card.deliveryStatus !== 'DELIVERED' && <Button onClick={() => {setShowScheduler(true)}} variant="contained" sx={{ margin: '20px 0', display: {xs: 'none', md:'flex'}, gap:'8px'}}>{sendIcon} Send or Schedule</Button>}*/}
        <Button onClick={() => {setShowScheduler(true)}} variant="contained" sx={{ margin: '20px 0', display: {xs: 'none', md:'flex'}, gap:'8px'}}>{sendIcon} Send or Schedule</Button>
        <Divider />
        <DesignOptions card={card} />
        {/*<Button onClick={() => {setShowInviteContributors(true)}} sx={{display:{xs:'none', md:'flex'}, padding: '6px 0', marginBottom:'4px'}}>{inviteIcon}<Typography variant="body1">Invite Contributors</Typography></Button>*/}
        <Button onClick={openViewAsRecipient} sx={{padding: '6px 0', marginBottom:'4px'}}>{editAnimationIcon} <Typography variant="body1">View as Recipient</Typography></Button>
        {card.tier === 'FREE_PLAN' && <Button onClick={() => setShowUpgradeOptions(true)} sx={{padding: '6px 0'}}>{premiumCrown} <Typography variant="body1" sx={{fontWeight: 500}}> Upgrade Card</Typography></Button>}
        {card.tier === 'PREMIUM_50' && <Button onClick={() => setShowUpgradeOptions(true)} sx={{padding: '6px 0'}}>{premiumCrown} <Typography variant="body1" sx={{fontWeight: 500}}> Upgrade Card</Typography></Button>}
        {card.tier === 'PREMIUM' && card.maxMsg < 101 && <Button onClick={() => setShowUpgradeOptions(true)} sx={{padding: '6px 0'}}>{premiumCrown} <Typography variant="body1" sx={{fontWeight: 500}}> Upgrade Card</Typography></Button>}

        {showLoginInfo  && !user?.id &&
        <Box sx={{ margin: '20px 0'}}>
          <Alert
            id="subscription-welcome-message"
            data-testid="subscription-welcome-message"
            icon={<PremiumCrown width="16px" height="16px" />}
            sx={{backgroundColor:'#F9F5FF', color: '#6941C6', fontFamily:"'Inter', sans-serif" }}
            severity="info">
            Thanks for subscribing!

            <Box sx={{marginTop: '12px'}}>Login or register with the email: {card?.creatorEmail} to create more cards with your subscription.</Box>
          </Alert>
        </Box>
        }

        {showLoginInfo  && user?.id &&
          <Box sx={{ margin: '20px 0'}}>
            <Alert
              id="subscription-welcome-message"
              data-testid="subscription-welcome-message"
              icon={<PremiumCrown width="16px" height="16px" />}
              sx={{backgroundColor:'#F9F5FF', color: '#6941C6', fontFamily:"'Inter', sans-serif" }}
              severity="info">
              Thanks for subscribing!
            </Alert>
          </Box>
        }
        {card.tier === 'FREE_PLAN' && hasPremiumFeatures(card) &&
          <Box sx={{ margin: '20px 0'}}>
            <Alert
              id="premium-message-warning"
              data-testid="premium-message-warning"
              icon={<PremiumCrown width="16px" height="16px" /> }
              sx={{backgroundColor:'#F9F5FF', color: '#6941C6', fontFamily:"'Inter', sans-serif" }}
              severity="info">
              Premium features visible to recipient after upgrading to premium card
              <Box sx={{marginTop: '20px'}}>
                 {listCurrentPremiumFeatures(card)}
              </Box>
            </Alert>
          </Box>
        }
      </Box>
      <Box>
        <Button id="settings-button" data-testid="settings-button" onClick={() => {setShowSettings(true)}} sx={{padding: '6px 0', marginBottom:'4px'}}>{settingsIconEditOptions}<Typography variant="body1"> Settings</Typography></Button>
        <Button id="delete-card-button" data-testid="delete-card-button" onClick={() => {setShowCardDeleteWarning(true)} } sx={{padding: '6px 0', marginBottom:'4px'}}>{deleteGroupCardIconEditOptions}<Typography variant="body1"> Delete Group Card</Typography></Button>
        <Divider />
        <MyUserBlock />
      </Box>
      <CardSettingsDialog card={card} open={showSettings} closeDialog={() => setShowSettings(false)}  />
      <SendOrSchedule card={card} open={showScheduler}  closeDialog={() => { setShowScheduler(false)}} />
      <InviteContributorsDialog card={card} closeDialog={() => {setShowInviteContributors(false)}} open={showInviteContributors} />
      <DeleteCardModal card={card} open={showCardDeleteWarning} closeDialog={() => {setShowCardDeleteWarning(false)}} />
      <CardUpgradeOptionsDialog card={card} open={showUpgradeOptions} closeDialog={() => setShowUpgradeOptions(false)} />
    </Box>
  );
}

export function ScheduleBlock({card, showScheduler}) {
  const [copied, setCopied] = useState(false);
  if(!card.schedule && card.deliveryStatus === 'PENDING') {
    return null;
  }

  function copyToClipboard(){
    setCopied(true)
    copyTextToClipboard(`${window.location.href}/mycard`)
    setTimeout(() => {
      setCopied(false)
    },3000)
  }

  return(<>
    <Box id="schedule-block" data-testid="schedule-block" sx={{margin: '10px auto' ,padding: '12px 12px', background: '#F2F4F7', border: '1px solid #EAECF0', borderRadius:'8px'}}>
      {(card.deliveryStatus === 'DELIVERED') && card.viewedByRecipient &&
        <Chip id="delivered-message-viewed" data-testid="delivered-message-viewed" sx={{padding: '8px',marginLeft:'-6px', backgroundColor: '#ECFDF3', marginBottom: '0'}} label={<Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>{greenEyeIcon} <Typography variant="caption" sx={{color: '#027A48', fontWeight: '500'}}>Opened</Typography></Box>} />
      }
      {(card.deliveryStatus === 'DELIVERED') && !card.viewedByRecipient &&
        <Chip id="delivered-message" data-testid="delivered-message" sx={{padding: '8px',marginLeft:'-6px', backgroundColor: '#ECFDF3', marginBottom: '0'}} label={<Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}> <Typography variant="caption" sx={{color: '#027A48', fontWeight: '500'}}>Card Sent</Typography></Box>} />
      }

      {(card.deliveryStatus === 'PENDING') &&
        <Box>
          <Typography variant="body1" sx={{fontSize: '14px', fontWeight: 500, color: '#101828'}}>Card Delivery Schedule</Typography>
          <Typography variant="body1" sx={{fontSize: '14px', fontWeight: 400, color: '#667085'}}>{dayjs(card.schedule).format('MM/DD/YYYY @ hh:mm a')}</Typography>
        </Box>
      }
      { card?.recipients[0]?.email &&
        <Box sx={{ margin: '5px auto'}}>
          <Typography variant="body1" sx={{fontSize: '14px', fontWeight: 500, color: '#101828'}}>Recipient's Email</Typography>
          <Typography variant="body1" sx={{fontSize: '14px', fontWeight: 400, color: '#667085'}}>{card?.recipients[0]?.email}</Typography>
        </Box>
      }
      {card.deliveryStatus === 'DELIVERED' && card?.deliveryDate &&
        <Box sx={{ margin: '5px auto'}}>
          <Typography variant="body1" sx={{fontSize: '14px', fontWeight: 500, color: '#101828'}}>Sent on</Typography>
          <Typography variant="body1" sx={{fontSize: '14px', fontWeight: 400, color: '#667085'}}>{dayjs(card.deliveryDate).format('MM/DD/YYYY @ hh:mm a')}</Typography>
        </Box>
      }
      {card.viewedByRecipient &&
        <Box sx={{ margin: '5px auto'}}>
          <Typography variant="body1" sx={{fontSize: '14px', fontWeight: 500, color: '#101828'}}>Opened on</Typography>
          <Typography variant="body1" sx={{fontSize: '14px', fontWeight: 400, color: '#667085'}}>{dayjs(card.viewedDate).format('MM/DD/YYYY @ hh:mm a')}</Typography>
        </Box>
      }
      {card.deliveryStatus === 'PENDING' &&
        <Box sx={{ display:'flex',justifyContent: 'flex-end', paddingRight: '10px'}}>
          {/*<Button onClick={showScheduler} sx={{fontSize: '14px', fontWeight: 400, color: '#667085', margin: 0}}>Copy Link</Button>*/}
          <Button onClick={showScheduler} sx={{fontSize: '14px', fontWeight: 400, color: '#667085', margin: 0}}>Edit</Button>
        </Box>
      }
      {card.deliveryStatus === 'DELIVERED' &&
        <Box sx={{ display:'flex',justifyContent: 'flex-end', paddingRight: '10px'}}>
          <Button disabled={copied} onClick={copyToClipboard} sx={{fontSize: '14px', fontWeight: 400, color: '#667085', margin: 0}}>{copied ? 'Copied' : 'Copy link to card'}</Button>
        </Box>
      }
    </Box>
  </>)
}
function PremiumBadge() {
  return(
    <Box id="premium-badge" data-testid="premium-badge" sx={{ display: 'flex', flexDirection: 'row', gap: '12px', padding: '8px 0'}}>
      <Box>{premiumCrown}</Box>
      <Typography variant="body1"><Box component="span" sx={{ fontWeight: 700}}>Grand Gathering</Box> Card</Typography>
    </Box>
  )
}

function PremiumGalaBadge() {
  return(
    <Box id="gala-badge" data-testid="gala-badge" sx={{ display: 'flex', flexDirection: 'row', gap: '12px', padding: '8px 0'}}>
      <Box>{premiumCrown}</Box>
      <Typography variant="body1"><Box component="span" sx={{ fontWeight: 700}}>Grand Gala</Box> Card</Typography>
    </Box>
  )
}

function Premium50Badge() {
  return(
    <Box id="50-badge" data-testid="50-badge" sx={{ display: 'flex', flexDirection: 'row', gap: '12px', padding: '8px 0'}}>
      <Box>{premiumCrown}</Box>
      <Typography variant="body1"><Box component="span" sx={{ fontWeight: 700}}>Community</Box> Card</Typography>
    </Box>
  )
}

function FreeBadge() {
  return(
    <Box id="free-badge" data-testid="free-badge" sx={{ display: 'flex', flexDirection: 'row', gap: '12px', padding: '8px 0'}}>
      <Box>{happyFaceSvg}</Box>
      <Typography variant="body1"><Box component="span" sx={{ fontWeight: 700}}>Free</Box> Card</Typography>
    </Box>
  )
}

function getCardBadge(card) {
  if(!!card.tier) {
    switch (card.tier){
      case 'PREMIUM_50':
        return <Premium50Badge />
      case 'PREMIUM':
        return <PremiumBadge />
      case 'PREMIUM_GALA':
        return <PremiumGalaBadge />
      case 'PREMIUM_SUB':
        return <PremiumBadge />
      default:
        return <FreeBadge />
    }
  } else {
    return <FreeBadge />
  }
}

function UpgradeMessage() {
  return(
    <Button id="upgrade-card-button" data-testid="upgrade-card-to-premium" sx={{padding: '6px 0'}}>{premiumCrown} <Typography variant="body1"> Upgrade to <Box component="span" sx={{ fontWeight: 700}}>Premium</Box></Typography></Button>
  )
}

function getUpgradeOptions(card) {
  if(!!card.tier) {
    switch (card.tier){
      case 'FREE':
        return <UpgradeMessage />;
      case 'FREE_PLAN':
        return <UpgradeMessage />;
      default:
        return null
    }
  } else {
    return <UpgradeMessage />;
  }
}

function DesignOptions({card}) {
  const [showOptions, setShowOptions] = useState(true);
  const [openTFDialog, setOpenTFDialog] = useState(false);
  const [openBackground, setOpenBackground] = useState(false);
  const [showAvatarDialog, setShowAvatarDialog] = useState(false);
  const [showAnimationsDialog, setShowAnimationsDialog] = useState(false)


  function toggleShowOptions(){
    setShowOptions(prevState => !prevState)
  }

  return(<>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', '&:hover':{ cursor: 'pointer'}  }} onClick={toggleShowOptions}>
        <Box sx={{ display: 'flex', gap: '12px', padding: '8px 0'}}>
          {penIcon}
          <Typography variant="body1">Edit Design</Typography>
        </Box>
        <Box sx={{ paddingTop: '8px'}}>{showOptions ? <CloseIcon sx={{fontSize: '14px'}}/> : carrot}</Box>
      </Box>
    {showOptions &&
      <Box sx={{ paddingLeft: '40px'}}>
        <Button id="edit-background-button" data-testid="edit-background-button" onClick={() => { setOpenBackground(true)}} sx={{padding: '4px 0 4px 0', marginBottom:'4px'}}>{editBackgroundIcon} <Typography variant="body1">Colors & Background</Typography></Button>
        <Button id="edit-avatats-button" data-testid="edit-avatats-button" onClick={() => setShowAvatarDialog(true)} sx={{padding: '4px 0 4px 0', marginBottom:'4px'}}>{editIconIcon} <Typography variant="body1">Avatars</Typography> <PremiumCrown card={card} width="16px" height="16px" /></Button>
        <Button id="edit-animations-button" data-testid="edit-animations-button" onClick={() => setShowAnimationsDialog(true)} sx={{padding: '4px 0 4px 0', marginBottom:'4px'}}>{eyeIcon}<Typography variant="body1">Intro Animations</Typography><PremiumCrown card={card} width="16px" height="16px" /></Button>
        <Button id="edit-title-font-button" data-testid="edit-title-font-button" onClick={() => {setOpenTFDialog(true)}} sx={{padding: '4px 0 4px 0', marginBottom:'12px'}}>{editTitleIcon} <Typography variant="body1">Title Font</Typography></Button>
      </Box>
    }
    <TitleFontDialog card={card} closeDialog={() => {setOpenTFDialog(false)}} open={openTFDialog} />
    <EditBackgroundDialog card={card} closeDialog={() => {setOpenBackground(false)}} open={openBackground} />
    <EditAvatarDialog closeDialog={() => setShowAvatarDialog(false)} card={card} open={showAvatarDialog} />
    <EditAnimationsDialog closeDialog={() => setShowAnimationsDialog(false)} open={showAnimationsDialog} card={card} />
    </>)
}
