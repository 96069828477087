import { createSlice } from "@reduxjs/toolkit";
const LOCAL_STORAGE_ITEMS_NAME = 'recognitionlink';

let initialState = { user: null, token: null, refreshToken: null };
const localData = window.localStorage.getItem(LOCAL_STORAGE_ITEMS_NAME);
if(localData) {
  try {
    initialState = JSON.parse(localData)
  } catch (e) {
    console.log('error parsing localData',e)
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken, refreshToken } = action.payload;
      try {
        state.user = user;
        state.token = accessToken;
        state.refreshToken = refreshToken;
        window.localStorage.setItem(LOCAL_STORAGE_ITEMS_NAME, JSON.stringify({user, token: accessToken, refreshToken}))
      } catch (e) {
        console.log('error setting the local storage in auth', e)
      }
    },
    logOut: (state, action) => {
      window.localStorage.clear();
      state.user = null;
      state.token = null;
      state.refreshToken = null;
    }
  }
})

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;