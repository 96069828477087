import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  XIcon,
} from "react-share";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


export default function SocialShare({title, url, closeModal}) {

  const iconSize = 54;
  return(
    <Grid container>
      <Grid item xs={12} md={3}>
        <Typography variant="body2" 
            sx={{ paddingTop: '13px', lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Social Links</Typography>
        <Typography variant="body2" 
            sx={{ lineHeight: '20px', fontSize: '14px', color: 'rgba(102, 112, 133, 1)', fontWeight: 500}}>Perfect for posting in your social media
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
          <Box component='div' sx={{
            display:'flex',
            gap: '10px',
            flexWrap: 'wrap',
            justifyContent:'flex-start',
            marginTop: {xs: '30px', md: 0}
          }}>

            <FacebookShareButton  url={url} quote={title}>
              <Box className='socialShareButton'>
              <FacebookIcon size={iconSize} round />
              <Typography variant="body1">Facebook</Typography>
              </Box>
            </FacebookShareButton>

            <TwitterShareButton url={url} via="RecognitionLink.com" hashtags={['#Recognition', '#RecognitionLink']}>
              <Box className='socialShareButton'>
                <XIcon size={iconSize} round />
                <Typography variant="body1">X</Typography>
              </Box>
            </TwitterShareButton>

            <LinkedinShareButton title={title} url={url} summary="View and contribute to this Group Card" source="https://recognitionlink.com">
              <Box className='socialShareButton'>
                <LinkedinIcon size={iconSize} round />
                <Typography variant="body1">LinkedIn</Typography>
              </Box>
            </LinkedinShareButton>

            <WhatsappShareButton title={title} url={url} separator=" | ">
              <Box className='socialShareButton'>
                <WhatsappIcon size={iconSize} round />
                <Typography variant="body1">WhatsApp</Typography>
              </Box>
            </WhatsappShareButton>

          </Box>
      </Grid>
    </Grid>
  )
}