import { Link, NavLink } from 'react-router-dom';
export function TopNavigationLink({to='/', children}) {
    return <NavLink
        className={({ isActive }) =>
            isActive ? 'menuButton buttonGenerated active' : 'menuButton buttonGenerated'
        }
        to={to}>{children}</NavLink>
}

export function PrimaryButton({to = '/', children}){
  return <Link to={to} className="primaryButton">{children}</Link>
}