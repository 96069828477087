import { useState } from "react";
import {Alert, Snackbar} from "@mui/material";
import Slide from "@mui/material/Slide";


function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

export default function InfoSnackbar({message = 'enter a message', severity="error", callback, onClick}){
  const [open , setOpen] = useState(true);

  function onClose() {
    if(callback) {
      callback()
    }
    setOpen(false)
  }
  const vertical = 'top';
  const horizontal = 'center';

  return (
    <Snackbar onClick={onClick} anchorOrigin={{ vertical, horizontal }} sx={{ maxWidth: '400px'}} autoHideDuration={6000} open={open} onClose={onClose} TransitionComponent={TransitionUp} key={message}>
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
}