import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useCreateCardMutation} from "../../../slices/api/cardApiSlice";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import InfoSnackbar from "../../infoSnackbar/InfoSnackbar";

export default function ContinueOption() {
  const [createCard, { isLoading: isCardCreating, error: errorCreatingCard }] = useCreateCardMutation();
  const card = useSelector(state => state.card);
  const navigate = useNavigate();

  async function handleContinue() {
    try {
      const cardCreated = await createCard({
        ...card
      }).unwrap()
      navigate(`/card/${cardCreated.id}`)
    } catch (e) {
      console.log('error creating card', e)
    }
  }

  return (
    <Box sx={{ display:'flex', justifyContent: 'flex-end'}}>
      <Button sx={{maxWidth: {xs: '92%', md: '220px'}, margin: '10px auto 30px auto' }} disabled={isCardCreating} onClick={handleContinue} variant="contained">Continue</Button>
      {errorCreatingCard &&
        <InfoSnackbar severity="error" message={errorCreatingCard.data.error} /> }
    </Box>
  )
}