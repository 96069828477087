
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import {getCurrentRelativePath} from "../../utils/functions";

export default function HaveAccount({small = false}) {
  const navigate = useNavigate();
  function goToLogin() {
    navigate('/login', { state: { from: getCurrentRelativePath()}})
  }

  function goToRegister() {
    navigate('/register', { state: { from: getCurrentRelativePath()}})
  }
  return(
    <Typography variant="body2" sx={{fontSize: '14px', lineHeight: '18px', marginTop: small ? '10px' : '32px'}}>Already have an account?
      <Box onClick={goToLogin} component="span" sx={{color: 'primary.main', '&:hover':{ cursor: 'pointer'}, fontSize: '14px', lineHeight: '18px' }}> Log in</Box> or <Box onClick={goToRegister} component="span" sx={{fontSize: '14px', lineHeight: '18px', color: 'primary.main', '&:hover':{ cursor: 'pointer'}}}>Register for free</Box>
    </Typography>)
}