import { useEffect } from 'react';

export function useIntersectionObserver({ target, onIntersect, threshold = 0.1, rootMargin = '0px', enabled = true }) {
  useEffect(() => {
    if (!enabled) return;

    const observer = new IntersectionObserver(
      entries => entries.forEach(entry => entry.isIntersecting && onIntersect()),
      {
        rootMargin,
        threshold,
      }
    );

    const currentTarget = target.current;
    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  }, [target, onIntersect, threshold, rootMargin, enabled]);

  return;
}
