import React, { useState } from "react";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  CloseEditIconSVG,
  deleteMessageIconMenu,
  EditIconSVG,
  editMessageIconMenu,
  pushPinIcon,
  removeMediaIcon
} from "../../../svgIcons/icons";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import mediaTypes from "../../../utils/mediaTypes";
import {
  useDeleteMessageImageMutation, useDeleteMessageMutation,
  useDeleteMessageVideoMutation,
  useUpdateMessageMutation
} from "../../../slices/api/apiSlice";
import InfoSnackbar from "../../infoSnackbar/InfoSnackbar";
import {getImageFileNameFromURL, getUpdatedMessagePositions} from "../../../utils/functions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import {colorsPallete} from "../../../utils/colors";
import PushPinIcon from '@mui/icons-material/PushPin';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import Divider from "../../divider/Divider";
import {useReorderMessagesMutation} from "../../../slices/api/messageApiSlice";


export default function EditMessageMenu ({message, canEditCard, messages = []}){
  const [updateMessage,{isLoading: updatingMessage, error: errorUpdating, isSuccess: messageUpdated}] = useUpdateMessageMutation();
  const [deleteVideo, {isLoading: isDeleting, error: deleteVideoError, isSuccess: videoDeleted}] = useDeleteMessageVideoMutation();
  const [deleteMessageImage, {isLoading: isDeletingImage, isSuccess: imagesDeleted}] = useDeleteMessageImageMutation();
  const [deleteMessage, {isLoading: isDeletingMessage, error: errorDeletingMessage, isSuccess: messageDeleted}] = useDeleteMessageMutation();
  const [reorderMessages, {error: errorReordering, isSuccess: successReordering }] = useReorderMessagesMutation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteModal, setShowDeleteModal] =useState(false);
  const [showRemoveMediaModal, setShowRemoveMediaModal] = useState(false);
  const open = Boolean(anchorEl);

  const messageCreator = message.canEdit;
  const cardEditor = canEditCard;

  const showItemToBoth = () => {
    if(messageCreator) return true;
    if(cardEditor) return true;
    return false;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function navigateToEditMessage() {
    navigate(`/card/${message.cardId}/message/${message.id}`)
  }

  async function clearMedia() {
    try {
      await updateMessage({ messageId: message.id, media: {type: null, data: []}}).unwrap()
    } catch (e){
      console.log('error updating message', e)
    }
    handleClose();
  }

  async function handleDeleteVideo() {
    handleClose();
    const fileName = getImageFileNameFromURL(message?.media?.data[0]?.fileUrl)
    if(fileName) {
      try {
        await deleteVideo(fileName).unwrap();
        await clearMedia();
      } catch (e) {
        await clearMedia();
        console.log('error deleting video',e)
      }
    }
  }

  async function handleDeleteImages() {
    handleClose();
    for (let i = 0; i < message.media.data.length ; i++) {
      const fileName = getImageFileNameFromURL(message.media.data[i].fileUrl)
      await deleteMessageImage(fileName).unwrap()
    }
    await clearMedia();
  }

  async function handleRemoveMedia() {
    closeMediaModal();
    if(message.media.type === mediaTypes.USER_VIDEO) {
      await handleDeleteVideo();
    } else if (message.media.type === mediaTypes.USER_IMAGE) {
      await handleDeleteImages();
    } else {
      //update message in backend
      handleClose();
      await clearMedia();
    }
  }

  function handleDeleteMessage(){
    // handleClose();
    setShowDeleteModal(true)
  }

  function closeModal() {
    setShowDeleteModal(false);
    handleClose();
  }

  function closeMediaModal() {
    setShowRemoveMediaModal(false);
    handleClose();
  }



  async function deleteThisMessage() {
    try {
      await deleteMessage(message.id).unwrap();
      closeModal();
    } catch (e) {
      console.log('Error deleting message', e)
    }
  }

  async function updatePosition(action) {
    const newPositions = getUpdatedMessagePositions(messages, message.id, action);
    if(newPositions.length === 0) {
      return handleClose();
    }
    try {
      await reorderMessages({messageUpdates: newPositions}).unwrap();
    } catch (e) {
      console.log('error reordering messages',e)
    }

    handleClose();
  }
  async function pinMessage(pinned) {
    try {
      await updateMessage({ messageId: message.id, pinned}).unwrap()
    } catch (e){
      console.log('error updating message', e)
    }
    handleClose();
  }

  return(
    <>
      {messageUpdated && <InfoSnackbar severity="success" message="Message updated" /> }
      {successReordering && <InfoSnackbar severity="success" message="Position updated" /> }
      {videoDeleted && <InfoSnackbar severity="success" message="Media removed" />}
      {imagesDeleted && <InfoSnackbar severity="success" message="Media removed" />}
      {messageDeleted && <InfoSnackbar severity="success" message="Message deleted" />}

      {errorUpdating &&
        <InfoSnackbar severity="error" message={errorUpdating?.data?.error} /> }

      {deleteVideoError &&
        <InfoSnackbar severity="error" message={deleteVideoError?.data?.error} /> }

      {errorReordering &&
        <InfoSnackbar severity="error" message={errorReordering?.data?.error} /> }

      {errorDeletingMessage &&
        <InfoSnackbar severity="error" message={errorDeletingMessage?.data?.error} /> }

      <Box onClick={handleClick} sx={{ position: 'absolute', zIndex: 1, right: '16px', top: '-10px'}}>
        {open ? <CloseEditIconSVG /> : <EditIconSVG />}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {cardEditor && <Typography sx={{paddingLeft: '16px'}} variant="caption">Message position</Typography>}
        { cardEditor && !message.pinned && <MenuItem onClick={() => pinMessage(true)}><PushPinIcon sx={{marginRight: '12px', color: '#344054', width: '16px'}} /> Pin to the top</MenuItem>}
        { cardEditor && message.pinned && <MenuItem onClick={() => pinMessage(false)}><PushPinIcon sx={{marginRight: '12px', color: '#344054', width: '16px'}} /> Unpin</MenuItem>}
        { cardEditor && <MenuItem onClick={() => updatePosition('move-to-top')}><VerticalAlignTopIcon sx={{marginRight: '12px', color: '#344054', width: '16px'}} /> Send to the top</MenuItem>}
        { cardEditor && <MenuItem onClick={() => updatePosition('move-to-bottom')}><VerticalAlignBottomIcon sx={{marginRight: '12px', width: '16px', color: '#344054'}} /> Send to the bottom</MenuItem>}
        { cardEditor && <MenuItem onClick={() => updatePosition('move-one-up')}><ArrowUpwardIcon sx={{marginRight: '12px', color: '#344054', width: '16px'}} /> Move one up</MenuItem>}
        { cardEditor && <MenuItem onClick={() => updatePosition('move-one-down')}><ArrowDownwardIcon sx={{marginRight: '12px', color: '#344054', width: '16px'}} /> Move one down</MenuItem>}
        {cardEditor && <Divider small={true} />}
        {messageCreator && <MenuItem onClick={navigateToEditMessage}><Box component="span" sx={{marginRight: '12px'}}>{editMessageIconMenu}</Box> Edit Message</MenuItem>}
        { message.media.type && messageCreator &&
          <MenuItem onClick={() =>  setShowRemoveMediaModal(true)}><Box component="span" sx={{marginRight: '12px'}}>{removeMediaIcon}</Box> Remove media</MenuItem>}
        <Dialog onClose={closeMediaModal} open={showRemoveMediaModal} fullWidth={true}  maxWidth='sm'>
          <DialogContent>
            <Box sx={{textAlign: 'center'}}>
              <Typography variant='h3'>Are you sure you want to remove the media?</Typography>
              <Typography variant='body2'>This will delete any photos or videos uploaded. The text in the message will stay.</Typography>
              <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', gap: '20px', marginTop: '20px'}}>
                <Button onClick={closeMediaModal} variant='outlined' color="secondary">Cancel</Button>
                <Button variant='contained' onClick={handleRemoveMedia} sx={{
                  background: colorsPallete.errorText,
                  '&:hover': {
                    background: colorsPallete.errorText,
                  }
                }}>Delete</Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>



        {showItemToBoth() && <MenuItem onClick={handleDeleteMessage}><Box component="span" sx={{marginRight: '12px'}}>{deleteMessageIconMenu}</Box> Delete Message</MenuItem>}
        <Dialog onClose={closeModal} open={showDeleteModal} fullWidth={true}  maxWidth='sm'>
          <DialogContent>
            <Box sx={{textAlign: 'center'}}>
              <Typography variant='h3'>Are you sure you want to delete?</Typography>
              <Typography variant='body2'>This will delete the message forever.</Typography>
              <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', gap: '20px', marginTop: '20px'}}>
                <Button onClick={closeModal} variant='outlined' color="secondary">Cancel</Button>
                <Button variant='contained' onClick={deleteThisMessage} sx={{
                  background: colorsPallete.errorText,
                  '&:hover': {
                    background: colorsPallete.errorText,
                  }
                }}>Delete</Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Menu>
    </>
  )
}