import { useDispatch } from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import { useGetTokensQuery } from "../../slices/api/authApiSlice";
import {setCredentials} from "../../slices/authSlice";
import Typography from "@mui/material/Typography";


export default function LoadCredentials() {

  const {
    data,
    isLoading,
    isSuccess,
    isError,
  } = useGetTokensQuery()

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  if(isSuccess) {
    dispatch(setCredentials(data))
    if(location.state?.from?.pathname) {
      return navigate(location.state.from.pathname)
    } else {
      return navigate('/dashboard')
    }
  }

  if(isError) {
    return navigate('/login')
  }

  return (
    <>
      {isLoading && <Typography variant="body2">Working on your log in... Please wait,you will be redirected.</Typography>}
    </>
  )
}