import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";

export default function PaymentConfirmationPage(){
  const auth = useSelector(state => state.auth)
  const isUserLoggedIn = !!auth?.user?.id;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tierValue = queryParams.get('success');
  const cardId = queryParams.get('card_id');
  const sessionId = queryParams.get('session_id');
  const isSubscription = tierValue.includes('subscription');

  const navigate = useNavigate();

  const continueToCard = () => {
    return navigate(`/card/${cardId}?success=${tierValue}`);
  }


  return(<Box sx={{ minHeight: {xs:'calc(100vh - 70px)', md:'calc(100vh - 80px)'}, display:'flex', alignItems:'center', justifyContent:'center'}}>
    <Box sx={{ width: '92%', maxWidth: '420px', border: 'solid 1px #7F56D9',textAlign: 'center', padding:'30px', borderRadius: '16px', marginBottom:'100px'}}>
      <Box sx={{width:'120px', margin:'auto'}}>
        <img width="100%" src="https://storage.googleapis.com/prod-rlink-avatars/birthday/confetti.svg" alt="Thank you" />
      </Box>
      <Typography variant="h4" sx={{marginBottom:'20px'}}>Thank you!</Typography>
      {isSubscription && !isUserLoggedIn && !cardId &&
      <>
        {/*Sub and Not logged in and no card*/}
        <Typography variant="body1" sx={{marginBottom: '20px'}}><b>Your subscription is ready! Use your email to login or register to use it.</b></Typography>
        <Button onClick={() => { navigate(`/login`)}} variant="outlined" color="secondary">Login</Button>
        <Button onClick={() => { navigate(`/register`)}} variant="outlined" color="secondary">Register</Button>
      </>
      }


      {isSubscription && !isUserLoggedIn && cardId &&
      <>
        {/*Sub and Not logged in and  card*/}
        <Typography variant="body1" sx={{marginBottom: '20px'}}><b>Your subscription and card are ready! Use your email to login or register to use subscription.</b></Typography>
        <Button onClick={continueToCard} variant="contained">Continue to card</Button>
      </>
      }

      {isSubscription && isUserLoggedIn && !cardId &&
      <>
        {/*sub and Logged in and not card*/}
        <Typography variant="body1" sx={{marginBottom: '20px'}}>Your subscription is ready! Go to your dashboard to create cards</Typography>
        <Button onClick={() => { navigate(`/dashboard`)}}  variant="contained">Go to Dashboard</Button>
      </>
      }

      {isSubscription && isUserLoggedIn && cardId &&
      <>
        {/*sub and Logged in and card*/}
        <Typography variant="body1" sx={{marginBottom: '20px'}}>Your subscription and card are ready!</Typography>
        <Button onClick={continueToCard}  variant="contained">Continue to card</Button>
      </>
      }

      {!isSubscription && cardId &&
      <>
        {/*Not subscription, card and does not matter logged in*/}
        <Typography variant="body1" sx={{marginBottom: '20px'}}>Your card is ready!</Typography>
        <Button onClick={continueToCard}  variant="contained">Continue to card</Button>
      </>
      }


    </Box>
  </Box>)
}