import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import { templateSelected, categoryUpdated, titleUpdated } from "../../slices/cardSlice";
import {linkPaths} from "../../utils/linkPaths";


export default function UseAiButton({text}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const template = {
    recipientsNameColor: '#FFF',
    cardTitleColor: '#FFF',
    messageTextColor: '#000000',
    messageBackgroundColor: '#FFFFFF',
    cardBackgroundColor: '#FFF',
    cardBackgroundImage: 'https://storage.googleapis.com/prod-rl-templates/default/default_background.png', // { file: File, url: local url}
    cardBackgroundImageFullSize: true,
    isCustomBackgroundImage: false,
    titleShadowColor: "rgba(0,0,0,1)",
    titleXShadow: 2,
    titleYShadow: 2,
    titleBlur: 6,
    titleFontFamily: "'Salsa', cursive",
    titleFontSize: 40,
    titleLetterSpacing: 1,
    titleFontWeight: '400',
    avatarImage: null,
    isCustomAvatar: false,
    showAnimation:false,
    animation: null,
  } //same for ai and upload, make it constant in utils
  const category = {id: '111111111111111111111111', title: 'custom'}



  function handleTemplateSelection() {
    const templateSel= Object.assign({}, template);
    //TODO: check on this, needs to be fixed in database
    templateSel.isCustomBackgroundImage = false;
    delete templateSel.categories;
    delete templateSel.createdAt;
    delete templateSel.updatedAt;
    delete templateSel.id;
    delete templateSel.title;
    dispatch(templateSelected(templateSel));
    dispatch(categoryUpdated({title: category.title, id: category.id}));
    navigate(linkPaths.createCardSettings.path )
  }

  return (
    <Box
      data-testid={template.id} id={template.id}
      onClick={() => { handleTemplateSelection() }}
      component="div"
      sx={{
        width: {
          xs: '154px',
          sm: '180px',
          md: '180px',
          lg: '220px'
        },
        height: {
          xs: '148px',
          sm: '172px',
          md: '170px',
          lg: '210px'
        },
        background: 'linear-gradient(90deg, #5D9AF3 0%, #5E19B7 100%)',
        borderRadius: '14px',
        textAlign: 'center',
        '&:hover': {
          cursor: 'pointer'
        }
      }}>
      <Box sx={{display:'flex', alignItems:'center', height:'100%', justifyContent:'center'}}>
        <Typography variant="body1" sx={{
          color: '#FFF',
          fontWeight: '500',
          fontSize:{xs:'18px'},
          lineHeight:{ xs:'22px'}
        }}
        >{text}</Typography>
      </Box>
    </Box>
  )
}