import AppBar from '@mui/material/AppBar';
import Box from "@mui/material/Box";
import {useNavigate, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import {Alert} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {hasPremiumFeatures, listCurrentPremiumFeatures} from "../../utils/functions";
import {useGetCardQuery} from "../../slices/api/cardApiSlice";
import {setVisibilityFakeMessages} from "../../slices/fakeMessagesSlice";
import {PremiumCrown} from "../../svgIcons/icons";
import React from "react";

export default function AsrecipientBar() {
  const navigate = useNavigate();
  const { cardId } = useParams();
  const dispatch = useDispatch();

  const {
    data: cardResponse,
    isLoading: getCardIsLoading,
    isSuccess: getCardIsSuccess,
    isError: getCardIsError,
  } = useGetCardQuery(cardId)

  let card = {}
  if(getCardIsSuccess) {
    card = cardResponse.card
  }
  const showFakeMessages = useSelector(state => state.showFakeMessages)

  function navigateEdit() {
    return navigate(`/card/${cardId}`)
  }

  function setShowFakeMessages() {
    dispatch(setVisibilityFakeMessages(!showFakeMessages))
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="relative" sx={{
        // height: {xs: '134px', sm: '154px', md: '96px'},
        padding: { xs: '12px 16px 12px 16px' ,md: '12px 20px 12px 20px'},
        boxShadow: 'none',
        borderBottom: 'none',
        display:'flex', flexDirection: {xs:'colum', lg: 'row'}, justifyContent: 'space-between',
        alignItems:'center',
        gap:'10px',
        // maxWidth: 'xl',
        // margin: '0 auto',
      }} >
        <Box sx={{ width:{ xs: '200px', md: '220px', lg: '220px'} }}>
          <img
            onClick={navigateEdit}
            className='logoTopBar'
            alt='RecognitionLink.com'
            src='https://storage.googleapis.com/prod-website-images-rlink/logos/logo-transparent-white-letters.png' />
        </Box>
        <Box sx={{  textAlign: 'center', }}>
          <Typography sx={{color: '#FFF'}} variant="subtitle1">This is how the recipient will see the card.</Typography>
          {getCardIsSuccess && card.tier === 'FREE_PLAN' && hasPremiumFeatures(card) &&
            <Alert
              icon={<PremiumCrown width="16px" height="16px" />}
              sx={{backgroundColor:'#F9F5FF', color: '#6941C6', fontFamily:"'Inter', sans-serif" }}
              severity="info">
              Premium features visible to recipient after upgrading to premium card: {listCurrentPremiumFeatures(card)}
            </Alert> }
        </Box>
        <Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, gap: '8px', justifyContent: 'flex-end'}}>
          <Button startIcon={<MessageOutlinedIcon />} sx={{width: '200px', fontSize: '12px',lineHeight:'14px', marginBottom: 0, maxHeight: '40px', padding: '12px'}} onClick={setShowFakeMessages} variant="outlined" color="secondary">{showFakeMessages ? 'Hide fake messages' : 'Add fake messages'}</Button>
          <Button startIcon={<EditOutlinedIcon />} sx={{width: '200px', fontSize: '12px',lineHeight:'14px', marginBottom: 0, maxHeight: '40px', padding: '12px'}} onClick={navigateEdit} variant="outlined" color="secondary">Back to edit card</Button>
        </Box>
      </AppBar>
    </Box>)
}