import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import {senderIcon} from "../../../svgIcons/icons";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {useSelector} from "react-redux";
import {
  creatorNameUpdated,
  creatorEmailUpdated,
} from "../../../slices/cardSlice";
import HaveAccount from "../../haveAccount/HaveAccount";
import {useEffect, useState} from "react";
import InfoSnackbar from "../../infoSnackbar/InfoSnackbar";

export default function SenderDetails() {
  const dispatch = useDispatch();
  const card = useSelector(state => state.card);
  const user = useSelector(state => state.auth.user);
  const [error, setError] = useState('');

  useEffect(() => {
    if(user?.email) {
      dispatch(creatorEmailUpdated(user.email))
    }

    if(user?.fullName) {
      dispatch(creatorNameUpdated(user.fullName))
    }
  }, []);

  function handleCreatorNameUpdate(e) {
    if(e.target.value.length >= 80) {
      dispatch(creatorNameUpdated(e.target.value.substring(0,80)))
      return setError('Names can only be up to 80 characters log');
    }
    return dispatch(creatorNameUpdated(e.target.value))
  }

  function handleCreatorEmailUpdate(e) {
    if(e.target.value.length >= 254) {
      dispatch(creatorEmailUpdated(e.target.value.substring(0,254)))
      return setError('Emails can only be up to 254 characters log');
    }
    return dispatch(creatorEmailUpdated(e.target.value))
  }


  return(
    <Box>
      {error && <InfoSnackbar message={error} onClick={() =>{setError('')}} callback={() => {setError('')}} severity="error" /> }
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'center',
        marginBottom:{ xs: '30px'},
      }}>
        {senderIcon} <Typography component="h3" variant="h3">Sender details</Typography>
      </Box>
      <Box component="div">
        <Typography variant="body2" sx={{  paddingTop: '0px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Your Name or team's name*</Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="fullName"
          name="fullName"
          value={card.creatorName}
          onChange={handleCreatorNameUpdate}
          autoFocus
          sx={{ marginBottom: {xs: '24px'} }}
        />
        { !user?.email &&
          <>
            <Typography variant="body2" sx={{  paddingTop: '0px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Your Email*</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              value={card.creatorEmail}
              onChange={handleCreatorEmailUpdate}
              type="email"
            />
            <Typography variant="body2" sx={{  paddingTop: '0px',lineHeight: '20px', fontSize: '14px', fontWeight: 400}}>We will email you a handy link to manage this group card.</Typography>
          </>
        }
      </Box>
      {!user?.email && <HaveAccount />}
    </Box>
  )
}