import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import React from "react";

export default function AnimationCategories({category, handleSelection, animationSelected}){
  return(
    <Box sx={{ marginBottom: '20px'}}>
      <Typography variant="body1" sx={{ fontSize:{xs:'14px',md:'16px'}, fontWeight: '500', marginBottom:{xs: '20px', md: '30px'} }}>{category.name}</Typography>
      <Box sx={{ display: 'flex', flexDirection:'row', flexWrap: 'wrap', gap:{ xs: '8px', md: '12px'} }}>
        {category.animations.map(animation => {
          return (
            <Box key={animation.animation} onClick={() => handleSelection(animation.animation)} sx={{
              width:{xs: '100px', md: '120px', lg: '140px'},
              height: {xs: '100px', md: '120px', lg: '140px'},
              '&:hover':{ cursor: 'pointer'},
              border: animationSelected === animation.animation ? '3px solid #7F56D9' : 'none',
            }}>
              <dotlottie-player id={animation.animation} style={{width: '100%'}} src={animation.animation} background="transparent" speed="1"  loop autoplay></dotlottie-player>
            </Box>
          )
        })}
      </Box>
    </Box>)
}