import Box from "@mui/material/Box";
import {goodCheckMarkLight} from "../../svgIcons/icons";
import {HtmlTooltip} from "../htmlTooltip/HtmlTooltip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function FeatureTitle({feature, fontWeight = 400, icon = goodCheckMarkLight}){
  return(<Box sx={{ display:'flex', flexDirection: 'row', alignItems:'center', justifyContent:'space-between'}}>
    <Typography variant="body2" sx={{ margin: '8px', display: 'flex', gap: '12px', alignItems: 'center', fontWeight: fontWeight, fontSize: '14px'}}>{icon} {feature.name}</Typography>
      <HtmlTooltip title={feature.description}><Button sx={{marginBottom: 0}}><InfoOutlinedIcon/></Button></HtmlTooltip>
    </Box>
  )
}