import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { downloadIcon } from '../../../svgIcons/icons';
import Box from "@mui/material/Box";

export default function QrCodeShare({url, closeModal}) {

  return(
    <Grid container>
      <Grid item xs={12} md={3}>
        <Typography variant="body2" 
            sx={{ lineHeight: '20px', fontSize: '14px', color: 'rgba(102, 112, 133, 1)', fontWeight: 500}}>Great for in-person uses or if you plan to print an invitation
        </Typography>
      </Grid>
      <Grid item xs={12} md={9} sx={{ textAlign: 'center'}}>
        <Box sx={{ border: 'solid 1px gray', width: '220px', height: '220px', background:'rgba(0,0,0,0.1)', margin: '20px auto'}}>
          <svg xmlns="http://www.w3.org/2000/svg" space="preserve" xlink="http://www.w3.org/1999/xlink" style={{maxWidth: '100%', height: 'auto'}} width="984" height="984" viewBox="0 0 984 984">
<rect width="984" height="984" fill="#ffffff" x="0" y="0"></rect>
            <g fill="#000000">
<g transform="translate(240,48) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(360,48) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,48) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(528,48) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,48) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,48) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,48) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,48) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,48) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,72) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,72) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(312,72) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,72) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,72) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,72) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,72) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(480,72) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(528,72) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,72) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,72) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,72) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,72) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,72) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,96) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,96) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,96) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,96) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(480,96) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(528,96) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,96) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,96) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(312,120) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(360,120) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,120) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,120) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,120) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,120) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,120) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(600,120) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,120) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,120) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,120) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,120) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,144) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,144) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,144) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,144) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(480,144) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,144) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(600,144) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,144) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,168) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,168) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(312,168) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,168) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,168) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,168) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,168) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,168) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,192) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,192) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(336,192) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,192) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,192) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(480,192) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(528,192) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,192) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,192) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,192) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,192) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,216) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,216) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,216) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,216) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,216) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(480,216) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(504,216) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,216) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(600,216) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,216) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,216) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,216) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(96,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(120,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(144,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(192,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(336,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(504,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(744,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(768,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(792,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(864,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(888,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(912,240) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(48,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(72,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(120,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(168,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(216,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(336,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(600,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(744,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(768,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(864,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(888,264) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(72,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(96,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(120,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(168,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(192,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(216,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(480,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(504,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(768,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(792,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(888,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(912,288) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(168,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(216,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(312,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(336,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(360,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(480,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(504,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(528,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(768,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(840,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(888,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(912,312) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(48,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(72,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(120,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(144,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(168,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(192,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(480,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(744,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(768,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(816,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(840,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(864,336) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(72,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(96,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(144,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(168,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(360,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(504,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(528,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(600,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(744,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(792,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(840,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(864,360) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(144,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(192,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(216,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(336,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(744,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(768,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(792,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(816,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(840,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(912,384) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(48,408) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(72,408) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(96,408) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(360,408) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,408) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,408) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,408) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(480,408) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,408) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(600,408) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,408) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,408) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,408) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(744,408) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(768,408) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(792,408) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(816,408) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(72,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(96,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(144,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(192,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(216,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(336,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(360,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(528,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(744,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(768,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(816,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(864,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(888,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(912,432) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(72,456) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(96,456) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(120,456) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(144,456) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,456) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,456) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(336,456) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(360,456) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,456) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,456) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(504,456) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(528,456) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,456) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,456) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(744,456) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(792,456) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(864,456) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(48,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(168,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(192,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(216,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(360,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(480,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(528,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(600,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(744,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(888,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(912,480) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(48,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(72,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(120,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(144,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(168,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(312,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(336,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(360,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(504,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(816,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(888,504) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(144,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(168,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(192,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(216,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(312,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(504,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(744,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(768,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(792,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(816,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(864,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(912,528) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(48,552) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(72,552) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(96,552) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(144,552) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(168,552) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,552) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(360,552) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,552) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,552) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,552) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(528,552) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,552) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,552) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(600,552) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,552) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(792,552) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(96,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(120,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(192,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(216,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(312,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(336,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(480,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(504,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(744,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(840,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(888,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(912,576) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(48,600) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(96,600) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(144,600) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(216,600) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(312,600) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(336,600) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,600) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,600) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,600) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(600,600) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,600) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(792,600) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(888,600) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(72,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(96,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(144,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(192,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(216,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(312,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(336,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(504,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(528,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(744,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(840,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(864,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(888,624) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(48,648) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(120,648) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(144,648) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(216,648) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,648) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,648) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(312,648) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(504,648) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(528,648) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,648) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,648) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(768,648) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(792,648) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(816,648) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(864,648) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(48,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(144,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(192,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(216,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(528,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(600,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(768,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(816,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(864,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(888,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(912,672) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(48,696) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(120,696) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(144,696) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(216,696) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(312,696) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(336,696) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(360,696) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,696) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,696) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,696) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(504,696) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(600,696) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,696) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(744,696) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(792,696) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(816,696) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(840,696) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(48,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(96,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(168,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(192,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(336,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(504,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(600,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(744,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(768,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(792,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(816,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(840,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(888,720) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,744) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,744) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(312,744) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(336,744) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,744) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,744) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(480,744) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(600,744) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,744) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,744) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(816,744) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(888,744) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,768) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,768) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,768) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,768) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,768) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,768) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,768) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,768) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,768) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,768) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(768,768) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(816,768) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(840,768) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(888,768) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(912,768) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,792) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,792) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,792) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,792) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(600,792) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,792) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,792) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,792) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(816,792) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(888,792) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(912,792) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(312,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(336,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(360,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(504,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(744,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(768,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(792,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(816,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(864,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(888,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(912,816) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,840) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,840) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,840) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,840) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,840) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,840) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(480,840) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,840) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(576,840) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,840) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,840) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,840) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(768,840) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(816,840) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(240,864) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,864) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(312,864) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,864) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(408,864) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(432,864) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,864) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(480,864) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(504,864) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,864) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,864) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,864) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,864) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(744,864) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(792,864) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(840,864) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(912,864) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,888) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(288,888) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(384,888) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(456,888) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,888) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(696,888) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(720,888) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(768,888) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(792,888) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(912,888) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(264,912) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(336,912) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(360,912) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(480,912) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(552,912) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(624,912) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(648,912) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(672,912) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(816,912) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(840,912) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(864,912) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(888,912) scale(4.12)"><rect width="6" height="6"></rect></g>
              <g transform="translate(912,912) scale(4.12)"><rect width="6" height="6"></rect></g>
</g><g><g transform="translate(48,48)" fill="#000000"><g transform="scale(12)"><path d="M0,0v14h14V0H0z M12,12H2V2h10V12z"></path></g></g>
            <g transform="translate(768,48)" fill="#000000"><g transform="scale(12)"><path d="M0,0v14h14V0H0z M12,12H2V2h10V12z"></path></g></g>
            <g transform="translate(48,768)" fill="#000000"><g transform="scale(12)"><path d="M0,0v14h14V0H0z M12,12H2V2h10V12z"></path></g></g>
            <g transform="translate(96,96)" fill="#000000"><g transform="scale(12)"><rect width="6" height="6"></rect></g></g>
            <g transform="translate(816,96)" fill="#000000"><g transform="scale(12)"><rect width="6" height="6"></rect></g></g>
            <g transform="translate(96,816)" fill="#000000"><g transform="scale(12)"><rect width="6" height="6"></rect></g></g></g>
</svg>
        </Box>
        <Button onClick={closeModal} variant='contained'><span style={{paddingTop:'5px', paddingRight: '8px'}}>{downloadIcon}</span>Download</Button>
      </Grid>
    </Grid>
  )
}