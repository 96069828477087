import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "../../components/divider/Divider";
import {useState} from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {useResetPasswordMutation} from "../../slices/api/authApiSlice";
import {useNavigate, useParams} from "react-router-dom";
import InfoSnackbar from "../../components/infoSnackbar/InfoSnackbar";

export default function ResetPassword() {
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [resetPassword,{isLoading, isSuccess}] = useResetPasswordMutation();
  const [error, setError] = useState('')
  const  { token } = useParams();
  const navigate = useNavigate();


  const updatePassword = async (e) => {
    e.preventDefault();
    if(password !== password2) {
      return setError('Passwords must match')
    }
    try {
      await resetPassword({token, password}).unwrap();
    } catch (e) {
      console.log('error resetting password',e)
    }

  }

  return(
    <Grid container maxWidth="lg" sx={{ margin: '0 auto'}}>
      {error && <InfoSnackbar message={error} onClick={() => setError('')} callback={() => setError('')} /> }
      <Grid xs={12} sx={{ padding: '20px'}}>
        {isSuccess &&
          <Box sx={{ textAlign:'center', padding: '20px', display: 'flex', flexDirection: 'column', margin: '0 auto', maxWidth: '460px'}}>
            <Typography variant="h2" sx={{ marginBottom: '20px'}}>Password was updated</Typography>
            <Button onClick={() => navigate('/login')} variant="text">Click here to login</Button>
          </Box>
        }

        {!isSuccess &&
          <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column', margin: '0 auto', maxWidth: '460px'}}>
            <Typography variant="h2" sx={{ marginBottom: '20px'}}>Enter your new password</Typography>
            <Typography variant="body2" sx={{  paddingTop: '13px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Your new password:</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              sx={{maxWidth: '400px'}}
              id="password"
              name="password"
              value={password}
              onChange={(e) => { setPassword(e.target.value)}}
              type="password"
            />
            <Typography variant="body2" sx={{  paddingTop: '13px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Confirm new password:</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              sx={{maxWidth: '400px'}}
              id="password2"
              name="password2"
              value={password2}
              onChange={(e) => { setPassword2(e.target.value)}}
              type="password"
            />
            <Button onClick={updatePassword} disabled={isLoading} sx={{maxWidth: '200px', marginTop:'20px'}} variant="contained">Update Password</Button>
          </Box>
        }
      </Grid>
    </Grid>
  )

}