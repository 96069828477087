import Grid from "@mui/material/Grid";
import PurplePill from "../../../components/purplePill/PurplePill";
import TitleText from "../../../components/titleText/TitleText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {CreateGroupCardGraph, InviteOtherGraph, ShareWithOthersGraph} from "../../../svgIcons/icons";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {linkPaths} from "../../../utils/linkPaths";
import {useDispatch} from "react-redux";
import {resetCardState} from "../../../slices/cardSlice";
import VideoPlayer from "../../../components/videoPlayer/VideoPlayer";
import Divider from "../../../components/divider/Divider";

export default function HowItWorksSection(){
  const navigate = useNavigate();
  const dispatch = useDispatch()

  function goCreateCard() {
    dispatch(resetCardState())
    navigate(linkPaths.create.path)
  }
  return (
    <Box sx={{ paddingBottom: {xs:'20px', md:'60px'}, width: '100%'}}>
      <Grid container maxWidth="lg" sx={{margin:'50px auto 0 auto',padding: { xs: '40px 17px'}}}>
        <Grid item xs={12}>
          <PurplePill maxWidth='180px' >Watch how it works</PurplePill>
          <TitleText align="center">Create, Collaborate, and Send it!</TitleText>
          <Typography variant="subtitle2" sx={{ textAlign:{ xs: 'center'}, marginBottom:{xs:'30px', md: '60px'} }}>Easily create group cards, invite others to contribute messages, photos, and videos, and share the joy with the recipient.</Typography>
        </Grid>
        <Grid item xs={12} md={6} >
          <Box sx={{width:'300px', margin: '0 auto'}}>
            <VideoPlayer
              coverImage={true}
              width="300px"
              height="534px"
              src="https://storage.googleapis.com/prod-website-images-rlink/videos/how_it_works_1.mp4"/>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ paddingTop:{xs:'40px', md: '40px'}, width:{xs:'80%', sm:'60%', md:'60%'}, margin: '0 auto', textAlign:{ xs:'center'} }}>
            <Typography variant="subtitle1">1. Create a Card</Typography>
            <Typography variant="body2">Choose from our diverse card designs, upload your own or use AI to generate a one-of-a-kind design.</Typography>
           <Divider small={true} lineColor="#7F56D9" />
            <Typography variant="subtitle1">2. Invite contributors</Typography>
            <Typography variant="body2">Multiple ways to invite everyone to share their messages.</Typography>
            <Divider small={true} lineColor="#7F56D9" />
            <Typography variant="subtitle1">3. Send it over!</Typography>
            <Typography variant="body2">Choose your method of delivery: share a link, by email or schedule a delivery date.</Typography>
            <Button id="create-card-hiw-button" onClick={goCreateCard}
                    sx={{ maxWidth: { xs: '100%', sm: '200px'}, margin: {xs:'20px auto', md:'40px auto'}, fontWeight: 700}}
                    variant="contained" color="primary">Create Group Card</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}