import { apiSlice } from "./apiSlice";

export const newsletterApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    signupToNewsletter: builder.mutation({
      query: (body) => {
        return {
          url: `/newsletter`,
          method: 'POST',
          body: {...body}
        }
      },
    })
  })
});

export const { useSignupToNewsletterMutation } = newsletterApiSlice ;