import { apiSlice } from "./apiSlice";
import {templatesApiSlice} from "./templatesApiSlice";

export const checkoutApiSlide = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createCheckout: builder.mutation({
      query: (body) => {
        return {
          url: `/checkout/`,
          method: 'POST',
          body: {...body}
        }
      },
    })
  })
});

export const { useCreateCheckoutMutation } = templatesApiSlice ;