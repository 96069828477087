import React, { useState } from 'react';
import Button from '@mui/material/Button';
import RemindersList from './RemindersList';
import EmptyRemindersList from './EmptyRemindersList';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import AddEditReminder from './AddEditReminder';
import ConfirmDelete from './CofirmDelete';

export default function Reminders({reminders =[]}){
  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(true)
  const thereAreReminders = reminders.length > 0;
  
  function handleClose() {
    setOpen(false)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setOpenDelete(false)
  }

  let Reminders = <EmptyRemindersList createReminderAction={handleClickOpen} />
  
  if(thereAreReminders) {
    Reminders = <RemindersList reminders={reminders} />
  }

  return(
    <React.Fragment>
      <Button variant='contained' sx={{ marginBottom:'20px'}} onClick={handleClickOpen}>Create Reminder</Button>
      {Reminders}
      <Dialog onClose={handleClose} open={open} fullWidth={true}  maxWidth='lg'>
        <DialogContent>
          <AddEditReminder closeModal={handleClose} />
        </DialogContent>
      </Dialog>
      <Dialog onClose={handleCloseDelete} open={openDelete} fullWidth={true}  maxWidth='sm'>
        <DialogContent>
          <ConfirmDelete closeModal={handleCloseDelete} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}