import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import {useState} from "react";
import {emailsToString, extractEmails, isValidEmail, removeFromArray, subtractStrings} from "../../utils/functions";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function EmailCollector({emails, setEmails, emailsArray, setEmailsArray}) {

  const handleEmails = (e) => {
    e.preventDefault();
    setEmailsArray(extractEmails(e.target.value))
    setEmails(e.target.value)
  }

  const deleteEmail =(email) => {
    const finalEmails = removeFromArray(emailsArray,email)
    setEmails(emailsToString(finalEmails))
    setEmailsArray(finalEmails)
  }

  function EmailChip({email}){
    if(email === '') return null;
    if(isValidEmail(email)) return <Chip onClick={() => { deleteEmail(email)}} sx={{margin: '5px 2px'}} variant="outlined" icon={<DeleteForeverIcon fontSize="small" variant="outlined" />} label={email} color="primary" />
    return <Chip onClick={() => { deleteEmail(email)}} sx={{margin: '5px 2px'}} variant="outlined" icon={<DeleteForeverIcon fontSize="small" variant="outlined" sx={{width: '10px'}}/>}  label={email} color="error" />;
  }

  return(
    <Box>
      <Box>
        {emailsArray.map((email, index) => <EmailChip key={`${email}-${index}`} email={email} />)}
      </Box>
      <TextField
        margin="normal"
        required
        fullWidth
        id="emails"
        name="emails"
        multiline
        value={emails}
        onChange={handleEmails}
      />
    </Box>
  )
}