import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {useState} from "react";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";
import {isValidEmail} from "../../utils/functions";
import Button from "@mui/material/Button";

export default function RegisterOption({title ="Enter email address for subscription",email,setEmail, callback, closeRegistration}){
  const [error, setError] = useState('');

  const updateEmail = (e) => {
    if(e.target.value.length >= 254) {
      setEmail(e.target.value.substring(0,254))
      return setError('Emails can only be up to 254 characters log');
    }
    setEmail(e.target.value)
  }

  const validEmail = isValidEmail(email);

  return(
    <Box>
      {error && <InfoSnackbar message={error} callback={() => setError('')} />  }
      <Typography variant="body2" sx={{  paddingTop: '0px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>{title}</Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="registrationEmail"
        name="fullName"
        value={email}
        onChange={updateEmail}
        autoFocus
        type="email"
        sx={{ marginBottom: {xs: '24px'} }}
      />
      <Box sx={{ display:'flex', flexDirection:'column'}}>
        <Button onClick={callback} variant="contained" disabled={!validEmail}>Checkout</Button>
        <Button sx={{fontSize:'12px'}} onClick={closeRegistration} variant="text" >Cancel</Button>
      </Box>
    </Box>
  )
}