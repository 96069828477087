import { useSelector } from "react-redux";
import {useGetPlansQuery} from "../../../slices/api/apiSlice";
import Box from "@mui/material/Box";
import {choosePlanIcon, senderIcon} from "../../../svgIcons/icons";
import Typography from "@mui/material/Typography";
import PlanOption from "../../planOption/PlanOption";
import {Alert} from "@mui/material";
import {useState} from "react";
import {getRelevantPlans, getRelevantPurchasePlans} from "../../../utils/functions";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

export default function CardPlanSelection({isPricingPage = false}){
  const {
    data: plans,
    isLoading: getPlansIsLoading,
    isSuccess: getPlansIsSuccess,
    isError: getPlansIsError,
  } = useGetPlansQuery()

  const card = useSelector(state => state.card)

  const [planCategorySelected, setPlanCategorySelected] = useState('single'); //possible 'single' and 'subscription'

  const settingsConfig = useSelector(state => state.card.settings);
  let planSelected = 'FREE_PLAN'
  if(settingsConfig["multiple-card-admins"]) {
    planSelected = 'PREMIUM_SUB'
  } else if(
    settingsConfig["slide-show"] ||
    settingsConfig["animated-intro"] ||
    settingsConfig["video-in-messages"] ||
    settingsConfig["custom-background-image"] ||
    settingsConfig["logo-photo-avatar"] ||
    settingsConfig["gift-card"] ||
    settingsConfig["admin-tools"] ||
    settingsConfig["video-card-download"]
  ) {
    planSelected = 'PREMIUM'
  } else {
    planSelected = 'FREE_PLAN'
  }

  if(getPlansIsLoading) {
    return null;
  }

  const relevantPlans = getRelevantPurchasePlans(plans, card, planCategorySelected);

  function showSingleCardOptions(){
    setPlanCategorySelected('single')
  }

  function showSubscriptionOptions(){
    setPlanCategorySelected('subscription')
  }


  return (
    <Box>
      {!isPricingPage &&
        <Box sx={{alignItems: 'center',display: 'flex', flexDirection: 'row', gap: '10px'}}>
          {choosePlanIcon}
          <Typography component="h3" variant="h3">Card Tier Options</Typography>
        </Box>
      }
      <Box sx={{ width: '100%', overflowX: 'scroll', paddingTop: '20px', paddingBottom: '60px'}}>
        { getPlansIsError && <Alert severity="error">So sorry... there was an error, please try again by reloading the page.</Alert> }
        { getPlansIsSuccess &&
          <Box sx={{ textAlign:'center', marginBottom:'20px'}}>
            <Box sx={{marginTop: '20px auto 0 auto', display:'flex', flexDirection: 'row', width:'100%', justifyContent:'center'}}>
              <Button onClick={showSingleCardOptions} sx={{'&:hover':{ border:0},marginBottom: 0,borderRadius: 0, width:{xs:'160px', sm:'200px', border:0, fontWeight:'700', fontSize:'18px'}}} variant={planCategorySelected === 'single' ? "contained" : "outlined"} color="secondary">Single card</Button>
              <Button onClick={showSubscriptionOptions} sx={{'&:hover':{ border:0},marginBottom: 0,borderRadius: 0, width:{xs:'160px', sm:'200px', border:0, fontWeight:'700', fontSize:'18px'}}}  variant={planCategorySelected === 'subscription' ? "contained" : "outlined"} color="secondary">Subscriptions</Button>
            </Box>
            <Typography variant="body2" sx={{marginTop:'10px'}}>{planCategorySelected === 'single' ? 'One-time payment':'Recurring payment'}</Typography>
          </Box>

        }
        { getPlansIsSuccess &&
          <Box
            data-testid="list-of-plans"
            id="list-of-plans"
            sx={{
            display:'flex',
            width: '100%',
            alignItems: {xs: 'center', md: 'flex-start'},
            justifyContent: {md: 'center'},
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            gap: {
              xs: '26px',
              sm: '20px',
              md: '12px'
            },
            padding: {
              xs: '10px',
              md: '30px',
            },
          }}>
            {relevantPlans.map(plan => {
              return <PlanOption
                card={card}
                key={plan.id}
                planSelected={planSelected}
                plan={plan}
                isPricingPage={isPricingPage}
              />
            })}
          </Box>
        }
      </Box>
    </Box>
  )
}