import { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {colorsPallete} from "../../utils/colors";
import Dialog from "@mui/material/Dialog";
import React from "react";
import {useDeleteCardMutation} from "../../slices/api/cardApiSlice";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

export default function DeleteCardModal({card, closeDialog, open, cb}){
  const [deleteCard, {isError: deleteError, isLoading: isDeleting}] = useDeleteCardMutation();
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const user = useSelector(state=>{ return state.auth.user })

  async function handleDeleteCard(){
    try {
      await deleteCard(card.id).unwrap();
      if(cb) {
        return cb()
      } else {
        if(user && user?.id) {
          return navigate(('/dashboard'))
        }
        navigate('/')
      }
    } catch (e) {
      setError('Error deleting card. Please try again.')
    }
  }

  return(
    <Dialog onClose={closeDialog} open={open} fullWidth={true}  maxWidth='sm'>
      <DialogContent>
        <Box sx={{textAlign: 'center'}}>
          <Typography variant='h3' sx={{ margin: '30px'}}>Are you sure you want to delete this card?</Typography>
          {/*{!cb && <Typography variant="body2" sx={{ margin: '20px 0'}}>You will be redirected to the home page after deleting.</Typography>}*/}
          {isDeleting && <Typography variant="body2">Deleting...</Typography> }
          <Box sx={{ display: 'flex', alignItems: 'top', gap: '20px', marginTop: '20px', justifyContent: 'flex-end'}}>
            <Button sx={{ height: '50px', maxWidth: '100px'}} onClick={closeDialog} variant='outlined' color="secondary">Cancel</Button>
            <Box>
              <Button variant='outlined'
                      onClick={handleDeleteCard}
                      disabled={isDeleting}
                      color="secondary"
                      sx={{
                        background: colorsPallete.errorText,
                        maxWidth: '120px',
                        border: `1px solid ${colorsPallete.errorText}`,
                        color: '#FFF',
                        '&:hover': {
                          background: colorsPallete.errorText,
                        }
                      }}>Delete</Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      {error && <InfoSnackbar onClick={() =>{setError('')}} severity="error" message={error} /> }
    </Dialog>
  )
}