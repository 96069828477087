import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import {linkPaths} from "../../utils/linkPaths";
import {getImageFileNameFromURL, isDefaultBackground, showPremiumFeature} from "../../utils/functions";
import { useDeleteCustomBackgroundImageByUserMutation } from "../../slices/api/apiSlice";
import {deleteBackgroundImageSetByUser} from "../../slices/cardSlice";
import {cardBackgroundImageRemoved} from "../../slices/templateSlice";
import GradientButton from "../gradientButton/GradientButton";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import IntroAnimation from "../introAnimation/IntroAnimation";
import InviteContributorsButton from "../../pages/groupCard/InviteContributorsButton";
import React from "react";

export default function CardPreview(){
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const card = useSelector(state => state.card );
  const template = card.template;
  const [deleteCustomerBackgroundImageByUser, {isLoading: deletingCustomerBackgroundImageByUser}] = useDeleteCustomBackgroundImageByUserMutation()

  if (isDefaultBackground(template?.cardBackgroundImage)) {
    return null;
  }
  async function handleDeleteImage() {
    try {
      await deleteCustomerBackgroundImageByUser(getImageFileNameFromURL(template.cardBackgroundImage)).unwrap();
      dispatch(deleteBackgroundImageSetByUser())
    } catch (e) {
      console.log('error', e)
      if(e.status === 404) {
        dispatch(deleteBackgroundImageSetByUser())
      }
    }
  }
  const handlePickAnotherCard = async ()=> {
    // Check if current background image is custom, delete it before navigating
    if(template.isCustomBackgroundImage) {
      await handleDeleteImage();
    }
    navigate(linkPaths.create.path)
  }

  return(
    <Box component="div" sx={{
      width: '100%',
      margin: 'auto',
      maxWidth: {
        xs: '400px',
        sm: '360px',
        lg: '420px',
      },
    }}>
      <Box component='div' className='groupCardContainer' sx={{
        padding: {xs:'30px', sm: '15px ', md: '15px 15px 160px', lg: '15px 15px 200px'},
        backgroundImage: `url(${card.template.cardBackgroundImage})`,
        backgroundPosition: 'center',
        // backgroundSize: 'cover',
        backgroundSize: card.template.cardBackgroundImageFullSize ? 'cover': '400px',
        backgroundRepeat: card.template.cardBackgroundImageFullSize ? 'no-repeat' : 'repeat',
        width: '100%',
        height: '100%',
        minHeight: {xs: '220px', md: '270px', lg: '370px'},
        // position: 'fixed',
        overflow:'scroll',
        borderRadius: '14px'
      }}>
        <Grid container>
          {/*{card?.template?.animation  && <IntroAnimation card={card} />}*/}
          <Grid item xs={12} sm={8} md={8}>
            <Box sx={{
              // background: {xs: 'none', sm: `radial-gradient(37.05% 49.04% at 49.16% 49.96%, ${card.template.titleShadowColor} 0%, rgba(255, 255, 255, 0.00) 100%)`},
              display: 'flex', flexDirection:{ xs: 'column', sm: 'row'}, alignItems: 'center' }}>
              { card.template.avatarImage &&  <Avatar sx={{ width: {xs: 50}, height: {xs: 50}, margin: { xs: '0 auto', sm:'0'} }} src={card.template.avatarImage} /> }
              <Box sx={{ paddingLeft: {xs: 0, sm: '10px'}, display: 'flex', flexDirection:'column', textAlign: {xs: 'center', sm: 'left', md: 'left'} }}>
                <Typography variant='h3' sx={{ fontSize: '80%', textShadow: `#000000 ${card.template.titleXShadow}px ${card.template.titleXShadow}px ${card.template.titleBlur}px`, marginBottom: '0px',color: card.template.recipientsNameColor,}}>
                  { card.recipients.length > 0 && card.recipients[0]?.fullName !== '' ? `${card.recipients[0].fullName},`: ''}</Typography>
                <Typography
                  variant='h3'
                  sx={{
                    fontWeight: card.template.titleFontWeight,
                    marginBottom: '0px',
                    letterSpacing: card.template.titleLetterSpacing,
                    color:card.template.cardTitleColor,
                    fontFamily: card.template.titleFontFamily,
                    fontStyle: card.template.titleFontStyle,
                    // fontSize: {xs:'30px', md:`${card.template.titleFontSize}px`},
                    // lineHeight:{ xs: '34px', md: `${Number(card.template.titleFontSize) + 12}px`},
                    fontSize: '100%',
                    lineHeight:'120%',
                    textShadow: `#000000 ${card.template.titleXShadow}px ${card.template.titleYShadow}px ${card.template.titleBlur}px`,
                  }}>{card.title}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} >
            <Box sx={{ textAlign: { xs: 'center', sm: 'right'}, margin: '0px 0px 10px 0px'}}>
              {card.creatorName && <Typography variant='h3' sx={{ fontSize: '80%', lineHeight: '160%', textShadow: `#000000 ${card.template.titleXShadow}px ${card.template.titleYShadow}px ${card.template.titleBlur}px`, color: card.template.recipientsNameColor}}>From: </Typography>}
              {card.creatorName && <Typography variant='h3' sx={{ fontSize: '80%', lineHeight: '160%', textShadow: `#000000 ${card.template.titleXShadow}px ${card.template.titleYShadow}px ${card.template.titleBlur}px`, color: card.template.recipientsNameColor}}><b>{card.creatorName}</b></Typography>}
            </Box>
          </Grid>
        </Grid>

        {/*{template.cardBackgroundImageFullSize && <img src={template.cardBackgroundImage} style={{ borderRadius: '14px'}} width="100%" alt="Card preview" />}*/}

        {!template.cardBackgroundImage &&
          <Box sx={{ textAlign: 'center', marginTop: '20%'}}>
            <Typography variant="body1" sx={{marginBottom: '10px'}}>Oops... You have no selected a card yet</Typography>
            <GradientButton maxWidth="200px"  onClick={handlePickAnotherCard} text="Choose a card" />
          </Box>

        }

      </Box>
      {/*{template.cardBackgroundImage &&*/}
      {/*  <Box sx={{ marginTop: '20px'}}>*/}
      {/*    <Button size="small"  variant="text" color="secondary" onClick={handlePickAnotherCard}>*/}
      {/*      Pick another card*/}
      {/*    </Button>*/}
      {/*  </Box>*/}
      {/*}*/}
    </Box>
  )
}