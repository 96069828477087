import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import CreationSteps from "../../components/creationSteps/CreationSteps";
import {linkPaths} from "../../utils/linkPaths";
import CategoriesDisplay from "../../components/categoriesDisplay/CategoriesDisplay";
import CategoriesDropdown from "../../components/categoriesDropdown/CategoriesDropdown";
import Box from "@mui/material/Box";
import {useEffect} from "react";

export default function CreateCardCategories() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const steps = [
    {id: 'step1', screen: 'pickDesign', status: 'active', lastStep: false, title: 'Pick your design', description: 'Choose a card from the catalogue or use AI', cb:() => {navigate(linkPaths.create.path)} },
    {id: 'step2', screen: 'cardSettings', status: 'notStarted', lastStep: false, title: 'Fill card details', description:'Who is receiving it', cb:() => {navigate(linkPaths.createCardSettings.path)} },
    {id: 'step3', screen: 'sendCard', status: 'notStarted', lastStep: true, title: 'Add messages and send it', description:'Send the card to contributors and recipient' },
  ]

  return(
    <Grid container sx={{minHeight: { xs: 'calc(100% - 70px)', sm: 'calc(100% - 80px)'} }}>
      <Grid item xs={12} md={3} >
        <CreationSteps steps={steps} />
      </Grid>
      <Grid item xs={12} md={9} sx={{ minHeight: '100%', overflowY: 'auto', padding: '0 0 0 0'}}>
        {/*<PickTemplateCategory />*/}
        <CategoriesDropdown />
        <Box sx={{ marginTop: {xs: '20px', md: '50px'} }}>
          <CategoriesDisplay titleColor="#101828" />
        </Box>
      </Grid>
    </Grid>
  )
}