import { apiSlice } from "./apiSlice";

export const templatesApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllTemplates: builder.query({
      query: () => `/templates`,
      providesTags:['Templates'],
    }),
    getTemplate: builder.query({
      query: (templateId) => `/templates/${templateId}`,
      providesTags:['Template'],
    }),
    updateTemplate: builder.mutation({
      query: (body) => {
        return {
          url: `/templates/${body.id}`,
          method: 'PUT',
          body: {...body}
        }
      },
      invalidatesTags:['Template', 'Templates'],
    })
  })
});

export const { useGetAllTemplatesQuery, useGetTemplateQuery, useUpdateTemplateMutation } = templatesApiSlice ;