import Box from "@mui/material/Box";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import faq from '../../utils/faq';

export default function FAQ() {
  return(<Box>
    {faq.map(item => {
      return(<Accordion key={item.question}>

        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="body1" sx={{fontWeight: 500}}>{item.question}</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Typography variant="body2">
            {item.answer}
          </Typography>
        </AccordionDetails>

      </Accordion>)
    })}
  </Box>)
}