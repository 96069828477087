import React from "react";
import Box  from "@mui/material/Box";
import Typography  from "@mui/material/Typography";
import ReminderListItem from "./ReminderListItem";

export default function RemindersList({reminders}) {
  return(
    <React.Fragment>
      <Box component='div' className='tableContainer'>
      <Box component='div' className='tableHeader'>
        <Box sx={{width:'40%'}}>
          <Typography component='p' variant='caption' sx={{fontWeight: 500, color: 'rgba(102, 112, 133, 1)'}}>Recipient/Ocassion</Typography>
        </Box>
        <Box sx={{width:'30%'}}>
          <Typography component='p' variant='caption' sx={{fontWeight: 500, color: 'rgba(102, 112, 133, 1)'}}>Reminder</Typography>
        </Box>
        <Box sx={{width:'14%'}}>
          <Typography component='p' variant='caption' sx={{fontWeight: 500, color: 'rgba(102, 112, 133, 1)'}}>Created</Typography>
        </Box>
        <Box sx={{width:'6%'}}></Box>
      </Box>
      <Box component='div' className='rowsContainer'>
        <ReminderListItem />
      </Box>
     </Box>
    </React.Fragment>
  )
}