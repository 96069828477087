import Box from "@mui/material/Box";
import UpgradeBox from "../../components/upgradeBox/UpgradeBox";
import {useParams} from "react-router-dom";
import {useGetCardQuery} from "../../slices/api/cardApiSlice";
import TitleText from "../../components/titleText/TitleText";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import PurplePill from "../../components/purplePill/PurplePill";

export default function  UpgradeCardPage(){
  const { cardId } = useParams();

  let urlParams = `${cardId}`

  const {
    data: cardResponse,
    isLoading: getCardIsLoading,
    isSuccess: getCardIsSuccess,
    isError: getCardIsError,
  } = useGetCardQuery(urlParams)

  return(
    <Box >
      <Box sx={{
        backgroundImage: `url(https://storage.googleapis.com/prod-website-images-rlink/blue-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/red-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/orange-vector.svg)`,
        backgroundPosition: 'top right, top 20% left, bottom right',
        backgroundRepeat: 'no-repeat',
      }}>
        <Grid container maxWidth="xl" sx={{padding: { xs: '40px 17px'}, margin:{xs: '56px auto 0 auto', md: '53px auto 0 auto'} }}>
          <Grid item xs={12} sx={{margin: '0 0 30px 0'}}>
            <PurplePill>Upgrade</PurplePill>
          <TitleText align="center">Upgrade card {getCardIsSuccess && <>for: {cardResponse.card.recipients[0].fullName}</>}</TitleText>
          <Typography variant="subtitle2" sx={{ textAlign:{ xs: 'center'} }}> Select one of the options to upgrade the card</Typography>
          </Grid>
          {getCardIsSuccess &&
            <Box sx={{ margin: '0 auto' }}>
              <UpgradeBox card={cardResponse.card}  isPricingPage={false}  />
            </Box>
          }
        </Grid>
      </Box>
    </Box>
  )
}