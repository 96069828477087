import ImageGallery from "react-image-gallery";
import ShowImage from "./ShowImage";

export default function ImagesByUser({message}) {
  const images = message.media.data
  if(images.length < 1) return null;

  return <ImageGallery
    items={images}
    showThumbnails={false}
    showBullets={images.length > 1}
    showPlayButton={false}
    renderLeftNav={() => ''}
    renderRightNav={() => ''}
    showFullscreenButton={false}
    renderItem={(image) => <ShowImage image={image} />}
  />;
}