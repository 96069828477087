import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

// Define multiple keyframe animations
const animations = [
  keyframes`
    0% { transform: scale(1) translate(-100px, -100px); }
    25% { transform: scale(1.1) translate(0, 0); }
    50% { transform: scale(1.2) translate(100px, 100px); }
    75% { transform: scale(1.1) translate(0, 0); }
    100% { transform: scale(1) translate(0, 0); }
  `,
  keyframes`
    0% { transform: scale(1) translateX(-100vw); }
    25% { transform: scale(1.1) translateX(-50vw); }
    50% { transform: scale(1.2) translateX(0); }
    75% { transform: scale(1.1) translateX(50vw); }
    100% { transform: scale(1) translateX(0); }
  `,
  keyframes`
    0% { transform: rotate(0deg) scale(1); }
    25% { transform: rotate(90deg) scale(1.1); }
    50% { transform: rotate(180deg) scale(1.2); }
    75% { transform: rotate(270deg) scale(1.1); }
    100% { transform: rotate(360deg) scale(1); }
  `,
  keyframes`
    0% { transform: scale(1) translateY(-100vh); }
    25% { transform: scale(1.1) translateY(-50vh); }
    50% { transform: scale(1.2) translateY(0); }
    75% { transform: scale(1.1) translateY(50vh); }
    100% { transform: scale(1) translateY(0); }
  `
];

const getRandomAnimation = () => animations[Math.floor(Math.random() * animations.length)];

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const floatAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

const FullScreenOverlay = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: #6941C6;
  //background-color: rgba(72, 43, 115, 1);
  background-color: #7F56D9;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FullScreenImage = styled(Box)`
  width: 100vw;
  height: 56.25vw; // 1920/1080 * 100vw
  max-width: 1920px;
  max-height: 1080px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  animation: ${(props) => props.animation} 3s ease-in-out;
`;

const FadedImage = styled(Box)`
  width: 100vw;
  height: 56.25vw; // 1920/1080 * 100vw
  max-width: 1920px;
  max-height: 1080px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 3s ease-in-out;
  z-index: 10000;
  ${(props) => props.visible && `opacity: 1;`}
`;

const ContentBox = styled(Box)`
  position: fixed;
  bottom: 30px;
  width: 100%;
  text-align: center;
  transition: transform 1s ease-in-out;
  z-index: 10001; /* Ensure the content is above the background image */

  @media (min-width: 600px) and (max-width: 799px) {
    bottom: 60px;
  }

  @media (min-width: 800px) {
    bottom: 120px;
  }
`;

const RevealedContent = styled(ContentBox)`
  animation: ${fadeIn} 1s ease-in-out, ${floatAnimation} 3s ease-in-out infinite;
`;

const AmazingImageComponent = ({ card, callback }) => {
  const [animationComplete, setAnimationComplete] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);
  const [visible, setVisible] = useState(true);
  const [animation, setAnimation] = useState(getRandomAnimation);

  const imageUrl = card?.template?.cardBackgroundImage;

  useEffect(() => {
    const animationTimer = setTimeout(() => {
      setAnimationComplete(true);
    }, 3000);

    const contentTimer = setTimeout(() => {
      setContentVisible(true);
    }, 4000);

    return () => {
      clearTimeout(animationTimer);
      clearTimeout(contentTimer);
    };
  }, []);

  const handleContinue = () => {
    if(callback) callback();

    setVisible(false);
  };

  if (!visible) {
    return null;
  }

  return (
    <FullScreenOverlay>
      {!animationComplete && <FullScreenImage style={{ backgroundImage: `url(${imageUrl})` }} animation={animation} />}
      {animationComplete && <FadedImage style={{ backgroundImage: `url(${imageUrl})` }} visible={visible.toString()} />}
      {animationComplete && (
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          zIndex: 10001,
        }}
        >
          {contentVisible && (
            <RevealedContent>
              <Box sx={{
                width: '90%',
                maxWidth: '420px',
                margin: '0 auto',
                textAlign: 'center',
                backgroundColor: '#FFFFFF',
                padding: { xs: '10px 10px 0 10px', md: '20px'},
                borderRadius:'16px',
              }}>

                <Typography variant="body1" style={{ marginBottom:'10px' }}>
                  {card?.recipients[0]?.fullName ? `${card?.recipients[0]?.fullName},` : ''} you have received a card from: {card.creatorName}
                </Typography>
                <Typography variant="h2" sx={{ fontSize:{xs: '20px'}, lineHeight:{xs:'23px'}, overflowWrap: 'break-word' }}>
                  {card.title}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleContinue}
                  style={{ marginTop: '20px' }}
                >
                  View card
                </Button>
              </Box>
            </RevealedContent>
          )}
        </Box>
      )}
    </FullScreenOverlay>
  );
};

export default AmazingImageComponent;
