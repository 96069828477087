import { useState } from "react";
import {useDispatch } from "react-redux";
import { datadogRum } from '@datadog/browser-rum';
import { useLoginMutation } from "../slices/api/authApiSlice";
import { setCredentials } from "../slices/authSlice";
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InfoSnackbar from "../components/infoSnackbar/InfoSnackbar";
import {useLocation, useNavigate} from "react-router-dom";
import SocialLogin from "../components/socialLogin/SocialLogin";
import Divider from "../components/divider/Divider";
import {isValidEmail} from "../utils/functions";

export default function SignInSide() {
  const [login, { isError: errorLogin}] = useLoginMutation();
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleSubmit = async (event) => {
    event.preventDefault();

    if(!email) {
      return setError('Email is required')
    }

    if(!isValidEmail(email)) {
      return setError('Emails must be valid')
    }

    if(!password) {
      return setError("Provide a password")
    }

    try {
      const userData = await login({ email, password}).unwrap();
      dispatch(setCredentials(userData))
      const { user } = userData;

      datadogRum.setUser({
        id: user?.id,
        name: user?.fullName,
        email: user?.email,
      })


      if(location.state?.from && location.state.from !== '/' ) {
        return navigate(location.state.from)
      } else {
        return navigate('/dashboard')
      }
    } catch (e) {
      console.log('error trying to login', e)
    }
  };

  function goToRegister(e) {
    e.preventDefault();
    navigate('/register',{ state: { from: (location?.state?.from) ? location?.state?.from : null  }});
  }
  return (
    <Grid container component="main" sx={{minHeight:'100vh'}}>
      {errorLogin && <InfoSnackbar severity="error" message="Oops. Check your email and password to try again." />}
      {error && <InfoSnackbar severity="error" message={error} callback={() => {setError('')}} onClick={()=>{ setError('')}} />}
        <Grid item xs={false} sm={false} md={6}
          sx={{
            backgroundImage: 'url(https://storage.googleapis.com/prod-website-images-rlink/login-signup-background-mid-quality.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12}  md={6}>
          <Box sx={{my: 8, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '360px', margin:{xs: '30px auto', md: '60px auto', lg: '120px auto'} }}>
            <Typography component="h1" variant="h2" sx={{marginBottom: {xs: '10px', md: '10px'} }}>
              Log in to your account
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row', }}>
              <Typography component="h2" variant="body1">Welcome back! New user? </Typography><Button onClick={goToRegister} sx={{padding:0}} variant="text" color="primary">Sign up</Button>
            </Box>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <Typography variant="body2" sx={{  paddingTop: '13px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Your Email Address:</Typography>
              <TextField
                margin="normal" required fullWidth id="email" name="email" autoComplete="email" autoFocus
                value={email} onChange={(e) => setEmail(e.target.value)}
              />
              <Typography variant="body2" sx={{  paddingTop: '13px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Password:</Typography>
              <TextField
                autoComplete="current-password"
                margin="normal" required fullWidth name="password"  type="password" id="password"
                value={password} onChange={(e) => setPassword(e.target.value)}
              />
              <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', width:'100%'}}>
                    <FormControlLabel sx={{flex: 1}}
                        control={<Checkbox value="remember" color="primary"  />}
                        label="Remember me"
                    />
                    <Button sx={{marginBottom:0}} color="primary" onClick={() => { navigate('/forgot-password')}}>Forgot password?</Button>
              </Box>
              
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Sign In</Button>
            </Box>
            <Divider label="OR" />
            <SocialLogin />
            {/*<Divider />*/}
            {/*<Grid container direction="column" alignItems="center">*/}
            {/*  <Grid item xs={12}>*/}
            {/*    <Typography component="span" variant='body2' >Don't have an account?</Typography>*/}
            {/*    <Link href="#" variant="body2">*/}
            {/*      {" Sign Up"}*/}
            {/*    </Link>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
          </Box>
        </Grid>
      </Grid>
  );
}