import Grid from "@mui/material/Grid";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from "@mui/material/Typography";
import {goBackArrow, ImageIcon, LupaIcon, previewImageIcon, VideoIcon, VolumeIcon} from "../../../svgIcons/icons";
import Divider from "../../../components/divider/Divider";
import TextField from "@mui/material/TextField";
import HaveAccount from "../../../components/haveAccount/HaveAccount";
import Box from "@mui/material/Box";
import Message from "../../../components/message/Message";

import Button from "@mui/material/Button";
import AddMediaButton from "../../../components/addMediaButton/AddMediaButton";
import ActionModal from "../../../components/actionModal/ActionModal";
import GiphySearch from "../../../components/giphySearch/GiphySearch";
import AddMessageImages from "../../../components/addMessageImages/AddMessageImages";
import AddMessageVideo from "../../../components/addMessageVideo/AddMessageVideo";
import mediaTypes from "../../../utils/mediaTypes";
import {useSelector} from "react-redux";
import MyUserBlock from "../../../components/myUserBlock/MyUserBlock";
import Chip from "@mui/material/Chip";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useState} from "react";
import UploadVideo from "../../../components/addMessageVideo/UploadVideo";
import * as React from "react";
import EmbedVideo from "../../../components/addMessageVideo/EmbedVideo";
import UnsplashSearch from "../../../components/unSplashSearch/UnsplashSearch";

export default function MessageContentInputFields({ isLoading = false,
                                                    navigateToCard, edit = false, card, creatorName, updateName, creatorEmail, updateEmail,
                                                    messageText, updateMessage, setShowGifModal, showGifModal, media, updateMedia,
                                                    setShowImagesModal, showImagesModal, addImage, removeImage, setShowVideoModal,
                                                    showVideoModal, setShowMusicModal, showMusicModal, handleConfirmation,
                                                    addUnsplashImage,
                                                    showUnsplashModal,
                                                    setShowUnsplashModal,
                                                    removeUnsplashImage
                                                  }){
  const user = useSelector(state => state.auth.user);
  const [showUploadVideo, setShowUploadVideo] = useState(false)
  const [showEmbedVideo, setShowEmbedVideo] = useState(false)
  const[showUploadImages, setShowUploadImages] = useState(false)
  const [showEmbedImage, setShowEmbedImage] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null);
  const imagesMenuOpen = Boolean(anchorEl);

  const [anchorVideos, setAnchorVideos] = useState(null);
  const videosMenuOpen = Boolean(anchorVideos);
  const openImagesMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeImagesMenu = () => {
    setAnchorEl(null);
  };

  const openVideosMenu = (event) => {
    setAnchorVideos(event.currentTarget);
  };
  const closeVideosMenu = () => {
    setAnchorVideos(null);
  };

  const mediaIsSet = media.type !== '' && media.data.length > 0;
  const isButtonDisabled = (mediaTypeToCheck) => {
    if(media.type === null) return false;
    if(media.type === '') return false;
    if(media.type === mediaTypeToCheck) return false;
    return true;
  }

  const isVideoDisabled = () => {
    if(media.type === null) return false;
    if(media.type === '') return false;
    let returnThis = true;
    if (media.type === mediaTypes.USER_VIDEO) { returnThis = false}
    if (media.type === mediaTypes.VIDEO_EMBED) { returnThis = false}
    return returnThis;
  }

  const videoText = () => {
    if(media.type === null) return 'Add video';
    if(media.type === '') return 'Add video';
    let returnThis = 'Add video';
    if (media.type === mediaTypes.USER_VIDEO) { returnThis = 'Edit video'}
    if (media.type === mediaTypes.VIDEO_EMBED) { returnThis = 'Edit video'}
    return returnThis;
  }

  function handleCloseEmbedVideo() {
    setShowEmbedVideo(false);
    setAnchorVideos(null)
  }

  function handleCloseUploadVideo() {
    setShowUploadVideo(false);
    setAnchorVideos(null)
  }

  function handleCloseEmbedImage() {
    // setShowImagesModal(false)
    // setShowEmbedImage(false)
    setShowUnsplashModal(false)
    setAnchorEl(null)
  }

  function handleCloseUploadImages() {
    // setShowImagesModal(false)
    setShowUploadImages(false)
    setAnchorEl(null)
  }

  function isImageDisable() {
    if(media.type === null) return false;
    if(media.type === '') return false;
    if(media.type === mediaTypes.UNSPLASH_IMAGE)  return false;
    if(media.type === mediaTypes.USER_IMAGE) return false;
    return true;
  }



  return (
    <Box component="div" sx={{ backgroundColor: '#FFF', paddingBottom: '30px', borderRadius: '0 0 12px 12px'}}>
      <Grid maxWidth="xl" sx={{ marginLeft: 'auto', marginRight: 'auto'}} container>
        <Grid item xs={12} md={7} sx={{ padding: { md: '0 20px'} }}>
          {/*<Typography onClick={navigateToCard} component="p" variant="caption" sx={{ '&:hover':{ cursor: 'pointer'}, marginBottom: '10px', color: 'primary.main'}}>*/}
          {/*  {goBackArrow} Go to card*/}
          {/*</Typography>*/}
          <Typography variant="h4" sx={{ fontSize: '24px', lineHeight: '28px'}}>
            {edit ? 'Edit message' : 'Add message'}
          </Typography>
          <Typography sx={{ marginTop: '5px', fontSize: '16px', lineHeight: '18px'}} variant="subtitle2" >
            {edit ? 'For ' : 'To' } {card.recipients[0].fullName}'s group card
          </Typography>
          <Divider small={true} />
          <Box sx={{ margin: '20px 0 20px 0'}}>
            <MyUserBlock big={true} />
          </Box>
          {!user?.email && !user?.fullName &&
            <>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" sx={{  paddingTop: '0px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Your Name</Typography>
                  <TextField
                    value={creatorName}
                    margin="normal"
                    required
                    fullWidth
                    id="creatorName"
                    name="creatorName"
                    onChange={updateName}

                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ paddingLeft: {xs: 'none', md: '20px'} }}>
                  <Typography variant="body2" sx={{ lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Your Email Address</Typography>
                  <TextField
                    value={creatorEmail}
                    margin="normal"
                    required
                    fullWidth
                    id="creatorEmail"
                    name="creatorEmail"
                    autoComplete="email"
                    onChange={updateEmail}
                  />
                </Grid>
              </Grid>
              <HaveAccount small={true} />
            </>
          }
          <Typography variant="body2" sx={{  marginTop: '10px', paddingTop: '13px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Your Message</Typography>
          <Typography variant="body2" sx={{ lineHeight: '20px', fontSize: '14px', color: 'rgba(102, 112, 133, 1)', fontWeight: 500, margin: '5px 0 10px 0'}}>Add a gif or a photo to make it extra awesome</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="message"
            name="message"
            sx={{background: '#FFFFFF', borderRadius: '8px', marginTop: '10px'}}
            multiline
            rows={2}
            value={messageText}
            onChange={updateMessage}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
            <Chip label={`${messageText.length}/2000`} />
          </Box>
          {/*<MediaButtons confirmAction={setMedia} />*/}
          <Box component='div' sx={{ marginTop: '10px', marginBottom: '30px', display: 'flex', flexDirection: { xs: 'column', sm:'row', lg: 'row'}, justifyContent:{ xs: 'space-evenly', sm: 'flex-start'}, gap: '12px'}}>
            {/*Search for Gifs button*/}
            <AddMediaButton
              disabled={isButtonDisabled(mediaTypes.GIPHY_IMAGES)}
              onClick={setShowGifModal}
              icon={<LupaIcon
                stroke={isButtonDisabled(mediaTypes.GIPHY_IMAGES) ? 'rgba(0, 0, 0, 0.26)' : undefined }/>}
              text={ (media.type === mediaTypes.GIPHY_IMAGES) ? 'Edit Gif' : 'Add Gifs'}
            />
            <ActionModal
              confirm={() => {setShowGifModal(false)}}
              open={showGifModal}
              cancel={() => {setShowGifModal(false)}}
              title="Make it fun with GIFs">

              <GiphySearch media={media} updateMedia={updateMedia}  closeModal={() => { setShowGifModal(false)}} />
            </ActionModal>

            {/*Add images button*/}
            {/*<AddMediaButton*/}
            {/*  disabled={isButtonDisabled(mediaTypes.USER_IMAGE)}*/}
            {/*  onClick={setShowImagesModal} icon={<ImageIcon*/}
            {/*    stroke={isButtonDisabled(mediaTypes.USER_IMAGE) ? 'rgba(0, 0, 0, 0.26)' : undefined } />}*/}
            {/*  text={ (media.type === mediaTypes.USER_IMAGE) ? 'Edit images' : 'Add images'} />*/}
            <Button
              disabled={isImageDisable()}
              // onClick={setShowImagesModal}
              onClick={openImagesMenu}
              variant='contained'
              endIcon={<KeyboardArrowDownIcon />}
              color="secondary">
              <span style={{paddingRight: '0px', paddingTop: '5px'}}>{<ImageIcon
                stroke={isButtonDisabled(mediaTypes.USER_IMAGE) ? 'rgba(0, 0, 0, 0.26)' : undefined } />}</span> {(media.type === mediaTypes.USER_IMAGE || media.type === mediaTypes.UNSPLASH_IMAGE) ? 'Edit images' : 'Add images'}
            </Button>
            <Menu
              id="images-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={imagesMenuOpen}
              onClose={closeImagesMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{paddingTop:0, paddingBottom: 0}}
            >
              <MenuItem onClick={() => setShowUnsplashModal(true)}>From the Internet</MenuItem>
              <MenuItem onClick={() => setShowUploadImages(true)}>Upload your own</MenuItem>
            </Menu>
            <ActionModal
              showActionButtons={false}
              confirm={handleCloseEmbedImage}
              cancel={handleCloseEmbedImage}
              open={showUnsplashModal}
              title="Images from the Internet">
              <UnsplashSearch media={media} handleAddUnsplash={addUnsplashImage} removeUnsplashImage={() => {
                removeUnsplashImage();
                handleCloseEmbedImage();
              }} handleCloseEmbedImage={handleCloseEmbedImage}  />
            </ActionModal>
            <ActionModal
              showActionButtons={media.type !== mediaTypes.UNSPLASH_IMAGE}
              confirm={handleCloseUploadImages}
              cancel={handleCloseUploadImages}
              open={showUploadImages}
              title="Upload your images">
              <AddMessageImages media={media}  addImage={addImage} removeImage={removeImage} />
            </ActionModal>



            {/*<AddMediaButton*/}
            {/*  disabled={isVideoDisabled()}*/}
            {/*  onClick={setShowVideoModal}*/}
            {/*  icon={<VideoIcon*/}
            {/*    stroke={isVideoDisabled() ? 'rgba(0, 0, 0, 0.26)' : undefined }/>}*/}
            {/*  text={ videoText()} />*/}
            <Button
              disabled={isVideoDisabled()}
              // onClick={setShowImagesModal}
              onClick={openVideosMenu}
              variant='contained'
              endIcon={<KeyboardArrowDownIcon />}
              color="secondary">
              <span style={{paddingRight: '0px', paddingTop: '5px'}}>{<VideoIcon
                stroke={isVideoDisabled() ? 'rgba(0, 0, 0, 0.26)' : undefined }/>}</span> {videoText()}
            </Button>
            <Menu
              id="videos-menu"
              anchorEl={anchorVideos}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={videosMenuOpen}
              onClose={closeVideosMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{paddingTop:0, paddingBottom: 0}}
            >
              <MenuItem onClick={() => setShowEmbedVideo(true)}>From YouTube</MenuItem>
              <MenuItem onClick={() => setShowUploadVideo(true)}>Upload your own</MenuItem>
            </Menu>
            <ActionModal
              confirm={handleCloseEmbedVideo}
              cancel={handleCloseEmbedVideo}
              open={showEmbedVideo}
              title="Video from YouTube">
              <EmbedVideo media={media} updateMedia={updateMedia} closeModal={() => { setShowVideoModal(false)}}  />
              {/*<AddMessageVideo media={media} updateMedia={updateMedia}  closeModal={() => { setShowVideoModal(false)}} />*/}
            </ActionModal>
            <ActionModal
              confirm={handleCloseUploadVideo}
              cancel={handleCloseUploadVideo}
              open={showUploadVideo}
              title="Upload your video">
              <UploadVideo media={media} updateMedia={updateMedia} closeModal={() => { setShowVideoModal(false)}} />
            </ActionModal>

            {/*<AddMediaButton*/}
            {/*  disabled={isButtonDisabled(mediaTypes.MUSIC_LINK)}*/}
            {/*  onClick={setShowMusicModal}*/}
            {/*  icon={<VolumeIcon*/}
            {/*    stroke={isButtonDisabled(mediaTypes.MUSIC_LINK) ? 'rgba(0, 0, 0, 0.26)' : undefined }/>}*/}
            {/*  text={ (media.type === mediaTypes.MUSIC_LINK) ? 'Edit music' : 'Add music'} />*/}
            {/*<ActionModal*/}
            {/*  confirm={()=> setShowMusicModal(false)}*/}
            {/*  cancel={()=> setShowMusicModal(false)}*/}
            {/*  open={showMusicModal}*/}
            {/*  title="Add a song to the message">*/}
            {/*  Music here*/}
            {/*</ActionModal>*/}
          </Box>
          <Divider/>
          <Box component='div' sx={{
            display: { xs: 'flex', md: 'flex'},
            flexDirection: 'row',
            justifyContent: { xs: 'space-around', sm: 'flex-end'},
            gap: '10px',
          }}>
            <Button disabled={isLoading} sx={{ width: '100px'}} variant='outlined' onClick={navigateToCard} color="secondary">Cancel</Button>
            <Button disabled={isLoading} sx={{maxWidth: '197px'}} variant='contained' onClick={handleConfirmation} type="submit">{edit? 'Update' : 'Post'} message</Button>
          </Box>

        </Grid>

        <Grid item xs={12} md={5} sx={{
          // background: card.template.cardBackgroundImage ? `url(${card.template.cardBackgroundImage})`: card.template.cardBackgroundColor,
          // backgroundPosition: 'center',
          // backgroundSize: 'cover',
          // paddingTop: '60px',
          // paddingBottom: '60px',
          // borderRadius: '12px'
        }}>
          <Box sx={{
            width: '80%',
            maxWidth: '420px',
            margin: 'auto',
            borderRadius: '8px',
            // border: `1px solid #D0D5DD`,
            boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
          }}>
            {!mediaIsSet &&
              <Box component="div" sx={{ padding: '120px 0', width: '100%', marginBottom: '0', borderRadius: '8px 8px 0 0', backgroundColor: '#F4F4F4', textAlign:'center' }}>
                {previewImageIcon}
                <Typography variant="body2">Add media for preview</Typography>
            </Box> }
            <Message preview={true} message={{creatorName, creatorEmail, message:messageText, media}} card={card}/>
          </Box>
        </Grid>
      </Grid>

      {/*<Grid maxWidth="xl" sx={{ marginLeft: 'auto', marginRight: 'auto', display: {xs: 'block', md: 'none'} }} container>*/}
      {/*  <Grid item xs={12}>*/}
      {/*    <Divider/>*/}
      {/*    <Box component='div' sx={{*/}
      {/*      display: 'flex',*/}
      {/*      flexDirection: 'row',*/}
      {/*      justifyContent: { xs: 'space-around', sm: 'flex-end'},*/}
      {/*      gap: '10px'*/}
      {/*    }}>*/}
      {/*      <Button variant='outlined' onClick={navigateToCard} color="secondary">Cancel</Button>*/}
      {/*      <Button variant='contained' onClick={handleConfirmation} type="submit">{edit? 'Update' : 'Post'} message</Button>*/}
      {/*    </Box>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
    </Box>

  )
}