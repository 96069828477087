import Box from "@mui/material/Box";
import {recipientIcon} from "../../../svgIcons/icons";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {useDispatch, useSelector} from "react-redux";
import { recipientNameUpdated, titleUpdated} from "../../../slices/cardSlice";
import React, {useState} from "react";
import InfoSnackbar from "../../infoSnackbar/InfoSnackbar";
import Chip from "@mui/material/Chip";

export default function RecipientDetails(){
  const dispatch = useDispatch();
  const card = useSelector(state => state.card);
  const [error, setError] = useState('');

  function handleRecipientNameUpdate(e){
    if(e.target.value.length >= 80) {
      dispatch(recipientNameUpdated(e.target.value.substring(0,80)))
      return setError('Names can only be up to 80 characters log');
    }
    return dispatch(recipientNameUpdated(e.target.value))
  }

  function handleTitleUpdate(e) {
    if(e.target.value.length >= 60) {
      dispatch(titleUpdated(e.target.value.substring(0,60)))
      return setError('Names can only be up to 60 characters log');
    }
    return dispatch(titleUpdated(e.target.value))
  }

  return (
    <Box>
      {error && <InfoSnackbar message={error} onClick={() =>{setError('')}} callback={() => {setError('')}} severity="error" /> }
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'center',
        marginBottom: '30px'
      }}>
        {recipientIcon} <Typography component="h3" variant="h3">Recipient details</Typography>
      </Box>

      <Box component="div">
        <Typography variant="body2" sx={{  paddingTop: '0px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Recipient's name*</Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="recipientName"
          name="recipientName"
          onChange={handleRecipientNameUpdate}
          value={card.recipients[0].fullName}
          sx={{ marginBottom: {xs: '24px'} }}
        />
      </Box>
      <Box component="div">
        <Typography variant="body2" sx={{  paddingTop: '0px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Card title*</Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="cardTitle"
          name="cardTitle"
          onChange={handleTitleUpdate}
          value={card.title}
        />
        <Box sx={{textAlign: 'right'}}>
          <Chip sx={{fontSize: '12px'}} label={`${card.title.length} / 60`} />
        </Box>
      </Box>
    </Box>
  )
}