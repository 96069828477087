import React, {useState} from "react";
import Box from "@mui/material/Box";
import PickColorButton from "../pickColorButton/PickColorButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CustomBackgroundUploader from "../customBackgroundUploader/CustomBackgroundUploader";
import Divider from "../divider/Divider";
import Button from "@mui/material/Button";
import {useUpdateCardMutation} from "../../slices/api/cardApiSlice";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";
import {useSelector} from "react-redux";
import BackgroundUploader from "./BackgroundUploader";
import {customBackgroundImageAddedByUser} from "../../slices/cardSlice";
import {cardBackgroundImageAdded} from "../../slices/templateSlice";
import {
  useDeleteCustomBackgroundImageByUserMutation,
  useUploadCustomBackgroundByUserMutation
} from "../../slices/api/apiSlice";
import DialogContent from "@mui/material/DialogContent";
import {colorsPallete} from "../../utils/colors";
import Dialog from "@mui/material/Dialog";
import {compressImage, getImageFileNameFromURL} from "../../utils/functions";
import {PremiumCrown} from "../../svgIcons/icons";
import Alert from "@mui/material/Alert";
import CardUpgradeOptionsDialog from "../cardUpgradeOptionsDialog/CardUpgradeOptionsDialog";

export default function EditColorsAndBackground({card = {}, closeDialog = () => {}, hideColors = false, hideUpload = false}){
  const [uploadUsersBackgroundImage, { isLoading: customBackgroundByUserUploading, isError: isErrorUploading}] = useUploadCustomBackgroundByUserMutation();
  const [deleteCustomerBackgroundImageByUser, {isLoading: deletingCustomerBackgroundImageByUser}] = useDeleteCustomBackgroundImageByUserMutation();
  // Colors
  const [cardTitleColor, setCardTitleColor] = useState(card.template.cardTitleColor)
  const [titleShadowColor, setTitleShadowColor] = useState(card.template.titleShadowColor)
  const [recipientsNameColor, setRecipientsNameColor] = useState(card.template.recipientsNameColor)
  const [messageBackgroundColor, setMessageBackgroundColor] = useState(card.template.messageBackgroundColor)
  const [messageTextColor, setMessageTextColor] = useState(card.template.messageTextColor)
  const [cardBackgroundColor, setCardBackgroundColor] = useState(card.template.cardBackgroundColor)
  // Background image
  const[isCustomBackgroundImage, setIsCustomBackgroundImage] = useState(card.template.isCustomBackgroundImage)
  const[cardBackgroundImageFullSize, setCardBackgroundImageFullSize] = useState(card.template.cardBackgroundImageFullSize)
  const[cardBackgroundImage, setCardBackgroundImage] = useState(card.template.cardBackgroundImage)
  //Warning before deleting
  const [showDeleteWarning, setShowDeleteWarning] = useState(false)
  //Errors
  const [error, setError] = useState('')
  const [updateCard] = useUpdateCardMutation();

  //upgrades
  const [showUpgradeOptions, setShowUpgradeOptions] = useState(false);

  const tempCard = {
    ...card,
    template: {
      ...card.template,
      cardTitleColor,
      titleShadowColor,
      recipientsNameColor,
      messageBackgroundColor,
      messageTextColor,
      isCustomBackgroundImage,
      cardBackgroundImageFullSize,
      cardBackgroundImage,
      cardBackgroundColor,
    }
  }

  async function updateColorsAndBackground() {
    try {
      await updateCard(tempCard).unwrap();
      closeDialog();
    } catch (e) {
      console.log('error updating card',e)
      if (e.originalStatus === 401) {
        setError('Not authorized to update the card. Login or register.')
      } else if (e.originalStatus === 403) {
        setError('Not authorized to update the card. Login or register.')
      }else if(e.originalStatus === 404) {
        setError('Card not found')
      }else {
        setError(`Oops, something went wrong on our end... Please try again`)
      }
    }
  }



  const handleFiles = async function(e){
    let files = [];

    if(e.target.files) {
      files = e.target.files;
    } else if (e.dataTransfer) {
      files = e.dataTransfer.files;
    }

    if(files.length > 1) {
      return setError('You can only upload one image for the background.')
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > 20 * 1024 * 1024) {
        setError('The file size is larger than 20MB. Please select a smaller file.');
        return; // Exit the function to prevent uploading
      }
      const formData = new FormData();
      try {
        const compressedFile = await compressImage({
          file,
          maxWidth: 1920,
          maxHeight: 1080,
        })
        formData.append('image', compressedFile);
      } catch (errorCompressing) {
        console.error('error compressing avatar image', errorCompressing)
        formData.append('image', file);
      }
      // formData.append('image', file)

      try {
        const fileUploaded = await uploadUsersBackgroundImage(formData).unwrap();
        setCardBackgroundImage(fileUploaded.fileUrl)
        setIsCustomBackgroundImage(true)

      } catch (e) {
        console.log('error uploading file', e)
        return setError('Error loading background. Please make sure the image is under 20MB.')
      }

    }
  }

  async function handleDelete() {
    try {
      const fileToDelete = getImageFileNameFromURL(tempCard.template.cardBackgroundImage);
      const currentImage = getImageFileNameFromURL(card.template.cardBackgroundImage)
      await deleteCustomerBackgroundImageByUser(fileToDelete).unwrap()
      if(fileToDelete === currentImage) {
        await updateCard({
          ...card,
          template: {
            ...card.template,
            cardTitleColor,
            titleShadowColor,
            recipientsNameColor,
            messageBackgroundColor,
            messageTextColor,
            cardBackgroundColor,
            isCustomBackgroundImage: false,
            cardBackgroundImageFullSize: false,
            cardBackgroundImage: ''
          }
        }).unwrap();
      }
      setShowDeleteWarning(false)
      setIsCustomBackgroundImage(false)
      setCardBackgroundImageFullSize(false)
      setCardBackgroundImage('')

    } catch (e) {
      console.log('error deleting image',e)
      setError('Error deleting the image, please try again.')
    }
  }

  return(<>
    {!hideColors &&
      <Grid container>
        <Grid item xs={12} md={3}>
          <Typography variant="body1" sx={{fontWeight: '500',  marginBottom:'8px' }}>Change colors</Typography>
          <Typography variant="body2" sx={{ marginBottom:{ xs: '20px', md: '30px'} }}>Select new colors to make it extra personal</Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box sx={{ marginBottom: '30px',  display: 'flex', flexDirection: 'row', flexWrap: 'wrap', textAlign: 'center', justifyContent: 'center', gap: {xs:'10px', md:'30px'}} }>
            <PickColorButton title="Card's Title" defaultColor={cardTitleColor} cb={setCardTitleColor} />
            <PickColorButton title="Title's shadow" defaultColor={titleShadowColor} cb={setTitleShadowColor} />
            <PickColorButton title="Recipient's Name" defaultColor={recipientsNameColor} cb={setRecipientsNameColor} />
            {/*<PickColorButton title="Messages Background" defaultColor={messageBackgroundColor} cb={setMessageBackgroundColor} />*/}
            <PickColorButton title="Text in Messages" defaultColor={messageTextColor} cb={setMessageTextColor} />
            {/*<PickColorButton title="Intro Animation background" defaultColor={cardBackgroundColor} cb={setCardBackgroundColor} />*/}
          </Box>
        </Grid>
      </Grid>
    }
    {/*{card.tier === 'FREE_PLAN' && !hideUpload &&*/}
    {/*  <>*/}
    {/*    <Alert*/}
    {/*      id="premium-message-warning"*/}
    {/*      data-testid="premium-message-warning"*/}
    {/*      icon={<PremiumCrown width="16px" height="16px" /> }*/}
    {/*      sx={{ borderRadius: '8px', backgroundColor:'#F9F5FF', color: '#6941C6', fontFamily:"'Inter', sans-serif", paddingBottom:'10px'}}*/}
    {/*      severity="info">*/}
    {/*      <Typography variant="h4" sx={{fontSize:'18px', lineHeight:'22px', marginBottom:'8px'}}>Upload your own background image</Typography>*/}
    {/*      <Typography variant="body2">Upgrade card to enjoy this benefit</Typography>*/}
    {/*      <Button onClick={() => setShowUpgradeOptions(true)}  sx={{marginTop: '20px'}} variant="contained">Upgrade card</Button>*/}
    {/*      <Typography variant="caption">Multiple options for upgrade</Typography>*/}
    {/*    </Alert>*/}
    {/*    <CardUpgradeOptionsDialog card={card} open={showUpgradeOptions} closeDialog={() => setShowUpgradeOptions(false)} />*/}
    {/*  </>*/}
    {/*}*/}
    {!hideUpload  &&
      <Grid container sx={{ marginTop: {xs: '10px', md: '10px'} }}>
        <Grid item xs={12} md={3}>
          <Typography variant="body1" sx={{ display:'flex', alignItems:'center', fontWeight: '500',  margin:{ xs: '0px 0 8px 0', md: '0 0 8px 0'} }}>Upload custom background</Typography>
          <Typography variant="body2" sx={{ marginBottom:{ xs: '20px', md: '30px'} }}>For full-screen <b>stretched</b> backgrounds the minimum image size is <b>1920x1080</b>.</Typography>
          <Typography variant="body2" sx={{ marginBottom:{ xs: '20px', md: '30px'} }}>For <b>tiled</b> repeating backgrounds we recommend using a <b>600x600</b> square image.</Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <BackgroundUploader customBackgroundByUserUploading={customBackgroundByUserUploading} handleDelete={() => { setShowDeleteWarning(true)}} handleFiles={handleFiles} handleBackgroundImageSize={setCardBackgroundImageFullSize} card={tempCard}/>
        </Grid>
      </Grid>
    }
    {/*//Save button for uploads is hidden if the plan is not paid*/}
    {card.tier !== 'FREE_PLAN' && !hideUpload &&
      <Grid container>
        <Divider small={true} />
        <Grid item xs={12} >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
            <Button onClick={updateColorsAndBackground} sx={{ margin: '10px 0', maxWidth: '200px'}} variant="contained">Save</Button>
          </Box>
        </Grid>
      </Grid>
    }

    {/*//Save option to show up to update colors when upload is not present*/}
    {hideUpload &&
      <Grid container>
        <Divider small={true} />
        <Grid item xs={12} >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
            <Button onClick={updateColorsAndBackground} sx={{ margin: '10px 0', maxWidth: '200px'}} variant="contained">Save</Button>
          </Box>
        </Grid>
      </Grid>
    }

    {!!error && <InfoSnackbar message={error} severity="error" onClick={() =>  setError('')} callback={() => setError('')}/> }
    <Dialog onClose={() => { setShowDeleteWarning(false)}} open={showDeleteWarning} fullWidth={true}  maxWidth='sm'>
      <DialogContent>
        <Box sx={{textAlign: 'center'}}>
          <Typography variant='h3' sx={{ margin: '30px'}}>Are you sure you want to delete the custom background?</Typography>
          {deletingCustomerBackgroundImageByUser && <Typography variant="body2">Deleting...</Typography> }
          <Box sx={{ display: 'flex', alignItems: 'top', gap: '20px', marginTop: '20px', justifyContent: 'flex-end'}}>
            <Button sx={{ height: '50px', maxWidth: '100px'}} onClick={() => { setShowDeleteWarning(false)}} variant='outlined' color="secondary">Cancel</Button>
            <Box>
              <Button variant='outlined'
                      onClick={handleDelete}
                      color="secondary"
                      sx={{
                        background: colorsPallete.errorText,
                        maxWidth: '120px',
                        border: `1px solid ${colorsPallete.errorText}`,
                        color: '#FFF',
                        '&:hover': {
                          background: colorsPallete.errorText,
                        }
                      }}>Delete</Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  </>)

}