import {useCallback, useEffect} from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {isDevelopmentPage} from "../../utils/functions";

export default function StripeCheckout() {
  console.log('isDevelopmentPage()',isDevelopmentPage())
  const publishableKey = isDevelopmentPage() ? "pk_test_51MxwxQDZ21cdC3rxmfvHRsw3xuP256Ambd9bkIEGminX1n97jfdvlvEGGFBFzG7WeqQTLKZqdbkgYWAADeGSpLEE00SoSFKpey" : "pk_live_51MxwxQDZ21cdC3rxUKaNrM2Ty6rmsEMX6h3yBInIqxqkYgL4VuX6qUPMsKWNKBfoOh53TsiBFbn68lJPyFRloeni00Ve74V7Cu";

  const stripePromise = loadStripe(publishableKey);
  const  { clientSecret } = useParams();
  const fetchClientSecret = useCallback(() => {
    return clientSecret;
  })
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cardId= queryParams.get('cardId');
  const navigate = useNavigate();

  const options = {fetchClientSecret};
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Grid container maxWidth="lg" sx={{marginLeft: 'auto', marginRight:'auto'}}>
      <Grid item xs={12}>
        <Box sx={{padding:{xs:'10px', md: '20px'} }}>
          <Typography variant="h3">Checkout page</Typography>
          {cardId &&
            <Box  onClick={() => {
              return navigate(`/card/${cardId}`)
            }} sx={{maxWidth: '60px', '&:hover':{ cursor: 'pointer'}, display:'flex', marginTop:{xs:'10px', md:'20px'}, alignItems:'center'}}>
              <ArrowBackIcon sx={{color: '#667085', width:'10px'}} /><Typography variant="caption"> Cancel</Typography>
            </Box>
          }
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ width:'100%', minHeight: '100vh', alignItems:'center', paddingTop:{xs:0,} }}>
          <div data-dd-privacy="mask">
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={options}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
        </Box>
      </Grid>
    </Grid>
  )
}