import Box from "@mui/material/Box";

export default function GifImage({message}){
  if(!message?.media?.data) {
    return null
  }

  return (
    <Box>
      <img width="100%" src={message.media.data[0].fileUrl} alt={message.message} />
    </Box>)
}