import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import {logOutIcon} from "../../svgIcons/icons";
import {useDispatch, useSelector} from "react-redux";
import {useLogoutMutation} from "../../slices/api/authApiSlice";
import {logOut} from "../../slices/authSlice";

export default function MyUserBlock({big = false}){
  const [logout] = useLogoutMutation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  if(!user?.id) return null;

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await logout().unwrap();
    } catch (e) {
      console.log('error login out',e)
    }
    dispatch(logOut());
  }

  return(
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', overflow: 'hidden'}}>
      {user.picture && <Avatar alt={user.fullName} src={user.picture} />}
      <Box>
        <Typography variant="body1"
                    sx={{
                      fontWeight: 500,
                      lineHeight: big ? '32px' : '20px',
                      fontSize: big ? '30px' : '16px'
        }}>{user.fullName}</Typography>
        <Typography variant="body2" sx={{ fontSize: big ? '16px' : '14px', lineHeight: big ? '20px' : '18px',}}>{user.email}</Typography>
      </Box>
      {!big && <Box onClick={handleLogout}>{logOutIcon}</Box>}
    </Box>
  )
}