import {useSelector} from "react-redux";
import {selectCurrentToken} from "../../slices/authSlice";
import {Navigate, Outlet, useLocation} from "react-router-dom";

const RequireAuth = () => {
  const token = useSelector(selectCurrentToken);
  const auth = useSelector(state => state.auth)
  const isUserLoggedIn = !!auth?.user?.id;
  const location = useLocation();

  return(
    isUserLoggedIn
    ? <Outlet />
      : <Navigate to="/login" state={{ from: location}} replace={true} />
  )
}

export default RequireAuth;