import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from "../components/divider/Divider";
import SocialLogin from "../components/socialLogin/SocialLogin";
import {useRegisterMutation} from "../slices/api/authApiSlice";
import InfoSnackbar from "../components/infoSnackbar/InfoSnackbar";
import {useDispatch} from "react-redux";
import {setCredentials} from "../slices/authSlice";
import {useLocation, useNavigate} from "react-router-dom";
import {isValidEmail} from "../utils/functions";

export default function RegisterPage() {
  const [register, { isLoading, error}] = useRegisterMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [pwd,setPwd] = useState('');
  const [pwd2,setPwd2] = useState('');
  const [inputError, setInputError] = useState('');
  const [checked, setChecked] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault();

    const valid = [name, email, pwd, pwd2, checked].every(Boolean)

    if(name.length < 2) {
      return setInputError("Your name must have at least two characters")
    }

    if(!checked) {
      return setInputError("Please take a look at our terms of service and privacy policy")
    }
    if(!isValidEmail(email)) {
      return setInputError("Valid email required")
    }

    if(!pwd) {
      return setInputError("The password is required")
    }

    if(pwd.length < 6) {
      return setInputError("Password must be at least 6 characters long")
    }

    if(!valid) {
      setInputError('All values are required');
      return;
    }

    if(pwd !== pwd2) {
      setInputError('Passwords need to match');
      return;
    }
    const user = {
      fullName: name,
      email: email,
      password: pwd
    }

    try {
      const userRegistered = await register(user).unwrap();
      dispatch(setCredentials(userRegistered))
      if(location.state?.from) {
        return navigate(location.state.from)
      } else {
        return navigate('/dashboard')
      }
    } catch (e) {
      console.log('error registering', e)
    }
  };

  const handleTerms = (e) => {
    setChecked(e.target.checked)
  }

  return (
    <Grid container component="main">
        <Grid item xs={false} sm={false} md={6}
          sx={{
            backgroundImage: 'url(https://storage.googleapis.com/prod-website-images-rlink/login-signup-background-mid-quality.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12}  md={6}>
          <Box
            sx={{my: 8, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '360px', margin:{xs: '30px auto', md: '60px auto', lg: '120px auto'}}}
          >
            <Typography component="h1" variant="h2">
              Create an account
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', }}>
              <Typography component="h2" variant="body1">Already have an account? </Typography><Button onClick={() => navigate('/login')} sx={{padding:0}} variant="text" color="primary">login</Button>
            </Box>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <Typography variant="body2" sx={{  paddingTop: '13px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Your full name:</Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="fullName"
                name="fullName"
                value={name}
                onChange={(e) => { setName(e.target.value)}}
                autoFocus
              />
              <Typography variant="body2" sx={{  paddingTop: '13px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Your email address:</Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Typography variant="body2" sx={{  paddingTop: '13px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Password</Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                type="password"
                id="password"
                value={pwd}
                onChange={(e) => { setPwd(e.target.value) } }
              />
              <Typography variant="body2" sx={{  paddingTop: '13px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Confirm password:</Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                name="passwordConfirm"
                type="password"
                id="passwordConfirm"
                value={pwd2}
                onChange={(e) => { setPwd2(e.target.value) } }
              />
              <Box
                sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width:'100%'
                }}>
                    <FormControlLabel sx={{flex: 1}}
                        control={<Checkbox onChange={handleTerms} checked={checked} value="remember" color="primary"  />}
                        label = {<Typography component="span" variant='caption' >I agree to the <Box component="span" sx={{color: "#7F56D9"}} onClick={() => { navigate('/terms-of-service')}}>terms of service</Box> and  <Box component="span" sx={{color: "#7F56D9"}} onClick={() => { navigate('/privacy-policy')}}>privacy policy</Box></Typography>}
                    />
                </Box>
              <Button disabled={isLoading} onClick={handleSubmit} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Register
              </Button>
              <Divider label="OR" />
              <SocialLogin register={true} />
            </Box>
          </Box>
        </Grid>
      {inputError && <InfoSnackbar severity="error" message={inputError} callback={() => { setInputError('')}} />}
      {error && <InfoSnackbar severity="error" message={error.data.error} />}
      </Grid>
  );
}