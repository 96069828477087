export default [
  {id: 'bday-all', name: 'Birthday', avatars:[
      {name: 'Birthday cake', image:'https://storage.googleapis.com/prod-rlink-avatars/birthday/birthday-cake.svg'},
      {name: 'Birthday cake', image:'https://storage.googleapis.com/prod-rlink-avatars/birthday/birthday.svg'},
      {name: 'Cannon', image:'https://storage.googleapis.com/prod-rlink-avatars/birthday/cannon.svg'},
      {name: 'Confetti', image:'https://storage.googleapis.com/prod-rlink-avatars/birthday/confetti.svg'},
      {name: 'Gift', image:'https://storage.googleapis.com/prod-rlink-avatars/birthday/gift.svg'},
      {name: 'Cupcake', image:'https://storage.googleapis.com/prod-rlink-avatars/birthday/kawaii-cupcake.svg'},
      {name: 'Champagne Bottle', image:'https://storage.googleapis.com/prod-rlink-avatars/birthday/champagne-bottle.svg'},
  ]},
  {id: 'thank-you-all', name: 'Thank You', avatars:[
      {name: 'Fire heart', image:'https://storage.googleapis.com/prod-rlink-avatars/thank-you/fire-heart.svg'},
      {name: 'Panda', image:'https://storage.googleapis.com/prod-rlink-avatars/thank-you/kiss-panda.svg'},
      {name: 'Trust', image:'https://storage.googleapis.com/prod-rlink-avatars/thank-you/trust.svg'},
  ]},
  {id: 'farewell-retirement', name: 'Farewell/Retirement', avatars:[
      {name: 'Beach', image:'https://storage.googleapis.com/prod-rlink-avatars/farewell-retirement/beach.svg'},
      {name: 'Thumbs up', image:'https://storage.googleapis.com/prod-rlink-avatars/farewell-retirement/good-quality.svg'},
      {name: 'Sad funny face', image:'https://storage.googleapis.com/prod-rlink-avatars/farewell-retirement/not-bad-meme.svg'},
      {name: 'Sailboat', image:'https://storage.googleapis.com/prod-rlink-avatars/farewell-retirement/sailboat.svg'},
      {name: 'Bye', image:'https://storage.googleapis.com/prod-rlink-avatars/farewell-retirement/so-so.svg'},
      {name: 'New day', image:'https://storage.googleapis.com/prod-rlink-avatars/farewell-retirement/sun.svg'},
      {name: 'Peace', image:'https://storage.googleapis.com/prod-rlink-avatars/farewell-retirement/v-live.svg'},
      {name: 'Umbrella', image:'https://storage.googleapis.com/prod-rlink-avatars/farewell-retirement/beach-umbrella.svg'},
      {name: 'Angel', image:'https://storage.googleapis.com/prod-rlink-avatars/farewell-retirement/angel-with-sword.svg'},
    ]},
  {id: 'new-baby', name: 'New baby', avatars:[
      {name: 'Stroller', image:'https://storage.googleapis.com/prod-rlink-avatars/new-baby/baby-stroller.svg'},
      {name: 'Embryo', image:'https://storage.googleapis.com/prod-rlink-avatars/new-baby/embryo.svg'},
      {name: 'Nappy', image:'https://storage.googleapis.com/prod-rlink-avatars/new-baby/nappy.svg'},
      {name: 'Pacifier', image:'https://storage.googleapis.com/prod-rlink-avatars/new-baby/pacifier.svg'},
      {name: 'Sippy cup', image:'https://storage.googleapis.com/prod-rlink-avatars/new-baby/sippy-cup.svg'},
      {name: 'Teddy bear', image:'https://storage.googleapis.com/prod-rlink-avatars/new-baby/teddy-bear.svg'},
      {name: 'Onesie', image:'https://storage.googleapis.com/prod-rlink-avatars/new-baby/onesies.svg'},
    ]},
  {id: 'congratulations', name: 'Congratulations', avatars:[
      {name: 'Education', image:'https://storage.googleapis.com/prod-rlink-avatars/congratulations/education.svg'},
      {name: 'Graduation', image:'https://storage.googleapis.com/prod-rlink-avatars/congratulations/graduation-cap.svg'},
      {name: 'Hammer', image:'https://storage.googleapis.com/prod-rlink-avatars/congratulations/hammer.svg'},
      {name: 'Hand shake', image:'https://storage.googleapis.com/prod-rlink-avatars/congratulations/handshake.svg'},
      {name: 'Improvement', image:'https://storage.googleapis.com/prod-rlink-avatars/congratulations/improvement.svg'},
      {name: 'Medal', image:'https://storage.googleapis.com/prod-rlink-avatars/congratulations/medal2.svg'},
      {name: 'Star', image:'https://storage.googleapis.com/prod-rlink-avatars/congratulations/star-filled.svg'},
      {name: 'Trophy', image:'https://storage.googleapis.com/prod-rlink-avatars/congratulations/trophy.svg'},
    ]},
  {id: 'wedding', name: 'Wedding', avatars:[
      {name: 'Diamond ring', image:'https://storage.googleapis.com/prod-rlink-avatars/wedding/diamond-ring.svg'},
      {name: 'Half heart', image:'https://storage.googleapis.com/prod-rlink-avatars/wedding/half-heart.svg'},
      {name: 'Heart puzzle', image:'https://storage.googleapis.com/prod-rlink-avatars/wedding/heart-puzzle.svg'},
      {name: 'Love arrow', image:'https://storage.googleapis.com/prod-rlink-avatars/wedding/love-arrow.svg'},
      {name: 'Romance', image:'https://storage.googleapis.com/prod-rlink-avatars/wedding/romance.svg'},
      {name: 'Small hearts', image:'https://storage.googleapis.com/prod-rlink-avatars/wedding/small-hearts.svg'},
    ]},
  {id: 'characters', name: 'Characters', avatars:[
      {name: 'Animation', image:'https://storage.googleapis.com/prod-rlink-avatars/characters/animation.svg'},
      {name: 'Anonymous mask', image:'https://storage.googleapis.com/prod-rlink-avatars/characters/anonymous-mask.svg'},
      {name: 'Dog', image:'https://storage.googleapis.com/prod-rlink-avatars/characters/dog.svg'},
      {name: 'Bender', image:'https://storage.googleapis.com/prod-rlink-avatars/characters/futurama-bender.svg'},
      {name: 'Harry Potter', image:'https://storage.googleapis.com/prod-rlink-avatars/characters/harry-potter.svg'},
      {name: 'Hello Kitty', image:'https://storage.googleapis.com/prod-rlink-avatars/characters/hello-kitty.svg'},
      {name: 'Homer', image:'https://storage.googleapis.com/prod-rlink-avatars/characters/homer-simpson.svg'},
      {name: 'Iron man', image:'https://storage.googleapis.com/prod-rlink-avatars/characters/iron-man.svg'},
      {name: 'Storm trooper', image:'https://storage.googleapis.com/prod-rlink-avatars/characters/istormtrooper.svg'},
      {name: 'Nurse', image:'https://storage.googleapis.com/prod-rlink-avatars/characters/nurse.svg'},
      {name: 'Pokemon', image:'https://storage.googleapis.com/prod-rlink-avatars/characters/pokemon.svg'},
      {name: 'Princess Leia', image:'https://storage.googleapis.com/prod-rlink-avatars/characters/princess-leia.svg'},
      {name: 'Millennium falcon', image:'https://storage.googleapis.com/prod-rlink-avatars/characters/star-wars-millenium-falcon.svg'},
      {name: 'Walter white', image:'https://storage.googleapis.com/prod-rlink-avatars/characters/walter-white.svg'},
    ]},
  {id: 'get-well', name: 'Get well soon', avatars:[
      {name: 'Boxing glove', image:'https://storage.googleapis.com/prod-rlink-avatars/get-well/boxing-glove.svg'},
      {name: 'Dumbbell', image:'https://storage.googleapis.com/prod-rlink-avatars/get-well/dumbbell.svg'},
      {name: 'Doctors bag', image:'https://storage.googleapis.com/prod-rlink-avatars/get-well/doctors-bag.svg'},
      {name: 'Exercise', image:'https://storage.googleapis.com/prod-rlink-avatars/get-well/exercise.svg'},
      {name: 'Hammer throw', image:'https://storage.googleapis.com/prod-rlink-avatars/get-well/hammer-throw.svg'},
      {name: 'Running', image:'https://storage.googleapis.com/prod-rlink-avatars/get-well/girl-running.svg'},
      {name: 'Health', image:'https://storage.googleapis.com/prod-rlink-avatars/get-well/health.svg'},
      {name: 'Strength', image:'https://storage.googleapis.com/prod-rlink-avatars/get-well/strength.svg'},
      {name: 'Track and field', image:'https://storage.googleapis.com/prod-rlink-avatars/get-well/women-track-and-field.svg'},
    ]},
  {id: 'holidays', name: 'Holidays', avatars:[
      {name: 'Day of the dead', image:'https://storage.googleapis.com/prod-rlink-avatars/holidays/day-of-the-dead.svg'},
      {name: 'Easter egg', image:'https://storage.googleapis.com/prod-rlink-avatars/holidays/easter-egg.svg'},
      {name: 'Gingerbread House', image:'https://storage.googleapis.com/prod-rlink-avatars/holidays/gingerbread-house.svg'},
      {name: 'Hay day', image:'https://storage.googleapis.com/prod-rlink-avatars/holidays/hay-day.svg'},
      {name: 'Jingle bell', image:'https://storage.googleapis.com/prod-rlink-avatars/holidays/jingle-bell.svg'},
      {name: 'Santa', image:'https://storage.googleapis.com/prod-rlink-avatars/holidays/santa.svg'},
      {name: 'USA', image:'https://storage.googleapis.com/prod-rlink-avatars/holidays/usa.svg'},
    ]},
]