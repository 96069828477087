import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import TransitionComponent from "../transitionComponent/TransitionComponent";
import DialogContent from "@mui/material/DialogContent";
import InviteContributors from "../../pages/groupCard/InviteContributors";
import * as React from "react";
import TitleFont from "./TitleFont";

export default function TitleFontDialog({card, closeDialog, open}){
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  function handleClose() {
    closeDialog();
  }

  return(
    <Dialog
      TransitionComponent={TransitionComponent}
      fullScreen={fullScreen}
      maxWidth="sm"
      // fullScreen={true}
      onClose={handleClose} open={open} >
      <DialogContent>
        <TitleFont card={card} closeModal={closeDialog} />
      </DialogContent>
    </Dialog>
  )

}