import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {CreateGroupCardGraph, InviteOtherGraph, ShareWithOthersGraph} from "../../svgIcons/icons";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import PurplePill from "../../components/purplePill/PurplePill";
import TitleText from "../../components/titleText/TitleText";
import VideoPlayer from "../../components/videoPlayer/VideoPlayer";

import {resetCardState} from "../../slices/cardSlice";
import {linkPaths} from "../../utils/linkPaths";
import Divider from "../../components/divider/Divider";


export default function HowItWorksHR(){
  const navigate = useNavigate();
  const dispatch = useDispatch()

  function goCreateCard() {
    dispatch(resetCardState())
    navigate(linkPaths.create.path)
  }
  return (
    <Box sx={{ paddingBottom: 0, width: '100%'}}>
      <Grid container maxWidth="lg" sx={{margin:'50px auto 0 auto',padding: { xs: '40px 17px'}}}>
        <Grid item xs={12}>
          <PurplePill maxWidth='180px' >How it works</PurplePill>
          <TitleText align="center">Automate Recognition with Digital Group Cards</TitleText>
          <Typography variant="subtitle2" sx={{ textAlign:{ xs: 'center'}, marginBottom:{xs:'30px', md: '60px'} }}>Fuel stronger relationships and achieve better business results through automated recognition. Enter your employees' information, and soon they will be engaging with each other, fostering a connected and motivated team.</Typography>
        </Grid>
        <Grid item xs={12} >
          <Box sx={{width:'300px', margin: {xs: '0 auto'} }}>
            <img width="100%" src="https://media3.giphy.com/media/RzNBbh3vF7Q41oLIE9/giphy.gif?cid=a477b6a03eo9xrf9gznwhp3o4yiu5tmnfjqsslqza0tby2e2&amp;ep=v1_gifs_search&amp;rid=giphy.gif&amp;ct=g" alt="recognition" />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ paddingTop:{xs:'40px', md: '40px'}, width:{xs:'80%', sm:'60%', md:'80%'}, margin: '0 auto', textAlign:{ xs:'center'} }}>
            <Typography variant="subtitle1">1. Add Employees and Configure Rewards</Typography>
            <Typography variant="body2" sx={{marginTop: '20px'}}>Provide the system with your employees' details, including their hire dates and birthdays. Optionally, you can configure monetary rewards to be included in the digital group cards based on the occasion or specific employees.</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ paddingTop:{xs:'40px', md: '40px'}, width:{xs:'80%', sm:'60%', md:'80%'}, margin: '0 auto', textAlign:{ xs:'center'} }}>
            <Typography variant="subtitle1">2. Automation Begins</Typography>
            <Typography variant="body2" sx={{marginTop: '20px'}}>Our system automatically creates group cards one week before the celebration date, allowing other employees to add personalized messages, GIFs, photos, and videos. You can also create group cards on demand to celebrate any business or employee milestone. </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ paddingTop:{xs:'40px', md: '40px'}, width:{xs:'80%', sm:'60%', md:'80%'}, margin: '0 auto', textAlign:{ xs:'center'} }}>
            <Typography variant="subtitle1">3. Cards are Delivered</Typography>
            <Typography variant="body2" sx={{marginTop: '20px'}}>On the celebration date, the card is delivered via email to the recipient. The card recipient also has the option to reply to those who contributed their messages, enhancing interaction and appreciation within your team.</Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ textAlign:{ xs: 'center'}, marginTop:{xs:'30px', md: '60px'} }}>This streamlined process ensures consistent and meaningful recognition, boosting morale and fostering a positive workplace culture.</Typography>
        </Grid>

      </Grid>
    </Box>
  )
}