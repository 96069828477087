import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import BrowseCardDesigns from "./BrowseCardDesigns";
import {useGetCategoriesQuery} from "../../slices/api/apiSlice";
import InfoSnackbar from "../infoSnackbar/InfoSnackbar";
import {goBackArrow} from "../../svgIcons/icons";
import CircularProgress from "@mui/material/CircularProgress";

export default function BrowseCategories({card, closeDialog}) {
  const {
    data: categories,
    isLoading: getCategoriesIsLoading,
    isSuccess: getCategoriesIsSuccess,
    isError: getCategoriesIsError,
  } = useGetCategoriesQuery()


  const [showCategories, setShowCategories] = useState(true);
  const [category, setCategory] = useState({});

  function handleCategorySelection(mainCategory) {
    setCategory(mainCategory)
    setShowCategories(false);
  }

  return(
    <Box>
      {getCategoriesIsError && <InfoSnackbar message="Sorry...error getting the card categories, please refresh this page."/>}
      {getCategoriesIsLoading && <CircularProgress />}
      {showCategories && getCategoriesIsSuccess &&
        <>
          <Typography variant="subtitle2" sx={{marginBottom:{xs: '20px', md: '30px'}, paddingLeft:{xs:'10px', sm:0}, }}>Choose a category</Typography>
          {/*<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', textAlign: 'center', justifyContent: 'center'}}>*/}
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', textAlign: 'center', padding:{xs:'5px', sm: '10px'} }}>
            {categories.map((mainCategory, index) => {
              return <Box
                key={mainCategory.id}
                onClick={() => handleCategorySelection(mainCategory)}
                sx={{width: {xs: '100px', md: '120px', xl: '160px'}, textAlign:'center','&:hover': {cursor: 'pointer'}, margin: {xs: '6px 6px 6px 6px', md: '6px 15px 24px 15px'} }}  >
                <Box sx={{
                  // width:{ xs: '155px', md: '238px'},
                  // height: { xs: '130px', md: '240px'},
                  width:{ xs: '100px',md: '120px', xl: '155px'},
                  height: { xs: '100px', md: '115px', xl: '130px'},
                  background: `url('${mainCategory.image}')`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  borderRadius: '20px',
                  margin: '0 auto',
                }}>
                </Box>
                <Typography variant="subtitle1" sx={{ fontSize: {xs: '14px', md: '16px', lg: '18px'}, fontWeight: 500}}>{mainCategory.title}</Typography>
              </Box>
            })}
          </Box>
        </>
      }
      {!showCategories &&
        <>
          <Box sx={{display:'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom:{ xs: '20px', md: '30px'}  }}>
            <Typography variant="subtitle2" >{category.title}</Typography>
            <Typography
              onClick={() => { setShowCategories(true)}}
              component="span"
              variant='subtitle2'
              sx={{ cursor: 'pointer', color:'primary.main', fontSize: {xs: '14px', md: '16px'} }}>{goBackArrow} View all categories</Typography>
          </Box>
          <BrowseCardDesigns closeDialog={closeDialog} category={category} card={card} />
        </>
      }
    </Box>
  )
}