import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";

export default function TermsOfService() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const returnAsLink = (text, link) => {
    return (
      <Button variant="text" onClick={() => {
        navigate(link);
      }}>
        {text}
      </Button>
    )
  };

  const cssOptions = { m: '16px 0px'}
  return (
    <Grid container maxWidth="lg" sx={{mr: 'auto', ml:'auto', textAlign: 'left'}}>
      <Grid xs={12} sx={{padding: '30px'}}>
        <Typography variant="h1"> Terms of Service</Typography>

        <Typography variant="h3"> Statement of Rights and Responsibilities</Typography>
        <Typography variant="body1" sx={cssOptions} >This Statement of Rights and Responsibilities is our terms of service that governs our relationship with users and others who interact with RecognitionLink.com, as well as RecognitionLink.com brands, products and services, which we call the "RecognitionLink.com Services" or "Services". By using or accessing the RecognitionLink.com Services, you agree to this Statement.</Typography>

        <Typography variant="h3"> Privacy</Typography>
        <Typography variant="body1" sx={cssOptions} >{returnAsLink('View Privacy Policy.','/privacy-policy')}</Typography>

        <Typography variant="h3">Sharing Your Content and Information</Typography>
        <Typography variant="body1" sx={cssOptions} > You own all of the content and information you post on RecognitionLink.com. In addition:</Typography>

        <Typography variant="body1" sx={cssOptions} >For all content that you post (IP Content), you specifically give us the following permission: you grant us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use any content that you post on or in connection with RecognitionLink.com (IP License). This IP License ends when you delete your IP content or your account unless your content has been shared with others, and they have not deleted it. This license is used to display content on your group cards and deliver RecognitionLink.com services to users. We do not publicly share user-generated RecognitionLink.com content on our website unless we have received permission from the card creator or card's recipient.</Typography>
        <Typography variant="body1" sx={cssOptions} > When you delete IP content, it is deleted in a manner similar to emptying the recycle bin on a computer. However, you understand that removed content may persist in backup copies for a reasonable period of time (but will not be available to others).</Typography>
        <Typography variant="body1" sx={cssOptions} >When you publish content or information, it means that you are allowing everyone, including people off of RecognitionLink.com, to access and use that information, including to repost and/or publish the content without restriction, and to associate it with you.</Typography>
        <Typography variant="body1" sx={cssOptions} >To delete all information related to your account, please send a request to support@recognitionlink.com</Typography>
        <Typography variant="body1" sx={cssOptions} >We always appreciate your feedback or other suggestions about RecognitionLink.com, but you understand that we may use your feedback or suggestions without any obligation to compensate you for them (just as you have no obligation to offer them).</Typography>


        <Typography variant="h3">Safety</Typography>
        <Typography variant="body1" sx={cssOptions} >We do our best to keep RecognitionLink.com safe, but we cannot guarantee it. We need your help to keep RecognitionLink.com safe, which includes the following commitments by you:</Typography>

        <ul>
          <Typography variant="body1" sx={cssOptions} >You will not post unauthorized commercial communications (such as spam) on RecognitionLink.com.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not collect users' content or information, or otherwise access RecognitionLink.com, using automated means (such as harvesting bots, robots, spiders, or scrapers) without our prior permission.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not engage in unlawful multi-level marketing, such as a pyramid scheme, on RecognitionLink.com.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not upload viruses or other malicious code.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not solicit login information or access an account belonging to someone else.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not bully, intimidate, or harass any user.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not post any content that: is hate speech, threatening, or pornographic; incites violence; or contains nudity or graphic or gratuitous violence.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not develop or operate a third-party application containing alcohol-related, dating or other mature content (including advertisements) without appropriate age-based restrictions.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not use RecognitionLink.com to do anything unlawful, misleading, malicious, or discriminatory.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not do anything that could disable, overburden, or impair the proper working or appearance of RecognitionLink.com, such as a denial of service attack or interference with page rendering or other RecognitionLink.com functionality.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not facilitate or encourage any violations of this Statement or our policies.</Typography>
        </ul>


        <Typography variant="h3">Registration and Account Security</Typography>
        <Typography variant="body1" sx={cssOptions} >RecognitionLink.com users provide their real names and information before having the ability to create a board, and we need your help to keep it that way. Here are some commitments you make to us relating to registering and maintaining the security of your account:</Typography>

        <ul>
          <Typography variant="body1" sx={cssOptions} >You will not provide any false personal information on RecognitionLink.com, or create an account for anyone other than yourself without permission.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not create more than one personal account.</Typography>
          <Typography variant="body1" sx={cssOptions} >If we disable your account, you will not create another one without our permission.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not use RecognitionLink.com primarily for your own commercial gain without our express written consent.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not use RecognitionLink.com if you are under 13.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not use RecognitionLink.com if you are a convicted sex offender.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will keep your contact information accurate and up-to-date.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not share your password , let anyone else access your account, or do anything else that might jeopardize the security of your account.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not transfer your account to anyone without first getting our written permission.</Typography>
          <Typography variant="body1" sx={cssOptions} >If you select a username or similar identifier for your account, we reserve the right to remove or reclaim it if we believe it is appropriate (such as when a trademark owner complains about a username that does not closely relate to a user's actual name).</Typography>
        </ul>


        <Typography variant="h3">Protecting Other People's Rights</Typography>
        <Typography variant="body1" sx={cssOptions} >We respect other people's rights, and expect you to do the same.</Typography>

        <ul>
          <Typography variant="body1" sx={cssOptions} >You will not post content or take any action on RecognitionLink.com that infringes or violates someone else's rights or otherwise violates the law.</Typography>
          <Typography variant="body1" sx={cssOptions} >We can remove any content or information you post on RecognitionLink.com if we believe that it violates this Statement or our policies.</Typography>
          <Typography variant="body1" sx={cssOptions} >If you repeatedly infringe other people's intellectual property rights, we will disable your account when appropriate.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not use our copyrights or Trademarks or any confusingly similar marks, except with our prior written permission.</Typography>
          <Typography variant="body1" sx={cssOptions} > You will not post anyone's identification documents or sensitive financial information on RecognitionLink.com.</Typography>
          <Typography variant="body1" sx={cssOptions} >You will not send RecognitionLink.com contribution invitations to people whom you do not know.</Typography>
        </ul>


        <Typography variant="h3">Mobile and Other Devices</Typography>
        <Typography variant="body1" sx={cssOptions} >We currently provide our mobile services for free, but please be aware that your carrier's normal rates and fees, such as text messaging and data charges, will still apply.
          You provide consent and all rights necessary to enable users to sync (including through an application) their devices with any information that is visible to them on RecognitionLink.com.</Typography>


        <Typography variant="h3"> Payments</Typography>
        <Typography variant="body1" sx={cssOptions} >Billing & Refunds. For individual RecognitionLinks purchases, you authorize RecognitionLink.com to charge you the agreed upon fee at time of purchase. For all subscriptions, you authorize RecognitionLink.com to charge you all fees for the duration of the term agreed to at the time of purchase. Except when required by law, RecognitionLink.com shall be under no obligation to issue refunds under any circumstances. All fees are non-refundable, even if an individual premium RecognitionLink.com is not used or a subscription is terminated before its expiration. In the event that RecognitionLink.com determines that you are entitled to a refund of all or part of the fees you paid, such refund shall be made using the payment method originally used by you to make your purchase.</Typography>

        <Typography variant="body1" sx={cssOptions} >Renewals. Following the expiration of a subscription plan, such plan will automatically renew on the same terms as your original plan purchase. You can disable automatic renewal at any time prior to renewal using your account settings. You expressly grant RecognitionLink.com the right to charge you for each automatic renewal until you disable automatic renewal.</Typography>

        <Typography variant="body1" sx={cssOptions} >Taxes. Fees do not include any local, state, federal or foreign taxes, levies, duties or similar governmental assessments of any nature, including value-added, use or withholding taxes (collectively, "Taxes"). Customer is responsible for payment of all Taxes associated with its purchases hereunder (excluding taxes based on RecognitionLink.com's net income or property), and any related penalties and interest. Customer will make all required payments to RecognitionLink.com free and clear of, and without reduction for, any withholding taxes. Customer will, upon RecognitionLink.com's request, provide RecognitionLink.com with official receipts issued by appropriate taxing authorities, or such other evidence as RecognitionLink.com may reasonably request, to establish that such Taxes have been paid.</Typography>


        <Typography variant="body1" sx={cssOptions} > Credits. RecognitionLink.com may, at its sole discretion, choose to offer credits for its services, including discount codes or promotional campaigns. Credits are non-transferable and do not have any monetary or cash value. Any credits can only be used to offset fees per the terms and conditions expressly identified by RecognitionLink.com and, unless otherwise stated by RecognitionLink.com, shall no longer be redeemable ninety (90) days from the date of issuance.</Typography>

        <Typography variant="body1" sx={cssOptions} >Failure of Payment. If Customer fails to pay fees, in addition to other available remedies, RecognitionLink.com reserves the right to suspend or terminate access to the Application Services and delete the Customer Content.</Typography>

        <Typography variant="h3">Amendments</Typography>
        <Typography variant="body1" sx={cssOptions} >We'll notify you before we make changes to these terms and give you the opportunity to review and comment on the revised terms before continuing to use our Services.</Typography>
        <Typography variant="body1" sx={cssOptions} >Your continued use of the RecognitionLink.com Services, following notice of the changes to our terms, policies or guidelines, constitutes your acceptance of our amended terms, policies or guidelines.</Typography>

        <Typography variant="h3">Abuse of Service and Termination of Subscription</Typography>
        <Typography variant="body1" sx={cssOptions} >We reserve the right to monitor the usage of our services and take necessary actions to prevent abuse or misuse. This includes but is not limited to, automated generation of cards, bulk creation without genuine user engagement, or any other activity deemed to be in violation of our intended use policy.</Typography>
        <Typography variant="body1" sx={cssOptions} >In the event that we detect any abuse or misuse of our services, we reserve the right to take immediate action, which may include terminating the subscription of the offending user without prior notice or refund. Additionally, we reserve the right to deny access to our services, either temporarily or permanently, to any user found to be abusing the service, at our sole discretion.</Typography>
        <Typography variant="body1" sx={cssOptions} >Furthermore, we may take further legal action if necessary to protect our rights and interests.</Typography>
        <Typography variant="body1" sx={cssOptions} >By agreeing to these terms, you acknowledge that our subscription service is intended for legitimate usage and agree not to engage in any activity that violates this policy.</Typography>

        <Typography variant="h3">Disputes</Typography>
        <Typography variant="body1" sx={cssOptions} >You will resolve any claim, cause of action or dispute (claim) you have with us arising out of or relating to this Statement or RecognitionLink.com exclusively in U.S. District Court in Texas and you agree to submit to the personal jurisdiction of such courts for the purpose of litigating all such claims. The laws of the State of Texas will govern this Statement, as well as any claim that might arise between you and us, without regard to conflict of law provisions.</Typography>
        <Typography variant="body1" sx={cssOptions} >If anyone brings a claim against us related to your actions, content or information on RecognitionLink.com, you will indemnify and hold us harmless from and against all damages, losses, and expenses of any kind (including reasonable legal fees and costs) related to such claim. Although we provide rules for user conduct, we do not control or direct users' actions on RecognitionLink.com and are not responsible for the content or information users transmit or share on RecognitionLink.com. We are not responsible for any offensive, inappropriate, obscene, unlawful or otherwise objectionable content or information you may encounter on RecognitionLink.com. We are not responsible for the conduct, whether online or offline, of any user of RecognitionLink.com.</Typography>

        <Typography variant="body1" sx={cssOptions} >WE TRY TO KEEP RecognitionLink.com UP, BUG-FREE, AND SAFE, BUT YOU USE IT AT YOUR OWN RISK. WE ARE PROVIDING RecognitionLink.com AS IS WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE DO NOT GUARANTEE THAT RecognitionLink.com WILL ALWAYS BE SAFE, SECURE OR ERROR-FREE OR THAT RecognitionLink.com WILL ALWAYS FUNCTION WITHOUT DISRUPTIONS, DELAYS OR IMPERFECTIONS. RecognitionLink.com IS NOT RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD PARTIES, AND YOU RELEASE US, OUR DIRECTORS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD PARTIES. IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE §1542, WHICH SAYS: A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR. WE WILL NOT BE LIABLE TO YOU FOR ANY LOST PROFITS OR OTHER CONSEQUENTIAL, SPECIAL, INDIRECT, OR INCIDENTAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH THIS STATEMENT OR RecognitionLink.com, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE LIABILITY ARISING OUT OF THIS STATEMENT OR RecognitionLink.com WILL NOT EXCEED THE GREATER OF ONE HUNDRED DOLLARS ($100) OR THE AMOUNT YOU HAVE PAID US IN THE PAST TWELVE MONTHS. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES, RecognitionLink.com'S LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.</Typography>

        <Typography variant="h3">Special Provisions Applicable to Users Outside the United States</Typography>
        <Typography variant="body1" sx={cssOptions} >We strive to create a global community with consistent standards for everyone, but we also strive to respect local laws. The following provisions apply to users and non-users who interact with RecognitionLink.com outside the United States:</Typography>

        <ul>
          <Typography variant="body1" sx={cssOptions} > You consent to having your personal data transferred to and processed in the United States.</Typography>
          <Typography variant="body1" sx={cssOptions} >If you are located in a country embargoed by the United States, or are on the U.S. Treasury Department's list of Specially Designated Nationals you will not engage in commercial activities on RecognitionLink.com (such as advertising or payments). You will not use RecognitionLink.com if you are prohibited from receiving products, services, or software originating from the United States.</Typography>
        </ul>

        <Typography variant="h3">Digital Millennium Copyright Act</Typography>
        <Typography variant="body1" sx={cssOptions} >RecognitionLink.com complies with the requirements of the Digital Millennium Copyright Act ("DMCA"). RecognitionLink.com may terminate your Account and this Agreement with you or any other user of the Applications who infringes on the copyrights of another (provided that these Terms and Conditions will survive any such termination). If you have any concerns or believe that any material on the Applications in any way constitutes copyright infringement, please submit an online takedown request here. Alternatively, you can contact the agent designated below, who has been designated to respond to reports alleging copyright infringement. Provide the DMCA Agent with the following information in writing by electronic mail:.</Typography>

        <ul>
          <Typography variant="body1" sx={cssOptions} >A physical or electronic signature of the copyright holder or a person authorized to act on his or her behalf</Typography>
          <Typography variant="body1" sx={cssOptions} >A description of the copyrighted work claimed to have been infringed</Typography>
          <Typography variant="body1" sx={cssOptions} >A description of the material that is claimed to be infringing or to be the subject of infringing activity, and its location on the Applications</Typography>
          <Typography variant="body1" sx={cssOptions} >Your address, telephone number, and, if available, an email address</Typography>
          <Typography variant="body1" sx={cssOptions} >A statement that the you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law</Typography>
          <Typography variant="body1" sx={cssOptions} >A statement that the information in your notice is accurate, and under penalty of perjury, and that you are authorized to act on behalf of the owner of the allegedly infringed copyright.</Typography>
        </ul>

        <Typography variant="body1" sx={cssOptions} >Email Address for RecognitionLink.com's DMCA Agent:</Typography>

        <Typography variant="body1" sx={cssOptions} >dmca@RecognitionLink.com.com</Typography>

        <Typography variant="h3">Definitions</Typography>
        <Typography variant="body1" sx={cssOptions} >By "RecognitionLink.com" or" RecognitionLink.com Services" we mean the features and services we make available, including through (a) our website at www.RecognitionLink.com.com and any other RecognitionLink.com branded or co-branded websites (including sub-domains, international versions, and mobile versions); (b) our Platform; (c) social plugins; and (d) other media, brands, products, services, software (such as a toolbar), devices, or networks now existing or later developed. RecognitionLink.com reserves the right to designate, in its sole discretion, that certain of our brands, products, or services are governed by separate terms and not this SRR.</Typography>
        <Typography variant="body1" sx={cssOptions} >By "information" we mean facts and other information about you, including actions taken by users and non-users who interact with RecognitionLink.com.</Typography>
        <Typography variant="body1" sx={cssOptions} >By "content" we mean anything you or other users post, provide or share using RecognitionLink.com Services.</Typography>
        <Typography variant="body1" sx={cssOptions} > By "data" or "user data" or "user's data" we mean any data, including a user's content or information that you or third parties can retrieve from RecognitionLink.com or provide to RecognitionLink.com through Platform.</Typography>
        <Typography variant="body1" sx={cssOptions} >By "post" we mean post on RecognitionLink.com or otherwise make available by using RecognitionLink.com.</Typography>
        <Typography variant="body1" sx={cssOptions} >By "use" we mean use, run, copy, publicly perform or display, distribute, modify, translate, and create derivative works of.</Typography>

        <Typography variant="h3">Other</Typography>
        <Typography variant="body1" sx={cssOptions} >If you are a resident of or have your principal place of business in the US or Canada, this Statement is an agreement between you and RecognitionLink.com. References to "us," "we," and "our" mean RecognitionLink.com</Typography>
        <Typography variant="body1" sx={cssOptions} >This Statement makes up the entire agreement between the parties regarding RecognitionLink.com, and supersedes any prior agreements.</Typography>
        <Typography variant="body1" sx={cssOptions} >If any portion of this Statement is found to be unenforceable, the remaining portion will remain in full force and effect.</Typography>
        <Typography variant="body1" sx={cssOptions} >If we fail to enforce any of this Statement, it will not be considered a waiver.</Typography>
        <Typography variant="body1" sx={cssOptions} >When you start a new RecognitionLink.com on our primary domain (RecognitionLink.com.com), you are added to our marketing email list. You can unsubscribe to this list at any point.</Typography>
        <Typography variant="body1" sx={cssOptions} >Any amendment to or waiver of this Statement must be made in writing and signed by us.</Typography>
        <Typography variant="body1" sx={cssOptions} >You will not transfer any of your rights or obligations under this Statement to anyone else without our consent.</Typography>
        <Typography variant="body1" sx={cssOptions} > All of our rights and obligations under this Statement are freely assignable by us in connection with a merger, acquisition, or sale of assets, or by operation of law or otherwise.</Typography>
        <Typography variant="body1" sx={cssOptions} >Nothing in this Statement shall prevent us from complying with the law.</Typography>
        <Typography variant="body1" sx={cssOptions} >This Statement does not confer any third party beneficiary rights.</Typography>
        <Typography variant="body1" sx={cssOptions} >We reserve all rights not expressly granted to you.</Typography>
        <Typography variant="body1" sx={cssOptions} >You will comply with all applicable laws when using or accessing RecognitionLink.com.</Typography>

        <Typography variant="h3">Contact Us</Typography>
        <Typography variant="body1" sx={cssOptions}>If you have any questions or suggestions about our Terms of Service, do not hesitate to contact us at support@RecognitionLink.com</Typography>
        <Typography variant="body1" sx={cssOptions}>LAST UPDATED: April 2th, 2024</Typography>

      </Grid>
    </Grid>
  )
}