import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {CloseIcon, copyToClipboardIcon, PremiumCrown} from "../../../svgIcons/icons";
import * as React from "react";
import Divider from "../../../components/divider/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import {getRecipientUrl, isValidEmail} from "../../../utils/functions";
import { colorsPallete } from "../../../utils/colors";
import Button from "@mui/material/Button";
import TransitionComponent from "../../../components/transitionComponent/TransitionComponent";
import {useUpdateCardMutation, useSendCardMutation} from "../../../slices/api/cardApiSlice";
import {useDispatch, useSelector} from "react-redux";
import {scheduleSet, updateCardData} from "../../../slices/cardSlice";
import {useParams} from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {copyTextToClipboard} from "../../../components/utils/utils";
import InfoSnackbar from "../../../components/infoSnackbar/InfoSnackbar";
import Grid from "@mui/material/Grid";
import {Alert} from "@mui/material";
import CardUpgradeOptionsDialog from "../../../components/cardUpgradeOptionsDialog/CardUpgradeOptionsDialog";


export default function SendOrSchedule({card, open = false, closeDialog = ()=>{}}) {

  // let recipientEmailInitialState = '';
  // if(card.recipients && card.recipients.length > 0) {
  //   recipientEmailInitialState = card.recipients[0].email;
  // }
  const { cardId } = useParams();
  const [updateCard, { isLoading: isCardUpdating, isSuccess: cardUpdatedSuccessfully}] = useUpdateCardMutation();
  const [sendCard, {isLoading: isSendingCard, isSuccess: cardSentNow}] = useSendCardMutation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const inAWeek = dayjs().add(7, 'day');
  const tomorrow = dayjs().add(1, 'day');
  const initialDate = !!card.schedule ? dayjs(card.schedule): inAWeek;
  const [datePicked, setDatePicked] = useState( initialDate );
  const [recipientEmail, setRecipientEmail] = useState(card.recipients[0].email);
  const [emailError, setEmailError] = useState('');
  const [successMessage, setSuccessMessage] = useState('')
  const [tab, setTab] = useState(0);
  const [copied, setCopied] = useState(false);
  const url = getRecipientUrl();
  const [error, setError] = useState('')
  const [scheduleMessage, setScheduleMessage] = useState('');
  const [showUpgradeOptions, setShowUpgradeOptions] = useState(false);

  function handleCopyToClipboard() {
    copyTextToClipboard(url)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    },3000)
  }

  const chooseTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleDateSelection = (date) => {
    setDatePicked(date)
  }

  const handleRecipientEmailUpdate = (e) => {
    setRecipientEmail(e.target.value)
    if(isValidEmail(e.target.value)) {
      setEmailError('')
      setSuccessMessage('Great, email ready!')
    } else if(e.target.value === '') {
      setEmailError('')
      setSuccessMessage('')
    } else {
      setSuccessMessage('')
      setEmailError("Please enter valid recipient's email")
    }
  }

  const handleScheduling = async (e, clearSchedule) => {
    e.preventDefault();
    if(!clearSchedule) {
      if(!isValidEmail(recipientEmail)) {
        return setEmailError('A valid email is required for scheduling');
      }
    }
    const date = new Date(datePicked);
    let utcTime = date.toISOString()

    let recipients = card.recipients.map((recipient) => {
      return { fullName: recipient.fullName, email: recipientEmail }
    })

    if(clearSchedule) {
      utcTime = null;
    }

    try {
      await updateCard({
        id:cardId,
        ...card,
        schedule: utcTime,
        recipients,
      }).unwrap();
      if(clearSchedule) {
        setScheduleMessage('Delivery Removed')
      } else {
        setScheduleMessage('Scheduled for delivery')
      }

      // dispatch(scheduleSet(utcTime));
      setEmailError('')
      setSuccessMessage('')
      // setRecipientEmail(card.recipients[0].email)
      closeDialog()
    } catch (e){
      console.log('error updating schedule', e)
      if (e.originalStatus === 401) {
        setError('Not authorized to update the card. Login or register.')
      } else if (e.originalStatus === 403) {
        setError('Not authorized to update the card. Login or register.')
      } else if(e.originalStatus === 404) {
        setError('Card not found')
      } else {
        setError(`Oops, something went wrong on our end... Please try again`)
      }
    }
  }

  const handleSendNow = async () => {
    if(!isValidEmail(recipientEmail)) {
      return setEmailError('We need a valid email to send the card');
    }
    const cardUpdated = await sendCard({cardId, email:recipientEmail})
    dispatch(updateCardData(cardUpdated.data));
    closeDialog();
  }


  return (
    <>
      {scheduleMessage && <InfoSnackbar severity="success" message={scheduleMessage} callback={() => {setScheduleMessage('')}} /> }
      {cardSentNow && <InfoSnackbar severity="success" message="Card sent successfully" /> }
      {error && <InfoSnackbar onClick={() => setError('')} message={error} severity="error" callback={() => setError('')} /> }
      <Dialog TransitionComponent={TransitionComponent} open={open} fullScreen={fullScreen}>
        <Box sx={{ padding: '30px 30px 20px 30px' }}>
          <Box className="title-and-exit-container" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <Typography variant="h4" sx={{ fontSize: '24px', lineHeight: '32px'}}>
              Schedule or Send Card
            </Typography>
            <CloseIcon cb={() => {closeDialog()}} color="#9E77ED" />
          </Box>
          <Box>
            <Typography variant="subtitle2">Choose one of the options below to send the card to the recipient, after all contributors have added their messages.</Typography>
          </Box>
          {/*<Divider  small={true}/>*/}
          <Box sx={{ marginTop:'20px', borderBottom: 1, borderColor: 'divider' }}>
            <Tabs variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  value={tab} onChange={chooseTab} aria-label="basic tabs example">
              <Tab sx={{padding: '16px 10px'}} className='dashboardTab'  label="Share link" {...a11yProps(0)} />
              <Tab sx={{padding: '16px 10px'}} className='dashboardTab' label="Send now" {...a11yProps(1)} />
              <Tab sx={{padding: '16px 10px'}} className='dashboardTab'  label="Schedule delivery" {...a11yProps(2)} />

            </Tabs>
          </Box>


          <CustomTabPanel value={tab} index={1}>
            <Typography variant="body2" sx={{ paddingRight:{xs: '30px', sm: '60px'}, marginBottom: '20px'  }}>Enter the email for the recipient and send the card now</Typography>

            <Box sx={{ display: 'flex', flexDirection:{ xs: 'column', md: 'row'}, gap: '10px', margin: {xs: '20px 0 0 0', md: '20px auto 0 auto'}, justifyContent: 'space-between', alignItems: { xs: 'left', md:'center'} }}>
              <Typography  variant="body1" sx={{ color: '#344054', fontWeight: 500, fontSize: '14px', lineHeight: '20px', flexGrow:1 }}>
                Recipient's email
              </Typography>
              <Box sx={{ flexGrow:1, textAlign: {xs: 'left', md: 'right'} }}>
                <TextField
                  sx={{ flexGrow: 1, width: '100%', maxWidth: '300px'}}
                  margin="normal"
                  required
                  id="recipientEmail"
                  name="recipientEmail"
                  onChange={handleRecipientEmailUpdate}
                  value={recipientEmail}
                />
              </Box>
            </Box>
            <Box sx={{ height: '20px', margin: '0 auto 30px auto', paddingRight: '10px', textAlign:'right'}}>
              <Typography variant="caption" sx={{color: colorsPallete.errorText}}>{emailError}</Typography>
              { card.deliveryStatus !== 'DELIVERED' && <Typography variant="caption" sx={{color: colorsPallete.successText}}>{successMessage}</Typography>}
              { card.deliveryStatus === 'DELIVERED' && <Typography variant="caption" sx={{color: colorsPallete.successText}}>Card has been delivered successfully.</Typography>}
            </Box>


            <Box sx={{display:'flex', gap: '10px', flexDirection:{ xs: 'column', md: 'row'}  }}>
              <Button  onClick={handleSendNow} disabled={isSendingCard} variant="contained">Send Now</Button>
            </Box>
          </CustomTabPanel>

          <CustomTabPanel value={tab} index={2}>
            {card.tier === 'FREE_PLAN'  &&
              <>
                <Alert
                  id="premium-message-warning"
                  data-testid="premium-message-warning"
                  icon={<PremiumCrown width="16px" height="16px" /> }
                  sx={{ borderRadius: '8px', backgroundColor:'#F9F5FF', color: '#6941C6', fontFamily:"'Inter', sans-serif", paddingBottom:'10px'}}
                  severity="info">
                  <Typography variant="h4" sx={{fontSize:'18px', lineHeight:'22px', marginBottom:'8px'}}>Set the day and time for the card to be delivered by email</Typography>
                  <Typography variant="body2">Upgrade card to enjoy this benefit</Typography>
                  <Button onClick={() => setShowUpgradeOptions(true)}  sx={{marginTop: '20px'}} variant="contained">Upgrade card</Button>
                  <Typography variant="caption">Multiple options for upgrade</Typography>
                </Alert>
                <CardUpgradeOptionsDialog card={card} open={showUpgradeOptions} closeDialog={() => setShowUpgradeOptions(false)} />
              </>
            }

            {card.tier !== 'FREE_PLAN' &&
              <>
                <Typography variant="body2" sx={{ paddingRight:{xs: '30px', sm: '60px'}, marginBottom: '20px'  }}>Set the date and time to deliver the group card.</Typography>
                <Box sx={{ gap: '10px', display: 'flex', flexDirection:{ xs: 'column', md: 'row'}, justifyContent: 'space-between', alignItems: { xs: 'left', md:'center'} }}>
                  <Typography  variant="body1" sx={{ color: '#344054', fontWeight: 500, fontSize: '14px', lineHeight: '20px', flexGrow:1 }}>
                    Choose a date and time
                  </Typography>
                  <Box sx={{ flexGrow:1, textAlign: {xs: 'left', md: 'right'} }}>
                    <DateTimePicker sx={{ width: '100%',maxWidth: '300px'}} value={datePicked} disablePast minDate={tomorrow} onChange={(newValue) => { handleDateSelection(newValue)}} />
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection:{ xs: 'column', md: 'row'}, gap: '10px', margin: {xs: '20px 0 0 0', md: '20px auto 0 auto'}, justifyContent: 'space-between', alignItems: { xs: 'left', md:'center'} }}>
                  <Typography  variant="body1" sx={{ color: '#344054', fontWeight: 500, fontSize: '14px', lineHeight: '20px', flexGrow:1 }}>
                    Recipient's email
                  </Typography>
                  <Box sx={{ flexGrow:1, textAlign: {xs: 'left', md: 'right'} }}>
                    <TextField
                      sx={{ flexGrow: 1, width: '100%', maxWidth: '300px'}}
                      margin="normal"
                      required
                      id="recipientEmail"
                      name="recipientEmail"
                      onChange={handleRecipientEmailUpdate}
                      value={recipientEmail}
                    />
                  </Box>
                </Box>
                <Box sx={{ height: '20px', margin: '0 auto 30px auto', paddingRight: '10px', textAlign:'right'}}>
                  <Typography variant="caption" sx={{color: colorsPallete.errorText}}>{emailError}</Typography>
                  { card.deliveryStatus !== 'DELIVERED' && <Typography variant="caption" sx={{color: colorsPallete.successText}}>{successMessage}</Typography>}
                  { card.deliveryStatus === 'DELIVERED' && <Typography variant="caption" sx={{color: colorsPallete.successText}}>Card has been delivered successfully.</Typography>}
                </Box>


                <Box sx={{display:'flex', gap: '10px', flexDirection:{ xs: 'column', md: 'row'}, justifyContent: 'center'  }}>
                  <Button sx={{ width: '100%', maxWidth:'200px'}} onClick={async (e) => { await handleScheduling(e,true)}}   color="secondary">Clear Schedule</Button>
                  <Button sx={{ width: '100%', maxWidth:'200px'}} onClick={handleScheduling} disabled={isCardUpdating} variant="contained">Schedule</Button>
                </Box>
              </>
            }
          </CustomTabPanel>

          <CustomTabPanel value={tab} index={0}>
            <Typography variant="body2" sx={{ paddingRight:{xs: '30px', sm: '60px'}, marginBottom: '20px'  }}>Copy the following link and share only with the recipient, it is an special link for them.</Typography>
            <Box sx={{marginTop: '10px', overflowWrap: 'break-word', borderRadius: '8px', padding: '10px 14px', border: '1px solid #D0D5DD', background: '#F9FAFB', boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'  }}>
              <Typography variant="body2">{url}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
              <Button
                onClick={() => { handleCopyToClipboard()}}
                sx={{marginTop: { xs: '20px', md: '40px'}, maxWidth: '300px' }}
                variant='contained'
                fullWidth><span style={{paddingRight: '10px', paddingTop: '5px'}}>{copyToClipboardIcon}</span> {copied ? 'Copied' : 'Copy link to clipboard'}</Button>
            </Box>
          </CustomTabPanel>
        </Box>
      </Dialog>
  </>)
}


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div

      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{marginTop: '40px'}}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}