import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { sendIcon } from '../../../svgIcons/icons';
import Chip from "@mui/material/Chip";
import PurplePill from "../../../components/purplePill/PurplePill";
import EmailCollector from "../../../components/emailsCollector/EmailCollector";
import {useState} from "react";
import InfoSnackbar from "../../../components/infoSnackbar/InfoSnackbar";
import {isValidEmail} from "../../../utils/functions";
import {useInviteByEmailMutation} from "../../../slices/api/cardApiSlice";

export default function EmailShare({card, url, closeModal}) {
  const [name, setName] =useState('');
  const [yourMessage, setYourMessage] = useState('');
  const [emails, setEmails] = useState('');
  const [emailsArray, setEmailsArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [success,setSuccess] = useState(false)
  const [sendInvite] = useInviteByEmailMutation();

  async function handleEmailInvite(e) {
    e.preventDefault();
    if(name === '') return setErrorMessage('Your name is required')
    if(emailsArray.length === 0) {
      return setErrorMessage('At least one email is required to send the invite')
    }
    if(!emailsArray.every(email => isValidEmail(email))) {
      return setErrorMessage('Remove invalid emails before sending the invite')
    }

    if(emailsArray.length > 30) {
      return setErrorMessage('You can add up to 30 emails at once')
    }

    try {
      await sendInvite({
        creatorName: name,
        creatorMessage: yourMessage === '' ? null : yourMessage,
        recipients: emailsArray,
        cardId:card.id,
        recipientName:card?.recipients[0]?.fullName,
      }).unwrap();
      setSuccess(true)

      setTimeout(()=>{
        closeModal();
      }, 2000)

    } catch (error) {
      console.log('error sending invite by email', error)
      setErrorMessage(error?.data?.error || 'Error sending the invites, please try again')
    }
  }

  return(
    <Box component='form' onSubmit={handleEmailInvite}>
        {/* Name row */}
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body2" 
            sx={{ paddingTop: '13px', lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Your Name</Typography>
          </Grid>
          <Grid item xs={12}>
          <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                name="name"
                autoFocus
                value={name}
                onChange={(e) => { setName(e.target.value)}}
              />
          </Grid>
        </Grid>
        {/* Email row */}
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body2" 
            sx={{ paddingTop: '13px', lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Contributors' Email Addresses</Typography>
            <Typography variant="body2" 
            sx={{ lineHeight: '20px', fontSize: '14px', color: 'rgba(102, 112, 133, 1)', fontWeight: 500}}>Use commas, spaces or new lines to separate multiple addresses
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <EmailCollector emails={emails} setEmails={setEmails} emailsArray={emailsArray} setEmailsArray={setEmailsArray}/>
          </Grid>
        </Grid>
        {/* Your message row */}
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body2" 
            sx={{  paddingTop: '13px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}>Your Message
            </Typography>
            <Typography variant="body2" 
            sx={{ lineHeight: '20px', fontSize: '14px', color: 'rgba(102, 112, 133, 1)', fontWeight: 500}}>Add a personal message to invite contributors
            </Typography>
          </Grid>
          <Grid item xs={12} >
          <TextField
                margin="normal"
                required
                fullWidth
                id="message"
                name="message"
                multiline
                rows={3}
                value={yourMessage}
                onChange={(e) => { setYourMessage(e.target.value)}}
              />
          </Grid>
        </Grid>
        <Box component='div' sx={{
          marginTop:'20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: '10px'
        }}>
          {errorMessage && <InfoSnackbar onClick={() => setErrorMessage('')} severity="error" message={errorMessage} callback={() => setErrorMessage('')} /> }
          {success && <InfoSnackbar onClick={() => setSuccess(false)} severity="success" message="Invites sent succesfully" callback={() => setSuccess(false)} /> }
          <Button onClick={handleEmailInvite} variant='contained'><span style={{paddingRight: '8px', paddingTop: '5px'}}>{sendIcon}</span>Send email invite</Button>
        </Box>  
      </Box>
  )
}