import ReactPlayer from "react-player";
import mediaTypes from "../../../utils/mediaTypes";
export default function VideoWithUrl({message}){
  if(!message.media) return null;
  if(!message.media.type) return null
  // if(message.media.type !== mediaTypes.USER_VIDEO) return null;
  if(!message.media.data.length === 0 ) return null;

  const mediaUrl = message.media.data[0].fileUrl;

  return(
    <ReactPlayer
      width="100%"
      url={mediaUrl}
      controls={true}
      light={false}
      playing={false}
    />
  )
}