import React, { useState} from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import { colorsPallete } from "../../../utils/colors";

import { smallCrownIcon, scheduleIcon, favoriteIcon, cloneIcon, DeleteIcon, shareIcon } from "../../../svgIcons/icons";

export default function ReminderListItem() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return(
    <Box component='div' className='tableRow'>
      <Box sx={{width:'40%', display: 'flex', alignContent:'center', alignItems: 'center'}}>
        <Box sx={{ paddingRight: '12px'}}>{smallCrownIcon}</Box>
        <Box>
        <Typography component='p' variant='body1' sx={{fontSize: '14px'}}>Max Cabrera, Alessandra Briceno...</Typography>
        <Typography component='p' variant='body2' sx={{fontWeight: 700, fontSize: '14px', color: 'rgba(16, 24, 40, 1)'}}>Happy Birthday</Typography>
        </Box>
      </Box>
      <Box sx={{width:'30%', display: 'flex', alignContent:'center', alignItems: 'center'}}>
        <Box sx={{ paddingRight: '12px', paddingTop: '7px'}}>{scheduleIcon}</Box>
        <Typography component='p' variant='body1' sx={{fontSize: '14px'}}> We will remind you anually on 09/23</Typography>
      </Box>
      <Box sx={{width:'14%', display:'flex', alignContent:'center', alignItems: 'center'}}>
        <Typography component='p' variant='body1' sx={{fontSize: '14px'}}>10/27/23</Typography>
      </Box>
   

      <Box sx={{width:'6%', textAlign: 'right', display:'flex', alignContent:'center', alignItems: 'center'}}>
        <MoreVertIcon
          onClick={handleClick}
          sx={{
            color: 'rgba(152, 162, 179, 1)',
            '&:hover': {
              color: 'primary.main',
              cursor: 'pointer'
            }
          }} />
        <Menu
        sx={{borderRadius: '16px'}}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClose}><Box sx={{paddingRight: '10px', marginTop: '5px'}}><EditIcon sx={{fontSize: '18px'}}/></Box> Edit</MenuItem>
          <MenuItem sx={{color: colorsPallete.errorText}} onClick={handleClose}>
            <Box sx={{paddingRight: '10px', marginTop: '5px'}}><DeleteIcon strokeColor={colorsPallete.errorText}/></Box> Delete
          </MenuItem>
        </Menu>  
      </Box>
    </Box>
 )
}
