import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import PurplePill from "../../components/purplePill/PurplePill";
import Typography from "@mui/material/Typography";
import FAQ from "../../components/faq/FAQ";
import {useEffect} from "react";


export default function SupportPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return(
    <>
      <Box sx={{
        background: '#212529',
        padding: { xs: '64px 30px 400px 30px', sm: '64px 30px 20px 30px', md: '58px 104px', lg:'90px 120px', xl: '100px 200px'},
        backgroundImage: `url('https://storage.googleapis.com/prod-website-images-rlink/black_section_bottom_right.svg'), url('https://storage.googleapis.com/prod-website-images-rlink/black_section_top_left.svg'), url('https://storage.googleapis.com/prod-website-images-rlink/black_section_orange_top_right.svg')`,
        backgroundPosition: { xs: 'right bottom, top left, top right', sm:'right bottom, top left, top right', md:'right bottom, top left, top right'  },
        backgroundRepeat: 'no-repeat',
        backgroundSize: { xs: ' 271px, 132px, 0', sm: ' 271px, 132px, 221px', md: ' 271px, 132px, 221px', lg: ' 271px, 132px, 221px', xl: ' 291px, 232px, 291px' },
      }}>
        <Grid container maxWidth="xl" sx={{margin: '0 auto'}}>
          <Grid item xs={12} sx={{textAlign:'center', marginBottom:'40px'}}>
            <PurplePill>Contact us</PurplePill>
            <Typography
              variant="h1"
              sx={{ color: "white", marginBottom: '20px', letterSpacing:{ xs: '-0.72px'}, fontSize:{ xs: '30px', md: '36px'}, lineHeight:{ xs: '44px'} }}>
              We'd love to hear from you
            </Typography>
            {/*<Typography variant="subtitle2" sx={{ textAlign:{ xs: 'center'}, color: '#FFF' }}>Connect with our friendly team</Typography>*/}
          </Grid>
          <Grid item xs={12} md={5} lg={6} sx={{textAlign:'center'}}>
            <Box sx={{width:'100%', maxWidth: '494px', margin: 'auto'}}>
              <img width='100%' src="https://storage.googleapis.com/prod-website-images-rlink/support.png" alt="Contact our team at support@recognitionlink.com" />
            </Box>
          </Grid>
          <Grid item xs={12} md={7} lg={6} sx={{textAlign:'left', padding: {xs: 0, md: '0 20px'} }}>
            <Typography variant="subtitle2" sx={{ textAlign:{ xs: 'center', md:'left'}, color: '#FFF', fontSize: {xs: '20px', md: '16px', lg: '20px'}, lineHeight:{xs: '30px', md: '24px', lg:'30px'}  }}>For support inquiries, please reach out to us at <a style={{color:'#9E77ED'}} href="mailto:support@recognitionlink.com">support@recognitionlink.com</a></Typography>
            <Typography variant="subtitle2" sx={{ textAlign:{ xs: 'center', md:'left'}, color: '#FFF', fontSize: {xs: '20px', md: '16px', lg: '20px'}, lineHeight:{xs: '30px', md: '24px', lg:'30px'} , marginTop:{xs: '20px'} }}>Our team is dedicated to providing timely assistance and resolving any issues you may encounter.
              We value your feedback and strive to ensure your experience with RecognitionLink is seamless and satisfying.</Typography>
            <Box sx={{background: '#FFF',display: 'flex', marginTop: '40px',
              borderRadius:'20px',
              padding: '30px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: '10px' }}>
              <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="3" y="3" width="40" height="40" rx="20" fill="#F4EBFF"/>
                <rect x="3" y="3" width="40" height="40" rx="20" stroke="#F9F5FF" strokeWidth="6"/>
                <path d="M31.3333 17.9997C31.3333 17.083 30.5833 16.333 29.6667 16.333H16.3333C15.4167 16.333 14.6667 17.083 14.6667 17.9997M31.3333 17.9997V27.9997C31.3333 28.9163 30.5833 29.6663 29.6667 29.6663H16.3333C15.4167 29.6663 14.6667 28.9163 14.6667 27.9997V17.9997M31.3333 17.9997L23 23.833L14.6667 17.9997" stroke="#7F56D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <Typography variant="subtitle1">What to include in your email:</Typography>
              <Typography variant="body2">If you need help with a specific card, please include the sharing link or the email you used to create it.</Typography>
              <Typography variant="body1" sx={{fontWeight: 700, marginTop: '50px'}}>Thank you for choosing RecognitionLink. We look forward to assisting you!</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: '100%',
        backgroundImage: `url(https://storage.googleapis.com/prod-website-images-rlink/blue-vector.svg), url(https://storage.googleapis.com/prod-website-images-rlink/red-vector.svg)`,
        backgroundPosition: 'top right, center  left',
        backgroundRepeat: 'no-repeat'}}>
        <Grid container maxWidth="xl" sx={{margin: '0 auto',padding: { xs: '64px 30px 40px 30px', sm: '64px 30px 60px 30px'}}} spacing={0}>
          <Grid item xs={12} md={4}>
            <Typography variant="body1" sx={{ textAlign:{ xs: 'left'}, color:'#6941C6', fontWeight: 600 }}> Support</Typography>
            <Typography variant="h2" sx={{fontSize: '36px', lineHeight:'44px'}}>FAQs</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box sx={{marginTop: {xs:'40px', md:0}}}>
              <FAQ />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
    )
}