import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {useState} from "react";
import Button from "@mui/material/Button";
import Divider from "../../../components/divider/Divider";
import {useUpdateCardMutation} from "../../../slices/api/cardApiSlice";
import InfoSnackbar from "../../../components/infoSnackbar/InfoSnackbar";
import Chip from "@mui/material/Chip";
import {useGetMessageQuery, useGetTotalNumberOfMessagesQuery} from "../../../slices/api/apiSlice";

export default function CardSettingsDetails({card, closeDialog}) {
  const [recipientName, setRecipientName] = useState(card.recipients[0].fullName);
  const [sender, setSender] = useState(card.creatorName);
  const [title, setTitle] = useState(card.title);
  const [error, setError] = useState('');
  const [updateCard, {isLoading: isCardUpdating, isError:isCardUpdateError }] = useUpdateCardMutation();

  const {
    data: totalMessages,
    isLoading: isLoadingTotal,
    isSuccess: isSuccessTotal,
    isError: isTotalError,
    error: totalError,
  } = useGetTotalNumberOfMessagesQuery(card.id)

  async function handleSave() {
    if(recipientName === '') {
      return setError("Recipient's name is required");
    }

    if(title === '') {
      return setError('A title for the card is required');
    }

    if(sender === '') {
      return setError('We need the name for the sender');
    }

    try {
      const cardUpdate = {
        ...card,
        title,
        creatorName: sender,
        recipients: [{
          fullName: recipientName,
          email: card.recipients[0].email,
        }]
      }
      await updateCard(cardUpdate).unwrap();
      closeDialog()
    } catch (e) {
      if (e.originalStatus === 401) {
        setError('Not authorized to update the card. Login or register.')
      } else if (e.originalStatus === 403) {
        setError('Not authorized to update the card. Login or register.')
      } else if(e.originalStatus === 404) {
        setError('Card not found')
      } else {
        setError(`Oops, something went wrong on our end... Please try again`)
      }
    }
  }

  function updateRecipientName (e) {
    if(e.target.value.length >= 80) {
      setError(`Recipient's name can have up to 80 characters`);
      return setRecipientName(e.target.value.substring(0,80));
    }
    setRecipientName(e.target.value)
  }

  function updateCardTitle(e) {
    if(e.target.value.length >= 120) {
      setError(`Card title can have up to 120 characters`);
      return setTitle(e.target.value.substring(0,120));
    }
    setTitle(e.target.value)
  }

  function updateSenderName(e) {
    if(e.target.value.length >= 80) {
      setError(`Sender's name can have up to 80 characters`);
      return setSender(e.target.value.substring(0,80));
    }
    setSender(e.target.value);
  }

  return (
   <>
     {error && <InfoSnackbar onClick={() => setError('')} message={error} severity="error" callback={() => setError('')} /> }
     <Grid container>
       <Grid item xs={12} md={4}><Typography variant="body1" sx={{fontWeight: 500, paddingTop: '14px'}}>Recipient's name</Typography></Grid>
       <Grid item xs={12} md={8}>
         <TextField
           margin="normal"
           required
           fullWidth
           id="recipientName"
           name="recipientName"
           onChange={updateRecipientName}
           value={recipientName}
           sx={{ marginBottom: {xs: '24px'} }}
         />
       </Grid>
       <Grid item xs={12} md={4}><Typography variant="body1" sx={{fontWeight: 500,  paddingTop: '14px'}}>Group Card title</Typography></Grid>
       <Grid item xs={12} md={8}>
         <TextField
           margin="normal"
           required
           fullWidth
           id="title"
           name="title"
           onChange={updateCardTitle}
           value={title}
           sx={{ marginBottom: {xs: '24px'} }}
         />
       </Grid>
       <Grid item xs={12} md={4}><Typography variant="body1" sx={{fontWeight: 500,  paddingTop: '14px'}}>Sender or Team's name</Typography></Grid>
       <Grid item xs={12} md={8}>
         <TextField
           margin="normal"
           required
           fullWidth
           id="sender"
           name="sender"
           onChange={updateSenderName}
           value={sender}
           sx={{ marginBottom: {xs: '24px'} }}
         />
       </Grid>


       <Grid item xs={12} md={4}><Typography variant="body1" sx={{fontWeight: 500,  paddingTop: '14px'}}>Messages</Typography></Grid>
       <Grid item xs={12} md={8}>
         {isSuccessTotal && <Chip sx={{marginTop: '10px'}} label={`${totalMessages.total}/${card?.maxMsg}`}  />}
       </Grid>
       <Grid item xs={12} sx={{ margin: { xs: '0px 0 16px 0', md: '15px 0 16px 0'}  }}>
         <Divider />
       </Grid>
       <Grid item xs={12}>
         <Box sx={{ display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, gap: '10px', justifyContent: 'flex-end' }}>
           <Button disabled={isCardUpdating} onClick={closeDialog} variant="outlined" color="secondary" sx={{ maxWidth: { xs: '100%', sm:'200px'} }}>Cancel</Button>
           <Button onClick={handleSave} disabled={isCardUpdating} variant="contained" sx={{ maxWidth: { xs: '100%', sm:'200px'} }}>Save</Button>
         </Box>
       </Grid>
     </Grid>
   </>
  )
}