import { apiSlice } from "./apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getUser: builder.query({
      query: () => `/users`,
      providesTags:['User'],
    }),
    updateUser: builder.mutation({
      query: (body) => {
        return {
          url:`/users`,
          method: 'PATCH',
          body:{...body}
        }
      },
      invalidatesTags:['User'],
    }),
    getSubscriptions: builder.query({
      query: () => `/subscriptions`,
      providesTags:['Subscriptions'],
    }),
    buySubscription: builder.mutation({
      query: (body) => {
        return {
          url: '/subscriptions/buy/unlimited-cards',
          method: 'POST',
          body: {...body}
        }
      },
      invalidatesTags:['Subscriptions'],
    }),
    upgradeCard: builder.mutation({
      query: (body) => {
        return {
          url: '/subscriptions/upgrade/premium-card',
          method: 'POST',
          body: {...body}
        }
      },
      invalidatesTags:['Subscriptions'],
    }),
  })
})

export const { useGetUserQuery, useUpdateUserMutation, useGetSubscriptionsQuery, useBuySubscriptionMutation, useUpgradeCardMutation  } = userApiSlice;