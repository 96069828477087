import Grid from "@mui/material/Grid";
import PurplePill from "../../../components/purplePill/PurplePill";
import TitleText from "../../../components/titleText/TitleText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {linkPaths} from "../../../utils/linkPaths";
import {useDispatch} from "react-redux";
import {resetCardState} from "../../../slices/cardSlice";
import {aiSamples} from "../../../utils/aiDesignsSamples";
import React from "react";
import ReactPlayer from "react-player";

export default function AIDesigns(){
  const navigate = useNavigate();
  const dispatch = useDispatch()

  function goCreateCard() {
    dispatch(resetCardState())
    navigate(linkPaths.create.path)
  }
  return (
    <Box sx={{
      paddingBottom: '90px',
      width: '100%',
      background: 'url(https://storage.googleapis.com/prod-website-images-rlink/occasions_top_right.svg), url(https://storage.googleapis.com/prod-website-images-rlink/occasions_bottom_left.svg), #FFF',
      backgroundRepeat:'no-repeat',
      backgroundPosition:'top right,bottom left',
    }}>
      <Grid container maxWidth="xl" sx={{margin:'0px auto 0 auto'}}>
        <Grid container maxWidth="xl" sx={{padding: { xs: '40px 17px'} }}>
          <Grid item xs={12}>
            <PurplePill maxWidth='200px'>One-of-a-kind Designs</PurplePill>
            <TitleText align="center">Using AI to make unique designs   </TitleText>
            <Typography variant="subtitle2" sx={{ textAlign:{ xs: 'center'} }}>Describe your vision and let our AI bring it to life. 🎨✨</Typography>
          </Grid>
        </Grid>
        <Grid maxWidth="lg" container sx={{ padding: { xs: '15x 17px'}, textAlign: 'center', marginTop:0, margin: '0 auto' }}>
          <Grid item xs={12} sx={{ padding: '10px'}} >
            {/*<Box sx={{ margin:'16px 0', display: 'flex', flexDirection: 'row', flexWrap: 'no-wrap', overflow: 'scroll', gap: '20px'}}>*/}
            {/*  {aiSamples.map(sample => {*/}
            {/*    return <Box key={sample.id} sx={{minWidth:{xs:'350px'} }}><img src={sample.url} alt={sample.description} width="100%" /> </Box>*/}
            {/*  })}*/}
            {/*</Box>*/}
            <Box sx={{display:'flex', justifyContent:'center', marginBottom: '30px'}}>
              <ReactPlayer
                url="https://youtu.be/flvjYZGpgPM"
                controls={true}
                light={false}
                playing={false}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}  sx={{ padding: '10px', marginBottom:{xs:'20px', }  }}>
            <Typography variant="subtitle1">Extreme Personalization</Typography>
            <Typography variant="body2">Each design is tailored to the recipient’s likes, making every card special and heartfelt.</Typography>
          </Grid>
          <Grid item xs={12} sm={6}  sx={{ padding: '10px', marginBottom:{xs:'20px', }  }}>
            <Typography variant="subtitle1">Uniqueness Guaranteed</Typography>
            <Typography variant="body2">No more cookie-cutter templates. Every card is unique, ensuring your message stands out.</Typography>
          </Grid>
          <Grid item xs={12} sm={6}  sx={{ padding: '10px', marginBottom:{xs:'20px', }  }}>
            <Typography variant="subtitle1">Super Fast and Easy</Typography>
            <Typography variant="body2">Simply describe what you want, and our AI takes care of the rest. In just a few clicks, you’ll have a masterpiece ready to go.</Typography>
          </Grid>
          <Grid item xs={12} sm={6}  sx={{ padding: '10px', marginBottom:{xs:'20px', }  }}>
            <Typography variant="subtitle1">Effortless Creativity</Typography>
            <Typography variant="body2">Perfect for birthdays, celebrations, or just showing appreciation, our AI makes it easy to create something that feels truly tailored to the person receiving it.</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sx={{ padding: { xs: '30px 34px 0 34px'}, textAlign: 'center'}}>
            <Button id="create-card-hiw-button" onClick={goCreateCard}
                    sx={{ maxWidth: { xs: '100%', sm: '200px'}, margin: '0', fontWeight: 700}}
                    variant="contained" color="primary">Create Group Card</Button></Grid>
        </Grid>
      </Grid>
    </Box>
  )
}