import React from "react";
import Box  from "@mui/material/Box";
import Typography  from "@mui/material/Typography";
import Button  from "@mui/material/Button";
import { calendarIcon } from "../../../svgIcons/icons";

export default function EmptyRemindersList({createReminderAction}) {
  return(
    <React.Fragment>
      <Box component='div' className='emptyTableContainer' sx={{ textAlign: 'center'}}>
        {calendarIcon}
        <Typography variant="body2">No reminders added yet. </Typography>
        <Button onClick={createReminderAction} sx={{textTransform: 'capitalize'}}>Create Reminder</Button>
     </Box>
    </React.Fragment>
  )
}