import Box from "@mui/material/Box";
import ImagePreview from "./ImagePreview";

export default function ImagesPreview({images = [], removeFromState}){
  if(images.length === 0) return null;
  return(
    <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: '0 20px', gap: { xs: '10px', sm: '14px'}, justifyContent:{ xs: 'space-between', sm: images.length === 4 ? 'space-between':'flex-start'  }  }}>
      { images.map(image => {
        return <ImagePreview key={image.fileUrl} image={image} removeFromState={removeFromState} />
      })}
    </Box>)
}


