import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import Slide from "@mui/material/Slide";
import {getBackgroundImage} from "../../utils/functions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const IntroAnimation = ({card}) => {
  const [showAnimation, setShowAnimation] = useState(card.template.showAnimation)
  useEffect(() => {
    setTimeout(() => {
      setShowAnimation(false)
    }, 3000)

  }, []);



  return <Dialog
    open={showAnimation} fullScreen={true}
    TransitionComponent={Transition} >
    <Box sx={{
      width: '100%',
      height: '100%',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'middle',
      backgroundColor: card.template.cardBackgroundColor,
      backgroundImage: `url(${getBackgroundImage(card)})`,
      backgroundPosition: 'center',
      backgroundSize: card.template.cardBackgroundImageFullSize ? 'cover': '400px',
      backgroundRepeat: card.template.cardBackgroundImageFullSize ? 'no-repeat' : 'repeat',
    }}>
      <Box sx={{ height:'100%', width:'100%', backgroundColor: 'rgba(255,255,255,0.9)'}}>
        <Box sx={{ height: {xs: '100%', sm: '100%'}, margin: '0 auto', maxWidth: '1000px' }}>
          <dotlottie-player style={{width: '100%'}} src={card.template.animation} background="transparent" speed="1"  loop autoplay></dotlottie-player>
        </Box>
      </Box>
    </Box>
  </Dialog>;


}
export default IntroAnimation;