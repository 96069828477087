import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useGetTemplatesQuery} from "../../slices/api/apiSlice";
import TemplatePreview from "../templatePreview/TemplatePreview";
import Button from "@mui/material/Button";
import {linkPaths} from "../../utils/linkPaths";
import ViewTemplatesWithSubcategories from "./ViewTemplatesWithSubcategories";
import UseAiButton from "../useAiButton/UseAiButton";

export default function ViewTemplates({category}) {
  const navigate = useNavigate();

  const {
    data: templates,
    isLoading: getTemplatesIsLoading,
    isSuccess: getTemplatesIsSuccess,
    isError: getTemplatesIsError,
    error: getTemplatesError,
  } = useGetTemplatesQuery(category.id)


  function navigateToAllCategories() {
   return navigate(linkPaths.create.path);
  }

  let linkText = `${category.title} cards`;
  if(category.childIds && category.childIds.length > 0) {
    linkText = `${category.title}`;
  }


  return (
    <Box  sx={{marginTop: {xs: '10px', md:'20px'}  }}>
      <Box component="div"
           sx={{
             display:'flex',
             justifyContent: 'space-between',
             flexDirection: 'row',
             padding: '0',
             alignItems: 'center',
           }}>
        <Typography
          variant='h4'
          sx={{
            marginBottom: {xs: '5px', md: '10px', xl: '15px'},
            // fontSize: {xs: '12px', md: '20px'},
            // lineHeight: { xs: '16px', md: '24px'},
            // maxWidth: {
            //   xs: '150px',
            //   sm: '400px'
            // }
        }}
        >
          {linkText }
        </Typography>
      </Box>
      { getTemplatesIsLoading &&
        <Typography variant="body2">Getting all the templates for {category.title}</Typography>
      }
      { getTemplatesIsSuccess && !category.isPrimary &&
        <>
          <Box
            component="div"
            sx={{ display: 'flex', flexWrap: 'wrap', gap: {xs: '8px', md:'12px'}, padding: '0 5px'}}>
            <UseAiButton text="Create with AI" />
            <UseAiButton text="Upload your design" />
            {templates.map(template => {
              return <TemplatePreview key={template.id} template={template} category={category}/>
            })}
          </Box>
        </>
      }
      { getTemplatesIsSuccess && category.isPrimary && category.childIds.length === 0 &&
        <Box
          component="div"
          sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: {xs: 'space-between', md: 'flex-start'}, gap: {xs: '8px', md:'12px'}, padding: '0 5px'}}>
          <UseAiButton text="Create with AI" />
          <UseAiButton text="Upload your design" />
          {templates.map(template => {
            return <TemplatePreview key={template.id} template={template} category={category}/>
          })}
        </Box>
      }
      { getTemplatesIsSuccess && category.isPrimary && category.childIds.length > 0 &&
        <ViewTemplatesWithSubcategories templates={templates} categorySelected={category} />
      }
      <Box sx={{ textAlign: 'center'}}>
        <Button
          onClick={navigateToAllCategories}
          variant='contained'
          sx={{
            width:{
              xs: '300px'
            },
            margin:'20px auto 20px auto'
        }}
        > View all the Categories</Button>
      </Box>
    </Box>
  )
}