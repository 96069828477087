import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {useState} from "react";
import InfoSnackbar from "../../../components/infoSnackbar/InfoSnackbar";
import {useValidateCardPasswordMutation} from "../../../slices/api/cardApiSlice";
import {useDispatch} from "react-redux";
import {setViewCardToken} from "../../../slices/viewCardTokenSlice";
import {useNavigate} from "react-router-dom";
import {getCurrentRelativePath} from "../../../utils/functions";

export default function RequireCardPassword({card = {}}) {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [validateCardPassword, {isLoading}] = useValidateCardPasswordMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function updatePassword(e){
    e.preventDefault();
    setPassword(e.target.value)
  }

  async function submitPassword(e) {
    e.preventDefault();
    if(!password) {
      return setError('Password required')
    }
    const cardId = card.id;

    if(!cardId) {
      return setError('Card id is required')
    }

    try {
      const validationToken = await validateCardPassword({cardId, password}).unwrap();
      dispatch(setViewCardToken(validationToken))
      const path = getCurrentRelativePath();
      navigate(`${path}?password=${password}`)
    }catch (e) {
      const message = e?.data?.error || 'Error validating password, please try again';
      setError(message)
    }
  }

  return (
    <>
      {error && <InfoSnackbar callback={() => setError('')} message={error} severity="error"/> }
      <Box sx={{display:'flex', alignItems:'center', paddingTop:'calc(50vh - 354px)' }}>
        <Paper sx={{margin: '0 auto', padding: '20px'}}>
          <Box sx={{ width: {xs: '120px' }, maxWidth: '200px', margin: '0 auto'}}>
            <img
              width="100%"
              src="https://storage.googleapis.com/prod-website-images-rlink/lock-200.png"
              alt="Lock, enter password to view card" />
          </Box>
          <Typography
            variant="body2"
            sx={{  paddingTop: '13px',lineHeight: '20px', fontSize: '14px', color: 'rgba(52, 64, 84, 1)', fontWeight: 500}}
          >Enter password to view card</Typography>
          <TextField
            value={password}
            onChange={updatePassword}
            margin="normal" required fullWidth id="password"  name="password"
          />
          <Button disabled={isLoading} onClick={submitPassword} variant="contained">Submit</Button>
        </Paper>
      </Box>
    </>
  )
}