export function addToArray(array, newItem) {
  if (!array.some((item) => JSON.stringify(item) === JSON.stringify(newItem))) {
    return [...array, newItem];
  }
  return array;
}

export function removeFromArray(array, itemToRemove) {
  const index = array.findIndex((item) => JSON.stringify(item) === JSON.stringify(itemToRemove));
  if (index !== -1) {
    const newArray = [...array];
    newArray.splice(index, 1);
    return newArray;
  }
  return array;
}

export function getImageFileNameFromURL(url) {
  if(url === null) return null;
  const urlParts = url.split('/');
  const fileNameWithExtension = urlParts[urlParts.length - 1];
  return fileNameWithExtension;
}

export function messageIntact(message) {
  return  message.creatorName === '' && message.message === '';
}

export function isValidURL(url) {
  // Regular expression for a simple URL pattern
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

  // Test the given string against the regular expression
  return urlRegex.test(url);
}

export function isValidEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function extractEmails(inputString) {
  // Split the input string based on commas, new lines, or spaces
  const emailsArray = inputString.split(/[,\s\n]+/);

  // Filter out any empty strings
  const filteredEmails = emailsArray.filter(email => email.trim() !== '');

  return filteredEmails;
}

export function emailsToString(emailsArray) {
  // Use the join method to concatenate the emails with commas
  const emailsString = emailsArray.join(', ');

  return emailsString;
}

export function subtractStrings(stringA, stringB) {
  // Escape special characters in String B to ensure it's treated as a literal in the regular expression
  const escapedStringB = stringB.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  // Create a regular expression to match String B and an optional comma
  const regex = new RegExp(`${escapedStringB},?`, 'g');

  // Use replace to create a new string without modifying stringA
  const result = stringA.replace(regex, '');

  return result;
}

export function truncateString(inputString) {
  if (inputString.length <= 20) {
    // If the string is 12 characters or shorter, return it as is
    return inputString;
  } else {
    // If the string is longer than 12 characters, truncate it and add three dots
    return inputString.slice(0, 12) + '...';
  }
}

export function checkUrlForCreateCard() {
  const currentUrl = window.location.href;
  if(currentUrl.includes('create/card')) {
    return true
  }
  if(currentUrl.includes('/card/')) {
    return true;
  }
  return false;
}

export function hasActiveSubscription(subs = []) {
  const result = subs.some(sub => sub.status === 'active')
  return result
}

export function getCurrentDomain() {
  return `${window.location.protocol}//${window.location.host}`;
}

export function checkUrlForDemo() {
  const currentUrl = window.location.href;
  return currentUrl.includes('/demo');
}

export function checkUrlForAsRecipient() {
  const currentUrl = window.location.href;
  return currentUrl.includes('/asrecipient');
}

export function checkUrlForMyCard() {
  const currentUrl = window.location.href;
  return currentUrl.includes('/mycard');
}

export function isPricingPage() {
  const currentUrl = window.location.href;
  return currentUrl.includes('/pricing');
}

export function isUpgradePage() {
  const currentUrl = window.location.href;
  return currentUrl.includes('/upgrade/card');
}

export function isCheckoutPage() {
  const currentUrl = window.location.href;
  if(currentUrl.includes('/checkout')) return true
  return currentUrl.includes('/thanks');

}

export function isCardCreationPage() {
  const currentUrl = window.location.href;
  return currentUrl.includes('create/card');
}

export function showPremiumFeature(card = { tier: 'FREE_PLAN', canEdit: false }){
  const currentUrl = window.location.href;
  if(currentUrl.includes('/asrecipient')) {
    // return card.tier !== 'FREE_PLAN';
    return true;

  } else if(currentUrl.includes('/mycard')) {
    return card.tier !== 'FREE_PLAN';
  } else {
    if(card?.canEdit) {
      return true
    }
    return card.tier !== 'FREE_PLAN';
  }
}

export function getBackgroundImage(card) {
  if(card.template.isCustomBackgroundImage) {

    if(showPremiumFeature(card)) {
      return card.template.cardBackgroundImage
    } else {
      return 'https://storage.googleapis.com/dev-rl-templates/1702750024141-idn0qxv7.jpeg';
    }
  } else {
    return card.template.cardBackgroundImage
  }
}

export function getUrlParamValue(paramName, location) {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(paramName);
}

export function getCurrentRelativePath() {
  return window.location.pathname;
}

export function showCard(card) {
  if(card.requirePassword && card.tier !== 'FREE_PLAN') {
    return false
  }
  return true
}


export function validateScheduleFields(email, date) {
  // Date validation
  const inputDate = new Date(date);
  const currentDate = new Date();
  const oneHourLater = new Date(currentDate.getTime() + (1 * 60 * 60 * 1000)); // Current time + 1 hour

  // Check if email is valid
  if (!isValidEmail(email)) {
    return {
      valid: false,
      error: 'Please enter a valid email address for the recipient.'
    }
  }

  // Check if date is valid and at least 1 hour ahead of the current time
  if (isNaN(inputDate.getTime()) || inputDate < oneHourLater) {
    return {
      valid: false,
      error: 'Please enter a valid date, and at least 1 hour ahead.'
    }
  }

  return {
    valid: true,
    message: 'Schedule can be created'
  };
}

export function hasPremiumFeatures(card) {
  if(card?.template?.animation && card?.template?.showAnimation) return true;
  if(card?.template?.avatarImage) return true;
  if(card?.template?.isCustomBackgroundImage) return true;
  if(card.password) return true;
  return false;
}

export function listCurrentPremiumFeatures(card) {
  let messages = []
  if(card?.template?.animation && card?.template?.showAnimation) { messages.push('Intro animation')}
  if(card?.template?.avatarImage) { messages.push('Avatar')}
  if(card?.template?.isCustomBackgroundImage) { messages.push('Custom background')}
  if(card?.password) { messages.push('Password protection')}
  return concatenateWithCommas(messages)
}


export function concatenateWithCommas(stringsArray) {
  // Check if the array has only one element
  if (stringsArray.length === 1) {
    // If there's only one element, return it as is
    return stringsArray[0];
  } else {
    // If there are multiple elements, join them with commas and spaces
    return stringsArray.join(', ');
  }
}

export function findParentId(data, id) {
  for (let i = 0; i < data.length; i++) {
    const category = data[i];
    if (category.subcategories) {
      for (let j = 0; j < category.subcategories.length; j++) {
        const subcategory = category.subcategories[j];
        if (subcategory.id === id) {
          return category.id;
        }
      }
    }
  }
  return null; // Return null if no parent found
}

export function findParentObject(arr = [], id) {
  for (const obj of arr) {
    if (obj.id === id) {
      return obj;
    }
    if (obj.subcategories) {
      const parent = findParentObject(obj.subcategories, id);
      if (parent) {
        return obj;
      }
    }
  }
  return null;
}

export function flattenArray(arr) {
  if(!arr) return [];
  return arr.reduce((acc, obj) => {
    acc.push(obj);
    if (obj.subcategories) {
      const subcategories = flattenArray(obj.subcategories);
      acc.push(...subcategories);
    }
    return acc;
  }, []);
}

export function findObjectById(arr, id) {
  for (const obj of arr) {
    if (obj.id === id) {
      return obj;
    }
  }
  return null; // If no object with the given id is found
}


export function removeQueryString(urlString) {
  try {
    const url = new URL(urlString);
    url.search = ''; // Resetting the query string part
    return url.toString();
  } catch (error) {
    console.error("Invalid URL:", urlString);
    return null;
  }
}

export async function compressImage({file, maxWidth, maxHeight}) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function(event) {
      const img = new Image();

      img.onload = function() {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = maxWidth; // Number
        const MAX_HEIGHT = maxHeight; // Number
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          const compressedFile = new File([blob], file.name, { type: 'image/jpeg', lastModified: Date.now() });
          resolve(compressedFile);
        }, 'image/jpeg', 0.7); // Adjust the quality here (0.7 is 70% quality)
      }

      img.src = event.target.result;
    }

    reader.readAsDataURL(file);
  });
}

export function associateTemplatesWithCategories(templates, categories) {
  if(!categories) return [];
  if(!templates) return [];
  // Clone categories and initialize the templates array inside each category and subcategory
  const clonedCategories = categories.map(category => {
    // Ensure subcategories is an array before cloning
    const subcategories = Array.isArray(category.subcategories) ? category.subcategories.map(subcategory => ({
      ...subcategory,
      templates: []
    })) : [];

    return {
      ...category,
      templates: [],
      subcategories // Use the validated and mapped subcategories
    };
  });

  // Associate each template with the corresponding category or subcategory
  templates.forEach(template => {
    template.categories.forEach(categoryId => {
      let found = false;
      // Check top-level categories
      clonedCategories.forEach(category => {
        if (category.id === categoryId) {
          category.templates.push(template);
          found = true;
        }
        // Check subcategories
        category.subcategories.forEach(subcategory => {
          if (subcategory.id === categoryId) {
            subcategory.templates.push(template);
            found = true;
          }
        });
      });
      if (!found) {
        // console.warn(`Template with id ${template.id} has a category id ${categoryId} which does not exist in the categories list.`);
      }
    });
  });

  return clonedCategories;
}

export function sortCategoriesDeepCopy(categories) {
  // Create a deep copy of the categories array to avoid modifying the original
  let copiedCategories = JSON.parse(JSON.stringify(categories));

  // Sort the copied categories array by the title field
  copiedCategories.sort((a, b) => a.title.localeCompare(b.title));

  // Iterate over each copied category to sort subcategories if they exist
  copiedCategories.forEach(category => {
    if (category.subcategories && category.subcategories.length) {
      category.subcategories = sortCategoriesDeepCopy(category.subcategories);
    }
  });

  return copiedCategories;
}


export function associateTemplatesWithSubcategories({templates,categories, categorySelected}) {
  for(let i = 0; i <= categories.length; i++) {
    if(categories[i]?.id === categorySelected?.id) {
      return mapTemplatesToSubcategories(categories[i], templates)
    }
  }
}

function mapTemplatesToSubcategories(category, templates) {
  // Create a deep copy of the category to prevent mutations to the original object
  let updatedCategory = JSON.parse(JSON.stringify(category));

  // Ensure each subcategory has a 'templates' array initialized
  updatedCategory.subcategories.forEach(subcategory => {
    subcategory.templates = [];
  });

  // Map templates to the appropriate subcategories based on IDs
  templates.forEach(template => {
    template.categories.forEach(categoryId => {
      // Find the subcategory with the matching ID and add the template to it
      let targetSubcategory = updatedCategory.subcategories.find(sc => sc.id === categoryId);
      if (targetSubcategory) {
        targetSubcategory.templates.push(template);
      }
    });
  });

  return updatedCategory;
}

export  function getRelevantUpgradePlans(plans = [], card, category) {
  const results = [];

  if (category === "single") {
    switch (card?.tier) {
      case "FREE_PLAN":
        // Return the items with the ids: "PREMIUM_50", "PREMIUM", and "ADD_100_MESSAGES"
        results.push(...plans.filter(plan =>
          plan.id === "PREMIUM_50" || plan.id === "PREMIUM"));
        break;
      case "PREMIUM_50":
        // Return the items with the ids: "UPGRADE_TO_PREMIUM" and "ADD_100_MESSAGES"
        results.push(...plans.filter(plan =>
          plan.id === "UPGRADE_TO_PREMIUM" || plan.id === "ADD_100_MESSAGES"));
        break;
      case "PREMIUM":
        // Check the field "maxMsg" inside the "card" object
        if (card.maxMsg < 101) {
          results.push(...plans.filter(plan => plan.id === "ADD_100_MESSAGES"));
        } // if "maxMsg" value is bigger than 100, return an empty array (by default)
        break;
    }
  } else if( category === "subscription") {
    results.push(...plans.filter(plan => plan.category === "subscription"))
  }

  return results;
}

export function getRelevantPurchasePlans(plans = [], card, category) {
  // Extract the tier from the card object
  const { tier } = card;

  // Define the mapping of card tiers to relevant plan IDs based on the "single" category
  const tierMapping = {
    "FREE_PLAN": ["FREE_PLAN", "PREMIUM_50", "PREMIUM"]
  };

  // Check if the category is "single" and the card tier matches the defined tiers
  if (category === "single" && tierMapping[tier]) {
    // Filter plans to include only those with IDs specified in the mapping for the current tier
    return plans.filter(plan => tierMapping[tier].includes(plan.id));
  }

  if(category === 'subscription') {
    return plans.filter(plan => plan.category === 'subscription')
  }

  // Return an empty array if no valid conditions are met
  return [];
}

export const isDevelopmentPage = () => {
  const currentURL = window.location.href;
  if (currentURL.includes("dev-therecognitionlink") || currentURL.includes("localhost")) {
    return true;
  }
  return false;
}

export function updateMessagePosition(messages, messageId, action) {
  //Need to modify function so it clones original array instead of modifying it

  const index = messages.findIndex(message => message.id === messageId);

  if (index === -1) {
    return messages; // If the message is not found, return the original array
  }

  switch (action) {
    case 'move-one-up':
      if (index > 0) {
        [messages[index].position, messages[index - 1].position] =
          [messages[index - 1].position, messages[index].position];
      }
      break;

    case 'move-one-down':
      if (index < messages.length - 1) {
        [messages[index].position, messages[index + 1].position] =
          [messages[index + 1].position, messages[index].position];
      }
      break;

    case 'move-to-top':
      const highestPosition = Math.max(...messages.map(message => message.position));
      messages[index].position = highestPosition + 1;
      break;

    case 'move-to-bottom':
      const lowestPosition = Math.min(...messages.map(message => message.position));
      messages[index].position = lowestPosition - 1;
      break;

    default:
      throw new Error(`Invalid action: ${action}`);
  }

  return messages;
}

export function getUpdatedMessagePositions(messages, messageId, action) {
  const index = messages.findIndex(message => message.id === messageId);

  if (index === -1) {
    return []; // If the message is not found, return an empty array
  }

  let updatedMessages = [];

  switch (action) {
    case 'move-one-up':
      if (index > 0) {
        updatedMessages.push(
          { messageId: messages[index].id, newPosition: messages[index - 1].position },
          { messageId: messages[index - 1].id, newPosition: messages[index].position }
        );
      }
      break;

    case 'move-one-down':
      if (index < messages.length - 1) {
        updatedMessages.push(
          { messageId: messages[index].id, newPosition: messages[index + 1].position },
          { messageId: messages[index + 1].id, newPosition: messages[index].position }
        );
      }
      break;

    case 'move-to-top':
      const highestPosition = Math.max(...messages.map(message => message.position));
      if (messages[index].position !== highestPosition) {
        updatedMessages.push({ messageId: messages[index].id, newPosition: highestPosition + 1 });
      }
      break;

    case 'move-to-bottom':
      const lowestPosition = Math.min(...messages.map(message => message.position));
      if (messages[index].position !== lowestPosition) {
        updatedMessages.push({ messageId: messages[index].id, newPosition: lowestPosition - 1 });
      }
      break;

    default:
      throw new Error(`Invalid action: ${action}`);
  }

  return updatedMessages;
}


export function isCardPage(url) {
  // Extract the pathname from the current browser URL
  const pathname = window.location.pathname;

  // Define a regex pattern to match the required URL patterns
  const pattern = /^\/card\/[a-f0-9]{24}(\/(asrecipient|mycard))?$/;

  // Test if the pathname matches the pattern
  return pattern.test(pathname);
}

export function isDefaultBackground(backgroundImage) {
  return backgroundImage === 'https://storage.googleapis.com/prod-rl-templates/default/default_background.png'
}

// Here's a JavaScript function that checks the
// readability of text color against a background color and adjusts the text color if necessary:'
// Example usage
// var bgColor = "#ffffff";
// var textColor = "#cccccc";
// console.log(ensureReadableTextColor(bgColor, textColor)); // Output: #000000 (because white text on white background is not readable)

export function ensureReadableTextColor(bgColor, textColor) {
  // Helper function to convert hex color to RGB
  function hexToRgb(hex) {
    if (hex.charAt(0) === '#') hex = hex.substr(1);
    if (hex.length === 3) {
      hex = hex.split('').map(function (hex) {
        return hex + hex;
      }).join('');
    }
    var int = parseInt(hex, 16);
    return [int >> 16, (int >> 8) & 255, int & 255];
  }

  // Helper function to parse RGB color string
  function parseRgb(rgb) {
    var result = rgb.match(/\d+/g);
    return result ? result.map(Number) : null;
  }

  // Helper function to get luminance of an RGB color
  function getLuminance(rgb) {
    var a = rgb.map(function (v) {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  // Helper function to calculate contrast ratio
  function getContrastRatio(lum1, lum2) {
    var brightest = Math.max(lum1, lum2);
    var darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
  }

  // Convert colors to RGB if in hex format
  var bgRgb = bgColor.startsWith('#') ? hexToRgb(bgColor) : parseRgb(bgColor);
  var textRgb = textColor.startsWith('#') ? hexToRgb(textColor) : parseRgb(textColor);

  if (!bgRgb || !textRgb) {
    throw new Error("Invalid color format");
  }

  var bgLuminance = getLuminance(bgRgb);
  var textLuminance = getLuminance(textRgb);

  // Calculate contrast ratio
  var contrastRatio = getContrastRatio(bgLuminance, textLuminance);

  // If contrast ratio is sufficient, return original text color
  if (contrastRatio >= 4.5) {
    return textColor;
  }

  // Otherwise, adjust text color to white or black
  var newTextColor = bgLuminance > 0.5 ? '#000000' : '#FFFFFF';
  return newTextColor;
}


export function getRecipientUrl() {
  const currentUrl = window.location.href;

  // Create a URL object for easier manipulation
  const url = new URL(currentUrl);

  // Extract the pathname part of the URL
  const pathname = url.pathname;

  // Use regex to match the pattern and extract the ID
  const match = pathname.match(/\/card\/([a-zA-Z0-9]+)/);
  if (match && match[1]) {
    const id = match[1];
    const newUrl = `${url.origin}/card/${id}/mycard`;
    return newUrl;
  }

  // If no match found, return null or handle the error as needed
  return null;
}

